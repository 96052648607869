import React from 'react';
import {quotesState} from "./QuotesState";
import './Quotes.css';

const Quotes = () => {
  return (
    <div className="container">
      {
        quotesState.map(item =>
          <>
            <div className="row" key={item.id}>
              <div className="col-md-8">
                <blockquote className="blockquote">
                  <p className="mb-3">
                    {item.quote}
                  </p>
                  <footer className="blockquote-footer">{item.author}</footer>
                </blockquote>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 quotes__bottomBorder"/>
            </div>
          </>
        )
        }
        </div>
        
        )
        ;
      };
      
      export default Quotes;
import React from 'react';
import armenian_temple_01 from '../../../images/volga-caspiy/armenian_temple_01.jpg';
import bash_01 from '../../../images/volga-caspiy/bash_01.jpg';
import bash_02 from '../../../images/volga-caspiy/bash_02.jpg';
import bash_03 from '../../../images/volga-caspiy/bash_03.jpg';
import beach_01 from '../../../images/volga-caspiy/beach_01.jpg';
import caspiy_01 from '../../../images/volga-caspiy/caspiy_01.jpg';
import castle_01 from '../../../images/volga-caspiy/castle_01.jpg';
import castle_02 from '../../../images/volga-caspiy/castle_02.jpg';
import castle_03 from '../../../images/volga-caspiy/castle_03.jpg';
import castle_04 from '../../../images/volga-caspiy/castle_04.jpg';
import chaihana_01 from '../../../images/volga-caspiy/chaihana_01.jpg';
import chaihana_02 from '../../../images/volga-caspiy/chaihana_02.jpg';
import chaihana_03 from '../../../images/volga-caspiy/chaihana_03.jpg';
import derbent_vorota_01 from '../../../images/volga-caspiy/derbent-vorota_01.jpg';
import derbent_01 from '../../../images/volga-caspiy/derbent_01.jpg';
import derbent_02 from '../../../images/volga-caspiy/derbent_02.jpg';
import derbent_03 from '../../../images/volga-caspiy/derbent_03.jpg';
import derbent_04 from '../../../images/volga-caspiy/derbent_04.jpg';
import elista_01 from '../../../images/volga-caspiy/elista_01.jpg';
import elista_02 from '../../../images/volga-caspiy/elista_02.jpg';
import embankment_01 from '../../../images/volga-caspiy/embankment_01.jpg';
import embankment_02 from '../../../images/volga-caspiy/embankment_02.jpg';
import forest_fire_01 from '../../../images/volga-caspiy/forest-fire_01.jpg';
import forest_fire_02 from '../../../images/volga-caspiy/forest-fire_02.jpg';
import gocatl_01 from '../../../images/volga-caspiy/gocatl_01.jpg';
import gocatl_02 from '../../../images/volga-caspiy/gocatl_02.jpg';
import history_01 from '../../../images/volga-caspiy/history_01.jpg';
import hunzah_01 from '../../../images/volga-caspiy/hunzah_01.jpg';
import hunzah_02 from '../../../images/volga-caspiy/hunzah_02.jpg';
import hunzah_03 from '../../../images/volga-caspiy/hunzah_03.jpg';
import hvalynsk_01 from '../../../images/volga-caspiy/hvalynsk_01.jpg';
import hvalynsk_02 from '../../../images/volga-caspiy/hvalynsk_02.jpg';
import hvalynsk_03 from '../../../images/volga-caspiy/hvalynsk_03.jpg';
import inzer_01 from '../../../images/volga-caspiy/inzer_01.jpg';
import inzer_02 from '../../../images/volga-caspiy/inzer_02.jpg';
import krutoi_kalmyk_01 from '../../../images/volga-caspiy/krutoi-kalmyk_01.jpg';
import leman_01 from '../../../images/volga-caspiy/leman_01.jpg';
import leman_02 from '../../../images/volga-caspiy/leman_02.jpg';
import magals_01 from '../../../images/volga-caspiy/magals_01.jpg';
import magals_02 from '../../../images/volga-caspiy/magals_02.jpg';
import magals_03 from '../../../images/volga-caspiy/magals_03.jpg';
import mahachkala_01 from '../../../images/volga-caspiy/mahachkala_01.jpg';
import mahachkala_02 from '../../../images/volga-caspiy/mahachkala_02.jpg';
import mahachkala_03 from '../../../images/volga-caspiy/mahachkala_03.jpg';
import mahachkala_04 from '../../../images/volga-caspiy/mahachkala_04.jpg';
import mamaev_kurgan_01 from '../../../images/volga-caspiy/mamaev-kurgan_01.jpg';
import mamaev_kurgan_02 from '../../../images/volga-caspiy/mamaev-kurgan_02.jpg';
import mamaev_kurgan_03 from '../../../images/volga-caspiy/mamaev-kurgan_03.jpg';
import modern_mosque from '../../../images/volga-caspiy/modern-mosque.jpg';
import mountains_01 from '../../../images/volga-caspiy/mountains_01.jpg';
import mountains_02 from '../../../images/volga-caspiy/mountains_02.jpg';
import mountains_03 from '../../../images/volga-caspiy/mountains_03.jpg';
import new_hurul_01 from '../../../images/volga-caspiy/new-hurul_01.jpg';
import new_hurul_02 from '../../../images/volga-caspiy/new-hurul_02.jpg';
import old_hurul_01 from '../../../images/volga-caspiy/old-hurul_01.jpg';
import old_hurul_02 from '../../../images/volga-caspiy/old-hurul_02.jpg';
import prival_01 from '../../../images/volga-caspiy/prival_01.jpg';
import rotonda_01 from '../../../images/volga-caspiy/rotonda_01.jpg';
import rotonda_02 from '../../../images/volga-caspiy/rotonda_02.jpg';
import samara_01 from '../../../images/volga-caspiy/samara_01.jpg';
import samara_02 from '../../../images/volga-caspiy/samara_02.jpg';
import samara_03 from '../../../images/volga-caspiy/samara_03.jpg';
import samara_04 from '../../../images/volga-caspiy/samara_04.jpg';
import samara_05 from '../../../images/volga-caspiy/samara_05.jpg';
import samara_06 from '../../../images/volga-caspiy/samara_06.jpg';
import samara_07 from '../../../images/volga-caspiy/samara_07.jpg';
import samara_08 from '../../../images/volga-caspiy/samara_08.jpg';
import sarykum_01 from '../../../images/volga-caspiy/sarykum_01.jpg';
import sarykum_02 from '../../../images/volga-caspiy/sarykum_02.jpg';
import sarykum_03 from '../../../images/volga-caspiy/sarykum_03.jpg';
import steppe_01 from '../../../images/volga-caspiy/steppe_01.jpg';
import sulak_01 from '../../../images/volga-caspiy/sulak_01.jpg';
import sulak_02 from '../../../images/volga-caspiy/sulak_02.jpg';
import sulak_03 from '../../../images/volga-caspiy/sulak_03.jpg';
import tesnina_01 from '../../../images/volga-caspiy/tesnina_01.jpg';
import tesnina_02 from '../../../images/volga-caspiy/tesnina_02.jpg';
import tesnina_03 from '../../../images/volga-caspiy/tesnina_03.jpg';
import tesnina_04 from '../../../images/volga-caspiy/tesnina_04.jpg';
import tesnina_05 from '../../../images/volga-caspiy/tesnina_05.jpg';
import volga_beach_01 from '../../../images/volga-caspiy/volga_beach_01.jpg';

const VolgaCaspiy = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <p>В&nbsp;первые дни самоизоляционной амнистии для туристов мы&nbsp;отправились в&nbsp;большое
                        путешествие. Формат поездки был самый правильный: машина, направление и&nbsp;нет чёткого плана,
                        где окажешься на&nbsp;следующий день. В&nbsp;общем, всё как я&nbsp;люблю.</p>
                    <p>Сказать по&nbsp;правде и&nbsp;направление ещё за&nbsp;неделю до&nbsp;отъезда не&nbsp;было выбрано
                        окончательно. Было два варианта: первый&nbsp;&mdash; ехать на&nbsp;Алтай,
                        второй&nbsp;&mdash; ехать на&nbsp;Кавказ. Не&nbsp;буду вдаваться в&nbsp;логику рассуждений, она
                        была не&nbsp;прочна, но&nbsp;возобладал второй вариант.</p>
                    <p>Поскольку пандемическая ситуация в&nbsp;разных регионах была до&nbsp;конца не&nbsp;ясна, ситуация
                        с&nbsp;жильём была также под вопросом. Поэтому решено было подстраховаться и&nbsp;подготовить
                        набор для автономного проживания. Была приобретена палатка, газовая горелка с&nbsp;балончиками,
                        докуплены спальники и&nbsp;коврики. Кароче, подготовились мы&nbsp;так, что несмотря,
                        на&nbsp;всего троих пассажиров (я, жена и&nbsp;сын) места в&nbsp;машине не&nbsp;было совсем.</p>
                    <p>Итак, 1&nbsp;июля в&nbsp;4:30 утра по&nbsp;уральскому времени мы&nbsp;таки двинулись в&nbsp;путь.
                        План был таков: доехать к&nbsp;вечеру до&nbsp;Тольятти, на&nbsp;следующий день
                        до&nbsp;Волгограда, ещё через день заночевать в&nbsp;Элисте, и&nbsp;наконец достигнуть конечной
                        области нашего следования&nbsp;&mdash; республики Дагестан.</p>
                    <p>Когда мы&nbsp;выезжали из&nbsp;Челябинска в&nbsp;России было жарко ещё не&nbsp;повсеместно,
                        поэтому первый день было ехать приятно и&nbsp;благодаря раннему выезду относительно легко.
                        Дороги до&nbsp;Самарской области хорошие, ремонтов затрудняющих движение нам практически
                        не&nbsp;попадалось.</p>
                    <p>Относительно рано где-то в&nbsp;шестом часу вечера мы&nbsp;уже доезжали до&nbsp;Тольятти.
                        У&nbsp;меня уже были намечены координаты базы, где планировалось разместиться с&nbsp;палаткой.
                        Но&nbsp;как нам предстаяло убедиться Яндекс навигатор дорог не&nbsp;знает. Точнее он&nbsp;знает
                        об&nbsp;их&nbsp;существовании, но&nbsp;ничего не&nbsp;знает об&nbsp;их&nbsp;качестве
                        и&nbsp;дорожных знаках регламентирующих движение по&nbsp;ним. Свернув в&nbsp;поселок,
                        и&nbsp;доехав до&nbsp;его окончания, мы&nbsp;довольно быстро уперлись в&nbsp;непроходимые для
                        нашего легкового клиренса буераки. Пришлось возвращаться обратно на&nbsp;автостраду,
                        а&nbsp;потом ещё ехать по&nbsp;ней около десяти километров, чтобы развернуться.</p>
                    <p>Сожалея о&nbsp;потерянном времени, решили заглянуть на&nbsp;базы которые были несколько ближе
                        искомой, но&nbsp;ни&nbsp;одна из&nbsp;них не&nbsp;работала. В&nbsp;итоге, найдя правильный
                        маршрут, поехали на&nbsp;место изначального назначения&nbsp;&mdash; т.б.&nbsp;со&nbsp;звучным
                        именем &laquo;Леман&raquo;.</p>
                    <p>Приехав на&nbsp;место, мы&nbsp;набрали номер телефона указанный на&nbsp;воротах забора
                        и&nbsp;к&nbsp;нам вышел молодой человек, отворивший их&nbsp;и&nbsp;проводивший нас на&nbsp;место
                        стоянки. Нам повезло, был уже вечер, а&nbsp;следующий день был рабочим, поэтому народу
                        на&nbsp;базе было немного и&nbsp;нам досталось козырное место на&nbsp;&laquo;первой
                        линии&raquo;, прямо с&nbsp;видом на&nbsp;Волгу.</p>
                    <p>Забегая вперед, отмечу, что из&nbsp;всех мест наших стоянок с&nbsp;палаткой, которых было пять
                        (и&nbsp;только одна дикая), только это место заслуживает добрых слов. Стоянки организованы так,
                        что между ними есть пространство, где растут деревья. Сами места оборудованы обеденным местом
                        и&nbsp;если повезёт неподалёку окажется ещё и&nbsp;розетка. Питьевой воды можно было набрать
                        у&nbsp;ворот, а&nbsp;машину подогнать непосредственно к&nbsp;месту палатки. Вид отсюда
                        открывается отличный, жаль, что насладиться им&nbsp;у&nbsp;нас времени почти не&nbsp;было.
                        Поставив палатку, мы&nbsp;разожгли горелку и&nbsp;приготовили ужин, после чего уже стало
                        смеркаться.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div id="leman" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#leman" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#leman" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={leman_01} className="d-block w-100"
                                     alt="т.б. Леман, Самарская обл."/>
                            </div>
                            <div className="carousel-item">
                                <img src={leman_02} className="d-block w-100"
                                     alt="т.б. Леман, Самарская обл."/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#leman" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#leman" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-12">
                    <p>Второй перегон до&nbsp;Волгограда хоть и&nbsp;был короче, но&nbsp;дался нам сложнее.
                        Во-первых мы&nbsp;двигались уже на&nbsp;юг, и&nbsp;становилось всё жарче,
                        а&nbsp;во&nbsp;вторых дороги становились хуже.</p>
                    <p>Я&nbsp;ждал плохих дорог в&nbsp;Саратовской области, но&nbsp;мои ожидания оправдались
                        не&nbsp;полностью. Между Самарской и&nbsp;Саратовской областью дорога захватывает небольшой
                        участок Ульяновской области, где дорога напоминала стиральную доску. Не&nbsp;успев проехать
                        этот участок, на&nbsp;панели приборов загореась лампочка открытия багажника. Впрочем,
                        я&nbsp;убедился, что багажник был закрыт, однако лампочка горела. Ладно, едем дальше,
                        возможно, просто что-то с&nbsp;датчиком.</p>
                    <p>В&nbsp;Саратовской области в&nbsp;районе Хвалынска и&nbsp;вообще в&nbsp;северной части
                        области, вдоль трассы на&nbsp;областной центр, дороги сделали и&nbsp;похоже недавно. Однако,
                        южнее начинались многочисленные ремонты дорог, из-за которых образовывались огромные заторы.
                        Забегая вперед скажу, что эти ремонты продолжились и&nbsp;в&nbsp;Волгоградской области,
                        поэтому мы&nbsp;теряли много времени просто стоя на&nbsp;трассе.</p>
                    <p>Вскоре выяснилось, что проблемы с&nbsp;машиной у&nbsp;нас не&nbsp;ограничились горящей лампой
                        открытия багажника, перестал, также работать стеклоподъёмник на&nbsp;водительской двери.
                        И&nbsp;окно осталось в&nbsp;полуоткрытом положении.</p>
                    <p>Добравшись до&nbsp;Камышина, мы&nbsp;решили попробовать найти автоэлектрика, чтобы выяснить
                        в&nbsp;чём проблема. Однако, заехав в&nbsp;3-4 места нам так и&nbsp;не&nbsp;удалось найти
                        нужного специалиста.</p>
                    <p>Ладно едем дальше на&nbsp;Волгоград. И&nbsp;как назло начинается первый на&nbsp;нашем пути
                        дождь. Кладу на&nbsp;левую ногу пакет и&nbsp;едем дальше. Благо осадки быстро прекращаются,
                        и&nbsp;сильно я&nbsp;не&nbsp;вымок.</p>
                    <p>Наконец, заезжаем в&nbsp;Волгоград. Хотя нам сегодня нужно не&nbsp;сюда, а&nbsp;по-сути
                        в&nbsp;соседний Краснослободск на&nbsp;очередную турбазу. Но&nbsp;нельзя так просто взять
                        и&nbsp;миновать город Волгоград. Проезжаем мост через Волгу, и&nbsp;встаём в&nbsp;пробку
                        подле ерика Верблюд. Потолкавшись с&nbsp;полчаса, поворачиваем на&nbsp;Краснослободск
                        и&nbsp;минут через двадцать мы&nbsp;на&nbsp;месте возле т.б.&nbsp;со&nbsp;звучным
                        названием &laquo;Дача Поддубного&raquo;. Однако, все входы за&nbsp;оградку закрыты. Идём
                        за&nbsp;соседний забор, но&nbsp;и&nbsp;там никого не&nbsp;находим. Рядом есть ещё базы. Одна
                        с&nbsp;говорящим названием &laquo;Рублевка&raquo;, там дорого-богато и&nbsp;с&nbsp;палатками
                        не&nbsp;пускают. Вторая безымянная, типа &laquo;совдеп&raquo; с&nbsp;деревянными домиками
                        полувековой давности. Похоже всё ещё на&nbsp;балансе у&nbsp;какого-то предприятия, так как
                        нам отворил сторож, а&nbsp;для заселения пришлось ждать пока подъедет директор. Палатки
                        здесь тоже ставить некуда, пришлось оплатить домик. Комфоту это не&nbsp;прибавило,
                        а&nbsp;свирепую мошкару после дождя не&nbsp;пугал ни&nbsp;один репилент. При заселении
                        попросили документы, сначала я&nbsp;не&nbsp;оценил это, но&nbsp;впоследствии понял, что
                        данная процедура фильтрует буйный контингент на&nbsp;территории. Оценить это придётся
                        на&nbsp;обратном пути в&nbsp;Челябинск, но&nbsp;об&nbsp;этом позже.</p>
                    <p>Переночевали. С&nbsp;утра поехали по&nbsp;местам боевой славы. Сперва на&nbsp;Мамаев Курган.
                        Даже будучи здесь не&nbsp;впервый раз, от&nbsp;вида монументальности и&nbsp;масштаба
                        конструкции захватывает дух. Сходили на&nbsp;вечный огонь, здесь в&nbsp;зале воинской славы
                        стали свидетелями смены караула под музыку Шумана. Безусловно весь комплекс это одно
                        из&nbsp;величественных мест в&nbsp;России.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div id="mamaev-kurgan" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#mamaev-kurgan" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#mamaev-kurgan" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#mamaev-kurgan" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={mamaev_kurgan_01} className="d-block w-100"
                                     alt="Мамаев Курган"/>
                            </div>
                            <div className="carousel-item">
                                <img src={mamaev_kurgan_02} className="d-block w-100"
                                     alt="Мамаев Курган"/>
                            </div>
                            <div className="carousel-item">
                                <img src={mamaev_kurgan_03} className="d-block w-100"
                                     alt="Мамаев Курган"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#mamaev-kurgan" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#mamaev-kurgan" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-6">
                    <p>После посещения Мамаева Кургана, подъехали к&nbsp;музею-панораме Сталинградской битве.
                        К&nbsp;сожалению все музеи закрыты.</p>
                </div>

                <div className="col-md-6">
                    <div id="rotonda" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#rotonda"
                                    data-bs-slide-to="0" className="active" aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#rotonda"
                                    data-bs-slide-to="1" aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={rotonda_01} className="d-block w-100"
                                     alt="Музей-панорама Сталинградской битве"/>
                            </div>
                            <div className="carousel-item">
                                <img src={rotonda_02} className="d-block w-100"
                                     alt="Музей-панорама Сталинградской битве"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#rotonda" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#rotonda" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-12">
                    <p>К&nbsp;этому времени, выяснились подробности относительно состояния нашего
                        автомобиля. Неисправности, о&nbsp;которых я&nbsp;упоминал, то&nbsp;появлялись,
                        то&nbsp;сами собой исчезали. Так, например, стекло удалось закрыть, а&nbsp;лампочка
                        открытия багажника, то&nbsp;гасла, то&nbsp;загоралась снова. И&nbsp;пока
                        мы&nbsp;в&nbsp;крупном городе, решили всё-таки разыскать эелектрика и&nbsp;выслушать
                        его вердикт относительно поломки. Такой специалист был найден,
                        и&nbsp;он&nbsp;ожидаемо сообщил, что проблема в&nbsp;проводке, но&nbsp;чтобы
                        выяснить где именно и&nbsp;устранить неисправность, необходимо разобрать половину
                        машины и&nbsp;это займёт полдня работы. Поэтому машину было решено чинить уже
                        по&nbsp;приезду в&nbsp;Челябинск.</p>

                    <p>После тяжёлого решения, мы&nbsp;доехали до&nbsp;ближайшего кафе,
                        перекусили &laquo;на&nbsp;вынос&raquo;, и&nbsp;направились на&nbsp;выезд
                        из&nbsp;города. 3-ю продольную улицу в&nbsp;Волгограде починили, поэтому движение
                        транзитного транспорта по&nbsp;городу несколько ускорилось.</p>

                    <p>Выехав из&nbsp;города мы&nbsp;уже вскоре пересекали границу республики Калмыкия. Если
                        деревья в&nbsp;окружающем нас пейзаже исчезли ещё где-то за&nbsp;Саратовым,
                        то&nbsp;здесь начали пропадать и&nbsp;кустарники. А&nbsp;ещё через сто километров,
                        нас окружала лишь голая степь, с&nbsp;лишь изредка попадающимися на&nbsp;пути сухими
                        и&nbsp;наполовину сухими балками.</p>

                    <p>Около семи часов вечера мы&nbsp;заезжали в&nbsp;Элисту. Сразу подъехали
                        к&nbsp;центральному Хурулу, но&nbsp;на&nbsp;территорию вход был закрыт из-за
                        пандемии. Тогда мы&nbsp;направились в&nbsp;частный сектор, где находлилась наша
                        гостиница, первая на&nbsp;пути нашего следования. Приняв, наконец, душ,
                        мы&nbsp;вновь поехали в&nbsp;центр.</p>

                    <p>Вообще, Элиста очень уютный и&nbsp;чистый город. Это в&nbsp;основном частный сектор,
                        с&nbsp;очень ухоженным и&nbsp;наполненным людьми центром. Чувствуется, что город
                        живёт. Здесь много детей и&nbsp;молодежи. Все гуляют, общаются, ездят... на&nbsp;чём
                        только они не&nbsp;ездят. Здесь в&nbsp;самом центре даже открыт прокат, где можно
                        взять в&nbsp;аренду педальный транспорт на&nbsp;двух, трёх, четырёх колёсах или
                        можно использовать электрические новомодные средства передвижения.</p>

                    <p>При этом здесь наблюдалась настоящая азиатская дисциплина, в&nbsp;плане соблюдения
                        мер безопастности в&nbsp;связи с&nbsp;пандемической ситуацией. Столько людей,
                        прилежно одевавших маски, мы&nbsp;не&nbsp;видели ни&nbsp;в&nbsp;одном регионе
                        по&nbsp;пути следования.</p>

                    <p>Интересно, что в&nbsp;Элисте на&nbsp;улице мы&nbsp;так и&nbsp;не&nbsp;услышали
                        калмыцкой речи. Калмыки говорят друг с&nbsp;другом только по-русски, при том
                        совершенно без какого-либо акцента. Вообще элистинцы оставляют впечатление очень
                        культурных и&nbsp;спокойных людей. В&nbsp;этом, как я&nbsp;полагаю, определённую
                        роль играет буддийская традиция.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div id="elista" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#elista"
                                    data-bs-slide-to="0" className="active" aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#elista"
                                    data-bs-slide-to="1" aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={elista_01} className="d-block w-100"
                                     alt="Элиста"/>
                            </div>
                            <div className="carousel-item">
                                <img src={elista_02} className="d-block w-100"
                                     alt="Элиста"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#elista" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#elista" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <p>На&nbsp;утро, выбравшись из&nbsp;кондиционированного номера гостиницы на&nbsp;улицу,
                        мы&nbsp;ощутили степень активности солнца. А&nbsp;ведь нам необходимо было
                        перемещаться ещё южнее. И&nbsp;вот тут следует упомянуть о&nbsp;моём крупном
                        просчёте. Дело в&nbsp;том, что живя в&nbsp;Уральском регионе,
                        я&nbsp;не&nbsp;пользуюсь автокондиционером. Во-первых редко бывает жарко, во-вторых,
                        раньше как только я&nbsp;пытался им&nbsp;воспользоваться и&nbsp;находился
                        в&nbsp;машине не&nbsp;один, меня тут&nbsp;же просили выключить кондёр из-за страха
                        простудиться. Поэтому во&nbsp;время подготовки автомобиля к&nbsp;путушествию
                        я&nbsp;по&nbsp;привычке даже не&nbsp;обратил внимание на&nbsp;необходимость его
                        заправки, ведь я&nbsp;уже много лет этого не&nbsp;делаю. Но&nbsp;вот здесь
                        на&nbsp;юге России, это (эврика!) оказалось чертовски актуально. Но&nbsp;поняли
                        мы&nbsp;это только сейчас. Увы! Надо ехать дальше.</p>
                    <p>И&nbsp;мы&nbsp;поехали. Правда сначала совсем недалеко, в&nbsp;Хурул Сякюсн-Сюме,
                        недалеко от&nbsp;посёлка Аршан, что по-сути в&nbsp;пригороде Элисты. Место это
                        замечательное, я&nbsp;был здесь пять лет назад и&nbsp;тогда познакомился
                        с&nbsp;одним молодым монахом из&nbsp;Шри-Ланки по&nbsp;имени Саду, который сейчас
                        закончил обучение и&nbsp;уехал к&nbsp;себе домой. Здесь не&nbsp;так помпезно как
                        в&nbsp;центральном элистинском хруруле, который носит название &laquo;Золотая
                        обитель Будды Шакьямуни&raquo;, но&nbsp;здесь чувствуется дух места. Хурул
                        расположен посреди степи, здесь немноголюдно и&nbsp;мне кажется место это
                        репрезентует настоящую Калмыкию. Здесь я&nbsp;разговорился с&nbsp;местным
                        работником, у&nbsp;которого распросил про Саду. После посещения хурула,
                        он&nbsp;предложил посетить мне расположенный рядом &laquo;домик Далай-ламы&raquo;.
                        Домик этот возвели ещё до&nbsp;строительства здания хурула, после освещения места
                        строительства храма Его Святейшеством Далай-ламой XIV,&nbsp;во время его личного
                        визита в&nbsp;Калмыкию. Пока шли по&nbsp;тропе к&nbsp;домику, мой новый знакомый
                        разбрасывал рис с&nbsp;&laquo;песком мандалы&raquo; от&nbsp;саранчи. Раньше
                        помогало, с&nbsp;улыбкой говорит&nbsp;он. Песок мандалы&nbsp;же это песок, который
                        был использован для создания узора мандалы монахами из&nbsp;миллинов песчинок,
                        символизируя тем самым божественное созидание. Затем мандала разрушается,
                        символизируя непостоянство всего сущего в&nbsp;мире. Вот этот песок потом может быть
                        использован как некая освящённая субстанция по&nbsp;своему усмотрению. В&nbsp;самом
                        домике по-сути нет нечего кроме алтаря и&nbsp;места для поклонения и&nbsp;молитвы.
                        После недолгой беседы о&nbsp;традициях тибетской ветви буддизма, к&nbsp;которой
                        и&nbsp;пренадлежат калмыки, этот добрый человек проводил меня обратно до&nbsp;хурула
                        и&nbsp;пожелал нам счастливого пути. Жаль, что мне&nbsp;редко представляется
                        возможность беседовать с&nbsp;истинными представителями этой религии.
                        Я&nbsp;не&nbsp;перестаю удивляться их&nbsp;необыкновенной простоте, открытости
                        и&nbsp;добродушию. А&nbsp;посещение таких мест оставляет на&nbsp;сердце приятное
                        чувство спокойствия и&nbsp;сдержанной радости.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div id="old-hurul" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#old-hurul"
                                    data-bs-slide-to="0" className="active" aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#old-hurul"
                                    data-bs-slide-to="1" aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={old_hurul_01} className="d-block w-100"
                                     alt="Хурул Сякюсн-Сюме"/>
                            </div>
                            <div className="carousel-item">
                                <img src={old_hurul_02} className="d-block w-100"
                                     alt="Хурул Сякюсн-Сюме"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#old-hurul" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#old-hurul" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <p>Сам&nbsp;же хурул Сякюсн-Сюме, хоть и&nbsp;не&nbsp;назовёшь старым, он&nbsp;был
                        открыт в&nbsp;1996&nbsp;году, но&nbsp;он&nbsp;в&nbsp;купе со&nbsp;ступами
                        и&nbsp;молитвенными барабанами установленными рядом, вызывает ощущение посещения
                        места гораздо более древнего. Внутри хурул красиво расписан сюжетами
                        из&nbsp;буддийских писаний, усыпан табличками с&nbsp;высказываниями Будды. Обход
                        хурула, как и&nbsp;поворот молитвенного барабана, принято осуществлять
                        по&nbsp;часовой стрелке.</p>
                    <p>Зарядившись светлой энергией, мы&nbsp;сели в&nbsp;машину и&nbsp;продолжили свой путь.
                        Постепенно при движении на&nbsp;юг&nbsp;местность вокруг становилась всё более
                        свободной от&nbsp;растительности, а&nbsp;её&nbsp;остатки в&nbsp;виде низкорослой
                        травы всё более вызженными иногда чёрными. Почва всё больше становилась песчаной.
                        Жара стояла как в&nbsp;пустыне, вместе с&nbsp;тем, задувал сильный ветер, поднимаший
                        пыль и&nbsp;песок. Трафика на&nbsp;трассе почти не&nbsp;было. В&nbsp;голове была
                        одна мысль: &laquo;Лишь&nbsp;бы не&nbsp;подвела машина, чтобы не&nbsp;встать посреди
                        этой пустыни&raquo;. Дорожное полотно, тоже было местами не&nbsp;должного качества,
                        что усугубляло ситуацию. Однажды даже пришлось словить внезапно возникшую ямку, что
                        повлекло за&nbsp;собой мощный удар. Испугался, что повторится ситуация пятилетней
                        давности, когда на&nbsp;другой калмыцкой дороге, южнее Элисты, от&nbsp;подобного
                        удара пришлось менять колесо. В&nbsp;этот раз обошлось.</p>
                    <p>Спустя ещё какое-то время нашего пути, вдали показался пост ДПС. Мы&nbsp;поняли, что
                        сейчас нам предстоит пересекать границу республик. Переход здесь почти как
                        на&nbsp;государственных границах, сначала стоит калмыцкий пост, потом подъезжаешь
                        к&nbsp;дагестанскому. Калмыки проверять не&nbsp;стали, у&nbsp;дагестанского поста,
                        досмотрели машину и&nbsp;попросили пройти на&nbsp;регистрацию. У&nbsp;пассажиров
                        документов не&nbsp;спрашивали. Через 5&nbsp;минут мы&nbsp;уже двигались
                        по&nbsp;Дагестану.</p>

                    <p>По&nbsp;началу, местность за&nbsp;окном менялось не&nbsp;шибко. Заметен был только
                        постоянный небольшой уклон в&nbsp;который мы&nbsp;поднимались. Постепенно
                        растительность начала возвращаться на&nbsp;местность. Кавказский&nbsp;же хребет
                        начал виднеться значительно южнее, за&nbsp;Тереком.</p>

                    <p>Под конец этого изнурительного пути, мы въехалии в Махачкалу, где по дороге сюда,
                        жена забронировала нам гостиницу со звучным названием "Арбат". И мы двигались в её
                        направлении.</p>

                    <p>Первое впечатление от&nbsp;местного движения&nbsp;&mdash; ты&nbsp;попал
                        в&nbsp;Ереван. Такое&nbsp;же хаотичное, местами агрессивное вождение, несоблюдение
                        рядности, игнорирование необходимости использовать поворотники, постоянно звучащие
                        вокруг клаксоны. При этом Махачкала наполнена баннерами вдоль дорог о&nbsp;том, что
                        соблюдение правил ПДД является обязанностью мусульманина. Кстати, как я&nbsp;понял,
                        звуковой сигнал тут активно используют не&nbsp;только для предупреждения аварийной
                        ситуации, но&nbsp;и&nbsp;для выражения благодарности другому участнику движения,
                        а&nbsp;также для &laquo;профилактики&raquo; (например, перед слепым поворотом),
                        ну&nbsp;и для приветсвия встреченых знакомых пешеходов и&nbsp;водителей.</p>

                    <p>Если в&nbsp;Армении, несколько лет назад, хитом автодорог была белая Нива,
                        то&nbsp;в&nbsp;Дагестане, как известно чёрная Приора. За&nbsp;машиной на&nbsp;юге
                        принято ухаживать, поэтому даже немолодые отечественные автомобили здесь
                        эксплуатируются в&nbsp;неплохом состоянии.</p>

                    <p>Благополучно добравшись до&nbsp;места, идём заселяться. Гостиница находится
                        не&nbsp;в&nbsp;самом центре, но&nbsp;и&nbsp;не&nbsp;так далеко от&nbsp;него,
                        на&nbsp;проспекте Имама Шамиля, недалеко от&nbsp;озера Акгель. На&nbsp;4&nbsp;этаж
                        без лифта с&nbsp;вещами идти тяжело. На&nbsp;букинге было указано наличие
                        паркинга&nbsp;&mdash; оказалось он&nbsp;действительно есть (оставь где хочешь, никто
                        не&nbsp;возьмёт). И&nbsp;на&nbsp;том спасибо. В&nbsp;нумерах&nbsp;&mdash; ремонт,
                        действительно дорого-богато для бюджетной гостиницы. Правда из&nbsp;первого
                        предложенного номера пришлось переселиться в&nbsp;другой, т.к.&nbsp;не&nbsp;работал
                        слив душа. В&nbsp;остальном всё&nbsp;ок, гостеприимный администратор и&nbsp;нам
                        полагался даже завтрак.</p>

                    <p>Вечером поехали в&nbsp;центр. Здесь есть неплохой бульвар на&nbsp;прспекте&nbsp;им.
                        Гамзатова, но&nbsp;по&nbsp;нему прогуляться не&nbsp;удалось. Мы&nbsp;двинули ближе
                        к&nbsp;набережной, к&nbsp;городскому саду, здесь сохранилась некоторая историческая
                        застройка, правда её&nbsp;совсем немного. Городской пляж от&nbsp;города отделяют ж/д
                        пути, как это водится в&nbsp;некоторых советских городах (возможно, не&nbsp;только
                        в&nbsp;них). В&nbsp;целом находясь в&nbsp;Махачкале нет ощущения, что это город
                        у&nbsp;моря. Возможно из-за того, что это не&nbsp;субтропики, воздух здесь довольно
                        сухой, а&nbsp;характерный морской бриз ощущается только в&nbsp;непосредственной
                        близости к&nbsp;воде, либо на&nbsp;высоте.</p>

                    <p>Прогулялись по&nbsp;вечернему городу&nbsp;&mdash; жизнь здесь кипит. Это тоже
                        характерная особенность юга, днём слишком жарко для променадов, все выходят
                        на&nbsp;улицу после заката солнца. Из-за того, что в&nbsp;городах Дагестана
                        проживает большое количество разных национальностей населяющих эту республику,
                        у&nbsp;них нет единого наречия, и&nbsp;все говорят друг с&nbsp;другом по-русски,
                        но&nbsp;каждый со&nbsp;своим колоритом, скрывающим в&nbsp;себе разной степени акцент
                        своего родного языка.</p>

                    <p>Одежда местных сдержана в&nbsp;красках и&nbsp;вольностях дизайнерской мысли. Это
                        касается и&nbsp;мужчин, и&nbsp;естественно, женщин. Причём далеко не&nbsp;все
                        женщины носят традиционные наряды. Это может быть просто длинное, сдержанное платье.
                        Пожалуй, самым фривольным, что удалось заметить на&nbsp;местных девушках были
                        джинсы, но&nbsp;это скорее редкость.</p>

                    <p>Причём, судя по&nbsp;всему, ношение городскими женщинами тадиционных костюмов
                        покрывающих голову это скорее тенденция последних лет. Многие молодые люди считают
                        правильным, чтобы их&nbsp;женщины одевались именно так.</p>

                    <p>Из&nbsp;плюсов также, отсутствие пьяных на&nbsp;улицах, не услышал никакой брани
                        в&nbsp;общественных местах, курящих женщин тоже нет, а&nbsp;также почти нет собак,
                        что хорошо сказывается на&nbsp;чистоте улиц.</p>

                    <p>Кстати, среди мужчин любого возраста здесь крайне затруднительно найти полных людей.
                        В&nbsp;Дагестане распространён культ война, поэтому большинство парней заняты
                        спортом, особенно, как известно, здесь популярны силовые виды
                        и&nbsp;единоборства.</p>

                    <p>Погуляв немного по&nbsp;парку и&nbsp;набережной,
                        мы&nbsp;перекусили &laquo;навынос&raquo; и&nbsp;поехали спать.</p>

                    <p>На&nbsp;следующее утро, позавтракав по-кавказски щедрой тарелкой каши от&nbsp;нашего
                        отеля, мы&nbsp;отправились на&nbsp;пляж. В&nbsp;принципе в&nbsp;Махачкале нет
                        проблемы с&nbsp;пляжами. Длинная удобная береговая линия, позволяет подойти
                        к&nbsp;морю практически в&nbsp;любом месте города. Поэтому проехав немного
                        на&nbsp;юго-восток вдоль ближайшего к&nbsp;берегу проспекта мы&nbsp;остановили
                        машину и&nbsp;направились к&nbsp;морю.</p>

                    <p>Надо сказать, что я&nbsp;удивлён. Удивлён тем, почему туристы отдыхая в&nbsp;России
                        предпочитают Черное море. Как мне показалось Каспийское как минимум не&nbsp;хуже. По
                        крайней мере здесь в&nbsp;Дагестане, это широкая береговая линия, песок
                        и&nbsp;относительная чистота. А&nbsp;главное, здесь на&nbsp;пляже
                        ты&nbsp;не&nbsp;чувствуешь себя как в&nbsp;супермаркете накануне нового года.
                        Простор.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <img src={caspiy_01} alt="Каспийское море" className="img-fluid travel__img" loading="lazy"/>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <p>Памятуя о&nbsp;том, что случилось со&nbsp;мной осенью 2014 года под лучами
                        кавказского солнца, я&nbsp;не&nbsp;стал раздеваться и&nbsp;до&nbsp;момента захода
                        в&nbsp;воду сидел на&nbsp;берегу в&nbsp;футболке и&nbsp;шортах до&nbsp;колен, наивно
                        полагая, что уж&nbsp;эта часть ног, снабжённая обильной растительностью останется
                        невридимой и&nbsp;под таким количеством ультрафиолета.</p>

                    <p>На&nbsp;пляже мы&nbsp;пробыли около двух&nbsp;&mdash; двух с&nbsp;половиной часов,
                        и&nbsp;уже тогда я&nbsp;начал ощущать, что сильно заблуждался. А&nbsp;ближе
                        к&nbsp;вечеру я&nbsp;в&nbsp;полной мере осознал степень своей ошибки. Ладно&nbsp;бы
                        ноги покраснели, верхняя часть ступней распухла так, что мне было трудно ходить даже
                        босиком, не&nbsp;говоря о&nbsp;том, чтобы засунуть ноги в&nbsp;сандалии или
                        кросовки. Обильнейшим образом используйте крем, если вы оказались летом на кавказе,
                        господа.</p>

                    <p>Тем не&nbsp;менее, отобедав, мы&nbsp;решили пойти, ещё раз прогуляться
                        по&nbsp;городу. Обедали мы,&nbsp;кстати, дарами из&nbsp;местного супермаркета. Здесь
                        в&nbsp;Дагестане ещё не&nbsp;встретить сетевых ретейлеров популярных в&nbsp;России,
                        нет здесь также привычных брендов АЗС. А&nbsp;также нужно быть готовым к&nbsp;тому,
                        что банковской картой можно расплатиться не&nbsp;везде. И&nbsp;если нет налички, вам
                        могут предложить перевести деньги &laquo;по&nbsp;номеру телефона&raquo;.</p>

                    <p>Если вы&nbsp;ищите местных фруктов/овощей в&nbsp;супермаркете, то&nbsp;даже
                        в&nbsp;Дагестанском супермаркете их&nbsp;сложно найти. Но&nbsp;вот молочная
                        продукция, естественно, представлена местная, а&nbsp;также есть
                        кабардино-балкарская. И&nbsp;вот, к&nbsp;примеру, кефир здесь просто потрясающий.
                        В&nbsp;нём нет, характерной для российского кефира кислой доминанты,
                        он&nbsp;настолько нежный как по&nbsp;вкусу, так и&nbsp;по&nbsp;консистенции, что
                        хочется, чтобы он&nbsp;продавался в&nbsp;магазине рядом с&nbsp;моим домом.</p>

                    <p>Итак, доехав до&nbsp;центра, я&nbsp;осилил лишь поход до&nbsp;ближайшей лавочки.
                        Жене&nbsp;же пришлось совершить забег отдуваясь за&nbsp;нас всех, благодаря этому
                        у&nbsp;нас есть несколько снимков города до&nbsp;наступления сумерек.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div id="mahachkala" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#mahachkala"
                                    data-bs-slide-to="0" className="active" aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#mahachkala"
                                    data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#mahachkala"
                                    data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#mahachkala"
                                    data-bs-slide-to="3" aria-label="Slide 4"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={mahachkala_01}
                                     className="d-block w-100" alt="Махачкала"/>
                            </div>
                            <div className="carousel-item">
                                <img src={mahachkala_02}
                                     className="d-block w-100" alt="Махачкала"/>
                            </div>
                            <div className="carousel-item">
                                <img src={mahachkala_03}
                                     className="d-block w-100" alt="Махачкала"/>
                            </div>
                            <div className="carousel-item">
                                <img src={mahachkala_04}
                                     className="d-block w-100" alt="Махачкала"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#mahachkala" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#mahachkala" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <p>После того как стемнело, мы&nbsp;заехали по&nbsp;дороге в&nbsp;одно местное
                        заведение, и&nbsp;захватив по&nbsp;шаурме, вернулись в&nbsp;гостиницу. Поужинав,
                        я&nbsp;весь обмазался, сначала сметаной, затем пантенолом и&nbsp;лёг спать.</p>

                    <p>На&nbsp;утро пришло некоторое облегчение, а&nbsp;мы&nbsp;собрав вещи
                        и&nbsp;позавтракав, отправились снова в&nbsp;путь. Нам предстояло поехать
                        в&nbsp;горы, где нас уже ожидали гостеприимные хозяева в&nbsp;селе Большой
                        Гоцатль.</p>

                    <p>Но&nbsp;сначала, мы&nbsp;решили всё-таки сделать&nbsp;то, что давно уже надо было
                        сделать&nbsp;&mdash; заправить кондиционер в&nbsp;машине. Сделать это оказалось
                        не&nbsp;так-то просто. После того, как мы&nbsp;безуспешно посетили несколько точек
                        предложенных навигатором, мы&nbsp;послушали совета местного жителя и&nbsp;подъехали
                        в&nbsp;очередной автосервис, который по-сути был нашей последней надеждой. И, как
                        это обычно бывает, последний шанс на&nbsp;успех, реализовался в&nbsp;удачное решение
                        вопроса. Ребята в&nbsp;автосервисе заправили фреон, промыли радиатор и&nbsp;всё
                        заработало. Один из&nbsp;работников, увидев наши номера <del>даже
                            прослезился</del> узнал &laquo;земляка&raquo;, и&nbsp;сказал, что пять лет жил
                        в&nbsp;Челябинске, на&nbsp;северо-западе города. Обменявшись воспоминаниями
                        и&nbsp;охладив салон авто, мы, наконец, отправились в&nbsp;путь.
                    </p>

                    <p>Но&nbsp;конечно, мы&nbsp;не&nbsp;могли просто так взять и&nbsp;отправится
                        в&nbsp;пункт назначения, по&nbsp;дороге мы&nbsp;должны были осмотреть хоть какую-то
                        часть местных достопримечательностей. И&nbsp;первым из&nbsp;них стал бархан
                        Сарыкум.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <p>В&nbsp;селении подле него стоит новая мечеть, почти без тени традиций в&nbsp;своей
                        архитектуре.</p>
                </div>
                <div className="col-md-6">
                    <img src={modern_mosque} alt="мечеть" className="img-fluid travel__img" loading="lazy"/>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-12">
                    <p>Бархан&nbsp;же находится на&nbsp;территории Дагестанского заповедника,
                        а&nbsp;сама дюна считается одной из&nbsp;крупнейших в&nbsp;Евразии и&nbsp;имеет
                        максимальную высоту около 250&nbsp;метров. Говорят именно здесь
                        снимали &laquo;Белое солнце пустыни&raquo;.</p>

                    <p>Тропа ведущая наверх дюны одна, и&nbsp;в&nbsp;некоторых местах занесена песком.
                        Сходить с&nbsp;неё категорически запрещается, т.к.&nbsp;в&nbsp;песках водятся
                        змеи и&nbsp;скорпионы. Наверху оборудована смотровая площадка.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div id="sarykum" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#sarykum"
                                    data-bs-slide-to="0" className="active" aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#sarykum"
                                    data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#sarykum"
                                    data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sarykum_01}
                                     className="d-block w-100" alt="Сарыкум"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sarykum_02}
                                     className="d-block w-100" alt="Сарыкум"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sarykum_03}
                                     className="d-block w-100" alt="Сарыкум"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#sarykum" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#sarykum" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <p>Поскольку ступни мои болели от&nbsp;ожогов, вынужден был подниматься
                        в&nbsp;сланцах. А&nbsp;вот спускаться пришлось уже босиком, т.к. сланцы увязнув
                        в&nbsp;песке разорвались. Одевайте удобную обувь.</p>

                    <p>Разговорились с&nbsp;местным смотрителем, который на&nbsp;самом деле являлся
                        смотрителем в&nbsp;Самурском заповеднике, а&nbsp;здесь оказался случайно,
                        по&nbsp;долгу службы. Приглашал посетить Самурский лес, что на&nbsp;самом юге
                        республики, показал где набрать ключевой воды, угостил веточкой абрикосов только
                        что сорванной из&nbsp;рощи неподалёку&nbsp;&mdash; молодец мужчина!</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div id="forest-fire" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#forest-fire"
                                    data-bs-slide-to="0" className="active" aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#forest-fire"
                                    data-bs-slide-to="1" aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={forest_fire_01}
                                     className="d-block w-100" alt="Лесные пожары"/>
                            </div>
                            <div className="carousel-item">
                                <img src={forest_fire_02}
                                     className="d-block w-100" alt="Лесные пожары"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#forest-fire" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#forest-fire" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>

                <div className="col-md-6">
                    <p>Двинулись дальше. Углубляясь в горы пейзаж всё больше радовал. Не радовали только
                        лесные пожары разыгравшиеся здесь.</p>
                </div>

            </div>

            <div className="row">
                <div className="col-md-12">
                    <p>Следующая точка нашего притяжения это настоящая жемчужина
                        кавказа&nbsp;&mdash; Сулакский каньон. Один из&nbsp;самых глубоких каньонов
                        мира, уступая лишь двум перуанским. Его глубина достигает 1920&nbsp;метров.
                        А&nbsp;река Сулак, протекая в&nbsp;этих местах, имеет завораживающий бирюзовый
                        оттенок. Мы&nbsp;любовались видами каньона в&nbsp;районе селения
                        с&nbsp;благозвучным названием Дубки.</p>

                    <p>Конечно, будь у&nbsp;нас больше времени, мы&nbsp;бы обязательно спустились
                        к&nbsp;реке, прокатились&nbsp;бы по&nbsp;ней на&nbsp;лодочке, созерцая каньон
                        снизу вверх (говорят такие услуги оказываются), но&nbsp;увы сделав на&nbsp;троих
                        с&nbsp;полсотни снимков мы&nbsp;были вынуждены продолжить свой путь.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div id="sulak" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#sulak"
                                    data-bs-slide-to="0" className="active" aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#sulak"
                                    data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#sulak"
                                    data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sulak_01} className="d-block w-100"
                                     alt="Сулакский каньон"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sulak_02} className="d-block w-100"
                                     alt="Сулакский каньон"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sulak_03} className="d-block w-100"
                                     alt="Сулакский каньон"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#sulak" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#sulak" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-6">
                    <p>Для меня это чувство наиболее близкое к&nbsp;полёту, когда мчишься вдоль
                        горной кручи и&nbsp;твой взгяд скользит по&nbsp;верхушкам гор
                        и&nbsp;уходящей за&nbsp;горизонт синеве неба. Над тобой только облака,
                        а&nbsp;перед тобой колосальный воздушный бассейн, созданный мощными изгибами
                        рельефа, а&nbsp;на&nbsp;его дне ты&nbsp;видишь течение воды, родившейся
                        здесь. И&nbsp;это только на&nbsp;зрительном уровне. На&nbsp;других&nbsp;же
                        каналах восприятия есть ещё запахи прозрачной свежести и&nbsp;звуки
                        умиротворения. А&nbsp;всё вместе это горы, лучше которых могут быть только
                        они сами, в&nbsp;другом месте или времени.</p>
                </div>

                <div className="col-md-6">
                    <div id="mountains" className="carousel slide"
                         data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#mountains"
                                    data-bs-slide-to="0" className="active" aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#mountains"
                                    data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#mountains"
                                    data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={mountains_01}
                                     className="d-block w-100" alt="горы Дагестана"/>
                            </div>
                            <div className="carousel-item">
                                <img src={mountains_02}
                                     className="d-block w-100" alt="горы Дагестана"/>
                            </div>
                            <div className="carousel-item">
                                <img src={mountains_03}
                                     className="d-block w-100" alt="горы Дагестана"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#mountains" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#mountains" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>

            </div>

            <div className="row">
                <div className="col-md-12">
                    <p>Ещё засветло мы&nbsp;прибыли в&nbsp;Большой Гоцатль. Село это знаменито
                        своими ювелирами и&nbsp;наряду с&nbsp;селом Кубачи, является одним
                        из&nbsp;центров традиционных ремёсел в&nbsp;Дагестане. Также Гоцатль
                        является родиной имама Гамзат-бека, который известен также, как действующее
                        лицо в&nbsp;повести Льва Толстова &laquo;Хаджи-Мурат&raquo;, описывающей
                        некоторые страницы кавказской войны. Село это аварское, это значит, что
                        проживают в&nbsp;нём в&nbsp;основном аварцы, самый многочисленный
                        из&nbsp;народов Дагестана.</p>
                    <p>Принимала нас хозяйка по&nbsp;имени Зайнаб вместе со&nbsp;своей семьёй.
                        Поскольку других гостей у&nbsp;них в&nbsp;этот день не&nbsp;было, для
                        ночёвки нам выделили почти весь второй этаж. Приняв с&nbsp;дороги душ,
                        мы&nbsp;спустились во&nbsp;двор ко&nbsp;столу, где Зайнаб с&nbsp;дочерьми
                        приготовила нам ужин. Зайнаб заранее спрашивала, что нам приготовить,
                        и&nbsp;мы попросили чего-нибудь национального. Нам приготовили Аварский
                        хинкал (варёные кусочки теста) поданый с&nbsp;чесночным и&nbsp;аджичным
                        соусами, картофель с&nbsp;домашними колбасками из&nbsp;сушёного мяса,
                        ну&nbsp;и&nbsp;конечно местные овощи и&nbsp;фрукты.</p>
                    <p>Зайнаб по профессии учитель истории и преподаёт в местной школе, поэтому
                        прекрастно может рассказать о своём крае, об его культуре и истоках
                        традиций. Чем мы и воспользовались больше часа распрашивая её о вещах нас
                        интересовавших.</p>
                    <p>Говоря о&nbsp;жизни в&nbsp;горном селении, Зайнаб упомянула о&nbsp;том, что
                        самое сложное здесь&nbsp;то, что согласно их&nbsp;традициям родители обязаны
                        построить дом для каждого своего сына. И&nbsp;что в&nbsp;этом смысле
                        их&nbsp;семье повезло, т.к. сын у&nbsp;них всего один, и&nbsp;тот сейчас
                        на&nbsp;зароботках в&nbsp;Москве, помогая накопить сумму
                        на&nbsp;строительство дома. Землю&nbsp;же местным жителям в&nbsp;сёлах
                        администрация даёт бесплатно.</p>

                    <p>После того как муэдзин совершил свой призыв к&nbsp;последней вечерней молитве
                        мы&nbsp;потихоньку отправились спать.</p>

                    <p>Утром перед отъездом нас ждал ещё национальный
                        завтрак&nbsp;&mdash; абрикосовая каша с&nbsp;урбечем. Кашу делают
                        из&nbsp;абрикосового пюре перемешенного с&nbsp;мукой, получается такая
                        кисленькая субстанция, вот в&nbsp;неё добавляют урбеч. А&nbsp;урбеч это
                        перемолотый лён, как правило чёрный, с&nbsp;добавлением местного горного
                        мёда. Всё вместе получается очень вкусно, и&nbsp;конечно, суперполезно.</p>

                    <p>Отлично позавтракав, мы&nbsp;с&nbsp;помощью Зайнаб составили наш маршрут
                        посещения местных достопримечательностей на&nbsp;сегодня, поблагодарили
                        хозяйку за&nbsp;радушный приём, сделали фото на&nbsp;память
                        и&nbsp;попрощавшись, отправились в&nbsp;путь.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div id="gocatl" className="carousel slide"
                         data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#gocatl"
                                    data-bs-slide-to="0" className="active" aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#gocatl"
                                    data-bs-slide-to="1" aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={gocatl_01}
                                     className="d-block w-100" alt="Гоцатль"/>
                            </div>
                            <div className="carousel-item">
                                <img src={gocatl_02}
                                     className="d-block w-100" alt="Гоцатль"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#gocatl" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#gocatl" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-12">
                    <p>Для начала поехали в&nbsp;знаменито село Хунзах. Столицу аварского
                        ханства, опять&nbsp;же &laquo;горячая точка&raquo; времён кавказской
                        войны, когда за&nbsp;контроль над этим местом (и&nbsp;не&nbsp;только)
                        соперничали воинство имама Шамиля и&nbsp;русские войска.</p>
                    <p>Хунзах также, а&nbsp;точнее расположенный совсем рядом с&nbsp;райцентром
                        аул Цада, является родиной всесоюзно признанного аварского поэта
                        и&nbsp;писателя Расула Гамзатова. При въезде в&nbsp;аул сейчас
                        установлена мемориальная композиция &laquo;Журавли&raquo;,
                        по&nbsp;названию самого известного стихотворения поэта.</p>
                    <p>Мы&nbsp;же приехали в&nbsp;Хунзах посмотреть на&nbsp;водопад Тобот.
                        Однако, из-за засушливой погоды, смогли увидеть только тоненькую ниточку
                        воды падающую в&nbsp;ущелье.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div id="hunzah" className="carousel slide"
                         data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#hunzah"
                                    data-bs-slide-to="0" className="active" aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#hunzah"
                                    data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#hunzah"
                                    data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={hunzah_01}
                                     className="d-block w-100" alt="Хунзах"/>
                            </div>
                            <div className="carousel-item">
                                <img src={hunzah_02}
                                     className="d-block w-100" alt="Хунзах"/>
                            </div>
                            <div className="carousel-item">
                                <img src={hunzah_03}
                                     className="d-block w-100" alt="Хунзах"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#hunzah"
                                data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon"
                                                          aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#hunzah"
                                data-bs-slide="next">
                                                    <span className="carousel-control-next-icon"
                                                          aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-12">
                    <p>Далее мы отправились на юго-восток от хунзахского района, в место
                        именуемое Карадахская теснина. Доезжаем до одноимённой таблички,
                        оставляем машину и зайдя за хлипкую оградку, которая нужна скорее
                        для того, чтобы обозначить вход, попадаем в ущелье. Минут 15-20
                        ходьбы и мы попадаем в теснину.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div id="tesnina" className="carousel slide"
                         data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button"
                                    data-bs-target="#tesnina"
                                    data-bs-slide-to="0" className="active"
                                    aria-current="true" aria-label="Slide 1"></button>
                            <button type="button"
                                    data-bs-target="#tesnina"
                                    data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button"
                                    data-bs-target="#tesnina"
                                    data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button"
                                    data-bs-target="#tesnina"
                                    data-bs-slide-to="3" aria-label="Slide 4"></button>
                            <button type="button"
                                    data-bs-target="#tesnina"
                                    data-bs-slide-to="4" aria-label="Slide 5"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={tesnina_01}
                                     className="d-block w-100" alt="Карадахская теснина"/>
                            </div>
                            <div className="carousel-item">
                                <img src={tesnina_02}
                                     className="d-block w-100" alt="Карадахская теснина"/>
                            </div>
                            <div className="carousel-item">
                                <img src={tesnina_03}
                                     className="d-block w-100" alt="Карадахская теснина"/>
                            </div>
                            <div className="carousel-item">
                                <img src={tesnina_04}
                                     className="d-block w-100" alt="Карадахская теснина"/>
                            </div>
                            <div className="carousel-item">
                                <img src={tesnina_05}
                                     className="d-block w-100" alt="Карадахская теснина"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#tesnina"
                                data-bs-slide="prev">
                                                        <span className="carousel-control-prev-icon"
                                                              aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#tesnina"
                                data-bs-slide="next">
                                                        <span className="carousel-control-next-icon"
                                                              aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <p>За&nbsp;тесниной начинаются высокие пороги реки, которая в&nbsp;это
                        время года выглядит как ручей. При определённой сноровке можно
                        взабраться и&nbsp;на&nbsp;них. Далее идти туристам не&nbsp;имеет
                        смысла, за&nbsp;порогами просто лежит русло реки усыпанное огромными
                        валунами.</p>
                    <p>Набираем здесь воды и&nbsp;идём в&nbsp;обратный путь.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <p>Конечной точкой всего нашего путешествия является Дербент, самый
                        южный и самый древний город в составе России. И мы начинаем движение
                        к нему.</p>

                    <p>По&nbsp;пути к&nbsp;трассе на&nbsp;Дербент, останавливаемся
                        на&nbsp;привал возле горной реки. Великолепное место, впечателение
                        от&nbsp;которого омрачает замусоренность берега. Отсутствие
                        элементарной культуры проезжающих оставляет прекрасное природное
                        место в&nbsp;плачевном состоянии.</p>
                </div>

                <div className="col-md-6">
                    <img src={prival_01}
                         alt="Привал у горной реки" className="img-fluid travel__img" loading="lazy"/>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-6">
                    <img src={derbent_vorota_01}
                         alt="Ворота Дербента" className="img-fluid travel__img" loading="lazy"/>
                </div>

                <div className="col-md-6">
                    <p>После привала двигаемся всё дальше на&nbsp;юг. Наконец,
                        мы&nbsp;у&nbsp;цели. Пригород Дербента встречает нас
                        многочисленными виноградниками, а&nbsp;город своими воротами,
                        сделанными, как и&nbsp;большинство домов в&nbsp;городе
                        из&nbsp;Дербентского камня, который является разновидностью
                        ракушечника. Если, например Ереван называют розовым городом
                        из-за оттенка туфа, из&nbsp;которого построен город,
                        то&nbsp;Дербент это город песчанных оттенков.</p>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-6">
                    <p>Так получилось, что первым местом где мы&nbsp;остановились
                        в&nbsp;Дербенте была армянская церковь, которая расположена
                        в&nbsp;самом центре города. Церковь не&nbsp;действующая,
                        построена была в&nbsp;1870-х годах, в&nbsp;настоящее время
                        используется как музей. Вход на&nbsp;территорию церкви был
                        недоступен из-за пандемических ограничений. Однако, для моей
                        жены не&nbsp;существует запретов по&nbsp;посещению армянских
                        церквей в&nbsp;любой точке света, и&nbsp;на&nbsp;следующий
                        день уговорив охранника, она таки провела нас
                        на&nbsp;терриотрию храма.</p>
                </div>

                <div className="col-md-6">
                    <img src={armenian_temple_01}
                         alt="Армянская церковь в Дербенте" className="img-fluid travel__img" loading="lazy"/>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-12">
                    <p>Договорившись с&nbsp;принимающей стороной,
                        мы&nbsp;поехали на&nbsp;место нашего прибывания. Нас
                        встречала женщина по&nbsp;имени Тамила, которая показала
                        нам своё жилище, и&nbsp;извинялась, за&nbsp;то, что
                        из-за внезапности нашего бронирования не&nbsp;успела
                        приготовить нам обед. Хотя, собственно, мы&nbsp;ничего
                        кроме места проживания и&nbsp;не&nbsp;бронировали.
                        Но&nbsp;тем, не&nbsp;менее тарелку с&nbsp;фруктами
                        Тамила всё&nbsp;же принесла, так как не&nbsp;могла
                        придти с&nbsp;пустыми руками. Вот оно, местное
                        гостипреимство.</p>

                    <p>Попив с&nbsp;нами чайку Тамила немного рассказала нам
                        о&nbsp;городе, ну&nbsp;а&nbsp;мы&nbsp;доложили
                        о&nbsp;впечатлениях о&nbsp;поездке. В&nbsp;Дербенте
                        мы&nbsp;всего две ночи, поэтому времени в&nbsp;обрез.
                        Наметили точки для посещения, Тамила звала нас
                        к&nbsp;себе на&nbsp;дачу&nbsp;&mdash; искупаться
                        в&nbsp;море. Вообще хозяйка показалась нам очень
                        интелегентной, тактичной женщиной, говорящей по-русски
                        совсем без акцента. Последнее, наверное, показатель
                        городского уроженца.</p>

                    <p>Вечером поехали на&nbsp;набережную. В&nbsp;Дербенте очень
                        красивая набережная, но&nbsp;дорогу к&nbsp;ней времено
                        разобрали, поэтому добраться до&nbsp;туда было весьма
                        проблемматично.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div id="embankment"
                         className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button"
                                    data-bs-target="#embankment"
                                    data-bs-slide-to="0" className="active"
                                    aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button"
                                    data-bs-target="#embankment"
                                    data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img
                                    src={embankment_01}
                                    className="d-block w-100"
                                    alt="Набережная Дербента"/>
                            </div>
                            <div className="carousel-item">
                                <img
                                    src={embankment_02}
                                    className="d-block w-100"
                                    alt="Набережная Дербента"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#embankment"
                                data-bs-slide="prev">
                                                                    <span className="carousel-control-prev-icon"
                                                                          aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#embankment"
                                data-bs-slide="next">
                                                                    <span className="carousel-control-next-icon"
                                                                          aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <p>После осмотра набережной, двинули обратно в&nbsp;центр.
                        Прогулявшись немного решили найти какое-нибудь заведение
                        где кормят, но&nbsp;нам не&nbsp;повезло. В&nbsp;итоге
                        поехали в&nbsp;супермаркет рядом с&nbsp;домом,
                        и&nbsp;набрали жраки в&nbsp;отделе готовых блюд.</p>

                    <p>Утром, заглянув в&nbsp;армянскую церковь, о&nbsp;чём
                        я&nbsp;писал выше, направились в&nbsp;сторону крепости
                        Нарын-кала (да, назавние уж&nbsp;больно схоже
                        с&nbsp;грузинской крепостью Нарикала в&nbsp;Тбилиси).
                        Цитадель эта, и&nbsp;отходящие от&nbsp;неё к&nbsp;морю
                        две стены, собственно и&nbsp;должны были
                        держать &laquo;Каспийские ворота&raquo; в&nbsp;Персию.
                        Да&nbsp;что там говорить, само название города Дербент
                        в&nbsp;переводе с&nbsp;персидского
                        означает&nbsp;&mdash; &laquo;закрытые врата&raquo;. Эти
                        врата и&nbsp;должны были защищать страны Закавказья
                        и&nbsp;Передней Азии от&nbsp;нашествий кочевников
                        с&nbsp;севера. Пока шли жене удалось сделать некоторые
                        фотографии города.</p>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-12">
                    <div id="derbent"
                         className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button"
                                    data-bs-target="#derbent"
                                    data-bs-slide-to="0" className="active"
                                    aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button"
                                    data-bs-target="#derbent"
                                    data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button"
                                    data-bs-target="#derbent"
                                    data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                            <button type="button"
                                    data-bs-target="#derbent"
                                    data-bs-slide-to="3"
                                    aria-label="Slide 4"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img
                                    src={derbent_01}
                                    className="d-block w-100"
                                    alt="Улицы Дербента"/>
                            </div>
                            <div className="carousel-item">
                                <img
                                    src={derbent_02}
                                    className="d-block w-100"
                                    alt="Улицы Дербента"/>
                            </div>
                            <div className="carousel-item">
                                <img
                                    src={derbent_03}
                                    className="d-block w-100"
                                    alt="Улицы Дербента"/>
                            </div>
                            <div className="carousel-item">
                                <img
                                    src={derbent_04}
                                    className="d-block w-100"
                                    alt="Улицы Дербента"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev"
                                type="button"
                                data-bs-target="#derbent"
                                data-bs-slide="prev">
                                                                        <span className="carousel-control-prev-icon"
                                                                              aria-hidden="true"></span>
                            <span
                                className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next"
                                type="button"
                                data-bs-target="#derbent"
                                data-bs-slide="next">
                                                                        <span className="carousel-control-next-icon"
                                                                              aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-12">
                    <p>Старый город с&nbsp;арабских времен разделён
                        на&nbsp;магалы. Сейчас их&nbsp;девять и&nbsp;все
                        они расположены между южной и&nbsp;северной
                        стенами исторической части города. Магалы
                        представляют собой участки жилой застройки
                        сосредоточенные вокруг мечети. Старейшая мечеть
                        Дербента находится в&nbsp;седьмом магале,
                        и&nbsp;является самой древней не&nbsp;только
                        в&nbsp;Дербенте, но&nbsp;и&nbsp;в&nbsp;СНГ.
                        Постройка её&nbsp;датируется 734&nbsp;годом,
                        т.е. спустя всего 102&nbsp;года после смерти
                        пророка. Джума означает, что это пятничная
                        мечеть для совершения всеобщей молитвы,
                        а&nbsp;значит самая крупная из&nbsp;мечетей
                        в&nbsp;старом городе.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div id="magals"
                         className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button"
                                    data-bs-target="#magals"
                                    data-bs-slide-to="0"
                                    className="active"
                                    aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button"
                                    data-bs-target="#magals"
                                    data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button"
                                    data-bs-target="#magals"
                                    data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img
                                    src={magals_01}
                                    className="d-block w-100"
                                    alt="Магалы и Нарын-кала"/>
                            </div>
                            <div className="carousel-item">
                                <img
                                    src={magals_02}
                                    className="d-block w-100"
                                    alt="Джума-мечеть"/>
                            </div>
                            <div className="carousel-item">
                                <img
                                    src={magals_03}
                                    className="d-block w-100"
                                    alt="Джума-мечеть"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev"
                                type="button"
                                data-bs-target="#magals"
                                data-bs-slide="prev">
                                                                            <span className="carousel-control-prev-icon"
                                                                                  aria-hidden="true"></span>
                            <span
                                className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next"
                                type="button"
                                data-bs-target="#magals"
                                data-bs-slide="next">
                                                                            <span className="carousel-control-next-icon"
                                                                                  aria-hidden="true"></span>
                            <span
                                className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <p>В&nbsp;самой мечети шла реконструкция, однако,
                        в&nbsp;её&nbsp;двор нам попасть удалось. Здесь
                        под сенью старых чинаров ведут неспшные
                        разговоры мужчины. По&nbsp;этим 800-летним
                        деревьям можно безошибочно определить
                        местонахождение мечети почти из&nbsp;любой точки
                        старого города. Кроме самого здания мечети здесь
                        расположены медресе и&nbsp;жилые помещения для
                        духовенства. Джума-мечеть внесена в&nbsp;состав
                        объектов курируемых ЮНЕСКО, поэтому
                        восстановительные работы проводятся под эгидой
                        этой организации.</p>

                    <p>Разговорились с&nbsp;местным смотрителем,
                        он&nbsp;рассказал немного о&nbsp;мечети. Жена
                        обратила внимание на&nbsp;отсутсвие в&nbsp;ней
                        минарета, старец объяснил нам, что это мечеть
                        изначально шиитская о&nbsp;чём свидетельствует
                        возвышающийся над куполом символ &laquo;длань
                        Фатимы&raquo; (жены пророка) вместо привычного
                        полумесяца, а&nbsp;минарет не&nbsp;всегда
                        обязательный атрибут мечетей.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <img src={history_01}
                         alt="История Дербента" className="img-fluid travel__img" loading="lazy"/>
                </div>

                <div className="col-md-6">
                    <p>Наконец, по&nbsp;узким улочкам магалов поднялись
                        до&nbsp;крепости. Крепость эта ещё доарабская,
                        и&nbsp;кем только не&nbsp;использовалась,
                        и&nbsp;естественно перестраивалась многократно.
                        Военная летопись Дербента кратко изложена
                        в&nbsp;табличке при входе в&nbsp;крепость.</p>
                    <p>Т.к. крепость расположена в&nbsp;самом высоком
                        месте города, из-за её&nbsp;стен отлично виден
                        город и&nbsp;море, т.е. территория которую
                        цитатель должна была контролировать.</p>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-12">
                    <div id="castle"
                         className="carousel slide"
                         data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button"
                                    data-bs-target="#castle"
                                    data-bs-slide-to="0"
                                    className="active"
                                    aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button"
                                    data-bs-target="#castle"
                                    data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button"
                                    data-bs-target="#castle"
                                    data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                            <button type="button"
                                    data-bs-target="#castle"
                                    data-bs-slide-to="3"
                                    aria-label="Slide 4"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img
                                    src={castle_01}
                                    className="d-block w-100"
                                    alt="Нарын-кала"/>
                            </div>
                            <div className="carousel-item">
                                <img
                                    src={castle_02}
                                    className="d-block w-100"
                                    alt="Нарын-кала"/>
                            </div>
                            <div className="carousel-item">
                                <img
                                    src={castle_03}
                                    className="d-block w-100"
                                    alt="Нарын-кала"/>
                            </div>
                            <div className="carousel-item">
                                <img
                                    src={castle_04}
                                    className="d-block w-100"
                                    alt="Нарын-кала"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev"
                                type="button"
                                data-bs-target="#castle"
                                data-bs-slide="prev">
                                                                                <span
                                                                                    className="carousel-control-prev-icon"
                                                                                    aria-hidden="true"></span>
                            <span
                                className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next"
                                type="button"
                                data-bs-target="#castle"
                                data-bs-slide="next">
                                                                                <span
                                                                                    className="carousel-control-next-icon"
                                                                                    aria-hidden="true"></span>
                            <span
                                className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-6">
                    <p>После посещения цитадели, присели
                        в&nbsp;расположенное на&nbsp;ярусах
                        пролётов лестницы ведущей к&nbsp;ней
                        заведение. Попить чайку
                        и&nbsp;что-нибудь зажевать. Антураж
                        великолепен, рядом с&nbsp;навесами
                        растут кусты с&nbsp;молодыми гранатами.
                        Подача аутентичная-эстетичная, армуды
                        вместо чашек и&nbsp;восточный чайник
                        с&nbsp;длинным носиком. Кушания
                        достойные всего вышеперечисленного, чай
                        с&nbsp;чабрецом, варенье из&nbsp;белой
                        черешни и&nbsp;пахлава с&nbsp;миндалём.
                        Были ещё пирожки с&nbsp;мясом,
                        но&nbsp;их&nbsp;название
                        я&nbsp;не&nbsp;запомнил. Кстати, здесь
                        в&nbsp;Дагестане очень популярны
                        промасленные лепёшки, с&nbsp;различными
                        начинками, которые здесь называют чуду.
                        Их&nbsp;продают на&nbsp;каждом шагу.</p>
                </div>

                <div className="col-md-6">
                    <div id="chaihana"
                         className="carousel slide"
                         data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button"
                                    data-bs-target="#chaihana"
                                    data-bs-slide-to="0"
                                    className="active"
                                    aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button"
                                    data-bs-target="#chaihana"
                                    data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button"
                                    data-bs-target="#chaihana"
                                    data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div
                                className="carousel-item active">
                                <img
                                    src={chaihana_01}
                                    className="d-block w-100"
                                    alt="Чайхана"/>
                            </div>
                            <div className="carousel-item">
                                <img
                                    src={chaihana_02}
                                    className="d-block w-100"
                                    alt="Чайхана"/>
                            </div>
                            <div className="carousel-item">
                                <img
                                    src={chaihana_03}
                                    className="d-block w-100"
                                    alt="Чайхана"/>
                            </div>
                        </div>
                        <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#chaihana"
                            data-bs-slide="prev">
                                                                                    <span
                                                                                        className="carousel-control-prev-icon"
                                                                                        aria-hidden="true"></span>
                            <span
                                className="visually-hidden">Previous</span>
                        </button>
                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#chaihana"
                            data-bs-slide="next">
                                                                                    <span
                                                                                        className="carousel-control-next-icon"
                                                                                        aria-hidden="true"></span>
                            <span
                                className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-6">
                    <img
                        src={beach_01}
                        alt="Пляж. Дербент." className="img-fluid travel__img" loading="lazy"/>
                </div>

                <div className="col-md-6">
                    <p>Направились к&nbsp;дому.
                        По&nbsp;дороги прихватили фруктов.
                        Килограмм черешни 100&nbsp;рублей.
                        Юг, однако.</p>

                    <p>Вечером решили воспользоваться
                        предложением
                        Тамилы&nbsp;&mdash; съездить
                        к&nbsp;ней на&nbsp;дачу. Благо ехать
                        туда было недалеко, минут двадцать
                        от&nbsp;города в&nbsp;южном
                        направлении. Тамила встретила нас
                        у&nbsp;своего дома
                        и&nbsp;мы&nbsp;вместе пошли
                        на&nbsp;пляж. Пляж был хорош, всё
                        тот&nbsp;же песок и&nbsp;та&nbsp;же
                        бесконечная береговая линия.
                        А&nbsp;значит комфорт
                        и&nbsp;простор.</p>

                    <p>После купания на&nbsp;пляже хозяйка
                        пригласила нас к&nbsp;себе домой.
                        Сыну выпала возможность временно
                        продолжить водные процедуры
                        в&nbsp;надувном бассейне. Тамила
                        с&nbsp;подругой угостила нас ужином.
                        Наверное, такое возможно только
                        на&nbsp;Кавказе.</p>

                    <p>В&nbsp;конце вечера после добрых
                        напутсвий в&nbsp;дорогу
                        от&nbsp;хозяев, мы&nbsp;отправились
                        домой. Уже утром мы&nbsp;должны были
                        отправляться в&nbsp;обратный путь
                        в&nbsp;далёкий Челябинск.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <p>Утром, собрав весь свой скарб,
                        мы&nbsp;двинулись в&nbsp;путь.
                        Ехали, в&nbsp;целом, в&nbsp;хорошем
                        темпе. Дороги в&nbsp;Дагестане
                        неплохие, даже в&nbsp;горах. Правда
                        в&nbsp;одном месте нас остановил
                        сотрудник ГИБДД, якобы за&nbsp;обгон
                        на&nbsp;перекрёстке, жду штрафа.
                        Удивительно, но&nbsp;это был
                        единственный случай за&nbsp;всё
                        путешествие, когда
                        я&nbsp;&laquo;попал
                        на&nbsp;деньги&raquo;. Памятуя
                        о&nbsp;том, что творилось
                        в&nbsp;Северной Осетии пять лет
                        назад, я&nbsp;ожидал худшего.
                        Но&nbsp;нет, всё в&nbsp;целом было
                        цивильно, никто денег
                        на&nbsp;дорогах не&nbsp;вымагал.</p>

                    <p>Постепенно доехали до&nbsp;границы
                        республики. На&nbsp;дагестанской
                        стороне привычно заглянул
                        на&nbsp;пост с&nbsp;документами.
                        Никаких очередей. На&nbsp;калмыцком
                        посту тоже остановили. Спросили про
                        усталый вид (уже второй сотрудник
                        за&nbsp;этот день этим
                        интересовался). Затем попросили
                        пройти с&nbsp;сотрудником
                        в&nbsp;постовое здание. Впервые
                        в&nbsp;жизни пришлось пописать
                        в&nbsp;баночку. После успешного
                        завершения этой процедуры
                        мы&nbsp;продолжили свой путь. Едем
                        по&nbsp;степям Калмыкии.</p>
                </div>

                <div className="col-md-6">
                    <img
                        src={steppe_01}
                        alt="Калмыцкая степь" className="img-fluid travel__img" loading="lazy"/>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div id="new-hurul"
                         className="carousel slide"
                         data-bs-ride="true">
                        <div
                            className="carousel-indicators">
                            <button type="button"
                                    data-bs-target="#new-hurul"
                                    data-bs-slide-to="0"
                                    className="active"
                                    aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button"
                                    data-bs-target="#new-hurul"
                                    data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div
                                className="carousel-item active">
                                <img
                                    src={new_hurul_01}
                                    className="d-block w-100"
                                    alt="Золотая обитель Будды Шакьямуни"/>
                            </div>
                            <div className="carousel-item">
                                <img
                                    src={new_hurul_02}
                                    className="d-block w-100"
                                    alt="Золотая обитель Будды Шакьямуни"/>
                            </div>
                        </div>
                        <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#new-hurul"
                            data-bs-slide="prev">
                                                                                        <span
                                                                                            className="carousel-control-prev-icon"
                                                                                            aria-hidden="true"></span>
                            <span
                                className="visually-hidden">Previous</span>
                        </button>
                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#new-hurul"
                            data-bs-slide="next">
                                                                                        <span
                                                                                            className="carousel-control-next-icon"
                                                                                            aria-hidden="true"></span>
                            <span
                                className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>

                <div className="col-md-6">
                    <p>Погода была несколько пасмурной, что
                        облегчало путь. К&nbsp;вечеру
                        добрались до&nbsp;Элисты.
                        Забронировали ту&nbsp;же гостинницу,
                        что и&nbsp;в&nbsp;прошлый раз. Будем
                        ночевать с&nbsp;комфортом.</p>

                    <p>В&nbsp;городе опять первым делом
                        к&nbsp;главному Хурулу. Так
                        и&nbsp;не&nbsp;открыли для
                        посещения. Жена поймала волшебный
                        свет, падающий на&nbsp;и&nbsp;без
                        того красивый фасад храма.</p>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-6">
                    <p>С&nbsp;дороги возникла мысль
                        о&nbsp;том, что неплохо
                        было&nbsp;бы где-нибудь
                        перекусить. Нашли заведение
                        с&nbsp;национальной кухней.
                        Думали
                        взять &laquo;на&nbsp;вынос&raquo;,
                        но&nbsp;нам предложили присесть
                        в&nbsp;беседку. Правда машину
                        попросили убрать подальше
                        от&nbsp;входа. Официально
                        в&nbsp;Калмыкии общепиту ещё
                        было запрещено работать
                        на&nbsp;приём гостей.</p>
                    <p>Взяли блюдо под
                        названием &laquo;Крутой
                        калмык&raquo;. Жирно, минимум
                        специй, кстати, тоже без
                        свинины. Вкусно, короче.</p>

                    <p>Перекусив, пошли ещё раз
                        прогуляться по&nbsp;центру,
                        а&nbsp;затем поехали
                        в&nbsp;гостинницу отдыхать.</p>
                </div>

                <div className="col-md-6">
                    <img
                        src={krutoi_kalmyk_01}
                        alt="Калмыцкая кухня" className="img-fluid travel__img" loading="lazy"/>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-6">
                    <img
                        src={volga_beach_01}
                        alt="Пляж на Волге" className="img-fluid travel__img" loading="lazy"/>
                </div>

                <div className="col-md-6">
                    <p>На&nbsp;следующий день
                        предстоял путь
                        до&nbsp;Волгограда. Решили
                        ехать небольшими перегонами,
                        чтобы было больше времени
                        для отдыха. Отпуск всё-таки.
                        Доехали рано, перекусисли
                        в&nbsp;кафе. Здесь всё
                        работает уже легально.
                        Получилось дозвониться
                        до&nbsp;той базы отдыха,
                        в&nbsp;которую
                        мы&nbsp;не&nbsp;попали
                        в&nbsp;прошлый раз. Сказали,
                        что можно приезжать
                        и&nbsp;устанавливать
                        палатку.</p>

                    <p>Приехали, оказалось, что база
                        тоже с&nbsp;деревянными
                        домиками, но&nbsp;есть место
                        между кустов где можно было
                        поставить палатку.
                        Здесь&nbsp;же похоже
                        не&nbsp;так давно жгли
                        упавшие ветки и&nbsp;прочий
                        мусор с&nbsp;территории.
                        Никакого устройства
                        территории не&nbsp;было
                        совсем. За&nbsp;это взяли
                        500&nbsp;рублей.</p>

                    <p>Зато рядом был берег реки
                        с&nbsp;видом
                        на&nbsp;Волгоград.</p>

                    <p>Рано утром какие-то алкаши,
                        занимавшие один
                        из&nbsp;домиков неподалёку,
                        разбудили своими пьяными
                        криками. Россия.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <p>В&nbsp;Волгоградской
                        и&nbsp;Саратовской областях
                        замучались с&nbsp;постоянным
                        ремонтом дорог.
                        Но&nbsp;мы&nbsp;не&nbsp;спешили,
                        делали частые привалы
                        в&nbsp;полях
                        и&nbsp;у&nbsp;Волги.
                        На&nbsp;ночёвку решили
                        остановиться в&nbsp;кемпинге
                        на&nbsp;притоке
                        Волги&nbsp;&mdash; реке
                        Чардым, что километрах
                        в&nbsp;пятидесяти
                        от&nbsp;Саратова.</p>

                    <p>По&nbsp;началу, всё было
                        неплохо. Кемпинг
                        на&nbsp;достаточном удалении
                        от&nbsp;города. Машины
                        паркуют за&nbsp;территорией.
                        Да, много народу,
                        но&nbsp;ведь суббота.
                        Отзывчивые ребята
                        организаторы. Люди вроде
                        вокруг все с&nbsp;семьями,
                        некоторые с&nbsp;лодками
                        и&nbsp;удочками. Поставили
                        палатку уже около восьми
                        вечера, ничто
                        не&nbsp;предвещало
                        беды...</p>

                    <p>И&nbsp;надо&nbsp;же такому
                        случиться, в&nbsp;тот момент
                        когда мы&nbsp;уже собирались
                        готовить ужин, прибыли новые
                        постояльцы и&nbsp;начали
                        разворачивать свои палатки
                        прямо рядом с&nbsp;нашей.
                        И&nbsp;вроде&nbsp;бы
                        2&nbsp;пары,
                        и&nbsp;с&nbsp;ними была даже
                        девочка младшего школьного
                        возраста, но&nbsp;меня сразу
                        насторожило количество
                        алкоголя, которое они
                        умудрились с&nbsp;собой
                        притащить и&nbsp;переносная
                        колонка.</p>

                    <p>Пока мы&nbsp;готовили пищу,
                        эти выродки начали заливать
                        в&nbsp;себя спиртное
                        и&nbsp;врубили свою акустику
                        на&nbsp;воспроизведение
                        чего-то
                        невнятного &laquo;тынц-тынц-быдыдымц&raquo;.
                        И,&nbsp;поскольку, уже
                        смеркалось и&nbsp;искать
                        новое место для палатки было
                        уже поздно, я&nbsp;понял что
                        нам предстоит тяжёлая
                        ночка.</p>

                    <p>К&nbsp;сожалению, мои
                        опасения подтвердились. Эти
                        свиньи, не&nbsp;взирая даже
                        на&nbsp;собственного&nbsp;ребёнка,
                        своим поросячим визгом
                        состящим в&nbsp;основном
                        из&nbsp;мата
                        и&nbsp;лексикона
                        отчисленного ПТУшника,
                        а&nbsp;также репертуаром
                        глухого сельского диск-жокея
                        распугали половину кемпинга.
                        А&nbsp;когда, ближе
                        к&nbsp;полуночи,
                        организаторы заставили-таки
                        этих тварей выключить свою
                        балалайку, их&nbsp;поросячий
                        визг никуда не&nbsp;делся,
                        и&nbsp;ещё долго
                        не&nbsp;давал заснуть
                        окружающим. Лично
                        я&nbsp;смог задремать только
                        благодаря наушникам
                        и&nbsp;плейлисту
                        с&nbsp;классической
                        музыкой.</p>

                    <p>В&nbsp;лучших традициях жанра
                        эти скоты ещё
                        и&nbsp;проснулись раньше
                        всех, видимо чтобы
                        опохмелиться.</p>

                    <p>Утром&nbsp;мы, выпив чаю,
                        немедленно снялись
                        с&nbsp;места и&nbsp;поехали
                        дальше.</p>

                    <p>По&nbsp;пути заехали
                        в&nbsp;&laquo;заповедный&raquo; город
                        Хвалынск, есть дорога
                        которая ведёт к нему по
                        живописному пути вдоль
                        Волги. Также посетили
                        здешний горнолыжный курорт.
                        Тут весьма симпатично, для
                        состоятельных господ есть
                        места для ночлега.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div
                        id="hvalynsk"
                        className="carousel slide"
                        data-bs-ride="true">
                        <div
                            className="carousel-indicators">
                            <button type="button"
                                    data-bs-target="#hvalynsk"
                                    data-bs-slide-to="0"
                                    className="active"
                                    aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button"
                                    data-bs-target="#hvalynsk"
                                    data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button"
                                    data-bs-target="#hvalynsk"
                                    data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                        </div>
                        <div
                            className="carousel-inner travel__img">
                            <div
                                className="carousel-item active">
                                <img
                                    src={hvalynsk_01}
                                    className="d-block w-100"
                                    alt="Хвалынск"/>
                            </div>
                            <div
                                className="carousel-item">
                                <img
                                    src={hvalynsk_02}
                                    className="d-block w-100"
                                    alt="Хвалынск"/>
                            </div>
                            <div
                                className="carousel-item">
                                <img
                                    src={hvalynsk_03}
                                    className="d-block w-100"
                                    alt="Хвалынск"/>
                            </div>
                        </div>
                        <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#hvalynsk"
                            data-bs-slide="prev">
                                                                                                <span
                                                                                                    className="carousel-control-prev-icon"
                                                                                                    aria-hidden="true"></span>
                            <span
                                className="visually-hidden">Previous</span>
                        </button>
                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#hvalynsk"
                            data-bs-slide="next">
                                                                                                <span
                                                                                                    className="carousel-control-next-icon"
                                                                                                    aria-hidden="true"></span>
                            <span
                                className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <br/>


            <div className="row">
                <div className="col-md-12">
                    <p>После последней ночи в
                        палатке, было решено
                        следующую провести
                        в&nbsp;гостинице,
                        желательно
                        в&nbsp;крупном городе. И
                        таким городом стала
                        Самара.</p>

                    <p>Надо сказать, что через
                        Самарскую губернию
                        я&nbsp;проезжал
                        несколько раз,
                        а&nbsp;вот
                        в&nbsp;её&nbsp;столице
                        побывал впервые. Вообще,
                        Самарская область, как
                        и&nbsp;пять лет назад,
                        была областью&nbsp;c,
                        пожалуй, лучшими
                        дорогами
                        на&nbsp;протяжении всего
                        пути с&nbsp;Урала
                        на&nbsp;Кавказ. Так ещё
                        областной центр здесь,
                        оказался едва&nbsp;ли
                        не&nbsp;лучшим городом
                        на&nbsp;этом пути
                        и&nbsp;немало удивил
                        нас.</p>

                    <p>Опять&nbsp;же отличная
                        дорожная сеть,
                        продуманная,
                        с&nbsp;хорошей
                        разметкой, с&nbsp;люками
                        выведенными чётко
                        в&nbsp;уровень асфальта,
                        с&nbsp;правильно
                        настроенными
                        светофорами, дающими
                        нечто похожее
                        на&nbsp;зелёную улицу
                        (не&nbsp;в&nbsp;час пик,
                        конечно). Здесь, как
                        и&nbsp;в&nbsp;Тольятти,
                        но&nbsp;только
                        в&nbsp;большем масштабе
                        применяется реверсивное
                        движение. И&nbsp;это
                        только впечатления
                        от&nbsp;перемещения
                        по&nbsp;Самаре
                        на&nbsp;машине.
                        Если&nbsp;же идти
                        пешком, то&nbsp;здесь
                        есть хорошая набережная
                        и,&nbsp;я&nbsp;так
                        понимаю, не&nbsp;одна.
                        Здесь есть прекрасный
                        и&nbsp;большой
                        исторический центр.
                        Здесь на&nbsp;улицах
                        много молодёжи,
                        а&nbsp;это показатель
                        развития города.</p>

                    <p>Это впечатления только
                        от&nbsp;одного
                        проведённого здесь
                        вечера. И&nbsp;нам
                        захотелось остаться
                        здесь ещё хотя&nbsp;бы
                        на&nbsp;день, чтобы
                        осмотреть всё
                        поподробней.
                        Но&nbsp;увы, время
                        поджимало, а&nbsp;ехать
                        в&nbsp;высоком темпе уже
                        не&nbsp;хотелось.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div
                        id="samara"
                        className="carousel slide"
                        data-bs-ride="true">
                        <div
                            className="carousel-indicators">
                            <button
                                type="button"
                                data-bs-target="#samara"
                                data-bs-slide-to="0"
                                className="active"
                                aria-current="true"
                                aria-label="Slide 1"></button>
                            <button
                                type="button"
                                data-bs-target="#samara"
                                data-bs-slide-to="1"
                                aria-label="Slide 2"></button>
                            <button
                                type="button"
                                data-bs-target="#samara"
                                data-bs-slide-to="2"
                                aria-label="Slide 3"></button>
                            <button
                                type="button"
                                data-bs-target="#samara"
                                data-bs-slide-to="3"
                                aria-label="Slide 4"></button>
                            <button
                                type="button"
                                data-bs-target="#samara"
                                data-bs-slide-to="4"
                                aria-label="Slide 5"></button>
                            <button
                                type="button"
                                data-bs-target="#samara"
                                data-bs-slide-to="5"
                                aria-label="Slide 6"></button>
                            <button
                                type="button"
                                data-bs-target="#samara"
                                data-bs-slide-to="6"
                                aria-label="Slide 7"></button>
                            <button
                                type="button"
                                data-bs-target="#samara"
                                data-bs-slide-to="7"
                                aria-label="Slide 8"></button>
                        </div>
                        <div
                            className="carousel-inner travel__img">
                            <div
                                className="carousel-item active">
                                <img
                                    src={samara_01}
                                    className="d-block w-100"
                                    alt="Самара"/>
                            </div>
                            <div
                                className="carousel-item">
                                <img
                                    src={samara_02}
                                    className="d-block w-100"
                                    alt="Самара"/>
                            </div>
                            <div
                                className="carousel-item">
                                <img
                                    src={samara_03}
                                    className="d-block w-100"
                                    alt="Самара"/>
                            </div>
                            <div
                                className="carousel-item">
                                <img
                                    src={samara_04}
                                    className="d-block w-100"
                                    alt="Самара"/>
                            </div>
                            <div
                                className="carousel-item">
                                <img
                                    src={samara_05}
                                    className="d-block w-100"
                                    alt="Самара"/>
                            </div>
                            <div
                                className="carousel-item">
                                <img
                                    src={samara_06}
                                    className="d-block w-100"
                                    alt="Самара"/>
                            </div>
                            <div
                                className="carousel-item">
                                <img
                                    src={samara_07}
                                    className="d-block w-100"
                                    alt="Самара"/>
                            </div>
                            <div
                                className="carousel-item">
                                <img
                                    src={samara_08}
                                    className="d-block w-100"
                                    alt="Самара"/>
                            </div>
                        </div>
                        <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#samara"
                            data-bs-slide="prev">
                            <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"></span>
                            <span
                                className="visually-hidden">Previous</span>
                        </button>
                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#samara"
                            data-bs-slide="next">
                            <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"></span>
                            <span
                                className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-12">
                    <p>Хорошо было спать
                        в&nbsp;гостиничном
                        номере,
                        но&nbsp;в&nbsp;планах
                        на&nbsp;следующую
                        ночь было взять
                        на&nbsp;себя
                        очередной риск
                        и&nbsp;остановиться
                        в&nbsp;палатке.
                        На&nbsp;этот раз
                        не&nbsp;на&nbsp;турбазе,
                        а&nbsp;просто
                        на&nbsp;берегу реки.
                        А&nbsp;конкретно
                        в&nbsp;Башкирии
                        на&nbsp;берегу реки
                        Инзер, рядом
                        с&nbsp;Абзановским
                        водопадом. Место это
                        красивое находится
                        в&nbsp;предгорье,
                        между деревнями Асы
                        и&nbsp;Абзаново.</p>

                    <p>Приехав сюда
                        мы&nbsp;осознали,
                        что несмотря
                        на&nbsp;понедельник,
                        народу здесь много.
                        Всё-таки сезон
                        отпусков. Правда
                        не&nbsp;все
                        отдыхающие были
                        с&nbsp;палатками,
                        кто-то просто
                        приехал пожарить
                        шашлычки
                        и&nbsp;на&nbsp;ночь
                        не&nbsp;оставался.
                        Поэтому,
                        мы&nbsp;решеили пока
                        палатку
                        не&nbsp;ставить,
                        а&nbsp;повременить
                        до&nbsp;вечера,
                        когда будет более
                        ясна диспозиция
                        ночующих
                        на&nbsp;берегу.
                        А&nbsp;пока пошли
                        к&nbsp;водопаду
                        окунуться.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div
                        id="inzer"
                        className="carousel slide"
                        data-bs-ride="true">
                        <div
                            className="carousel-indicators">
                            <button
                                type="button"
                                data-bs-target="#inzer"
                                data-bs-slide-to="0"
                                className="active"
                                aria-current="true"
                                aria-label="Slide 1"></button>
                            <button
                                type="button"
                                data-bs-target="#inzer"
                                data-bs-slide-to="1"
                                aria-label="Slide 2"></button>
                        </div>
                        <div
                            className="carousel-inner travel__img">
                            <div
                                className="carousel-item active">
                                <img
                                    src={inzer_01}
                                    className="d-block w-100"
                                    alt="река Инзер"/>
                            </div>
                            <div
                                className="carousel-item">
                                <img
                                    src={inzer_02}
                                    className="d-block w-100"
                                    alt="Абзановский водопад"/>
                            </div>
                        </div>
                        <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#inzer"
                            data-bs-slide="prev">
                                                                                                        <span
                                                                                                            className="carousel-control-prev-icon"
                                                                                                            aria-hidden="true"></span>
                            <span
                                className="visually-hidden">Previous</span>
                        </button>
                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#inzer"
                            data-bs-slide="next">
                                                                                                        <span
                                                                                                            className="carousel-control-next-icon"
                                                                                                            aria-hidden="true"></span>
                            <span
                                className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <p>В&nbsp;этом месте
                        река очень мелкая,
                        меня скрывало только
                        посередине. Из-за
                        стоящей жары вода
                        у&nbsp;берега
                        по&nbsp;ощущениям
                        прогрелась почти
                        до&nbsp;температуры
                        тела. Рай для
                        детворы.
                        У&nbsp;водопада
                        начиналось течение,
                        поэтому вода здесь
                        была холодной,
                        такой&nbsp;же как
                        падающая
                        со&nbsp;склона.
                        Течение сносило,
                        плыть было
                        невозможно, только
                        идти. Вода здесь
                        скрывает острые
                        камни, нужно быть
                        аккуратным.</p>

                    <p>Накупавшись,
                        вернулись
                        к&nbsp;машине.
                        Шашлычники
                        потихоньку
                        рассасывались,
                        но&nbsp;правда
                        и&nbsp;несколько
                        палаток добавилось.
                        Мы&nbsp;специально
                        прошли весь берег,
                        оценили контингент.
                        Осмотрели отдыхающих
                        на&nbsp;наличие
                        спиртного. Пьющих
                        в&nbsp;открытую
                        я&nbsp;так
                        и&nbsp;не&nbsp;углядел.
                        Башкирия, конечно,
                        не&nbsp;Дагестан
                        по&nbsp;строгости
                        общественного
                        порицания,
                        но&nbsp;тоже, всё
                        таки, имеет
                        исламский культурный
                        код. Правда
                        любителей подолбить
                        на&nbsp;природе
                        музычку
                        предостаточно, были
                        и&nbsp;кальянные
                        курители
                        и&nbsp;молодёж,
                        периодически дружным
                        криком возвещавшая
                        на&nbsp;весь
                        берег: &laquo;Мы&nbsp;счастливы!&raquo;.
                        Вот от&nbsp;таких
                        любителей природы
                        мы&nbsp;постарались
                        разместить свою
                        палатку как можно
                        дальше. Нашими
                        ближайшими соседями
                        были семьи
                        с&nbsp;малыми детьми
                        и&nbsp;стариками,
                        что внушало
                        оптимизм.</p>

                    <p>Ну&nbsp;и&nbsp;в&nbsp;целом,
                        всё прошло неплохо.
                        Один хозяин авто
                        неподалёку, всё-таки
                        решил устроить
                        небольшую часовую
                        дискотеку около
                        одиннадцати вечера,
                        но&nbsp;потом
                        прибрал громкость
                        своей магнитолы
                        до&nbsp;приемлемого
                        для окружающих
                        уровня.</p>

                    <p>Кароче, ночевать
                        в&nbsp;палатке это,
                        как оказалось, целая
                        наука, которой ещё
                        предстоит научиться,
                        но&nbsp;первый почин
                        сделан.</p>

                    <p>Утром проснулись
                        бодрячком,
                        приготовили завтрак
                        и&nbsp;неспеша
                        отправились
                        в&nbsp;путь. Нам
                        предстоял последний
                        участок пути
                        до&nbsp;Челябинска.
                        Обратно
                        на&nbsp;М5&nbsp;было
                        решено
                        не&nbsp;возвращаться,
                        а&nbsp;проехать
                        по&nbsp;дороге мимо
                        Белорецка, Учалов
                        и&nbsp;выйти
                        на&nbsp;трассу уже
                        в&nbsp;районе
                        Миасса.</p>

                    <p>Избранный путь,
                        конечно, не&nbsp;так
                        хорош в&nbsp;плане
                        качества дорожного
                        полотна, однако,
                        он&nbsp;и&nbsp;не&nbsp;предполагает
                        быстрой
                        езды&nbsp;&mdash; это
                        настоящяя горная
                        дорога, живописная
                        и&nbsp;азартная.
                        Пожалуй, этот
                        отрезок пути
                        до&nbsp;Миасса это
                        одна из&nbsp;самых
                        красивых дорог,
                        по&nbsp;которым
                        доводилось мне
                        ездить
                        на&nbsp;Урале. Тут
                        тебе и&nbsp;горы
                        и&nbsp;горные реки,
                        текущие вдоль
                        дороги,
                        и&nbsp;заповедные
                        леса
                        и&nbsp;аутентичные
                        деревни,
                        незагруженность
                        трассы, чистный
                        воздух. Здесь, прямо
                        возле домашнего
                        региона, можно
                        почувствовать себя
                        как
                        на&nbsp;Кавказе.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div
                        id="bash"
                        className="carousel slide"
                        data-bs-ride="true">
                        <div
                            className="carousel-indicators">
                            <button
                                type="button"
                                data-bs-target="#bash"
                                data-bs-slide-to="0"
                                className="active"
                                aria-current="true"
                                aria-label="Slide 1"></button>
                            <button
                                type="button"
                                data-bs-target="#bash"
                                data-bs-slide-to="1"
                                aria-label="Slide 2"></button>
                            <button
                                type="button"
                                data-bs-target="#bash"
                                data-bs-slide-to="2"
                                aria-label="Slide 3"></button>
                        </div>
                        <div
                            className="carousel-inner travel__img">
                            <div
                                className="carousel-item active">
                                <img
                                    src={bash_01}
                                    className="d-block w-100"
                                    alt="Башкирия"/>
                            </div>
                            <div
                                className="carousel-item">
                                <img
                                    src={bash_02}
                                    className="d-block w-100"
                                    alt="Башкирия"/>
                            </div>
                            <div
                                className="carousel-item">
                                <img
                                    src={bash_03}
                                    className="d-block w-100"
                                    alt="Башкирия"/>
                            </div>
                        </div>
                        <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#bash"
                            data-bs-slide="prev">
                                                                                                        <span
                                                                                                            className="carousel-control-prev-icon"
                                                                                                            aria-hidden="true"></span>
                            <span
                                className="visually-hidden">Previous</span>
                        </button>
                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#bash"
                            data-bs-slide="next">
                                                                                                        <span
                                                                                                            className="carousel-control-next-icon"
                                                                                                            aria-hidden="true"></span>
                            <span
                                className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <p>На&nbsp;этом рассказ
                        об&nbsp;этом
                        путешествии можно
                        считать законченным.
                        Вечером мы&nbsp;были
                        уже дома полные
                        эмоций
                        и&nbsp;впечатлений
                        на&nbsp;долгое
                        время.</p>
                    <p>Что касается самого
                        формата таких
                        путешествий,
                        то&nbsp;он&nbsp;мне
                        был всегда
                        по&nbsp;душе.
                        Собственный
                        транспорт
                        не&nbsp;ограничивает
                        в&nbsp;движении,
                        отсутствие
                        детального плана
                        передвижения
                        и&nbsp;заранее
                        забронированных мест
                        стоянок, придаёт
                        азарта
                        и&nbsp;чувства
                        свободы. Ну,
                        а&nbsp;наличие
                        палатки
                        и&nbsp;необходимых
                        для организации
                        стоянки снастей,
                        страхует
                        от&nbsp;неожиданностей
                        и&nbsp;даёт
                        возможность строить
                        ещё более вольные
                        маршруты. Очень рад
                        за&nbsp;людей,
                        которые имеют
                        достаточный уровень
                        сноровки
                        и&nbsp;техники,
                        а&nbsp;также
                        времени, для
                        путешествий
                        в&nbsp;подобном
                        формате
                        на&nbsp;&laquo;собсвенных
                        условиях&raquo; с&nbsp;полным
                        погружением
                        в&nbsp;природную
                        среду. Надеюсь сам
                        когда-нибудь
                        оказаться участником
                        такого
                        путешествия.</p>
                </div>
            </div>


        </div>
    );
};

export default VolgaCaspiy;
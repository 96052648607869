import React from 'react';
import '../../Travel/Travel.css';
import slovak_001 from '../../../images/deneb/001_slovak.jpg';
import slovak_002 from '../../../images/deneb/002_slovak.jpg';
import slovak_003 from '../../../images/deneb/003_slovak.jpg';
import slovak_004 from '../../../images/deneb/004_slovak.jpg';
import slovak_005 from '../../../images/deneb/005_slovak.jpg';
import slovak_006 from '../../../images/deneb/006_slovak.jpg';
import slovak_007 from '../../../images/deneb/007_slovak.jpg';
import slovak_008 from '../../../images/deneb/008_slovak.jpg';
import slovak_009 from '../../../images/deneb/009_slovak.jpg';
import slovak_010 from '../../../images/deneb/010_slovak.jpg';
import slovak_011 from '../../../images/deneb/011_slovak.jpg';
import slovak_012 from '../../../images/deneb/012_slovak.jpg';
import slovak_013 from '../../../images/deneb/013_slovak.jpg';
import slovak_014 from '../../../images/deneb/014_slovak.jpg';
import slovak_015 from '../../../images/deneb/015_slovak.jpg';
import slovak_016 from '../../../images/deneb/016_slovak.jpg';
import slovak_018_5 from '../../../images/deneb/018.5_slovak.jpg';
import slovak_019 from '../../../images/deneb/019_slovak.jpg';
import slovak_020 from '../../../images/deneb/020_slovak.jpg';
import slovak_021 from '../../../images/deneb/021_slovak.jpg';
import slovak_022 from '../../../images/deneb/022_slovak.jpg';
import slovak_023 from '../../../images/deneb/023_slovak.jpg';
import slovak_026 from '../../../images/deneb/026_slovak.jpg';
import slovak_027 from '../../../images/deneb/027_slovak.jpg';
import slovak_028 from '../../../images/deneb/028_slovak.jpg';
import slovak_029 from '../../../images/deneb/029_slovak.jpg';
import slovak_030 from '../../../images/deneb/030_slovak.jpg';
import slovak_031 from '../../../images/deneb/031_slovak.jpg';
import slovak_033 from '../../../images/deneb/033_slovak.jpg';
import slovak_034 from '../../../images/deneb/034_slovak.jpg';
import magyar_035 from '../../../images/deneb/035_magyar.jpg';
import magyar_036 from '../../../images/deneb/036_magyar.jpg';
import magyar_037 from '../../../images/deneb/037_magyar.jpg';
import magyar_038 from '../../../images/deneb/038_magyar.jpg';
import magyar_039 from '../../../images/deneb/039_magyar.jpg';
import magyar_040 from '../../../images/deneb/040_magyar.jpg';
import magyar_041 from '../../../images/deneb/041_magyar.jpg';
import magyar_042 from '../../../images/deneb/042_magyar.jpg';
import magyar_043 from '../../../images/deneb/043_magyar.jpg';
import magyar_045_5 from '../../../images/deneb/045.5_magyar.jpg';
import magyar_046 from '../../../images/deneb/046_magyar.jpg';
import magyar_047 from '../../../images/deneb/047_magyar.jpg';
import magyar_048 from '../../../images/deneb/048_magyar.jpg';
import magyar_049 from '../../../images/deneb/049_magyar.jpg';
import magyar_050 from '../../../images/deneb/050_magyar.jpg';
import magyar_051 from '../../../images/deneb/051_magyar.jpg';
import magyar_052 from '../../../images/deneb/052_magyar.jpg';
import magyar_053 from '../../../images/deneb/053_magyar.jpg';
import austria_054 from '../../../images/deneb/054_austria.jpg';
import austria_055 from '../../../images/deneb/055_austria.jpg';
import austria_056 from '../../../images/deneb/056_austria.jpg';
import austria_057 from '../../../images/deneb/057_austria.jpg';
import austria_058_r from '../../../images/deneb/058_r_austria.jpg';
import austria_061 from '../../../images/deneb/061_austria.jpg';
import austria_062_r from '../../../images/deneb/062_r_austria.jpg';
import austria_064_r from '../../../images/deneb/064_r_austria.jpg';
import austria_066_r from '../../../images/deneb/066_r_austria.jpg';
import austria_067_r from '../../../images/deneb/067_r_austria.jpg';
import austria_073 from '../../../images/deneb/073_austria.jpg';
import austria_073_2 from '../../../images/deneb/073_2_austria.jpg';
import austria_074 from '../../../images/deneb/074_austria.jpg';
import slovak_075 from '../../../images/deneb/075_slovak.jpg';
import slovak_076 from '../../../images/deneb/076_slovak.jpg';
import slovak_077 from '../../../images/deneb/077_slovak.jpg';
import slovak_078 from '../../../images/deneb/078_slovak.jpg';
import sechenya from '../../../images/deneb/sechenya.png';


const Deneb = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <p>Это рассказ о поездке в восточную и центральную Европу которая состоялась в ранней весной, в
                        марте 2017 года. Состав путешественников – 3 человека, в числе которых я и моя супруга, а также
                        наш старый знакомый Алексей Кирилов, который уже был нашим хорошим попутчиком во время поездки
                        по Армении – Грузии в 2014 году.</p>

                    <p>Поездка эта значилась под кодовым названием Дунайский тур, т.к. в ней мы собирались посетить три
                        столицы, каждая из которых стояла на реке Дунай. А началось всё, как водится, с билетов. Моя
                        супруга отыскала дешёвое предложение от лоукостера Победа из Москвы в Братиславу (Словакия). И
                        мы потихоньку начали разрабатывать маршрут и бронировать жильё по его ходу. В итоге план
                        маршрута был таков: Из Братиславы, на съёмной машине мы должны были двинуться на восток в Татры,
                        а затем в культурную столицу Словакии – город Кошице. Далее на юг до границы с Венгрией и
                        городка Мишкольц, а затем на запад в Будапешт. Завершающим манёвром должна была стать дорога до
                        австрийской столицы (Вены) и наконец, петля должны была замкнуться снова в Братиславе.</p>

                    <p>Когда маршрут был утверждён, бронирования жилья сделаны, нужно было решить вопрос с визами.
                        Ближайшим от нас местом, где делали словацкие визы, согласно сайту посольства Словакии, было,
                        как ни странно, посольство Венгрии в г. Екатеринбурге. Туда мы и отправились после
                        предварительной записи по телефону. В назначенный день и час мы с женой прибыли в место
                        назначения. Здание посольства Венгрии было разделено между посольствами двух представительств:
                        США и собственно Венгрии. Оплатив необходимую для получения визы страховку, на первом этаже
                        здания в офисе компании Pony Express, мы отправились на второй этаж, где были досмотрены
                        сотрудниками, как это снова ни странно, посольства США. После чего таки поднялись к «венграм».
                        Мы пришли точно в срок, однако, всё небольшое пространство перед окнами, как и сами окна, были
                        оккупированы группой студентов, в результате мы подошли к приёмному окну минут на 25 позже
                        назначенного срока, и соответственно время нашего официального приёма сократилось на это время.
                        После проверки всех наших заявлений, анкет, справок с работы, паспортов, дело дошло до листов
                        бронирования, и выяснялось, что, если мы указываем в маршрутном листе Австрийскую республику, то
                        бронь для места проживания в ней должна быть уже предоплачена. Соответственно нам либо нужно
                        было быстро оплатить забронированное место, либо предъявить бронь на эти даты в другом месте.
                        Сроку нам дали на это полчаса. Спустившись вновь на первый этаж, мы выяснили, что здесь,
                        случайно, буквально в пятидесяти метрах есть интернет кафе, куда мы, торопясь и отправились.
                        Здесь очень предусмотрительно можно было не только воспользоваться компьютером, но и принтером
                        поэтому, мы с благодарностью воспользовались этой возможностью, и распечатали ещё одну бронь, в
                        первом попавшемся отеле в Братиславе. А через 15 минут успешно предъявили её в посольстве. А для
                        пущего удобства, здесь же у женщины аффилированной с посольством, заказали доставку наших
                        паспортов по их готовности в Челябинск. Вуаля.</p>

                    <p>Арендой машины, как обычно, занимался я, и в этот, прямо скажем, невысокий сезон интересных по
                        цене предложений было довольно много (а именно это нас интересовало). Нас было всего трое,
                        поэтому подошла бы практически любая машина, способная карабкаться под сравнительно небольшие
                        уклоны асфальтовых европейских дорог. Интересней всех смотрелось предложение от словацкой фирмы
                        Eden cars, которое я нашёл на агрегаторе rentalcars.com, и забронировал автомобиль Skoda Fabia,
                        даже оставив небольшую предоплату. Однако, после бронирования, я так и не понял, куда мне
                        приезжать за машиной. При бронировании у меня был выбор только из вариантов: забрать машину в
                        аэропорту или в центре города, этот выбор я разрешил в пользу города, но адрес мне не указали
                        даже после предоплаты брони. В подтверждении брони был указан лишь адрес для обратной связи,
                        который впрочем, вёл на сервис, на первый взгляд, ни как не был связан не с Eden не с
                        Rentalcars. Оказалось, это был какой-то глобальный «кол-центр», работающий на широкий круг
                        компаний из сферы ренты. У них удалось выяснить, что Eden cars не имеет офиса в Братиславе и
                        работает по системе доставки машин до адреса, который укажет клиент, но вот только у меня, они
                        почему-то не поинтересовались до какого адреса нужно совершить доставку (видимо, надеясь
                        связаться со мной уже в Словакии). Но меня такой подход не устаивал и передал арендодателю через
                        этот «кол-центр» информацию об адресе доставки.</p>

                    <p>Постепенно, время нашего отъезда приближалось, мы созвонились с Алексеем, который проживает в г.
                        Москва, и он сообщил, что тоже полностью готов к путешествию. День "Х" настал.</p>

                    <p>Рано утром мы отправились в аэропорт Челябинска, и встали в очередь на регистрацию рейса Победы
                        до Москвы. Правила у них были такие до 10 кг багажа на каждого, и ручная кладь до определённых
                        габаритов примерно соответствующий небольшой женской сумочке. У нас было как раз две вещи в
                        багаж до 10 кг каждая, и у жены на плече миниатюрный рюкзачок, полностью соответствующий
                        габаритам допустимой ручной клади. Однако, когда очередь дошла до нас, и багаж уже ушёл на
                        погрузку, нам сообщили, что любые рюкзаки не допускаются. Когда я пытался парировать, что рюкзак
                        полностью соответствует допустимым габаритам и даже по виду напоминает рюкзак лишь наличием
                        второй лямки на спинке, мне сообщили, что поскольку есть две лямки – это всё равно рюкзак, и на
                        борт он может быть допущен только за дополнительную плату. Конечно, разумного объяснения запрета
                        проносить на борт предметы в противоречие своим же правилам, только на основании наличия у них
                        определённого количества лямок, кроме наглого выбивания бабла из клиентуры быть не может, и
                        данный факт чрезвычайно испортил нам настроение перед поездкой. Пришлось оплатить, по-моему,
                        девятьсот рублей за провоз рюкзачка и вернуться на стойку регистрации. Посадочные, наконец,
                        получены. Садимся в самолёт, вылетаем в Москву.</p>

                    <p>Прибываем в аэропорт Внуково. До сих пор не доводилось здесь бывать. Аэропорт очень приличный,
                        современный и неперегруженный, по крайней мере, на тот момент. Тем не менее, в общем зале
                        сидячих мест не много, но свободные всё же нашлись. Здесь нам торчать несколько часов до
                        регистрации на следующий рейс. Наш друг Алексей обещал приехать к самому её началу. Поскольку в
                        самолёте «Победа» не кормит, кушать приходиться в кафешках при аэропорте. Вскоре к нам
                        присоединяется прибывший в аэропорт Лёха, и практически сразу начинается регистрация на наш рейс
                        до Братиславы. Предварительно спрятав рюкзачок жены в чемодан, идём к стойкам регистрации. Но
                        перед ними нас встречает человек, вероятно сотрудник авиакомпании и просит предъявить документы.
                        Мы передаём ему билеты и паспорта, и только тут выясняется, что виза у нас с женой не словацкая,
                        а венгерская. Почему так произошло, что мы подавали на словацкую визу, а выдали нам, всё-таки,
                        венгерскую я не знаю, но до этого момента мы не обращали на это внимания. Но поскольку виза
                        венгерская, то нас спросили как мы собираемся добираться до Венгрии, на что я сказал, что мы
                        забронировали авто в Братиславе. Следующей просьбой от сотрудника была: «Подтвердите
                        бронирование автомобиля». У меня была распечатка с бронью машины, однако, не понятно, что мы
                        должны были, предъявить, если, к примеру, мы хотели бы добраться до Венгрии на автобусе, поезде
                        или с помощью иных видов трансфера.</p>

                    <p>Садимся в Братиславе. Аэропорт маленький, на вход всего два окна. Причем одно работает только для
                        граждан, а второе очень медленно. Скапливается очередь, мы в её хвосте, потому что толкаться на
                        выходах и на входах недолюбливаем. Когда очередь доходит до нас, сотрудница пограничного
                        контроля долго осматривает документы и спрашивает о том, сколько мы дней будем в Словакии и
                        какие у нас планы. Из неожиданного для нас, вопросы она задаёт на русском, хоть и не очень
                        уверенно. Наконец, выходим в зал с багажом, где уже давно крутятся наши чемоданы. Открываем
                        один, чтобы достать, спрятанный рюкзачок жены, чем привлекаем двух мужчин в форме, стоящих на
                        выходе из зала. В итоге они попросили нас открыть наш багаж для досмотра, что нам пришлось
                        сделать. Выходим из здания аэропорта последними, т.к. других рейсов на прилёт в это время просто
                        не было.</p>

                    <p>Ищем такси, чтобы добраться до города. Естественно искать долго не приходится. Рядом с выходом
                        выстроилась очередь из местных таксистов, нас отправляют к первому из очереди. Наверняка сейчас
                        зарядят цену, но разбираться с работой общественного транспорта на тот момент охоты не было.
                        Садимся и едем в город. Таксист Мартин выглядит интеллигентно, он быстро довозит нас до
                        городского массива, где мы встаём в пробку. Чёткое попадание в час-пик, все возвращаются с
                        работы. Наконец, добираемся до отеля, который находиться неподалёку от центра и располагается в
                        старом здании. Мартин озвучивает цену поездки 23 евро, забегая вперёд, скажу, что обратно в
                        аэропорт мы доберёмся почти в три раза дешевле.</p>

                    <p>Отель называется Elisabeth Old Town. Входим в парадную, оплачиваем номера, получаем ключи,
                        поднимаемся на второй этаж. Номера чистые, уютные, со всеми необходимыми удобствами. Что
                        немаловажно, для нас, это наличие чайника в номере, и он здесь присутствовал. В общем, наш номер
                        был отличный, для полного счастья можно было добавить ещё холодильник, но мы и без него
                        обошлись.</p>

                    <p>Немного переведя дух, решили выйти оглядеть окрестности. Около половины восьмого, уже темно, и на
                        улицах совсем немного людей. Осмотрели церковь неподалёку, такую пряничную, небесно-голубого
                        цвета. Прошлись по узким улочкам, добрели до большого молла, где посетили супермаркет Billa, с
                        целью закупа продуктов на завтра. На сегодня хватит, идём в гостиницу ночевать.</p>

                    <img src={slovak_011} alt="" className="img-fluid travel__img"/>

                    <p>На следующее утро просыпаемся очень рано около 4:30 по местному, сказывается разница во времени.
                        Но мы не одни такие жаворонки, через полчаса за окном начинают шевелиться коммунальные службы,
                        делать рабочие приготовления лавочники, ходить прохожие.</p>

                    <p>Неспешный душ и завтрак. Списываемся с Лёхой, он тоже проснулся и обещает скоро зайти. Сказано –
                        сделано, и наша прогулка по городу начинается с раннего утра. Первое впечатление о Братиславе –
                        город небольшой, не перенаселённый, спокойный, скромный, хотя и не безынтересный. Центр с шармом
                        историчности. Средний житель средних же лет и не бедствующий. Автомобили в основном новые,
                        чешские, и других представителей WAG. Расслоения не чувствуется, бездомных вообще не помню.</p>


                    <img src={slovak_001} alt="" className="img-fluid travel__img"/>

                    <p>Тянемся к историческому центру. У входа через Михайловские ворота красивый мостик, и башня с
                        часами, далее пешеходные улицы.</p>

                    <img src={slovak_002} alt="" className="img-fluid travel__img"/>

                    <p>На них есть соборы, дом ратуши и другие здания административного значения. Здесь, как-положено,
                        множество сувенирных магазинчиков, баров, кафешек.</p>

                    <img src={slovak_003} alt="" className="img-fluid travel__img"/>


                    <img src={slovak_007} alt="" className="img-fluid travel__img"/>

                    <p>Центр разделён дорогой, после которой рельеф вздымается вверх. Там на вершине холма белеют мощные
                        стены замка - Братиславский град, к которыму мы и стремимся.</p>

                    <img src={slovak_004} alt="" className="img-fluid travel__img"/>

                    <p>Проходя через пешеходные улицы, встречаем множество интересных зданий.</p>

                    <img src={slovak_005} alt="" className="img-fluid travel__img"/>

                    <p>Осмотр града начинаем снаружи, затем покупаем билет на осмотр внутреннего пространства замка.
                        Широкие коридоры и залы с картинами и некоторой исторической утварью. Особенно интересен подъём
                        на одну из его башен замка. Отсюда открывается вид на Дунай и город с высоты.</p>

                    <img src={slovak_008} alt="" className="img-fluid travel__img"/>

                    <p>Осмотр замка занимает довольно много времени, мы проголодались и устали.</p>

                    <img src={slovak_009} alt="" className="img-fluid travel__img"/>

                    <p>Идём ужинать в Дивный Джанко. Хорошая харчевня с традиционной кухней, здесь многолюдно, и как
                        заметил Алексей Кириллов: «Наверняка вкусно кормят, т.к. здесь кушают полицейские» - цитата
                        неточная. И действительно, блюда оказались аппетитными, хотя и простыми, а порции большими. Я
                        снова не доел.</p>

                    <p>С официантами, да и вообще со словаками объясняться лучше по-русски. Т.к. русский они понимают
                        интуитивно, и точно лучше, чем английский. И это не удивительно, множество слов в словацком
                        языке очень похожи на русские или просто понятны для русскоговорящих людей. Например приветствие
                        по-словацки будет: «Добрый дэн», слово «хорошо» звучит как «добрэ», «пожалуйста» - «просим». На
                        аптечной вывеске будет слово «Lekaren», а на почтовой «Posta», только над латинской «S», будет
                        небольшая галочка. Есть и курьёзные для нас слова, например, слово «продукты» звучит как
                        «Потравины», а слово «внимание» - «Позор».</p>

                    <img src={slovak_006} alt="" className="img-fluid travel__img"/>

                    <p>Вечером пошли на мост через Дунай, здесь их несколько один с «летающей тарелкой» наверху, мы же
                        пошли на зелёный. Тут ходят трамваи и прохожие, а также бегают тренирующиеся (коих довольно
                        много) и ездят велосипедисты.</p>

                    <img src={slovak_010} alt="" className="img-fluid travel__img"/>

                    <p>Наелись, нагулялись, пошли отдыхать. Завтра отправляемся в путь.</p>

                    <p>Наступило утро, сегодня был день нашего отъезда из Братиславы по намеченному маршруту. Но чтобы
                        уехать, нам нужно было транспортное средство, которое нам должны были предоставить сотрудники
                        компании проката Eden cars. На всякий случай решили позвонить им по указанному в брони телефону.
                        Вечером прошлого дня я уже пытался это сделать, но не дозвонился. Идём на ресепшн с просьбой
                        позвонить и на этот раз удачно. Мне сообщают, что всё в порядке, машина прибудет вовремя.</p>

                    <p>Вскоре раздаётся звонок в номер, прибыл сотрудник проката. С документами спускаюсь вниз, нахожу
                        машину недалеко от входа. Меня приветствует молодой сотрудник, он просит водительское
                        удостоверение и паспорт. Оплачиваю остаток суммы на месте картой. Франшизу тоже замораживают на
                        карте. Сотрудник пытается впарить мне дополнительную страховку, но я отказываюсь. Спрашиваю его
                        о платных дорогах. Парень рассказывает о том, что дороги в Словакии уже оплачены на эту машину,
                        а вот в Венгрии и в Австрии необходимо будет приобретать талоны на въезде. Отлично, можем
                        ехать.</p>

                    <p>Собираем невеликий скарб, грузимся и начинаем движение. Шкода совсем новая, и по новой евромоде
                        оборудована старт-стоп системой, к чему я совсем был не готов, и приудивился когда она заглохла
                        на первом светофоре. Мы постепенно выезжаем на автостраду и движемся в восточном направлении в
                        горы. Но первым пунктом нашего маршрута должна стать Банска-Штьявница - небольшой городок у
                        подножья Татр.</p>

                    <p>Навигатор уводит нас с трассы, и мы едем по узенькой дорожке через холмистую местность не густо
                        покрытую лесным массивом. Местность кажется захолустной и почти заброшенной, как внезапно
                        заезжаем ухоженный почти «игрушечный» городок, каждым своим зданием погружающий нас в
                        историю.</p>

                    <img src={slovak_012} alt="" className="img-fluid travel__img"/>

                    <p>Мы были немало удивлены той концентрацией красивейших строений, которую нам представила
                        Штьявница, когда мы припарковали авто и прошли буквально 200 метров по одной её улицам.</p>

                    <img src={slovak_013} alt="" className="img-fluid travel__img"/>

                    <p>Судя по обилию известных символов на зданиях, город заложили вольные каменщики, и надо сказать
                        заложили основательно.</p>

                    <img src={slovak_014} alt="" className="img-fluid travel__img"/>

                    <p>Здесь же располагается замок, точнее замка два Старый град и новый. Мы посетили старый, он
                        неподалёку от центра. Полазали по стенам, внутри которых также имеются помещения типа тюремных
                        камер и даже пыточной. Осмотрели внутренний двор, залезли на башню с колоколом, с которой
                        открывается вид на город.</p>

                    <img src={slovak_015} alt="" className="img-fluid travel__img"/>

                    <p>Замок сооружался для защиты от турецких набегов и был исключительно фортификационным сооружением,
                        без какого-либо лоска или парадности, как например Братиславский град.</p>

                    <p>Вообще Банска-Штьявница это изначально шахтёрский город, здесь издревле занимались добычей. И
                        впоследствии, она превратилась в довольно крупный город. Здесь в местных штольнях впервые при
                        добыче были использованы взрывчатые вещества, тогда это был порох. А впоследствии, (в XVIII
                        веке) в городе даже возникает горная Академия.</p>

                    <p>Здесь в Банской-Штьявнице, что совсем удивительно, есть памятник советскому солдату-освободителю,
                        он располагается в крохотном скверике на окраине исторической части города. Возле него мы
                        почувствовали себя как на своей территории, и решили перекусить рядышком на лавочке. Правда,
                        перед нашим уходом сквер решили осквернить два местных алкаша, усевшись на лавку напротив с
                        пивом. Хотя не знаю, запрещено ли в Словакии распития пива в общественных местах. Ну да
                        ладно.</p>

                    <img src={slovak_016} alt="" className="img-fluid travel__img"/>

                    <p>Садимся в машину, и отправляемся обратно на трассу, чтобы продолжить путь на восток в горы.
                        Постепенно мы приближались к Высоким Татрам, поэтому виды кругом становились всё величественнее
                        и интересней. К сожалению, вместе с высокими горами на нас надвигались сумерки, поэтому дальше
                        красота уже скрылась от нас за темнотой. И только некоторые особенно крупные силуэты гор,
                        заставляли нас удивляться.</p>

                    <p>Вообще-то сначала, мы немного напутали с адресом, поэтому слегка проехали место назначения
                        местечко Нова Лесна, приехав в посёлок с одноимённым горам названием Высокие Татры, но зато
                        нашли там, чуть ли не последнюю открытую кафешку, и позволили себе выпить по чашке чая.</p>

                    <p>После этого мы вернулись по дороге назад, и, отыскав нужный поворот, достигли искомого места. Это
                        был гостевой дом Pension Crystal. Похоже, нас тут не особо ждали. Во дворе было темно, дверь
                        заперта, и даже на стук в дверь не ответили. В глубине двора горели три окна. Подойдя к ним, мы
                        увидели за ними мужчину, а он увидел нас. Это был хозяин дома. Он открыл нам дверь,
                        поинтересовался кто мы и откуда, и начал что проверять в своих записях. После этого он разрешил
                        припарковать нашу машину во дворе рядом с его машинами, что я и сделал. Мы вошли в дом. Мужчина
                        показал нам наши комнаты. Они располагались на мансарде второго этажа. В каждой из них было всё
                        что нужно, включая душ. Вами комнаты были весьма уютны и ухожены.</p>

                    <p>Хозяин поняв, что мы из России сразу начал говорить с нами на русском. В его речи было много
                        ошибок, но он обладал солидным словарным запасом, так, что взаимопонимание мы нашли без труда.
                        Мы спросили, где тут можно перекусить, на что он ответил, что т.к. предварительно мы ужин не
                        заказывали, он не сможет нас накормить, но километрах в трёх есть ресторан. Также мы можем
                        свободно пользоваться чайным столом, который располагался в столовой. Мы решили так и сделать, и
                        перекусить здесь тем, что мы прихватили с собой в дорогу, а на утро заказали завтрак.</p>

                    <p>Прекрасное место. Здесь в столовой располагался камин, рядом с которым мы и уселись, накрыв его,
                        чем Бог посла, и налив чайку. Здесь в горах было прохладно, поэтому тепло камина и уютная
                        обстановка способствовали приятному досугу.</p>

                    <img src={slovak_018_5} alt="" className="img-fluid travel__img"/>

                    <p>После ужина мы вернулись в свои комнаты для ночлега, запланировав на завтра небольшой поход в
                        горы.</p>

                    <p>С утра мы неторопливо спустились на завтрак. Хозяин с хозяйкой постарались на славу, приготовив
                        нам щедрый разнообразный стол. Тем, что нам предлагалось, в принципе можно было накормить вдовое
                        больше народу.</p>

                    <p>Поблагодарив за приём мы откланялись, предварительно расспросив о интересном маршруте на
                        пару-тройку часов.</p>

                    <p>Наша машина вновь двигалась в сторону туристического посёлка Высокие Татры, достигнув которых мы
                        принялись искать парковочное место.</p>

                    <img src={slovak_019} alt="" className="img-fluid travel__img"/>

                    <p>Наша машина вновь двигалась в сторону туристического посёлка Высокие Татры, достигнув которых мы
                        принялись искать парковочное место. И как мы поняли, вблизи нужного нам места припарковаться
                        можно было только за деньги. И оставив машину, мы принялись за поиски способов оплаты нашей
                        стоянки, и наткнулись на молодого человека, который работал при местном подъёмнике ни то
                        инструктором, ни то работником проката спортинвентаря. Он был весьма приветлив, неплохо понимал
                        и даже говорил по-русски, и был немало удивлён, узнав, что мы с Урала, и почему-то думал, что
                        наши (Уральские горы) значительно выше местных. Он рассказал нам он местных пеших маршрутах, и
                        благодаря его помощи, мы смогли правильно сориентироваться на местности. В результате мы
                        подъехали к фуникулёру, который забросил нас повыше в горы, где повсеместно лежал снежный
                        покров. С этого места уже можно было отправляться по одному из доступных маршрутов.</p>

                    <img src={slovak_020} alt="" className="img-fluid travel__img"/>

                    <p>Мы решили пойти по самому недолгому, до ручьев. Маршрут шёл мимо небольшой избушки, в которой
                        располагалась кафешка со звучным названием «Белякова Хата», и далее вниз по тропе к ручьям.</p>

                    <img src={slovak_021} alt="" className="img-fluid travel__img"/>

                    <p>Спустя 15-20 минут неспешной прогулки по прекрасной горной местности мы достигли цели.</p>

                    <p>Вид, как и атмосфера природной чистоты места и замечательного воздуха были под стать друг другу.
                        Образ не слишком крутых порогов горной речки спускающейся меж белеющих склонов полностью
                        оправдывал не слишком утомительный путь к месту. И запечатлев его в различных цифровых формах,
                        мы отправились в обратный путь.</p>

                    <img src={slovak_022} alt="" className="img-fluid travel__img"/>

                    <p>Вернувшись к «Беляковой Хате» мы решили зайти на чашку чая, что было, кстати, в смысле
                        поддержания энергетического и теплового баланса.</p>

                    <p>Фуникулёр спустил нас вниз к машине, и под впечатлением интересного и красивого места мы
                        отправились на поиски новых впечатлений.</p>

                    <p>Немного спустившись с Высоких Татр мы проезжали небольшой предгорный городок под названием
                        Кежмарок и решили остановиться и пройтись немного. Припарковав авто недалеко от старого замка мы
                        пошли пешеходной улицей по направлению к его центру.</p>

                    <img src={slovak_023} alt="" className="img-fluid travel__img"/>

                    <p>Город мне сразу понравился. Тихий, умиротворённый, содержащий здесь в центре исключительно
                        историческую застройку. Время как будто бы застыло здесь пару веков назад, и вся атмосфера
                        города погружала сознание в какой-то медитативный транс. Мне кажется, это место идеально
                        подходит, например, для писателя, который приехал сюда на несколько лет, чтобы создать главный в
                        своей жизни роман, смыслы для которого можно постичь прямо здесь, гуляя по этим улицам и вдыхая
                        этот воздух.</p>

                    <img src={slovak_026} alt="" className="img-fluid travel__img"/>

                    <p>После душевного отдохновения в центре Кежмарока, мы заехали в местный супермаркет за провизией и,
                        выехав из города, достигли автострады ведущей на восток, а затем сворачивающей на юг к городу
                        Кошице, куда мы и прибыли ранним вечером.</p>

                    <p>Наш отель с названием Penzion Grand, располагался в историческом центре города, но машину можно
                        было бесплатно оставить на улице с противоположной стороны от входа в гостиницу. В холе отеля
                        располагалась обширная оранжерея с различными растениями, буквально всё было уставлено ими. В
                        центре большого зала находились обеденные столики. На ресепшн мы заполнили анкеты, зачем-то на
                        каждого гостя, взяли ключи и поднялись на второй этаж в свои номера. Надо сказать, номера
                        располагались в мансарде, но были очень просторными с высокими потолками, хотя и не очень
                        уютными и не отличающиеся педантичной чистотой убранства. В целом не самый плохой вариант, чтобы
                        переночевать, существенный для нас недостаток — нет чайника в номерах.</p>

                    <img src={slovak_027} alt="" className="img-fluid travel__img"/>

                    <p>Оставив вещи, мы пошли на прогулку по городу. Поскольку мы уже находились в центре, идти до
                        основных достопримечательностей пришлось недолго.</p>

                    <img src={slovak_028} alt="" className="img-fluid travel__img"/>

                    <p>Кошице — это второй город Словакии после Братиславы. И он является его по сути культурной
                        столицей. Здесь множество различных учебных заведений, университетов, государственных
                        учреждений, театров, храмов. Пройдя немного по улице, мы тут же наткнулись на множество красивых
                        зданий, а свернув на соседнюю, немало были удивлены увидев кафедральный собор.</p>

                    <img src={slovak_029} alt="" className="img-fluid travel__img"/>

                    <p>Он производил впечатление почти невероятное, такой храм ожидаешь увидеть в Вене или Милане, но
                        никак не в таком небольшом городке как Кошице. Тем интереснее было осматривать его, в душе было
                        почти детское удивление, и пытливое настроение, которое бывает, когда что-то превосходит твои
                        ожидания. Это был собор Святой Елизаветы Венгерской, построенный в готическом стиле между концом
                        XIV и началом XVI веков.</p>

                    <img src={slovak_030} alt="" className="img-fluid travel__img"/>

                    <p>После внешнего осмотра собора, почти сразу стемнело. Мы ещё побродили по улочкам, наткнувшись на
                        множество красивых зданий, а также памятник советскому солдату освободителю (да, в Кошице он
                        тоже есть).</p>

                    <img src={slovak_031} alt="" className="img-fluid travel__img"/>

                    <p>А затем зашли перекусить в одно из заведений, расположенное в проулке, отходящем от центральной
                        пешеходной улицы. Заведение это было совершенно безлюдное, в какой-то момент мы даже оказались в
                        нём одни. Не помню, что я там ел, запомнил только кувшин зелёного чая, наполненный свежими
                        мятными листами, и длинную ложку, опущенную в него для перемешивания. На этом осмотр города, по
                        сути, был завершён, и мы пошли в отель, чтобы переночевать.</p>

                    <p>На следующее утро мы спустились на завтрак в отеле. Завтрак был включён в стоимость номеров,
                        однако был невысокого качества. В него входили, так называемые, континентальные закуски, чай в
                        пакетиках, яичницу сразу разобрали, так, что на нас не хватило. Ну да ладно. Мы ещё раз пошли в
                        центр, чтобы быстро оглядеть его при дневном свете, и зайти куда-нибудь на чашечку кофе.</p>

                    <img src={slovak_033} alt="" className="img-fluid travel__img"/>

                    <p>Эта нехитрая программа была полностью выполнена где-то за час. После чего мы удовлетворённые
                        выехали из города. Наш путь лежал к венгерской границе.</p>
                    <p>Приближение к границе мы почувствовали, по практически полному отсутствию трафика, и по
                        некоторому количеству фур, стоящему вдоль обочины.</p>

                    <p>На границе никакого КПП, можно сказать, нет. Стоит будка, где можно оформить виньетку, и
                        неподалёку, с обратной стороны дороги, стоит одна полицейская машина. Мы зашли в эту будку и
                        заплатили, что-то около 10 Евро за проезд по дорогам Венгрии на 10 дней (минимальный срок). При
                        этом машина заноситься в базу данных, никакого документа на руки не дают. После этого мы
                        продолжили свой путь уже по мадьярской земле.</p>

                    <p>Первым местом интереса в Венгрии у нас были окрестности города Мишкольц. А конкретно так
                        называемая «Пещера Анны», а также местная купальня на термальных источниках. Первым делом было
                        решено ехать в пещеру. Координаты привели в горно-лесную зону прямо за выездом из города. Мы
                        припарковались на полупустой парковке, и вышли в небольшой сквер. Вокруг было малолюдно,
                        туристический сезон был явно ещё не начат. Побродив вдоль ручьев на склоне гор пещеру мы не
                        нашли, отсутствие нормальных указателей тоже сбивало с толку. Было решено спросить дорогу, но с
                        английским у местных были проблемы, но направление нам, всё-таки, указали. Следуя этим
                        направлением, мы наткнулись и на указатель, однако, за ним указателей уже не было, и нас
                        заманила тропа, продолжающая прежнее заданное направление движения. В итоге мы вышли к какому-то
                        пруду, где было совсем безлюдно, и прошли по тропе вдоль него пару–тройку километров, так ничего
                        и не увидев. Пришлось возвращаться назад, после чего оказалось, что нужно было совершить поворот
                        вдоль трассы, сразу за которым находился вход на территорию огромного дворца Палас отеля, за
                        которым пряталась искомая пещера. Но, увы, после установления её местонахождения, оказалось, что
                        в этот день она не работает. Зато размялись, успокаивали себя мы. К тому же рядом с входом в
                        пещеру располагался небольшой симпатичный водопад, рядом с которым мы с удовольствием
                        пофотографировались.</p>

                    <img src={slovak_034} alt="" className="img-fluid travel__img"/>

                    <p>После вынужденного пешего похода мы с удовольствием вернулись к машине и направились к купальням.
                        Подъехав к ним, оказалось, что бесплатно припарковаться здесь не получиться. И у нас есть два
                        варианта, либо стоянка напротив подешевле, но там нужно было платить местные форинты в
                        парковочный аппарат, либо рядом со входом в купальни оплатить парковщикам при помощи евро.
                        Поскольку форинтов у нас пока не было, было решено выбрать второй вариант, что обошлось около
                        пяти евро. Как оказалось евро в Венгрии совсем не в ходу, поэтому нам ещё повезло, что у нас их
                        приняли.</p>

                    <p>В самих купальнях платили уже по картам. Переодевшись, мы вошли в основной зал. Перед нами была
                        часть природной пещеры, в которой и располагались термальные источники. Внутри пещеры было
                        множество ходов, внутри которых была разной температуры вода, а по некоторым из троп пускалась
                        искусственная волна. Так, что если лечь на её течение, можно было из одного входа в пещеру
                        пронестись к другому, сделав при этом круг. Были, также, зоны с джакузи. Кроме того, здесь
                        располагались небольшие бассейны с уже совсем тёплой водой, а также дополнительно можно было
                        оплатить вход в зону с саунами, что мы и сделали. В этой зоне были кабинки с сухим и влажным
                        паром, а в центре зала находился двухметровый колодец с ледяной водой, вокруг которого стояли
                        лежаки. Всем этим великолепием мы с удовольствием воспользовались, и с неохотой уходили от туда
                        спустя пару с лишним часов.</p>

                    <p>После водных процедур мы изрядно проголодались, и зашли, в располагавшийся рядом с парковкой
                        ресторан, который, если я не ошибаюсь, был совмещён с апартаментами. В зале нас никто не
                        встретил, и даже спустя пять минут ожидания за столиком никто не подошёл. Пришлось идти к
                        девушке за стойкой бара с просьбой обслужить голодных российских туристов самостоятельно.
                        Девушка с большим трудом справлялась с английским, но вежливо нас обслужила. Здесь я впервые
                        попробовал настоящий венгерский гуляш. И это было волшебно! Хорошо проваренная говядина с
                        овощами плавала в невероятно ароматном, незабываемом и не на что не похожем бульоне. После
                        такого прекрасного вечера, уже совершенно ничего не хотелось, кроме как посмотреть на венгерский
                        закат, что мы частично успели сделать, выйдя из ресторана. Но нам сегодня ещё предстояла дорога
                        до Будапешта.</p>

                    <p>Выехав из Мишкольца, мы довольно быстро добрались до автомагистрали, по которой без излишнего
                        напряжения проделали почти 200 км до столицы Венгрии. Главный город страны сразу начал удивлять
                        нас, для начала архитектурой своих построек, порой весьма неожиданных, с которыми нам ещё
                        предстояло познакомиться поближе.</p>

                    <p>Довольно долго искали наш Hotel Unio, который располагался в окружении улиц с односторонним
                        движением. Припарковавшись как попало, мы пошли заселяться. За стойкой ресепшн стоял учтивый
                        пожилой дядечка, несколько похожий на Вуди Аллена, но не такой пожилой, конечно. Впечатление
                        складывалось, что он работал здесь очень давно, возможно даже присутствовал при создании этого
                        отеля, а отель был старым. Старина отеля чувствовалась в мебели, технике, отделке, но эту
                        старину нельзя было назвать ветхостью, эта старина обладала некоторым шармом. Когда мы заполнили
                        въездные бумаги, я спросил про парковку, неожиданно дядечка вышел из-за стойки, и мы отправились
                        на улицу, чтобы посмотреть, где я оставил авто (оно стояло за углом от входа). Дяденька сказал,
                        что здесь машину оставлять нельзя и нужно перепарковаться на улицу у входа в отель, и в это
                        время прямо от двери входа уезжал чей-то автомобиль. Это место было всего в тридцати метрах от
                        нашей машины, но чтобы попасть туда, по предупреждению учтивого старичка, мне нужно было сделать
                        оборот вокруг квартала, так как все улицы здесь были односторонними. Я поблагодарил мужчину за
                        помощь, прыгнул в машину и помчался вокруг квартала. Как ни странно, место ещё не было занято, и
                        я оставил машину прямо у входа отель. Дополнительно к этому успеху прибавлялся ещё и тот факт,
                        что мы приехали сюда в пятницу, а все парковки в городе в пятницу вечером становятся бесплатными
                        до утра понедельника, т.е. аккурат до того времени когда мы должны были покинуть Будапешт.</p>

                    <p>Поднявшись в номера, мы оставили вещи и немного отдышались. Нажав на кнопку пульта, кинескоп
                        телевизора загорался приветственной надписью с изображением нарисованного солдатика, словно
                        сошедшего со страниц книг Ярослава Гашека. Через несколько минут мы пошли в город, чтобы
                        осмотреться, и быть может, чего-нибудь перекусить на ночь.</p>

                    <p>Пройдя несколько сот метров по центру, мы с супругой были повергнуты в шок количеством галдящей,
                        курящей и выпивающей молодёжи на улицах. У меня было такое странное чувство, что мы самые старые
                        люди среди гуляющих, буквально все встречные были моложе нас. Контингент состоял не только из
                        местных, но и из большого количества приезжей молодёжи. Вероятно, на ситуацию сильно влиял и тот
                        факт, что это было начало уикенда (вечер пятницы). Но всё равно Будапешт, как показали следующие
                        дни, можно было назвать молодёжным и очень свободолюбивым городом.</p>

                    <p>Так и не найдя ни одного приличного места, мы наскоро перекусили в какой-то забегаловке, и
                        заглянув в магазинчик рядом с отелем за провизией, отправились спать.</p>

                    <p>На следующее утро мы начали обстоятельный осмотр территории Венгерской столицы. И сразу же она
                        подтвердила впечатления о своём молодёжном лице. На улицах было множество детей, молодых людей,
                        зрелых детей с детьми, и очень мало пожилых.</p>

                    <img src={magyar_035} alt="" className="img-fluid travel__img"/>

                    <p>Перво-наперво мы отправились к зданию парламента, встретив по пути внушительное здание вокзала и
                        множество других впечатляющих зданий. Вообще Будапешт производил впечатление города в котором
                        красота везде. Куда бы ты не направил свой взгляд, то обязательно получишь эстетическое
                        наслаждение.</p>

                    <p>Мы постепенно подошли к Парламентскому дворцу. Он стоял на площади окружённый монументальнейшими
                        зданиями, а перед ним курсировали знаменитые старые трамваи, и несли караул двое военных.</p>

                    <img src={magyar_036} alt="" className="img-fluid travel__img"/>

                    <p>Ну что описывать сам Парламент — это огромный, красивейший дворец в готическом стиле, невероятно
                        сложно украшенный. А за ним находилась набережная реки Дунай, с видами на великолепные мосты
                        через неё, а также на другую часть города. Та другая часть называлась Буда, а мы стало быть
                        находились в Пеште. Эти части некогда были самостоятельными городами, а ныне составляют собой
                        сборное великолепие столицы венгров.</p>

                    <img src={magyar_037} alt="" className="img-fluid travel__img"/>

                    <p>Мы прошлись по набережной реки до ближайшего из мостов.</p>

                    <img src={magyar_038} alt="" className="img-fluid travel__img"/>

                    <p>А затем направились к Базилике святого Иштвана – одного из центральных соборов Будапешта,
                        построенного на рубеже XIX-XX веков. По дороге мы набрели на сквер, где был установлен памятник
                        советским солдатам, примечательно, что сегодня он находится прямо рядом с посольством США.</p>

                    <img src={magyar_039} alt="" className="img-fluid travel__img"/>

                    <p>Постепенно мы достигли места, где располагалась Базилика и после её осмотра снаружи купили
                        билеты, для того, чтобы подняться к соборному куполу.</p>

                    <img src={magyar_040} alt="" className="img-fluid travel__img"/>

                    <p>Подъём происходит на лифте, а последняя его часть по лестницам, в т.ч. винтовым.</p>

                    <img src={magyar_042} alt="" className="img-fluid travel__img"/>


                    <p>После чего мы выходим на смотровую площадку под центральным куполом Базилики. Здесь почти на сто
                        метровой высоте открывается вид на город, и он очень красив.</p>

                    <img src={magyar_041} alt="" className="img-fluid travel__img"/>

                    <p>На этот день особой программы у нас более запланировано не было. И мы просто слонялись по городу,
                        по пути встречая много интересных домов и напитываясь впечатлениями. </p>

                    <p>Вообще Венгрия и в особенности Будапешт, производит впечатление города в котором имеется довольно
                        серьёзное расслоение общества. Здесь можно было запросто встретить на улице проезжающую Ferrari,
                        а на углу той же улице группу бомжей. Их вообще в Будапеште довольно много, и чувствуют они себя
                        довольно вольготно, ютятся прямо в центре города, расстилая свои матрасы и отдыхая чуть ли не в
                        витринах центральных магазинов. Мы даже стали свидетелями того, как в одном из небольших парков,
                        недалеко от нашей гостиницы, приехавшая машина (что-то вроде полевой кухни) раздавала им еду и
                        тут же в парке за столиками происходила общая трапеза.</p>

                    <p>Ещё в Будапеште есть народная забава для молодёжи: по городу курсируют своеобразные барные стоки
                        на велосипедном ходу. Спереди такой конструкции сидит рулевой, а за ним располагается стол за
                        которым лицом к лицу сидят пассажиры. Причём в их задачу входит крутить педали, чтобы эта штука
                        ехала. У каждого пассажира во рту прозрачный шланчик, напоминающий капельницу, и пока он крутит
                        педали по этому шланчику к нему в рот поступает жидкость, предположительно пивная. Такие повозки
                        издают невероятное количество шума, проезжая по улице пассажиры что-то кричат, поют, улюлюкают и
                        т.д. В общем, ужасная непотребщина, но всем очень весело.</p>

                    <p>Незаметно дошли до замка Вайдахуняд. Замок построен в эклектичном стиле, его мы осматривали
                        только снаружи, парк же его окружавший, был ещё явно не полностью готов к сезону, что было
                        понятно по незаполненным водой искусственным прудикам и тусклым краскам газонов. Думаю в мае
                        здесь должно быть очень симпатично.</p>

                    <img src={magyar_043} alt="" className="img-fluid travel__img"/>

                    <p>Решили воспользоваться местным метро. Оно здесь в Будапеште совершенно уникальное —
                        многоуровневое. Первый уровень находится буквально на глубине подземного пешеходного перехода.
                        Ты просто преодолеваешь небольшой лестничный пролёт и всё ты в метро. Эта ветка строилась ещё
                        при Австро-Венгерской империи и являлась старейшей в континентальной Европе. Оформление станций
                        осталось аутентичное: стены выложены милой плиточкой, колоритный шрифт в оформлении названий
                        станций, и вагоны метро — они старые (хотя в отличном состоянии), жёлтого цвета и совершенно
                        миниатюрные. Зайдя в вагон мы буквально упирались головой в потолок. Эта ветка метро так и
                        называется жёлтой. Две другие ветки метро располагаются уровнем ниже, на них уже приходится
                        прилично спускаться. Эти ветки строилась в те времена, когда Венгрия была частью восточного
                        блока и вагоны метро здесь (как мы поняли - преимущественно) точно такие же как были в Москве
                        при СССР (Мытищинского завода). И наконец, последняя ветка Будапештского метрополитена (М4)
                        строилась совсем недавно (открыта в 2014). Здесь уже всё современное: оформление станций и
                        подвижной состав. Вообще посещение Будапештского метро это как посещение музея — интересно и
                        познавательно.</p>

                    <img src={magyar_045_5} alt="" className="img-fluid travel__img"/>

                    <p>Вечером сходили поесть гуляшика, всё-таки чертовски вкусная штука.</p>

                    <img src={magyar_046} alt="" className="img-fluid travel__img"/>


                    <p>На следующий день мы отправились осматривать Будайскую часть города. Эта часть города за рекой
                        находится на некотором возвышении, но не менее богата достопримечательностями, чего здесь только
                        нет, и всё настолько красиво. Мы же ограничились осмотром той части, где располагается так
                        называемый Рыбацкий бастион и Королевский дворец.</p>

                    <img src={magyar_047} alt="" className="img-fluid travel__img"/>

                    <p>Вообще на момент нашего путешествия здесь было достаточно прохладно, возможно, конечно мы не
                        совсем угадали с одеждой, однако, в Венгрии нас удивляло то, что многие представители молодёжи
                        вообще перемещались по улице в одних футболках, в то время как мы, приехавшие с Урала, были в
                        куртках и плотных свитерах и всё равно мёрзли. Горячий венгерский народ.</p>

                    <img src={magyar_048} alt="" className="img-fluid travel__img"/>

                    <p>Кстати, о народе: венгры, согласно истории, это народ угорской группы, пришедший в эти места
                        из-за Урала и смешавшийся здесь со славянскими племенами, а позднее ещё и частично с турками и
                        австрияками (во времена османской и австро-венгерской империи). И влияние всех этих народов
                        сказалось и на венгерском языке. Венгерский язык это вообще отдельная тема, когда читаешь
                        вывески (алфавит на основе латинице) или слушаешь речь, то уловить смысл совершенно не
                        получается, человеку знакомому только с русским и английским просто не за что зацепится,
                        практически нет схожих корней.</p>

                    <img src={magyar_050} alt="" className="img-fluid travel__img"/>

                    <p>Вечером гуляем по свободному маршруту, смотрим на подсвеченный замок парламента, своды мостов,
                        бегущий Дунай и другие места интереса.</p>

                    <img src={magyar_051} alt="" className="img-fluid travel__img"/>

                    <p>На следующее утро наступил понедельник, мы выселяемся из гостиницы и уезжаем с парковки, которая
                        с наступлением рабочего дня становиться платной, и едем в купальню Сеченьи, ещё раз погреться в
                        термальных источниках. Здесь расположен целый комплекс с термальными ваннами различных
                        температур, кабинами саун, бассейнами, в том числе на открытом воздухе. </p>

                    <img src={sechenya} alt="" className="img-fluid travel__img"/>

                    <p>Больше всего понравилась сауна финского типа с установленной посредине комнаты установкой по
                        распылению ментола – очень бодрящая процедура. А также купальни на открытом воздухе, где
                        установлены два бассейна с тёплой и горячей водой, после которых можно забежать в очень жаркую
                        парилку.</p>

                    <img src={magyar_052} alt="" className="img-fluid travel__img"/>

                    <p>В общем, отныне Венгрия в моей памяти останется здравницей.</p>

                    <img src={magyar_053} alt="" className="img-fluid travel__img"/>

                    <p>Страну мы покидали в прекрасном расположении духа, и в отличном состоянии тела.</p>
                    <p>Около часа неспешного движения на северо-запад и мы на границе с Австрией. Останавливаемся, чтобы
                        прикупить виньетку. Здесь в отличии от Венгрии, она физически выдаётся на руки водителю, и судя
                        по всему к машине не привязана. На границе нейтральной зоны нас встречает тётенька полицейский и
                        доброжелательно жестом показывает, чтобы наш пассажир на заднем диване, тоже пристегнул ремень
                        безопасности. Выполняем требование, выслушиваем пожелание счастливого пути на немецком, и
                        двигаемся дальше в сторону Вены.</p>

                    <p>По пути замечаем и решаем заехать в outlet, который располагался загородом. Цены вполне
                        адекватные.</p>

                    <p>После совершения покупок, быстро доезжаем до Вены. У самого въезда нас встречает огромный
                        промышленный комплекс, но никакого дыма от него нет, неприятных запахов не замечено тоже.
                        Постепенно заезжаем в центр, и двигаемся вдоль реки, но это не Дунай, а речушка поменьше.
                        Прибываем к нашему отелю под названием Meininger. Бросаем автомобиль и идём на ресепшн. В холе
                        много народу, в основном молодёжь, дожидаемся в очереди, оформляемся. На вопрос где можно
                        оставить машину, получаем ответный вопрос : «А где она сейчас?». Сообщаем, что припарковались
                        вдоль дороги рядом с отелем. Парень администратор выдаёт нам бумажку, с разрешением на парковку
                        длительностью на 15 минут, и просит поторопиться с багажом, чтобы успеть покинуть место парковки
                        до прихода полиции. В этом районе города три больших платных парковки, сообщает нам
                        администратор, две не так далеко, но стоянка стоит 25-35 евро за сутки, есть парковка и за 3
                        евро, но она подальше. Он отмечает нам место на карте, и мы поднимаемся с багажом в номера. Моя
                        супруга остаётся в отеле, а мы с Алексеем отправляемся на поиски дешёвой парковки.</p>

                    <p>Навигатор не знает нужного нам адреса, поэтому забиваем дом с нумерацией, которая по нашей логике
                        должна быть рядом. Едем долго, чувствуется в объезд, т.к. приходится ехать до ближайшего моста
                        через речку, далее обилие односторонних дорог. Минут через 15-20, судя по навигатору, мы где-то
                        рядом и перед нами какая-то многоэтажная парковка. Вероятно, это то, что мы ищем. Заезжаем,
                        берём карточку у автомата, открывается шлагбаум. Поднимаемся по винтовому проезду наверх,
                        парковочные места есть, находим что-то похожее на прайс – место стоит 3 евро в час, за сутки
                        более 30. Да, это мы неудачно заехали, пытаемся выбраться. Получается это не сразу, выезд здесь
                        не там где въезд, к тому же всё по-немецки, а мы ни разу не шпрехен, а путь до выездного винта
                        не очевиден. Покрутившись слегка, пристраиваемся за машиной, которая нас и выводит на нужный
                        путь, но чтобы проехать шлагбаум необходимо оплатить час стоянки, что нам приходиться сделать,
                        дабы выбраться из этого бетонного плена. Выезжаем и ищем место назначения дальше. Дорога уводит
                        нас совсем уж далеко от места на карте. Решаем бросить машину и спросить у прохожих, но сходу
                        места для парковки найти не можем, приходится заезжать во дворик. Здесь одно место удаётся
                        найти, но впечатление такое, что все места тут закреплены за резидентами, и нас за это может
                        нагреть местная полиция. Спешно выходим на улицу, но мы оказались в довольно глухом месте, и
                        прохожих тут как назло нет совсем. Решаем, что быстрее будет найти место назначения пешком, и
                        двигаемся назад по улице. Пройдя метров 500, видим здание, которое может оказаться стоянкой,
                        подходим ближе – бинго, и даже адрес совпадает. Забиваю географические координаты в навигатор.
                        Возвращаемся к машине, она ещё на месте, выставляю маршрут на сохранённые координаты, но дорога,
                        на которую мы выезжаем односторонняя и мы вынуждены ехать в обратную от нужной сторону. Чтобы
                        вернуться к стоянке мы вынуждены снова пересечь реку по мосту вернуться в сторону центра, а
                        дальше вновь повторить пройденный маршрут, с поиском нужного поворота. С задачей мы, с помощью
                        навигатора, всё-таки справляемся, и мы заезжаем на стоянку. Также берём карточку, шлагбаум
                        отворяется и мы оставляем машину на свободном месте. Прайс тоже нас устраивает 3 евро за сутки,
                        не зря мы столько мучились. </p>

                    <p>Жена уже меня потеряла, шлю СМС, что скоро приедем. Теперь нам остаётся лишь добраться до отеля.
                        С этим всё оказывается просто и очень элегантно. На первом этаже автопарковочного комплекса
                        стоит указатель на станцию метро, располагающуюся на 3 этаже. С помощью карточки, выданной нам
                        на въезде на парковку, вызываем лифт, и поднимаемся на 3 этаж. Попадаем на пешеходный мост, в
                        ста метрах от лифта виднеется вывеска метро, к которой мы и направляемся. Зайдя в здание
                        станции, с помощью терминала, интерфейс которого доступен и на русском языке, покупаем билет и
                        спускаемся к поездам, причём проход совершенно свободен, никаких турникетов задерживающих
                        пассажиров на входе не существует. Проехав несколько станций, выходим на станции неподалёку от
                        нашего отеля и через 5 минут мы на месте.</p>

                    <p>На следующее утро идём на прогулку по Рембрантштрассе по мосту через реку, и далее выходим к
                        церкви Обета, которая стоит вся в строительных лесах на реставрации. Выполнена она в готическом
                        стиле, и имеет форму креста, который отлично просматривается, если смотреть на церковь
                        сверху.</p>

                    <p>Поскольку мы толком не завтракали, то решили пойти перекусить в кафе. Поступило предложение
                        обязательно зайти в любимое, по слухам, место Зигмунда Фрейда Cafe Landtmann, расположенным
                        между Бургтеатром и Венским университетом. Устроившись на веранде, мы дружно заказали кофе с
                        чаем и местные десерты, среди которых: венский штрудель и торт Захер. Обслуживание здесь,
                        конечно, безупречное, официанты взрослые мужчины, что говорит о том, что работать здесь
                        статусно, и само по себе напоминает о классе заведения. Удивило то, что в кофейню можно запросто
                        прийти с собачкой, которую не только поприветствуют наравне с хозяевами, но и дадут миску с
                        водичкой для утоления жажды. Не будь я провинциальным писателем, если не похвалю местные
                        туалеты, здесь всё на высшем уровне, отдельного внимания заслуживают сенсорные смесители,
                        совмещённые с сушилкой для рук и по форме напоминающие самолёт.</p>

                    <p>Немного перекусив и поэстетствовав, мы вышли из кафе и, пройдя мимо здания Парламента, нырнули в
                        парк, и вышли на площадь Марии Терезы расположенную между двумя музеями – естествознания и
                        истории искусств.</p>

                    <img src={austria_056} alt="" className="img-fluid travel__img"/>


                    <img src={austria_057} alt="" className="img-fluid travel__img"/>

                    <p>На улицах Вены до сих пор можно встретить легендарный восточно-германский автособиль -Трабант</p>

                    <img src={austria_054} alt="" className="img-fluid travel__img"/>

                    <p>Вена не производит впечатление шумного мегаполиса, здесь живёт более полутора миллионов человек,
                        но на улицах не очень людно, тихо и спокойно. В это время года видимо ещё не очень много
                        туристов. Поэтому гулять по улицам – одно удовольствие, тем более что городское пространство
                        великолепно упорядочено.</p>

                    <img src={austria_055} alt="" className="img-fluid travel__img"/>

                    <p>Забредаем на местный рынок. Здесь есть много чего интересного. Поскольку десерта было
                        недостаточно для плотного завтрака, решаем взять по венской сосиске, съедаем, и идём дальше на
                        осмотр города.</p>

                    <img src={austria_058_r} alt="" className="img-fluid travel__img"/>

                    <p>Проходим здание венской оперы и вновь поворачиваем на северо-запад. Спустя несколько минут
                        лицезреем барочный Хофбург – это местный зимний дворец, только не Романовых, а Габсбургов.</p>

                    <p>В этой части города для прогулок популярен гужевой транспорт.</p>

                    <img src={austria_061} alt="" className="img-fluid travel__img"/>

                    <p>Здесь уже недалеко до собора Святого Стефана. По оживлённой пешеходной зоне движемся туда. </p>

                    <img src={austria_062_r} alt="" className="img-fluid travel__img"/>

                    <p>После его осмотра ныряем в метро и едем по направлению к нашей гостинице, чтобы немного отдохнуть
                        и перекусить. Для этого по пути забегаем в местный Spar.</p>

                    <p>Вечером вновь тянемся на прогулку, на этот раз осматриваем в основном современную архитектуру. И
                        ещё катаемся на трамвае.</p>

                    <img src={austria_064_r} alt="" className="img-fluid travel__img"/>

                    <p>А в конце-концов оказываемся в парке развлечений Пратер, где просто глазеем на аттракционы и
                        гуляем.</p>

                    <p>На следующий день идём в венский технологический музей, который располагается недалеко от летнего
                        императорского дворца Шёнбрунн, который мы осматриваем мельком.</p>

                    <p>Технологический музей – это огромное здание, где на нескольких этажах располагаются экспонаты
                        рассказывающие историю техники. Причём техники всевозможной от пылесосов и утюгов до доменных
                        печей и самолётов. Да ещё и с большинством предметов можно повзаимодействовать, что делает это
                        музей уже на порядок интереснее, особенно для детей, коих тут очень много. </p>

                    <img src={austria_066_r} alt="" className="img-fluid travel__img"/>

                    <p>На самом деле, здесь можно провести целый день, мы же ограничились 3-4 часами т.к. проходили залы
                        довольно бегло. На самом деле, здесь можно провести целый день, мы же ограничились 3-4 часами
                        т.к. проходили залы довольно бегло.</p>

                    <p>Мне особенно запомнился зал с музыкальными инструментами, где среди прочего были представлены
                        первые музыкальные аппараты воспроизводящие музыку. Технически это были простые пианино, иногда
                        совмещённые с духовой секцией, а материал записан на рулонах перфокарт. В действии это всё
                        выглядит почти магически.</p>

                    <img src={austria_067_r} alt="" className="img-fluid travel__img"/>

                    <p>После забега по музею, естественно нужно перекусить, но это Вена, тут рискуешь нарваться на
                        какой-нибудь ресторан высокой кухни. И мы конечно вляпались именно в такой. Услужливые официанты
                        усадили в небольшой и совершенно пустой зал на втором этаже и принесли меню. Меню было,
                        по-моему, всего на двух листах, и мы старались заказывать максимально простые по описанию
                        блюда.</p>

                    <img src={austria_073} alt="" className="img-fluid travel__img"/>

                    <p>В итоге каждый из нас получил совершенно несоответствующее его ожиданиям угощение. Как это
                        водиться, на большой тарелке было нечто небогато справленное какими-то, видимо, особенными
                        соусами и украшенное травкой или каким-нибудь микро овощем. При этом то, что должно было быть по
                        описанию мясом, было либо чем-то типа куска колбаски, либо чем-то вроде тефтеля и на вкус
                        настолько тонким, что с трудом улавливалось его происхождение. А рецепторы на языке были не
                        перегружены, даже если использовать весь размазанный по тарелке соус на один укус предложенного
                        деликатеса. В общем, мы были не вполне удовлетворены энергетической ценностью нашего обеда, но
                        получили весёлый опыт.</p>

                    <p>Конечно же, мы не могли не посетить армянскую церковь мхитаристов, расположенную в Вене. Правда,
                        подъехав по адресу, мы обнаружили её закрытой, однако, после настойчивого общения с домофоном у
                        непарадного входа нам таки отворили. Жена перекинулась несколькими предложениями на армянском, и
                        мы начали осмотр. Вообще-то орден мхитаристов относится к католической ветви армянской церкви, и
                        поэтому конечно сильно отличается от классических армянских церквей по всему миру. Внутреннее
                        убранство изобилует роскошью в отделке, что как я уже сказал, совсем нетипично для апостольских,
                        но вполне распространено в католических храмах. Завершив осмотр, мы раскланялись, и
                        удалились.</p>

                    <img src={austria_073_2} alt="" className="img-fluid travel__img"/>

                    <p>Всё, пора было прощаться с австрийской столицей, мы сели на метро и двинулись к месту парковки
                        нашего автомобиля. Кстати, рядом с парковкой находиться мусоросжигательный завод, и здесь он
                        выглядит так:</p>

                    <img src={austria_074} alt="" className="img-fluid travel__img"/>
                    <p>Дорога до Братиславы недолгая, всего около 80 километров пути. Но достигаем столицы Словакии уже
                        затемно. Подъезжаем к нашему отельчику с интересным названием: Freddie next to Mercury. По сути
                        это хостел, но с несколькими апартаментами. Два из них мы и занимаем. Парковка возможна прямо у
                        дверей отеля и совершенно бесплатна. Удобно.</p>

                    <p>На следующий день запланирована последняя поездка по Словакии. А пока идём за продуктами, ведь
                        теперь у нас в номерах есть своя кухня. Ужинаем и отдыхаем.</p>

                    <p>Наутро, отправляемся в путь. Около 130 километров на северо-восток и мы в пункте нашей первой
                        остановки – небольшом городке Тренчин. Здесь, основное место притяжения туристов – Треньчинский
                        град, который является третьим по величине замком в Словакии, и благодаря своему расположению на
                        холме и крепостным стенам, имеет прекрасные фортификационные качества. Берём билет на вход в
                        замок с экскурсией. </p>

                    <img src={slovak_075} alt="" className="img-fluid travel__img"/>

                    <p>Женщина экскурсовод ведёт рассказ на словацком языке, но это почти не мешает пониманию. В целом
                        замок интересен, ходя экскурсия по нему слегка затянута.</p>

                    <img src={slovak_076} alt="" className="img-fluid travel__img"/>

                    <p>Последним местом нашего интереса в данном путешествии становиться деревенька Чичмани, куда мы и
                        направляемся. Около 50 километров на восток, вторая половина которых проходит уже в горах. Здесь
                        очень живописно и хорошо. Деревня знаменита своими традиционного типа домиками со своеобразной
                        этнической росписью.</p>

                    <img src={slovak_077} alt="" className="img-fluid travel__img"/>

                    <p>Не знаю чем объяснить феномен этого места, почему именно здесь решили сохранять свой традиционный
                        стиль в постройках не понятно, возможно, в этих местах он был наиболее интересен в своём облике.
                        Известно только, что после большого пожара, уничтожившего значительную часть деревни в 1921
                        году, отстраивали деревню в том же стиле. А слово «чичман» переводится со словацкого как
                        лесоруб, т.е. изначально это был посёлок лесорубов, и впервые основан на этом месте аж в 1272
                        году.</p>

                    <img src={slovak_078} alt="" className="img-fluid travel__img"/>

                    <p>Пройдясь вдоль главной улочки, подышав горным воздухом, послушав журчание небольшой речки, грех
                        было не зайти в местное заведение и не заказать себе чего-нибудь местного и вкусного. Мной с
                        Алексеем была опробована и одобрена наваристая чесноковка – традиционный суп из бульона, щедро
                        сдобренного чесноком, с сухарями. Прекрасная завершающая нота нашего словацкого путешествия.</p>

                    <p>После ужина мы отправились в обратный путь. И ехали на красивейший закат к столице полюбившегося
                        края. Через пару часов мы уже были здесь.</p>

                    <p>В городе заезжаем на заправку, чтобы долить горючего до полного, ведь завтра машину придётся
                        сдавать. На АЗС не обнаруживается заправщика, что для Европы редкость, ну ладно думаю, я ж из
                        России, мне не привыкать. Вставляю пистолет, иду к кассе и прошу на английском залить мне полный
                        бак. На меня смотрят как на идиота. Не пойму толи мой английский совсем никудышный, толи
                        работники на кассе с ним совсем не знакомы. Ещё раз называю номер колонки, класс топлива,
                        которым бы хотел заправиться, при этом справляю английскую речь несколькими славянскими корнями.
                        Всё равно «миссантерстуд». И тут мне говорят, мол, если, хочешь заправиться, заправляйся, а
                        потом придёшь и оплатишь, сколько получится. Тут до меня дошёл опыт прошлого: степень доверия
                        заправщиков к клиентуре увеличивается по мере удаления на запад. Т.е. тут можно просто подъехать
                        к колонке, просто вставить пистолет, просто заправиться сколько надо, никому ничего не говоря, а
                        только потом пойти оплатить топливо. Цивилизация, однако.</p>

                    <p>Подъехав к нашим апартаментам, мы в последний раз паркуем машину на ждущее нас место возле
                        Freddie next to Mercury. А на следующий день прощаемся с Братиславой. Это Дунайский тур был
                        разнообразен и интересен и лично мне очень запомнился своим настроением и наполненностью. До
                        будущих открытий друзья!</p>

                    <p>Видео из поездки ниже:</p>

                    <div className="travel__iframeWrap">
                        <iframe className="travel__iframe"
                                src="https://www.youtube.com/embed/qKj3TkNC2KI?rel=0&amp;showinfo=0" frameBorder="0"
                                allowFullScreen></iframe>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Deneb;
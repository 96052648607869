import React from 'react';
import transcaucasia_001 from '../../../images/transcaucasia_2016/transcaucasia_001.jpg';
import transcaucasia_002 from '../../../images/transcaucasia_2016/transcaucasia_002.jpg';
import transcaucasia_003 from '../../../images/transcaucasia_2016/transcaucasia_003.jpg';
import transcaucasia_004 from '../../../images/transcaucasia_2016/transcaucasia_004.jpg';
import transcaucasia_005 from '../../../images/transcaucasia_2016/transcaucasia_005.jpg';
import transcaucasia_006 from '../../../images/transcaucasia_2016/transcaucasia_006.jpg';
import transcaucasia_007 from '../../../images/transcaucasia_2016/transcaucasia_007.jpg';
import transcaucasia_008 from '../../../images/transcaucasia_2016/transcaucasia_008.jpg';
import transcaucasia_009 from '../../../images/transcaucasia_2016/transcaucasia_009.jpg';
import transcaucasia_010 from '../../../images/transcaucasia_2016/transcaucasia_010.jpg';
import transcaucasia_011 from '../../../images/transcaucasia_2016/transcaucasia_011.jpg';
import transcaucasia_012 from '../../../images/transcaucasia_2016/transcaucasia_012.jpg';
import transcaucasia_013 from '../../../images/transcaucasia_2016/transcaucasia_013.jpg';
import transcaucasia_014 from '../../../images/transcaucasia_2016/transcaucasia_014.jpg';
import transcaucasia_015 from '../../../images/transcaucasia_2016/transcaucasia_015.jpg';
import transcaucasia_016 from '../../../images/transcaucasia_2016/transcaucasia_016.jpg';
import transcaucasia_017 from '../../../images/transcaucasia_2016/transcaucasia_017.jpg';
import transcaucasia_018 from '../../../images/transcaucasia_2016/transcaucasia_018.jpg';
import transcaucasia_019 from '../../../images/transcaucasia_2016/transcaucasia_019.jpg';
import transcaucasia_020 from '../../../images/transcaucasia_2016/transcaucasia_020.jpg';
import transcaucasia_021 from '../../../images/transcaucasia_2016/transcaucasia_021.jpg';
import transcaucasia_022 from '../../../images/transcaucasia_2016/transcaucasia_022.jpg';
import transcaucasia_023 from '../../../images/transcaucasia_2016/transcaucasia_023.jpg';
import transcaucasia_024 from '../../../images/transcaucasia_2016/transcaucasia_024.jpg';
import transcaucasia_025 from '../../../images/transcaucasia_2016/transcaucasia_025.jpg';
import transcaucasia_026 from '../../../images/transcaucasia_2016/transcaucasia_026.jpg';
import transcaucasia_027 from '../../../images/transcaucasia_2016/transcaucasia_027.jpg';
import transcaucasia_028 from '../../../images/transcaucasia_2016/transcaucasia_028.jpg';
import transcaucasia_029 from '../../../images/transcaucasia_2016/transcaucasia_029.jpg';
import transcaucasia_030 from '../../../images/transcaucasia_2016/transcaucasia_030.jpg';
import transcaucasia_031 from '../../../images/transcaucasia_2016/transcaucasia_031.jpg';
import transcaucasia_032 from '../../../images/transcaucasia_2016/transcaucasia_032.jpg';
import transcaucasia_033 from '../../../images/transcaucasia_2016/transcaucasia_033.jpg';
import transcaucasia_034 from '../../../images/transcaucasia_2016/transcaucasia_034.jpg';
import transcaucasia_035 from '../../../images/transcaucasia_2016/transcaucasia_035.jpg';
import transcaucasia_036 from '../../../images/transcaucasia_2016/transcaucasia_036.jpg';
import transcaucasia_037 from '../../../images/transcaucasia_2016/transcaucasia_037.jpg';
import transcaucasia_038 from '../../../images/transcaucasia_2016/transcaucasia_038.jpg';
import transcaucasia_039 from '../../../images/transcaucasia_2016/transcaucasia_039.jpg';
import transcaucasia_040 from '../../../images/transcaucasia_2016/transcaucasia_040.jpg';
import transcaucasia_041 from '../../../images/transcaucasia_2016/transcaucasia_041.jpg';
import transcaucasia_042 from '../../../images/transcaucasia_2016/transcaucasia_042.jpg';
import transcaucasia_043 from '../../../images/transcaucasia_2016/transcaucasia_043.jpg';
import transcaucasia_044 from '../../../images/transcaucasia_2016/transcaucasia_044.jpg';
import transcaucasia_045 from '../../../images/transcaucasia_2016/transcaucasia_045.jpg';
import transcaucasia_046 from '../../../images/transcaucasia_2016/transcaucasia_046.jpg';

const Transcaucasia = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <p>Август 2016 года. Наша очередная поездка в Закавказье. Помимо четырёх туристов из Челябинска (нас
                        с женой и дружественной нам семейной паре), в нашей команде один представитель солнечного
                        острова Сардиния, по имени Массимо, тоже заинтересованный горными подъёмами и спусками. На этот
                        раз маршрут поездки представляет собой кольцо длинной около 2000 км. Старт в Ереване затем мы
                        движемся на юг к Татеву, далее к Севану, потом через Лори пробираемся к границе с Грузией и
                        пересекаем её. В Грузии мы изучаем Тбилиси, после чего едем на северо-запад через Сатаплию в
                        Местию, потом на юго-запад в Батуми, и наконец, возвращаемся в Ереван, посещая по пути Ахалцихе
                        и Вардзию. Об этом всём подробнее далее, но в начале, в начале мы идём в поход…</p>

                    <p>
                        После сбора нашей компании в Ереване и беглой прогулки по нему, первым нашим приключением
                        становиться восхождение на вершину вулкана Аждаак (3697 метров). Едем мы туда не одни, поход
                        организует компания OneWay Travel. С утра пораньше мы выходим из хостела с одноимённым названием
                        OneWay и движемся к месту сбора недалеко от оперного театра. Усевшись в микроавтобус, мы
                        доезжаем до деревни Гегерд, находящейся у подножия Гегамского хребта на высоте около 2000
                        метров. В деревне мы пересаживаемся в кузова двух грузовиков марки ГАЗ и ЗИЛ. И эти «звери»
                        довозят нас по горным тропам до высоты порядка 3000 м, попутно доставляя нам немало куража.
                    </p>
                    <img src={transcaucasia_001} alt="Наш горный транспорт" className="img-fluid travel__img"/>

                    <p>
                        После этого мы высаживаемся и продолжаем путь пешком. Пробираясь через застывшие вулканические
                        реки по началу не испытываешь никакого дискомфорта, и лишь наслаждаешься прекрасным горным
                        воздухом и красивыми видами. Но дальше естественно, начинаются основные препятствия, взбираться
                        приходится, иногда карабкаясь, посещает первая усталость. Постепенно добираемся до первой
                        кальдеры. В кратере вода, а по его краям лежит снег. Ветер становиться очень сильным и ледяным.
                    </p>

                    <img src={transcaucasia_002} alt="Первая кальдера Аждаака" className="img-fluid travel__img"/>

                    <p>
                        Далее движемся до следующей виднеющейся вершины, затем спуск и снова подъём на второй кратер.
                        Последние метры даются уже с трудом. Но вот мы на Аждааке. Здесь в кратере почти такое же
                        небольшое озерцо, снежок и чуть менее ветрено.
                    </p>

                    <img src={transcaucasia_003} alt="Вершина Аждаака" className="img-fluid travel__img"/>

                    <p>
                        Перекусив на вершине, я возвращаюсь обратной дорогой, чтобы встретить наших чуть подотставших
                        друзей. Последний кратер они решили не штурмовать, и я настигаю их уже возвращавшихся к месту
                        сбора. Мы устраиваем привал, и спускаемся к грузовикам, дожидаемся основной группы.
                    </p>

                    <p>
                        Чувствую, что на «диком» горном солнце моё лицо обгорело, несмотря на, обмазывания кремом.
                        Группа в сборе и вновь в кузове грузовиков, горными тропами пробираемся к горному озеру
                        Вишапалич, посмотреть на две каменные стелы (вишапкары) периода анимизма. Это поездка была явно
                        лишней, ехать долго, и уже все устали прыгать в кузовах траков.
                    </p>

                    <img src={transcaucasia_004} alt="Вишапкар" className="img-fluid travel__img"/>

                    <p>
                        Потом долгий спуск обратно к деревне Гегард. Наконец, достигнув места, мы стали жертвой
                        несогласованности наших проводников из OneWay Travel, и ещё минут 30 пришлось ждать микроавтобус
                        до Еревана. В столицу мы уже возвращались затемно. А на завтра нас уже ждала в прокате машина,
                        на которой нам предстояло отправиться по нашему маршруту, начав который нам предстояло с южного
                        направления.
                    </p>

                    <p>
                        На утро следующего дня, мы отправились в прокат, чтобы забрать наш авто. Как и в прошлые разы,
                        было решено воспользоваться услугами проверенной компании Caravan. Исходя из наших финансовых
                        возможностей и наличия автомобилей, было решено забронировать Nissan Tiida, багажника которого
                        как раз хватило на все наши сумки и чемоданы, а салона на то чтобы худо-бедно разметить пятерых
                        автотуристов.
                    </p>

                    <p>
                        Итак, мы забрали авто, подъехали к хостелу на Туманяна, чтобы выселиться, и забрать вещи с
                        пятого этажа (очень неудобно). Погрузив поклажу и разместившись в салоне, мы двинулись на выезд
                        из города в южном направлении. Первым нашим пунктом остановки был Хор-Вирап. Описывать данное
                        место нет особого смысла, это место у подножья Арарата в котором начилась история Армении, как
                        христьянской страны. На сегодняшний день это крепостные стены на холме, за которыми
                        располагается храм, а также подземная темница, в которой, как считается, был заключён за свою
                        веру Григорий Просветитель, ставший впоследствии первым католикосом армян.
                    </p>

                    <img src={transcaucasia_005} alt="Хор-Вирап" className="img-fluid travel__img"/>

                    <p>
                        Далее привычное продолжение маршрута, с остановкой в Нораванке. То же прекрасное ущелье, горная
                        речка и солнце, мой вечный спутник в этих местах. Не буду приводить излишних исторических
                        справок, тем более по мной ранее описанным местам, информации по ним в избытке.
                    </p>

                    <img src={transcaucasia_006} alt="Нораванк" className="img-fluid travel__img"/>

                    <p>
                        После Нораванка едем дальше на юг. Преодолев пару перевалов мы оказываемся в Сюнике. Хотели
                        заехать в Караундж, но дорога перекрыта и ремонтируется. Едем дальше в Хндзореск. Теперь здесь
                        построили цивильный спуск с обратной стороны ущелья, о котором мы знали. Поворот на него
                        находится в начале селения. Теперь здесь скопление людей и машин, хотя ещё два года назад, здесь
                        редко кого можно было встретить.
                    </p>

                    <p>
                        Спуск по деревянной лестнице и мы на мосту, всё как всегда, только много туристов. В старой
                        часовне, какой-то мужчина проверяет акустику помещения, стоя в углу и напевая церковные тексты.
                    </p>

                    <img src={transcaucasia_007} alt="Хндзореск" className="img-fluid travel__img"/>

                    <p>
                        Начинает смеркаться, мы спешно возвращаемся к машине. Наша ночёвка запланирована в Арснадзоре, у
                        «нашего знакомого» Гарика. Мы возвращаемся к Татеву, по пути попадаем в привычную вечернюю
                        пробку из баранов. На месте оказываемся уже в темноте, но без труда находим место на краю
                        ущелья. Находим Гарика, представляемся, заселяемся. Бочки, предоставленные им, находятся в
                        лучших местах, за что ему спасибо. Ужинаем, Гарик не может оставить без подарка, хотя
                        большинство из нас он видит впервые. Дарит две бутылки вина собственного приготовления. Говорим,
                        что не пьём, но этот номер у него не проходит, приходиться брать. После ужина расходимся по
                        бочкам на отдых.
                    </p>

                    <p>
                        С утра пораньше, жена встаёт оцифровывать местность, я сплю до завтрака.
                    </p>

                    <img src={transcaucasia_008} alt="Арснадзор" className="img-fluid travel__img"/>

                    <p>
                        Принимаем пищу и едем в Сатани Камурдж, тут рядом минут 5 езды. Я полон решимости провести всех
                        в грот с минеральной водой. Спускаемся. Течение в августе куда спокойней чем в мае, уровень воды
                        гораздо ниже, можно идти относительно спокойно. Нахожу нужный лаз и жду остальных. Пролезаем в
                        него и оказываемся в нужном месте, восторг. Ванночки с минеральной водой, капающей со
                        сталактитов, теплой и целебной. Местный житель, стоя в речке неподалёку принимая здесь
                        своеобразный душ, сообщает нам, что если пройти ещё немного по реке, то впереди будет ещё один
                        грот. Я, пытаясь делать первые шаги с небольшого выступа по скользким камням срываюсь в реку, но
                        зацепившись за камень остаюсь на месте и меня не сносит течением. Хорошо, что вынул из карманов
                        документы. Эмоции отличные, водичка освежает, но от затеи идти дальше отказываюсь. Будет в
                        следующий раз чем заняться.
                    </p>

                    <img src={transcaucasia_009} alt="Сатани-Камурдж" className="img-fluid travel__img"/>

                    <p>
                        После этого освежающего приключения, мы вернулись к Гарику забрать свои вещи и отправиться к
                        Татеву. Подъехав к канатной дороге, мы вошли в помещение, где располагается касса. Здесь было
                        непривычно много людей, но хуже было то, что, как нам сообщили, теперь заказывать места можно и
                        через интернет, чем охотно пользуются туроператоры и выкупают билетов сразу на большой автобус.
                        Два таких автобуса как раз стояли на стоянке перед входом. Короче говоря, ближайшие места были
                        только через 3 часа. У нас такого времени не было, т.к. нам нужно было пересечь Селимский
                        перевал и доехать до озера Севан. Досадно, да ладно.
                    </p>

                    <img src={transcaucasia_010} alt="Татев" className="img-fluid travel__img"/>

                    <p>
                        Через пару часов мы были на Селимском перевале, где традиционно рассматривали средневековый
                        каравансарай, как остановочный пункт на великом шёлковом пути, и наслаждались видом с высоты
                        почти 2400 метров.
                    </p>

                    <img src={transcaucasia_011} alt="Каравансарай" className="img-fluid travel__img"/>

                    <p>
                        Еще примерно через час мы достигли Севана. Севан был глубокого синего цвета и мы остановились в
                        одном из мест, у его берега, поразмяться и подышать свежим ветром, разносящим брызги волн по
                        нашим скованным дорогой телам.
                    </p>

                    <img src={transcaucasia_012} alt="Севан" className="img-fluid travel__img"/>

                    <p>
                        Продолжив наш путь, очень скоро мы очутились в месте назначения – селе Чкаловка. Здесь
                        располагался досуговый комплекс Цовацоц. Ресторан и комнаты, где можно было переночевать. На
                        этом месте как остановочном пункте для нас настаивала моя жена, и как оказалось не зря.
                        Интересное место с национальными нотками и оригинальным дизайном. Ресторан с хорошей кухней,
                        видом на берег и хорошей атмосферой собрал нас за вечерней трапезой. Естественно на Севане мы
                        заказывали рыбные блюда и традиционный кебаб из раков. Здесь всё на уровне. В соседней комнате
                        проходило какое-то застолье с громкой живой музыкой. Присутствовали зурна и барабаны поэтому
                        было громко. Но уровень звука ничуть не напрягал нас, так как нам хотелось приобщиться к
                        местному национальному колориту. К тому же музыка звучала не всегда, а только после того, как за
                        столом произносился тост и гости вставали со своих мест немного потанцевать, после чего их
                        трапеза продолжалась в тишине.
                    </p>

                    <img src={transcaucasia_013} alt="Цовацоц" className="img-fluid travel__img"/>

                    <p>
                        После ужина мы отправились в номера. Они были под стать ресторану, оригинальны и в то же время
                        просты. Я бы сказал в средиземноморском стиле. При всей простоте отделки, в них было всё
                        необходимое. В общем, ночь мы провели славно.
                    </p>

                    <img src={transcaucasia_014} alt="Цовацоц" className="img-fluid travel__img"/>

                    <p>
                        На следующее утро мы позавтракали здесь же. И продолжили путь на север. Сегодня по плану мы
                        должны были ночевать уже в Тбилиси. Но с начала, мы посетили Севанаванк, поглазев на озеро с
                        холма полуострова, а затем двинулись в Дилижан.
                    </p>

                    <img src={transcaucasia_015} alt="Севанаванк" className="img-fluid travel__img"/>
                    <p>
                        В Дилижане осмотр был лаконичен, в основном Тюфенкяновские владения старого города и амфитеатр.
                        Далее небольшой перекус с видом на пруд за фигурами троицы из Мимино и мы снова на колёсах.
                    </p>

                    <img src={transcaucasia_016} alt="Амфитеатр в Дилижане" className="img-fluid travel__img"/>

                    <p>
                        Постепенно достигаем Лорийского марза, и оглядываем взаправду эпическую реку Дебед.
                    </p>

                    <img src={transcaucasia_017} alt="река Дебед" className="img-fluid travel__img"/>

                    <p>
                        Следующее место нашего интереса это один из древнейший храмов Армении - Одзун.
                    </p>

                    <img src={transcaucasia_018} alt="монастырь Одзун" className="img-fluid travel__img"/>

                    <p>
                        Тер-Ованеса мы, к сожалению, не встретили, но места здесь очень красивые, особенно вид на
                        ущелье.
                    </p>

                    <img src={transcaucasia_019} alt="Одзун" className="img-fluid travel__img"/>

                    <p>
                        Продолжив движение на север, мы приближались к границе с Грузией у Садахло. Пропустив нужный и
                        такой незаметный поворот, мы уже было поехали дорогой на Ноемберян, но вовремя спохватились. Ещё
                        около получаса и мы на границе. Проходим легко и быстро, очередей нет совсем. На этот раз я был
                        в Грузии даже раньше пассажиров, которые здесь проходят границу пешком.
                    </p>

                    <p>
                        Отсюда до Тбилиси километров сто. Но туда мы въезжали уже под опускающееся солнце. Машин в
                        городе много, особенно тесно в центре. Толкаясь на узких улочках, вымощенных брусчаткой, ищем
                        место нашей ночёвки – Cozy Hostel. Движение настолько плотное, что приходиться опустить передние
                        стекла, чтобы в кого-нибудь ни въехать. А причина в том, что в нашей машине они наглухо
                        затонированы, что ни в Грузии ни в Армении не запрещено. Много односторонних дорог, это
                        затрудняет маршрут, Навител сходит с ума, Google Map спешит на помощь. Наконец мы на месте, и
                        находим место для парковки всего в пятидесяти метрах от нашего пристанища.
                    </p>

                    <p>
                        Место на удивление уютное и чистое, при входе заставляют даже обувь снимать. Огромной высоты
                        потолки и такие же двери. В общем, если вам повезло с комнатой то жить можно. Ладно, не будем о
                        мелочах.
                    </p>

                    <p>
                        В Тбилиси мы два полных дня, есть время осмотреться и не спешить. Наутро мы идём на местную
                        канатку, ведущую к крепости, церкви Святой троицы и парку. Рядом с ней расположены современные
                        постройки, это, прежде всего, здание оперы и мост Свободы.
                    </p>

                    <img src={transcaucasia_020} alt="Тбилиси" className="img-fluid travel__img"/>

                    <p>
                        Тбилиси очень много туристов и очень жарко. В парке мы спасаемся у небольшого водопадика.
                    </p>

                    <img src={transcaucasia_021} alt="Тбилиси" className="img-fluid travel__img"/>

                    <p>
                        Вообще в Тбилиси очень много красивых зданий, но не заметно доминанты стиля в архитектуре. Здесь
                        очень много всего намешено: и средневековые церкви и некоторые здания эпохи Российской империи,
                        причём совершенно разной стилистики, постройки времён советской власти и современные строения.
                        Причём присутствуют специфические кварталы типа еврейского, армянского, квартал возле серных
                        бань, явно с восточным влиянием, а также частный сектор из простых одноэтажных домиков.
                    </p>

                    <img src={transcaucasia_022} alt="Тбилиси" className="img-fluid travel__img"/>

                    <p>
                        Вечером идём в ресторан испробовать чего-нибудь истинно грузинского. Выбора много, но
                        по-настоящему колорит задают хинкали, по-моему. В Тбилиси, в отличие от Еревана, душно даже
                        вечером, поэтому, погуляв немного перед сном по проспекту Руставели, ночуем под кондиционером.
                    </p>

                    <p>
                        На следующий день идём в крупнейшую в Закавказье церковь – Цминда Самеба. Её размеры
                        действительно впечатляют, под её светлыми сводами огромное воздушное пространство, наполняемое
                        грузинским церковным пением. Стены храма богато украшены и расписаны с преобладанием золотого и
                        белого цветов.
                    </p>

                    <img src={transcaucasia_023} alt="Тбилиси" className="img-fluid travel__img"/>

                    <p>
                        После осмотра Цминды Самебы, спускаемся снова к центру, где посещаем менее крупные, зато более
                        древние святыни грузинской православной церкви, затем углубляемся по узкой улочке к симпатичной
                        башенке театра кукол.
                    </p>

                    <img src={transcaucasia_024} alt="Тбилиси" className="img-fluid travel__img"/>

                    <p>
                        После этого снова идём на Руставели, где в закуточке обедаем в украинском кафе. Затем идём к
                        фуникулёру, откуда поднимаемся в парк. Главное, зачем мы здесь - это прокатиться на пятидесяти
                        метровом колесе, что мы и делаем. Программа осмотра на этом заканчивается, вечером свободное
                        время, завтра уезжаем на запад.
                    </p>

                    <img src={transcaucasia_025} alt="Тбилиси" className="img-fluid travel__img"/>
                    <p>
                        Утром собираем вещи и выселяемся из хостела. Находим нашу машину, загружаемся и начинаем
                        движение. Постепенно выходим на нужную дорогу, ведущую на западную автостраду. Первая наша цель
                        – это парк Сатаплия недалеко от Кутаиси. Дорога до первых гор проходит по многополосной трассе,
                        затем становиться узкой и ведёт через горы.
                    </p>

                    <p>
                        К полудню мы на месте, берём билеты на вход и идём вместе с мультиязычным гидом, от которого
                        проку к слову никакого, по маршруту. Для начала нам демонстрируют небольшие следы,
                        отпечатавшиеся в мягкой породе, а затем ведут по лесу, где демонстрируют модели нескольких
                        динозавров в четверть от их реальных размеров.
                    </p>

                    <img src={transcaucasia_026} alt="Сатаплия" className="img-fluid travel__img"/>

                    <p>
                        В лесу очень влажно, капли в воздухе можно увидеть в виде неплотной туманной дымки. Затем лес
                        выходит к горному склону, который полностью утопает в зелени деревьев. Отсюда виднеется
                        полупрозрачный балкон на вершине холма, который служит смотровой площадкой туристам.
                    </p>

                    <img src={transcaucasia_027} alt="Сатаплия" className="img-fluid travel__img"/>

                    <p>
                        Мы проходим ещё несколько десятков метров и достигаем входа в пещеру, куда с удовольствием
                        проникаем. Здесь прохладно и воздух гораздо комфортнее для дыхания. Внутри организована красивая
                        подсветка и текут пещерные ручейки. Говорят, здесь неподалёку есть пещера «Прометея», которая
                        больше и глубже этой, но мы туда не успеваем.
                    </p>

                    <img src={transcaucasia_028} alt="Сатаплия" className="img-fluid travel__img"/>

                    <p>
                        Покинув пещеру, объектив фотокамеры мгновенно запотевает, так, что съёмка какое-то время
                        становится невозможной. Наконец, мы доходим до анонсированного мной полупрозрачного балкона,
                        который становится заключительным пунктом данной прогулки. Мы возвращаемся к машине.
                    </p>

                    <p>
                        Теперь мы должны вернуться на трассу и продолжить движение на запад. И только почти у самого
                        моря развернуться на север в сторону Кавказского хребта. После означенного поворота мы совершаем
                        остановку в Зугдиди для отдыха и трапезы.
                    </p>

                    <p>
                        Как мы поняли, в центре этого города есть только два заведения, где можно отобедать, и находятся
                        они в соседних зданиях: это Макдональдс и некое безымянное трёхэтажное заведение, где, такое
                        впечатление, останавливаются все голодные люди в Зугдиди. И мы были, естественно, в их числе.
                        Сначала мы походили по этажам и поискали свободный стол, это оказалось безуспешно. Также
                        бегающие по этажам официанты неуверенно предложили нам подождать, что не добавило нам
                        уверенности в правильности нашего выбора, однако, не увидев альтернативы, мы последовали их
                        совету и уже совсем скоро оказались сидящими за столом. Книга меню была толстой, и каждый мог
                        заказать себе обед по потребностям, что мы и сделали, отметив официанту по паре блюд, которые, к
                        слову, тоже пришлось изрядно подождать. Однако, уже первый заказ, принесённый официантом,
                        заставил нас пожалеть о количестве заказанного. Порции были просто огромными, мой суп харчо
                        наполнял чашу, по объёму соперничающую с небольшой кастрюлей. Стоит ли говорить, что второе
                        блюдо я уже почти не попробовал. В целом заведение хорошее, готовят здесь вкусно и крайне сытно.
                    </p>

                    <p>
                        После обеда мы продолжили путь на север и постепенно достигли реки Ингури. На этой реке
                        расположена крупнейшая на Кавказе ГЭС. К плотине данной ГЭС стекаются не только воды Ингури, но
                        и множество туристов проезжающих мимо, среди них, конечно, оказались и мы. Перед плотиной
                        образуется довольно обширное водохранилище с водой прекрасного бирюзового цвета. Нет большого
                        смысла долго описывать данное зрелище, поэтому просто полюбуйтесь.
                    </p>

                    <img src={transcaucasia_029} alt="Ингурская ГЭС" className="img-fluid travel__img"/>

                    <p>
                        Дальше дорога уходит на северо-восток и так до самой Местии. И надо сказать, данный отрезок
                        пути, я запомню, как один из самых живописных. Горы, покрытые густой растительностью, уходящие
                        на севере в Кавказский хребет, бурные горные речки, водопадики, стекающие со склонов то тут, то
                        там, горные тоннели и просто расщелины, через которые ведёт хорошего качества узкая дорога. А
                        также вкусный воздух, пьянящий купажом горных трав и смоченный брызгами быстрых потоков реки.
                        Остановись мгновение.
                    </p>

                    <img src={transcaucasia_030} alt="Дорога к Местии" className="img-fluid travel__img"/>

                    <p>
                        Спустя сотни поворотов серпантин привёл нас в центр местийского муниципалитета, месту нашего
                        назначения. Постепенно склонялись сумерки.
                    </p>

                    <img src={transcaucasia_031} alt="Местия" className="img-fluid travel__img"/>

                    <p>
                        Мы отыскали гостевой дом, в котором нас ждал ночлег, и оставили вещи. Дом традиционный, как и
                        все вокруг. Хозяин местный житель средних лет, хорошо владеющий русским языком, несмотря на
                        удалённость региона. В доме тоже всё было традиционно, но в спальнях новая мебель, удивил также
                        уверенный напор горячей воды, гораздо лучший, чем в хостеле в Тбилиси.
                    </p>

                    <p>
                        Местия знаменита своими каменными башенками, окутанные легендами, они возвышаются над остальной
                        застройкой. Вечером мы уже не успели их толком разглядеть, т.к. быстро стемнело. На следующее
                        утро моя жена помчалась исправлять это недоразумение, я же решил немного отдохнуть от вчерашней
                        дороги и поспать немного подольше.
                    </p>

                    <img src={transcaucasia_032} alt="Местия" className="img-fluid travel__img"/>

                    <p>
                        . После завтрака мы двинулись дальше в горы к подъёмнику, чтобы обозреть всю картину,
                        раскинувшуюся между гор. Мы прибыли к канатке аккурат к её открытию. Упав на лавки подъемника,
                        мы возвышались меж зелёной травки и высоких елей на склоне и через несколько минут были уже на
                        смотровой площадке.
                    </p>

                    <img src={transcaucasia_033} alt="Местия" className="img-fluid travel__img"/>

                    <p>
                        Перед нами лежала Местия с виднеющимися башенками, тонкими еле заметными венами улиц, с
                        расположившимся к востоку небольшим аэродромом. За всем этим раскинулись грозные склоны
                        Кавказских гор, а в самом центре гряды, высились двумя клыками, уходящие в облака два скальных
                        пика мистической Ушбы. А всего в полсотни километров к северо-западу за ней пряталась величайшая
                        точка Европы.
                    </p>

                    <img src={transcaucasia_034} alt="Местия" className="img-fluid travel__img"/>

                    <p>
                        После обзора всего этого великолепия мы спустились на подъёмнике к машине и отправились в
                        обратный путь. Подбираясь к Местии, мы остановились, чтобы сделать ещё несколько кадров города
                        издалека и попрощаться с ним.
                    </p>

                    <img src={transcaucasia_035} alt="Местия" className="img-fluid travel__img"/>

                    <p>
                        Мы покидали Местию, чтобы к вечеру достичь черноморского побережья. Нас ждал Батуми.
                    </p>

                    <p>
                        На обратном пути нас сопровождала пасмурная, местами дождливая погода. Мы остановились в
                        Зугдиди, чтобы отобедать в том же месте, и также не нашли сходу свободных мест. Начавшийся
                        сильный дождь отрезал нам охоту к новым поискам, поэтому пришлось дожидаться своей очереди.
                        Наконец, наши животы были довольны, и мы отправились дальше.
                    </p>

                    <p>
                        Наш путь лежал через Поти. Город долго тянулся длинной лентой своих портов, море было
                        неспокойно. Проехав дальше на юг, мы решили остановиться в местечке Уреки, известном своим
                        чёрным вулканическим песком.
                    </p>

                    <p>
                        С трудом припарковавшись, мы направились к берегу. Он был действительно чёрным от цвета
                        специфического песка, а за ним вздымалось и накатывалось на нас Чёрное море. Народу на берегу
                        было много, но никто не купался, так как на море был шторм. Дул сильный ветер, большие волны
                        спешили к берегу, так и намереваясь отхватить у него территорию побольше, облизывая его своими
                        пенными языками. Далеко в небе висели грозные тучи, кое-где пробивавшиеся светом закатывающегося
                        солнца, но прямо над головой было просто серо и беспросветно.
                    </p>

                    <img src={transcaucasia_036} alt="Уреки" className="img-fluid travel__img"/>

                    <p>
                        После долгой дороги мы решили помочить ноги в воде, но коварные волны то и дело накатывали с
                        новой силой, стремясь обрызгать нас с ног до головы. С этой задачей они справились, и наша
                        одежда стала почти полностью мокрой.
                    </p>

                    <p>
                        Надвигался вечер, и нам нужно было продолжать путь. Мы ехали дальше на юг. Скоро пошёл дождь и
                        уже не оставлял нас до самой ночи. Кабулети мы проезжали уже в темноте. Дальше нас ждал
                        последний отрезок пути, спуск к Батуми по извилистому серпантину.
                    </p>

                    <p>
                        В город мы заехали около десяти вечера, шёл сильный дождь и около двадцати градусов за бортом.
                        Не все из нас остановились в Батуми в одном месте. Наш итальянский друг снимал комнату в
                        гостевом доме, поэтому сначала нам пришлось изрядно покрутиться в поисках дороги по частному
                        сектору. Все остальные остановились в апартаментах недалеко от центра. Это было красивое
                        многоэтажное здание, целиком сдаваемое туристам для проживания.
                    </p>

                    <p>
                        Достигнув места, мы нашли парковку и побежали искать вход. Продолжал лить дождь. Мы нашли
                        входную дверь, однако, она была заперта и на звонки в домофон нам никто не отвечал. Вымокнув до
                        нитки, мы уже теряли терпение, и я начал тарабанить в дверь и кричать, с просьбой открыть нам
                        дверь. Но помощь пришла с другой стороны. Какая-то индийская пара с ребёнком, видимо снимавшая
                        квартиру в этом же здании, подошла к двери и, проведя магнитной картой возле замка, открыла
                        дверь.
                    </p>

                    <p>
                        Мы вошли в здание, коридор был полностью отделан красноватым камнем, а в конце него отливали
                        металлическим светом двери лифта. Рядом с ними находилась, такая же красная как и коридор,
                        лестничная клетка. Не было ничего похожего на ресепшн или стойку с информацией. Мы поднялись на
                        первый этаж, но все двери на нём были плотно закрыты, никаких людей или просто приветственных
                        надписей здесь не было. Так мы поднялись ещё на два этажа наверх, но картина никак не
                        изменилась. Наконец, достигнув четвёртого этажа, мы встретили двух молодых людей, с виду арабов,
                        выходивших из своих апартаментов и говоривших между собой на своём наречии. Тогда мы решили
                        испробовать свой базовый английский, и уточнить есть здесь всё же ресепшн или что-нибудь похожее
                        на него, впрочем, совсем не надеясь на то, что нас поймут. Однако, парни хорошо понимали наш
                        ломанный английский, оказались очень приветливыми, и даже предложили позвонить управляющему
                        данного заведения со своего телефона. Чем мы с благодарностью и воспользовались. Мы
                        поблагодарили новых знакомых за помощь, и они решили поинтересоваться откуда мы: «Where are you
                        from?», - спросил один из них, - «From Russia», отозвался я, - «Oh, Russia — Good!», - с улыбкой
                        отреагировал наш новый друг, и мы горячо распрощались.
                    </p>

                    <p>
                        Через пять минут приехал управляющий, и на ломанном русском объяснил, что обычно заселения
                        происходят до девяти вечера, поэтому мы здесь никого не застали. Он выдал ключи от наших
                        апартаментов и проводил нас. В квартире было всё, что нужно для жизни, весь набор кухонной
                        утвари, бытовая техника и чистая ванная. Наш приезд в Батуми состоялся.
                    </p>

                    <p>
                        На следующий день робко пошли испробовать свои силы на пляже. Дождь прекратился, но море
                        по-прежнему было не спокойным. В небе было пасмурно, солнце изредка показывало свои отблески
                        сквозь почти сплошную облачность.
                    </p>

                    <img src={transcaucasia_037} alt="Батуми" className="img-fluid travel__img"/>

                    <p>
                        Народу на пляжах было не много, купающихся ещё меньше. Волны накатывали на берег довольно
                        внушительно. Тем не менее, хотелось поскорее окунуться в морскую воду, но зайти с первого раза
                        не удалось, как впрочем, и со второго, волны отбрасывали назад, часто роняли на крупную гальку.
                        Но выждав момент, удалось сделать несколько широких шагов и распластаться на набегающей волне. В
                        общем, купание удалось. Выход из воды был таким же увлекательным, как и вход, с использование
                        более чем двух конечностей.
                    </p>

                    <p>
                        После купания нас ждала прогулка по городу, в котором с 2014 года достроились некоторые отели,
                        но в целом город остался весьма узнаваемым. После обеда нас ждал небольшой отдых, а вечером мы
                        отправились к канатной дороге, что была в трёх минутах ходьбы от нашего дома, и поднялись на
                        вершину холма к смотровой площадке.
                    </p>

                    <img src={transcaucasia_038} alt="Батуми" className="img-fluid travel__img"/>

                    <p>
                        Здесь наверху можно было осмотреть город с высоты под несколькими ракурсами, также здесь была
                        организована сцена, где играла местная команда, рядом стояли столики. Как нам потом сказали,
                        музыкальная группа называлась «Lurji Ialqani», и она создавала прекрасную атмосферу здесь
                        наверху, играя как кавер музыку на английском языке, так песни на грузинском, возможно,
                        собственного сочинения. Публика была в восторге.
                    </p>

                    <img src={transcaucasia_039} alt="Батуми" className="img-fluid travel__img"/>

                    <p>
                        На следующий день программа повторилась: купание в уже заметно более спокойном море, затем
                        прогулки по городу. В этот раз доехали на такси до конца Батумского бульвара, тянувшегося вдоль
                        берега моря, и начали движение от туда. Увидели кое-что новое для себя.
                    </p>

                    <img src={transcaucasia_040} alt="Батуми" className="img-fluid travel__img"/>

                    <p>
                        Потом готовим обед, продукты покупаем на развалах недалеко от нашего дома, в глубине квартала,
                        выходит гораздо дешевле, чем супермаркетах для туристов.
                    </p>

                    <p>
                        Вечером хотели ещё раз послушать музыку, но поднявшись на канатке на смотровую площадку, увидели
                        только облако, которое зависло над холмом, и стало причиной отмены культурной программы на
                        сегодня. Обидно, да.
                    </p>

                    <p>
                        На следующее утро прощаемся с Батуми, кто хочет, идёт к совсем уже спокойному морю, кто-то
                        гуляет по городу, я же просто набираюсь сил лёжа на диване.
                    </p>
                    <p>
                        Наконец час пробил, и мы, выселяясь из квартиры, грузимся в автомобиль. Наш путь лежит на
                        юго-восток к городу Ахалцихе. По пути заезжаем в Боржоми. Находим источник с минеральной водой,
                        но краник один, а желающих много. Добрым словом вспоминаю Джермук. Всё таки, решаем наполнить
                        пятилитровую флягу, стоим в очереди. Начинается дождь. Вокруг пёстрый национальный состав,
                        некоторые лезут без очереди. Наконец набираем водицы. Из бутылки резкий запах сероводорода, на
                        вкус тоже малоприятно. Ещё раз добрым словом вспоминаю Джермук. Прощаемся с Боржоми, двигаемся
                        дальше.
                    </p>

                    <img src={transcaucasia_041} alt="Боржоми"
                         className="img-fluid travel__img"/>

                    <p>
                        Уже надвигаются сумерки, когда мы заезжаем в Ахалцихе. Находим наш отель со странным названием
                        3D. Хозяин отеля радушный пожилой человек с юмором. Громко и с колоритом здоровается с Массимо,
                        сразу провожает его в номер. С остальными более сдержан, но узнав, что мы вместе с итальянцем,
                        почему-то становится веселее, и разрешает пользоваться чайным столиком в коридоре. В номерах
                        чисто, всего хватает.
                    </p>

                    <p>
                        На ужин спускаемся в кафе, которое располагается на первом этаже этого здания. Полное ощущение,
                        что попали в девяностые. Интерьер: обитые деревом стены, деревянная мебель, на столах пепельницы
                        и тоненькие салфетки. Звучит русский шансончик. Тётушка приносит меню, но, похоже, плохо говорит
                        по-русски и бежит за помощью. К нам подходит средних лет мужчина, уже лысоватый с золотой
                        цепочкой и в кожаной куртке. Почти без акцента осведомляется, что мы будем кушать. Заказываем
                        котлеты с пюрешкой, салатик, водичку и.т.п. Ну, совсем как дома. Казалось бы без национального
                        колорита. Мужчина желает нам хорошего вечера. Колорит замечаем, когда приносят еду: на каждой
                        порции с пюре почему-то по две котлеты. Всё вкусно и по-домашнему. После бесконечных хачапури и
                        кебабов, как глоток свежего воздуха.
                    </p>

                    <p>
                        Утром после завтрака, идём в крепость Рабат, где я с друзьями уже был в 2014 году. Здесь всё
                        также красиво и ухожено. Но приходиться спешить, т.к. у нас сегодня по плану ещё посещение
                        пещерного храмового комплекса Вардзия, а ночёвка уже в Ереване.
                    </p>

                    <img src={transcaucasia_042} alt="Ахалцихе"
                         className="img-fluid travel__img"/>

                    <p>
                        Пробегаемся по основным достопримечательностям крепости и удаляемся. Едем дальше на юг,
                        достигаем поворота на Вардзию и поворачиваем. Постепенно попадаем в грозное по виду ущелье,
                        идущее вдоль русла реки Куры. Начинает виднеться Вардзия. Издалека это множество выдолбленных в
                        скалах окошек. И забегая вперёд скажу, что я ожидал от этого места гораздо меньшего, поэтому
                        Вардзия меня приятно удивила. Паркуемся на небольшой стояночке перед подъёмом и начинаем
                        движение по серпантину наверх. Сверху идут какие-то реставрационные работы, и со склона время от
                        времени скатывают довольно внушительных размеров камни. В эти моменты один из рабочих голосом
                        преграждает дорогу туристам и те вынуждены ждать. Наконец, проход свободен, можно подниматься
                        дальше. Достигаем первых углублений в скалах, это разных размеров комнаты, выдолбленные на
                        склонах, и они то, как раз, не очень впечатляют. Однако, впереди по склону расположен целый
                        монастырь полностью выдолбленный в скале.
                    </p>

                    <img src={transcaucasia_043} alt="Вардзия"
                         className="img-fluid travel__img"/>

                    <p>
                        Монастырь даже снаружи расписан цветными картинами святых, здесь же располагается небольшая
                        колокольня.
                    </p>

                    <img src={transcaucasia_044} alt="Вардзия"
                         className="img-fluid travel__img"/>

                    <p>
                        А если заглянуть за монастырь то можно найти извилистый и разветвлённый проход, один из ходов
                        которого ведёт внутри скалы наверх, на склон, который располагается над монастырём и поодаль от
                        него. Объём работ проделанный монахами с использованием примитивных инструментов восхищает.
                        Историки относят комплекс к XII-XIII веку.
                    </p>

                    <img src={transcaucasia_045} alt="Вардзия"
                         className="img-fluid travel__img"/>

                    <p>
                        Всё, на этом наша экскурсионная программа закончилась. Мы приближаемся к границе с Арменией.
                    </p>

                    <img src={transcaucasia_046} alt="Грузия"
                         className="img-fluid travel__img"/>

                    <p>
                        Этот пограничный переход не пользуется таким спросом как переход у Садахло и дорогу здесь
                        забросили. Последние 15 километров едем, наверное, минут 40. Проезжая часть в непролазных ямах
                        местами во всю её ширину, ехать приходиться преимущественно по обочине. В пограничной зоне тоже
                        дороги нет, всё засыпано крупным щебнем. Здесь идёт строительство нового пограничного пункта.
                        Возможно, когда его достроят, дороги тоже отремонтируют.
                    </p>

                    <p>
                        Очередей здесь конечно нет, быстро проходим как грузинский, так и армянский пункты. Причём
                        выходить из машины никому не приходится, соответствие пассажиров представленным паспортам
                        проверяют через опущенные стекла дверей.
                    </p>

                    <p>
                        Дорога у границы с армянской стороны значительно лучше, чем была два года назад, видны
                        относительно свежие заплатки на асфальте. Где-то через час мы уже в Гюмри, в Ереван заезжаем
                        традиционно после заката. Проезжая мимо местного ТРК, останавливаемся прикупить подарков домой и
                        заодно перекусить. После ужина едем к месту ночлега и это снова One Way Hostel, правда другой, в
                        непосредственной близости от площади Республики. Однако, очков это ему не добавило. Прибыв на
                        место, выяснилось, не смотря на бронирование, четырёхместный номер отдали кому-то другому, и
                        есть только трёхместный, а номера для Массимо нет совсем. Пришлось нам использовать одну
                        раскладушку вместо кровати, а для итальянца в срочном порядке подыскивать место в другом
                        хостеле. Парень администратор, а по его словам просто ночной сторож, дозвонился до хостела
                        неподалёку, и там нас ждали. Наши женщины остались в OneWay, мы же поехали провожать нашего
                        итальянского друга до места его нового жилища. Прибыв на место, я с Массимо поднялись в Хостел,
                        где нас встретила дотошная бабушка. Я сказал, что привёл очень важного итальянского гостя, и
                        поинтересовался, говорит ли она по-английски, на что она недоумённо и мягко выговорила: «А
                        зачем? Мы итак найдём с ним общий язык». И я ей как-то сразу поверил. Поскольку мы с ней
                        говорили по-русски, она поинтересовалась: «А вы кто грузин?», - «Нет, я русский», твёрдо
                        выговорил я. Она ещё раз внимательно оглядела меня с головы до ног, и мы с ней рассмеялись.
                        Когда я вернулся к машине выяснилось, что ни у меня, ни у моего спутника не было с собой
                        водительских удостоверений, т.к. мы оставили их нашем хостеле. Но до него было недалеко, и мы
                        решили, что доедем и без них. Всё так и получилось, мы доехали без происшествий, и наконец,
                        заслужили отдых.
                    </p>

                    <p>
                        Следующий день был у нас в Ереване крайним. Наши челябинские друзья улетали раньше всех, и утром
                        мы попрощались с ними и отправили в аэропорт. В наших планах было посетить родственников, а
                        Массимо отправился в сольный поход по недоисследованному Еревану.
                    </p>

                    <p>
                        Вечером мы встретились с ним уже в аэропорту. До Москвы он летел тем же рейсом, что и мы,
                        поэтому прощаться с ним нам пришлось только там. Его ждало ещё два перелёта до его дома в
                        Сардинии, нам же предстоял всего один, длинной практически идентичной тому пути, который мы
                        проделали в минувшие две недели по дорогам Закавказья. Но мы обязательно вернёмся, ведь даже для
                        нас остались ещё белые места на карте этого прекрасного края.
                    </p>

                    <p>
                        Если вы дочитали до конца, то вам будет особенно интересно посмотреть видео-ролик,
                        смонтированный по итогам этого путешествия. Приятного просмотра!
                    </p>
                    <div className="travel__iframeWrap">
                        <iframe className="travel__iframe"
                                src="https://www.youtube.com/embed/vrXyZKTvc88?rel=0&amp;showinfo=0" frameBorder="0"
                                allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Transcaucasia;
import React from 'react';
import {Link} from "react-router-dom";
import './Navbar.css';
import MobileMnu from "../MobileMnu/MobileMnu";

const Navbar = ({mobileMnu, setMobileMnu}) => {
    const handleMobileClick = () => {
        setMobileMnu(true);
        document.body.classList.add('lock');
    }
    return (
        <>
            <div className="container">
                <div className="row navbar__row">
                    <div className="col-md-2 col-4">
                        <Link to="/main" className="navbar__navLinkLogo"/>
                    </div>
                    <div className="col-md-10 col-8">
                        <nav className="navbar__nav">
                            <Link to="/travel" className="navbar__navLink">Поездки</Link>
                            <Link to="/quotes" className="navbar__navLink">Цитаты</Link>
                            <Link to="/music" className="navbar__navLink">Музыка</Link>
                        </nav>

                        <div className="navbar__burgerWrap">
                            <button
                                onClick={handleMobileClick}
                                className="navbar__burger d-block d-sm-none"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <MobileMnu mobileMnu={mobileMnu} setMobileMnu={setMobileMnu}/>
        </>
    );
};

export default Navbar;
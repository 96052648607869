import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div className="container footer__con">
            <div className="row">
                <div className="col-12 d-flex justify-content-center">
                    <p className="footer__text">
                        Artem Arshakyan &copy; Since 2015
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
import React from 'react';
import tyumen_01 from '../../../images/tyumen/tyumen_01.jpg';
import tyumen_02 from '../../../images/tyumen/tyumen_02.jpg';
import tyumen_03 from '../../../images/tyumen/tyumen_03.jpg';
import tyumen_04 from '../../../images/tyumen/tyumen_04.jpg';
import tyumen_05 from '../../../images/tyumen/tyumen_05.jpg';
import tyumen_06 from '../../../images/tyumen/tyumen_06.jpg';
import tyumen_07 from '../../../images/tyumen/tyumen_07.jpg';
import tyumen_08 from '../../../images/tyumen/tyumen_08.jpg';
import tyumen_09 from '../../../images/tyumen/tyumen_09.jpg';
import tyumen_10 from '../../../images/tyumen/tyumen_10.jpg';
import tyumen_11 from '../../../images/tyumen/tyumen_11.jpg';
import tyumen_12 from '../../../images/tyumen/tyumen_12.jpg';
import tyumen_14 from '../../../images/tyumen/tyumen_14.jpg';

const Tyumen = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <p>
                        Решили использовать майские праздники для небольшого путешествия с семьей (я с женой и
                        ребёнком). Сначала рассматривался вариант посещения города Пермь, с заездом в Кунгурскую пещеру.
                        Однако, прогноз погоды, а также чрезвычайная, как мы поняли загруженность «Кунгура» в праздники
                        заставили нас рассмотреть ещё варианты мест для посещения. Выбор пал на город Тюмень по ряду
                        причин: во-первых он находится на относительно доступной досягаемости от г. Челябинска,
                        во-вторых город, по слухам, довольно богатый, и соответственно, должен был быть ухоженным,
                        в-третьих город довольно древний, что прибавляло баллов в копилку его достопримечательностей и,
                        наконец, в четвёртых рядом с ним располагалось множество естественных горячих источников, в
                        которых приятней всего купаться в прохладную погоду. Учитывая все эти факторы, было решено
                        направиться именно в Тюмень. А теперь подробно, об этом…
                    </p>

                    <p>С утра пораньше грузимся в машину, выезжаем на Бродокалмакский тракт и дальше всё время прямо на
                        Курган. Дорога через Шадринск хоть и короче, но по слухам в плохом состоянии. Дорога на Курган
                        тоже не идеал, но вполне сносная по общероссийским меркам, и в этот день была малозагружена.
                        Через пару часов хотели остановиться покушать, свернув в какую-то деревеньку, но разлившийся
                        Тобол частично затопил дорогу, преградив нам путь.</p>
                    <img src={tyumen_01} alt="Разлившийся Тобол" className="img-fluid travel__img"/>

                    <p>Я не рискнул следовать за местными, ехавших на машинах с клиренсом повыше, тем более что у меня
                        не было в этом острой необходимости. В результате мы доехали до Кургана и сделали привал
                        там.</p>

                    <p>
                        На следующем отрезке пути, приближаясь к Тюменской области, мы заехали Чимеевский монастырь (да,
                        Курганская область очень богата на различные религиозные сооружения). Дорога до монастыря около
                        15 км с трассы и местами весьма разухабистая и совсем без покрытия. Деревня же, где расположен
                        монастырь, весьма ухожена, множество паломников, в том числе организованных, занимали парковку
                        возле комплекса, и осматривали территорию монастыря. Здесь неподалёку расположен, также,
                        «святой» родник. Главный храм Чимеевского монастыря красив внешне, и богато оформлен изнутри.
                        Место в целом интересное.
                    </p>
                    <img src={tyumen_02} alt="Чимеевский монастырь" className="img-fluid travel__img"/>

                    <p>
                        После посещения монастыря возвращаемся на трассу, чтобы продолжить путь в Тюмень. Вскоре
                        пересекаем границу областей. За приветственной табличкой нас ожидает уже другой уровень
                        «качества жизни». Асфальт сразу становиться гладким, разметка яркой, кроме того дорогу окружает
                        плотный таёжный лес, ехать становиться приятней.
                    </p>

                    <p>
                        Трасса не меняет своего качества вплоть до самой Тюмени, в которую мы заезжаем по широкой дороге
                        и ищем место нашего назначения по ул. Прокопия Артамонова, дом 5, где мы заблаговременно
                        забронировали квартиру на время своего пребывания, а о прибытии сообщали дважды по телефону за 4
                        часа, а также за 40 минут до приезда. Однако, отыскав нужный двор, и припарковавшись, очередные
                        наши звонки арендодателям несколько раз оставили без ответа, а затем, всё же взяв трубку,
                        сообщили, что в «нашем» доме сегодня отключали воду, и организаторы не смогли убраться в
                        помещении, по этой причине нам придётся подождать ещё два часа, после 7 часового пути, чтобы
                        попасть внутрь. Почему раньше об этом нам не сообщили неясно, а точнее ясно то, что рассказ про
                        отключенную воду был выдуман только что. А когда моя жена потребовала предоставить вычет из
                        стоимости за несвоевременное предоставление жилья, тогда зарвавшийся трусливый ублюдок в прямом
                        смысле послал её на три буквы и бросил трубку. Больше на наши звонки нам попросту не отвечали.
                        Стоит сказать, что с таким случаем безответственности и уверенности в свою безнаказанность я не
                        сталкивался нигде. Даже если услуга оказывалась не должным образом или были какие-то косяки с
                        бронью, нам всегда шли навстречу с пониманием и дружелюбием. Очень жаль, что в России ещё
                        остаются такие недолюдки, делающие подобные случаи ведения дел возможными, что определённо
                        тормозит внутренний туризм в России, делая его малопривлекательным.
                    </p>

                    <p>
                        Практический вывод из этой ситуации следующий, заказывая жильё через сервисы бронирования,
                        обязательно выбирайте варианты с хорошими отзывами, и оценкой основанной на большом количестве
                        благодарных пользователей. Такие места обычно очень дорожат своим трудом заработанной
                        репутацией, кроме того такую репутацию просто не могут иметь откровенные проходимцы и дилетанты.
                        Новые места, особенно те в которых невозможно отследить арендодателя, прийти к нему лично и
                        заставить проявить ответственность идут лесом.
                    </p>

                    <p>
                        После этой крайне неприятной ситуации, нам ещё и пришлось расхлёбывать её последствия, т.е.
                        наспех бронировать первое попавшееся жильё, и двигаться на его поиски. Стоит ли говорить, что
                        квартира, где мы оказались гораздо скромнее, чем планируемое заранее жильё, хотя и не в худшем
                        месте (Червишевский тракт, 7). Но что поделаешь, спасибо и на этом, ведь представитель агентства
                        прибыл быстро, и нам более не пришлось ничего ждать. Приятным сюрпризом оказалось наличие
                        отопления в квартире, которое в Тюмени повсеместно не выключали в отличие от Челябинска. Что
                        было крайне актуально в связи с серьёзным похолоданием (около 5-7 градусов Цельсия).
                    </p>

                    <p>
                        Немного передохнув, отправились на экскурсию в центр. Здесь в изобилии малоэтажная историческая
                        застройка, чистота и порядок. Из за репетиции парада некоторые улицы были перекрыты, постояли в
                        пробке неспешно изучали город из окна автомобиля. Объехав перекрытую улицу, мы выехали к реке
                        Тобол и остановились на набережной.
                    </p>
                    <img src={tyumen_03} alt="Тюмень" className="img-fluid travel__img"/>

                    <p>
                        Восприятие красивого вида города, раскинувшегося по обеим сторонам реки, портила неприятная
                        погода. Мы двинулись дальше вдоль набережной и выехали к белым стенам монастыря. Это был
                        Свято-Троицкий мужской монастырь, основанный аж в 1616 году, который, к сожалению, уже
                        закрывался, поэтому наскоро осмотрев его территорию, нам нужно было его покидать.
                    </p>
                    <img src={tyumen_04} alt="Свято-Троицкий монастырь" className="img-fluid travel__img"/>

                    <p>
                        Дальше ещё небольшая авто-экскурсия по городу и мы паркуемся возле центрального сквера, напротив
                        здания Роснефти. Здесь фонтан, различные аттракционы, а также есть где поужинать.
                    </p>
                    <img src={tyumen_05} alt="Тюмень" className="img-fluid travel__img"/>

                    <p>
                        Мы забегаем в Пиццу Мию, здесь привычное меню, то, что нам было нужно, без сюрпризов. А из
                        приятных особенностей имеется детская игровая, где ребятки могут порезвится пока родители
                        доедают свои порции. После ужина едем в квартиру, отдыхать.
                    </p>

                    <p>
                        На следующий день, после завтрака, потихоньку собираемся, чтобы поехать на естественный горячий
                        источник, коих в окрестностях Тюмени несколько. Выбираем источник «Советский» по Салаирскому
                        тракту чуть больше 30 километров от Тюмени. Добираемся, платим за вход у въезда на территорию,
                        нам выдают чипованные браслеты и пропускают. Ищем на территории парковку и идём в корпус. Здесь
                        всё вполне прилично, в раздевалке можно выбрать шкафчик для переодевания, ключом к которому
                        будет служить браслет, здесь есть фены для сушки, душевые и даже небольшая парилка. Из
                        раздевалки выходим на улицу где и расположен бассейн с горячей (около 50 градусов) водой.
                        Контраст между прохладной погодой и температурой воды очень приятен. В воде много людей, но
                        места всем хватает. В бассейне также установлено несколько видов леек для гидромассажа,
                        выпускающих воду сверху вниз. Ребёнку всё это очень нравится, и мы купаемся около полутора часов
                        и идём переодеваться. Многие остаются на шашлык, мангал можно установить прямо на территории.
                        Начинается моросящий дождь, и мы отправляемся обратно в Тюмень.
                    </p>

                    <p>
                        Вечером идём в местный ТРК под названием «Галерея Вояж» и находим там грузинский ресторан
                        «Хинкальная Кацо» и естественно решаем его посетить. Он забит людьми, но мы находим одно место и
                        готовимся к трапезе. Поджаренные хинкали с кисло-сладким соусом очень вкусны.
                    </p>

                    <p>
                        После трапез, едем ещё немного покататься по городу. Перебираемся на другой берег Тобола. Далее
                        едем в продуктовый магазин за продуктами. Совершив покупки, выходим из него и попадаем в зиму.
                        На улице идёт настоящая метель. Согревает мысль, что в квартире есть отопление. Мы сразу
                        движемся туда и спасаемся от стихии.
                    </p>

                    <p>
                        С утра собираемся, выселяемся из квартиры и ещё раз идём гулять по городу.
                    </p>
                    <img src={tyumen_06} alt="Тюмень" className="img-fluid travel__img"/>

                    <p>
                        На улице прохладно но солнечно. Наконец-то есть условия, что бы пройтись пешком по центру.
                    </p>

                    <img src={tyumen_07} alt="Тюмень" className="img-fluid travel__img"/>

                    <p>
                        Движемся вдоль центральных улиц.
                    </p>
                    <img src={tyumen_08} alt="Тюмень" className="img-fluid travel__img"/>

                    <p>
                        Доходим до сквера. Выходим на набережную. Красиво и опрятно.
                    </p>
                    <img src={tyumen_09} alt="Тюмень" className="img-fluid travel__img"/>

                    <p>
                        Удовлетворённые мы идём к машине и движемся в обратный путь.
                    </p>
                    <img src={tyumen_10} alt="Тюмень" className="img-fluid travel__img"/>

                        <p>
                            Чтобы разнообразить маршрут едем через Свердловскую область, так длиннее, но интереснее.
                            Странно, но на выезде из Тюмени в этом направлении почти нет приличных заправок. Быстро
                            пересекаем границу областей.
                        </p>

                        <p>
                            По пути заезжаем в город Камышлов останавливаемся у Покровского собора. Здесь запустение. На
                            входе убогий мужик спрашивает у меня: «Какой сегодня день недели?». Сам с трудом вспоминаю,
                            что понедельник – выходные затянулись. Заходим внутрь, осматриваемся – старина, как она
                            есть, никаких следов реставрации не видно. В этом тоже своя прелесть.
                        </p>
                        <img src={tyumen_11} alt="Камышлов. Покровский собор." className="img-fluid travel__img"/>

                        <p>
                            Едем дальше, следующая остановка Екатеринбург. Разминаемся прогулкой по центру и
                            перекусываем в местном ТРК Гринвич.
                        </p>
                        <img src={tyumen_12} alt="Екатеринбург" className="img-fluid travel__img"/>

                        <p>
                            Далее по пешей улице обратно к месту стоянки автомобиля. Чувствуется приближение праздника,
                            завтра 9 мая.
                        </p>
                        <img src={tyumen_14} alt="Екатеринбург" className="img-fluid travel__img"/>

                        <p>
                            Всё, вояж окончен, остался последний отрезок до Челябинска. Уральская столица провожает нас
                            закатом. Через два с половиной часа мы будем дома.
                        </p>

                        <p>
                            Традиционный видео-ролик, смонтированный по итогам поездки.
                        </p>
                        <div className="travel__iframeWrap">
                            <iframe className="travel__iframe"
                                    src="https://www.youtube.com/embed/Gvt_z-77sBE?rel=0&amp;showinfo=0" frameBorder="0"
                                    allowFullScreen></iframe>
                        </div>
                </div>
            </div>
        </div>
);
};

export default Tyumen;
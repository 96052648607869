import React from 'react';
import '../../Travel/Travel.css'
import landing001 from '../../../images/zakavkaz/001_landing.jpg';
import road_to_flat002 from '../../../images/zakavkaz/002_road_to_flat.jpg';
import flat003 from '../../../images/zakavkaz/003_flat.jpg';
import streets004 from '../../../images/zakavkaz/004_streets.jpg';
import melnica005 from '../../../images/zakavkaz/005_melnica.jpg';
import lusavorich006 from '../../../images/zakavkaz/006_lusavorich.jpg';
import square007 from '../../../images/zakavkaz/007_square.jpg';
import cafe_sleepy08 from '../../../images/zakavkaz/008_cafe_sleepy.jpg';
import breakfast009 from '../../../images/zakavkaz/009_breakfast.jpg';
import lovers_park010 from '../../../images/zakavkaz/010_lovers_park.jpg';
import president_palace011 from '../../../images/zakavkaz/011_president_palace.jpg';
import cascade012 from '../../../images/zakavkaz/012_cascade.jpg';
import cascade_top013 from '../../../images/zakavkaz/013_cascade_top.jpg';
import town_view014 from '../../../images/zakavkaz/014_town_view.jpg';
import park_pobeda015 from '../../../images/zakavkaz/015_park_pobeda.jpg';
import kolesnica016 from '../../../images/zakavkaz/016_kolesnica.jpg';
import erebuni017 from '../../../images/zakavkaz/017_erebuni.jpg';
import erebuni_build018 from '../../../images/zakavkaz/018_erebuni_build.jpg';
import brandy_factory019 from '../../../images/zakavkaz/019_brandy_factory.jpg';
import bazar020 from '../../../images/zakavkaz/020_bazar.jpg';
import garni_temple021 from '../../../images/zakavkaz/021_garni_temple.jpg';
import garni022 from '../../../images/zakavkaz/022_garni.jpg';
import gegard023 from '../../../images/zakavkaz/023_gegard.jpg';
import gegard_inter024 from '../../../images/zakavkaz/024_gegard_inter.jpg';
import gegard_away025 from '../../../images/zakavkaz/025_gegard_away.jpg';
import khorVirap026 from '../../../images/zakavkaz/026_khor-virap.jpg';
import ararat027 from '../../../images/zakavkaz/027_ararat.jpg';
import to_noravank028 from '../../../images/zakavkaz/028_to_noravank.jpg';
import noravank_church029 from '../../../images/zakavkaz/029_noravank_church.jpg';
import noravank_view030 from '../../../images/zakavkaz/030_noravank_view.jpg';
import djermuk_walking031 from '../../../images/zakavkaz/031_djermuk_walking.jpg';
import djermuk_waterfall032 from '../../../images/zakavkaz/032_djermuk_waterfall.jpg';
import djermuk_mineral033 from '../../../images/zakavkaz/033_djermuk_mineral.jpg';
import djermuk_away034 from '../../../images/zakavkaz/034_djermuk_away.jpg';
import road_to_sunik from '../../../images/zakavkaz/road_to_sunik.jpg';
import hnzoresk_break035 from '../../../images/zakavkaz/035_hnzoresk_break.jpg';
import hnzoresk_temple036 from '../../../images/zakavkaz/036_hnzoresk_temple.jpg';
import hnzoresk_bridge037 from '../../../images/zakavkaz/037_hnzoresk_bridge.jpg';
import hnzoresk_cafe038 from '../../../images/zakavkaz/038_hnzoresk_cafe.jpg';
import tatev_wings039 from '../../../images/zakavkaz/039_tatev_wings.jpg';
import tatev_temple040 from '../../../images/zakavkaz/040_tatev_temple.jpg';
import tatev_view040 from '../../../images/zakavkaz/040_tatev_view.jpg';
import karaunge_kurgan041 from '../../../images/zakavkaz/041_karaunge_kurgan.jpg';
import karaunge_stones042 from '../../../images/zakavkaz/042_karaunge_stones.jpg';
import karavansarai043 from '../../../images/zakavkaz/043_karavansarai.jpg';
import georgia_road_castle044 from '../../../images/zakavkaz/044_georgia_road_castle.jpg';
import ahalcihe_castle045 from '../../../images/zakavkaz/045_ahalcihe_castle.jpg';
import borjomi046 from '../../../images/zakavkaz/046_borjomi.jpg';
import police_dep047 from '../../../images/zakavkaz/047_police_dep.jpg';
import sign_to_poti048 from '../../../images/zakavkaz/048_sign_to_poti.jpg';
import road_to_batumi049 from '../../../images/zakavkaz/049_road_to_batumi.jpg';
import prepare_to_swim050 from '../../../images/zakavkaz/050_prepare_to_swim.jpg';
import batumi_town051 from '../../../images/zakavkaz/051_batumi_town.jpg';
import batumi_bulevard052 from '../../../images/zakavkaz/052_batumi_bulevard.jpg';
import batumi_square053 from '../../../images/zakavkaz/053_batumi_square.jpg';
import batumi_mosaic054 from '../../../images/zakavkaz/054_batumi_mosaic.jpg';
import batumi_church055 from '../../../images/zakavkaz/055_batumi_church.jpg';
import batumi_dinner056 from '../../../images/zakavkaz/056_batumi_dinner.jpg';
import batumi_last_night057 from '../../../images/zakavkaz/057_batumi_last_night.jpg';
import rabat_towers058 from '../../../images/zakavkaz/058_rabat_towers.jpg';
import rabat_osman_style058 from '../../../images/zakavkaz/058_rabat_osman_style.jpg';
import rabat_walls059 from '../../../images/zakavkaz/059_rabat_walls.jpg';
import rabat_view060 from '../../../images/zakavkaz/060_rabat_view.jpg';
import rabat_bridge061 from '../../../images/zakavkaz/061_rabat_bridge.jpg';
import erevan_sport_complex062 from '../../../images/zakavkaz/062_erevan_sport_complex.jpg';
import erevan_dance063 from '../../../images/zakavkaz/063_erevan_dance.jpg';
import dendro_intro064 from '../../../images/zakavkaz/064_dendro_intro.jpg';
import dendro_way065 from '../../../images/zakavkaz/065_dendro_way.jpg';
import dendro_trees066 from '../../../images/zakavkaz/066_dendro_trees.jpg';
import lori_berd_mountain067 from '../../../images/zakavkaz/067_lori_berd_mountain.jpg';
import lori_berd_bridge068 from '../../../images/zakavkaz/068_lori_berd_bridge.jpg';
import comunism_cave069 from '../../../images/zakavkaz/069_comunism_cave.jpg';
import comunism_cave_waterfall070 from '../../../images/zakavkaz/070_comunism_cave_waterfall.jpg';
import lastiver_way071 from '../../../images/zakavkaz/071_lastiver_way.jpg';
import lastiver_forest072 from '../../../images/zakavkaz/072_lastiver_forest.jpg';
import lastiver_cafe073 from '../../../images/zakavkaz/073_lastiver_cafe.jpg';
import lastiver_river074 from '../../../images/zakavkaz/074_lastiver_river.jpg';
import lastiver_waterfall075 from '../../../images/zakavkaz/075_lastiver_waterfall.jpg';
import lastiver_cave_way076 from '../../../images/zakavkaz/076_lastiver_cave_way.jpg';
import lastiver_cave_artefacts077 from '../../../images/zakavkaz/077_lastiver_cave_artefacts.jpg';
import lastiver_cave_wall078 from '../../../images/zakavkaz/078_lastiver_cave_wall.jpg';
import dilijan_old079 from '../../../images/zakavkaz/079_dilijan_old.jpg';
import sevan808 from '../../../images/zakavkaz/080_sevan.jpg';
import aquapark081 from '../../../images/zakavkaz/081_aquapark.jpg';
import pantenol083 from '../../../images/zakavkaz/083_pantenol.jpg';


const Zakavkaz = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <p>
                        В Августе - Сентябре 2014 года, я (Артём Аршакян), Алексей Украинский и Алексей Кириллов решили
                        отправиться в республику Армения, где на тот момент уже отдыхали моя супруга (Джульетта) с
                        сыном. Это был первый совместный опыт путешествий в таком составе, но мы уже очень давно знали
                        друг друга, поэтому, забегая вперёд, неожиданностей не было. План был такой: мы с Алексеем
                        Украинским летим в Москву, встречаем там Алексея Кириллова, и летим все вместе в город Ереван.
                        Далее мы должны были заселиться в предварительно снятую моей супругой для нас квартиру, где бы
                        мы базировались на протяжении всей поездки. Затем в наших планах было арендовать автомобиль и
                        отправиться колесить по Армении. К тому же, нам не хотелось ограничиваться только одной страной,
                        и мы по-возможности решили отправиться на Чёрное море в Грузию.
                    </p>
                    <p>
                        Итак, мы с Алексеем Украинским рано утром вылетели из Челябинска в Москву, т.к. наш путь в
                        Ереван лежал транзитом через столицу нашей Родины. Во-первых, так было дешевле, чем покупать
                        билеты на прямой рейс, а во-вторых, нам нужно было встретить там с ещё одного участника нашего
                        путешествия Алексея Кириллова. В Москве нам практически не нужно было ждать следующего рейса, и
                        мы сразу отправились проходить паспортный контроль. Вскоре, после того, как все были в сборе
                        объявили посадку, и сев в самолёт, мы покинули Домодедово, а через 2,5 часа были уже в Ереване в
                        аэропорту Звартноц.
                    </p>
                    <p>
                        (Сразу выражу признательность Алексею Кириллову за фотографии, благодаря ему у нас остались
                        качественные фотоматериалы.)
                    </p>
                    <img src={landing001} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Забрав багаж и немного переведя дух, мы отправились к выходу. Теперь нам предстояло попасть на
                        место нашей дислокации, т.е. на адрес квартиры, об аренде которой предварительно договарилась
                        моя супруга. У выхода из аэропотра к нам тут же, как водится, подбежали таксисты и стали
                        предлагать свои услуги. С одним из них у нас пошёл торг. Он спросил, какой валютой мы собираемся
                        платить, и т.к. местной валюты у нас ещё не было, мы сказали, что рублями. После недолгих
                        препирательств сошлись на пятистах рублях, хотя понятно, что это всё равно гораздо дороже
                        средней стоимости такой поездки для местных на тот момент (конец августа 2014 г.). Оказалось,
                        что тот, с кем мы торговались, вовсе не собирался сам нас везти, он передал нас "в руки"
                        непосредственному исполнителю и ретировался. Мы дошли до старенькой Лады Самары, уложили багаж в
                        сморщенный от газового балона багажник и поехали на место, где нас уже должна была ждать моя
                        жена.</p>
                    <img src={road_to_flat002} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>И вот мы приехали на место. Однако ни жены, ни риелтора на месте не было. Тогда мы решили ей
                        позвонить, но дозвониться так и не смогли. Но не успев начать волноваться, к нам во двор
                        подъехал автомобиль. Из него вышел парень и, увидев нас стоящих с багажом, скромно спросил:
                        "Вы не от Джульетты?". Всё прояснилось, это был наш риелтор, который должен был нам передать
                        ключи от нашего прибежища. Он пояслнил, что моя жена должна уже ехать сюда, но видно просто
                        задерживается в пути. Гор (так представился риелтор) показал нам квартиру, это была
                        двухкомнатная квартира на первом этаже многоэтажного дома постройки на вскидку годов 80-х и,
                        на первый взгляд, выглядела неплохо. Тут, наконец, подоспела моя супруга Джульетта, мы
                        окончательно расплатились с Гором, он пожелал нам хорошего отдыха и удалился.</p>
                    <img src={flat003} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Немного отдохнув, мы решили пойти обменять немного рублей на местные драмы и заодно
                        немного прогуляться по городу.</p>
                    <img src={streets004} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Мы естественно проголадались и решили зайти в одно популярное у местных заведение
                        перекусить. Заведение называется "Мельница", всего за время нашего пребывания в
                        Армении мы побывали там три раза, но остались полностью довольны только в первый
                        раз.</p>
                    <img src={melnica005} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Достопримечатольностей в Ереване не так много, но город вызывает цельное
                        монументальное впечатление из-за особенностей архитектуры (в качестве
                        строительного материала повсеместно используется красный туф, а также имеется
                        некоторая преемственность времён в стилистике зданий), а также из-за перепадов
                        высот в разных частях города.</p>
                    <img src={lusavorich006} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Вечером прогулки продолжились.</p>
                    <img src={square007} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Под конец, мы с Алексеем Украинским уже выдохлись, для нас это был самый
                        длинный день из-за разницы во времени.</p>
                    <img src={cafe_sleepy08} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Мы поехали высыпаться. Ведь завтра нас ждал новый день и новые
                        впечатления.</p>
                    <p>Итак, день второй. Наконец, нормально выспавшись, и придя в себя от
                        перелётного тумана в голове, мы позавтракали приобретёнными с утра
                        ягодами на фермерском развале неподалёку от нашего дома и решили
                        посвятить этот день основательному осмотру города и гедонизму.</p>
                    <img src={breakfast009} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Сначала мы дошли до ближайшей станции метро "Дружба"
                        (Барекамуцюн) и, сойдя уже на следующей станции, зашли в так
                        называемый "Парк влюблённых". Излюбленное место встреч молодёжи
                        и молодых мамочек, с искусственными ручейками и бесплатным
                        Wi-Fi.</p>
                    <img src={lovers_park010} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Побродив по узким гравийным тропкам в тени деревьев, мы
                        отправились вниз по улице на встречу "Большому каскаду"
                        (любопытному архитектурному сооружению). По пути нам
                        встретился Президетский дворец.</p>
                    <img src={president_palace011} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>А вот и он - Каскад. Это многоуровневое сооружение
                        представляет собой по-сути огромную лестницу с
                        фонтанами, скульптурами, стеллами и прочими элементами
                        декора. А функционально оно соеденяет собой центр города
                        и Парк победы. Высота каскада согласно Википедии 118
                        м.</p>
                    <img src={cascade012} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>На Каскад днём можно подняться и по эскалатору.
                        Поднимаясь по нему, вокруг себя можно наблюдать
                        различные экспонаты современного искусства. А вот мы
                        уже на верхнем уровне Каскада.</p>
                    <img src={cascade_top013} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>В тот день мы ещё поднялись по Каскаду и вышли на
                        смотровую площадку перед Парком победы.</p>
                    <img src={town_view014} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Ну, и конечно, зашли в сам парк.</p>
                    <img src={park_pobeda015} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Видимо, войдя во вкус, мы захотели
                        взобраться ещё выше и решили прокатиться
                        на колесе обозрения, имеющемся в парке.
                        Вид был отличный, и прохладный ветер,
                        который часто приходит в Ереван с
                        сумерками, способствовал остроте
                        ощущений. Вдоволь нагулявшись, мы взяли
                        такси и поехали высыпаться перед началом
                        нового дня.</p>
                    <p>Третий день был, по-сути, последним нашим пешим днём, т.к. на следующий день мы должны были
                        получить машину, которую я забронировал за месяц до отъезда. Нас осталось трое, поскольку моя
                        жена уехала к сыну в город Гавар (90 км от Еревана), где он был с бабушкой. И мы решили занять
                        этот день ещё одним знаковым для Еревана местом. Местом, с которого всё и начиналось, крепости
                        Эребуни древнего государства Урарту. Крепость находится не в центре города, а на его переферии в
                        юго-восточной части. Но мы решили, что доедем до станции метро Давид Сасунский, что на ж/д
                        вокзале, а затем дойдём пешком. Шли мы довольно долго по жаре минут 40-50. Пожалуй, стоит
                        сказать, что я, в отличии от своих спутников, уже не раз был Армении, поэтому невольно выступал
                        для них гидом, хотя наверно и не очень высокого качества ;)</p>
                    <p>И вот мы пришли к крепости, она находится на холме, на который нам предстояло забраться, а снизу
                        нас встречала вот такая скульптура колесницы.</p>
                    <img src={kolesnica016} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>В саму крепость можно попасть и бесплатно, по дороге с обратной стороны холма, если лицевой
                        стороной считать ту, где находится вход в музей при крепости и турникет наверх. Но мы решили не
                        мелочиться и приобрели билет, который, кстати, можно было использовать и для посещения музея.
                        Сама крепость находится в удручающем состоянии, видно, что здесь уже давно не проводилось
                        никакаих реставрационных работ.</p>
                    <img src={erebuni017} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>А это камень об основании крепости, используется Ассирийская клинопись. По данным историков,
                        крепость была основана в 8 веке до н.э.</p>
                    <img src={erebuni_build018} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Обратно домой поехали уже на такси. Вообще такси в Ереване на момент нашего путешествия (конец
                        августа - начало сентября 2014 г.) стоило недорого, за 1000-1200 драм можно было добраться почти
                        до любой точки города, учитывая курс, который был тогда - около 11 драм за рубль, это
                        действительно не дорого (к сожалению, на момент написания этого текста (февраль 2015 г.) курс
                        уже 6 драм за рубль).</p>
                    <p>Вечером отправились на привычный проминат по городу. На этот раз двинулись в сторону реки
                        Раздан.</p>
                    <img src={brandy_factory019} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>А затем пошли опять к центру. По пути встретили знакомый мне с былых времён базар напротив
                        Голубой мечети, теперь в его здании какие-то торговые площади с эскалаторами и неоновыми
                        витринами. Заходить не стали - колорит не тот.</p>
                    <img src={bazar020} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Насладившись как следует ходьбой по вечернему городу, отправляемся домой. Завтра нас ждёт первая
                        разминочная поездка в Гарни и Гегард. Тогда мы ещё не знали, что нас ждут не только эти
                        прекрасные места, но и не совсем приятные неожиданности.</p>
                    <p>Итак, сегодня нам предстояло забрать наш автомобиль из проката. Бронировал я <a
                        href="http://www.caravan.am">здесь</a>, т.к. у меня уже был опыт общения с этой фирмой в прошлом
                        году, и никаких нареканий по поводу её работы у меня не было. Бронировал я практически новую на
                        тот момент Kia Cerato III с АКПП. В назначенное время мы пришли в офис проката, заплатили деньги
                        за машину, а также за оформление документов на проезд в Грузию. Такое разрешение стоило тогда
                        около 1000 рублей на наши деньги, кроме того необходимо было оставить залог в размере около 120
                        000 драм. Для чего берётся залог, если оформляется страховка? Как мы поняли, страховка работает
                        при вине водителя арендовавшего автомобиль и при сумме ущерба превышающей эти 120 000 драм. А
                        вот если сумма ущерба меньше, то его возмещают из суммы залога. Такие вот странные правила.</p>
                    <p>Как бы то ни было, нам представили автомобиль, отметили в листе осмотра все уже имеющиеся
                        повреждения, объяснили куда звонить в случае ДТП, отдали нам ключи и пожелали счастливого пути!
                        Сначала мы заехали домой за провизией в дорогу, а затем начали потихоньку выбираться из города к
                        пунктам нашего назначения: античному храму Гарни, и древнему монастырю Гегард.</p>
                    <p>Ориентировались мы при помощи навигатора в смартфоне, поэтому сразу поехали по самому короткому
                        пути который нам был предложен. И вот уже ближе к выезду из города мы подъехали к нетипичному
                        X-образному перекрёстку. Причём со входом только с нашей стороны, т.к. со стороны соседней -
                        главной дороги на разметке была сплошная линия. Однако, занятый анализом подсказок навигатора,
                        озвучиваемых моими друзьями, я этого сразу и не увидел. Автомобиль, ехавший по соседней дороге,
                        включив поворотник, агресивно, с набором скорости стал перестраиваться в крайнюю полосу
                        навстречу мне, на что я среагировал как на попытку встроиться на нашу дорогу прямо перед нашей
                        Kia. Инстинктивной реакцией было резкое торможение. И тут я должен сделать небольшое
                        отступление. На тот момент мой водительский стаж был 10 лет, но на коробках автомат я ездил
                        всего 2-3 раза. И в момент экстренного торможения, что называется, включилась "автоматика" в
                        моей голове, и я начал тормозить двумя ногами одной на тормоз, а другой на несуществующую педаль
                        сцепления. А на самом деле на левый край широкой педали тормоза в Kia. Короче торможение
                        получилось чересчур резким, и пока я понял, что к чему было уже поздно. К нам задний бампер
                        приехал местный таксист.</p>
                    <p>И снова небольшое отступление на тему дорожного движения в Армении. Я был ни в первый раз за
                        рулём в Армении, и в Ереване в том числе. И я знал, что движение там хаотичное, водители не
                        соблюдают полосность впринципе, водят довольно агрессивно, пешеходов пропускают с неохотой даже
                        на регулируемых перекрёстках, используют клаксон не по назначению, а скорее для общения друг с
                        другом, дистанцию не соблюдают. И всё это к таксистам относится в превосходной степени.</p>
                    <p>Да конечно фактически я создал аварийную ситуацию, в незнакомой дорожной обстановке, на не
                        объезженной мной машине, но формально виновен был таксит, не державший дистанцию. В общем я
                        вышел, извинился за слишком резкое торможение, в ответ получил, естественнно, порцию возмущений.
                        Осмотрел машину, у нас несколько царапин и небольшая вмятина диаметром в пару сантиметров на
                        заднем бампере, на Опеле таксиста повреждения немного серьёзней. После осмотра ущерба я позвонил
                        в прокат и сообщил о том, что попал в аварию. Мы начали ждать страховых агентов, первым приехали
                        из страховой таксиста. Агент пытался что-то со мной выяснить, но он практически не понимал
                        по-русски, а я ещё хуже владею армянским. Минут через 10-15 приехал и мой агент из проката. Он
                        спросил меня как всё случилось, и получив от меня подробный рассказ, дальше разбирался со всеми
                        уже сам. На улице было за 30, пекло жаркое солнце, и я в периоды ожидания старался прятаться в
                        машине где работал кондиционер. После долгого разговора нашего агента с нашими оппонентами, он
                        подозвал меня и сказал, что водитель такси свою вину не признаёт, и придётся ждать полицию,
                        чтобы те определили виновного. Водитель такси попробовал отозвать меня в сторонку и договориться
                        о ремонте моей машины в случае если я признаю свою вину. Но я был твёрд, и сказал, что я не
                        владелец автомобиля, и чтобы все предложения он может озвучить в прокате. На, что он
                        чертыхнулся, и удалился. Всё это время пока мы ждали полицию возле нас останавливались
                        проезжавшие мимо таксисты, выражали солидарность своему коллеге, и уезжали.</p>
                    <p>Наконец подъехала машина полиции. Объяснив всё ещё раз, с помощью синхронного перевода нашего
                        агента, полицейские начали разбор уже с ним и водителем такси. Через некоторое время они
                        попросили надиктовать по-русски текст протокола с моей стороны, которое наш агент написал
                        по-армянски, а я его подписал. Агент сказал мне, что всё в порядке и скоро можно будет ехать.
                        Единственное, мне придётся прибыть в полицию через несколько дней, чтобы подписать ещё какие-то
                        документы, т.к. я являюсь иностранным гражданином. Тогда я сообщил ему, что мы собираемся в
                        Грузию и возможно не смогу прийти в через несколько дней. На что он ответил, что тогда мы вместе
                        сходим туда, когда я буду сдавать автомобиль. На этом и порешили.</p>
                    <p>Потеряв в общей сложности около 2,5 часов, мы наконец-то продолжили путь к местам нашего
                        назначения. Первым из них должен был стать языческий храм Гарни. Выбравшись, наконец из города,
                        наша дорога пошла в горы, и проехав по извилистым путям километров 30 мы достигли сначала
                        одноимённого посёлка, а затем и храма Гарни. Припарковавшись и заплатив за вход, мы вошли на
                        территорию, и нам предстоял её осмотр. Храм, датируемый I в н.э., представляет собой типичную
                        храмовую постройку античного периода, и посвящён древнеармянскому богу солнца Михру.</p>
                    <img src={garni_temple021} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Храм был разрушен в результате землетрясения в семнадцатом веке, и был восстановлен из руин в
                        советское время. Комплекс из храма, остатков царских бань и невосстановленных руин рядом с ними
                        стоят на краю ущелья, откуда открывается отличный вид на него и речку Азат текущую внизу.</p>
                    <img src={garni022} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>После Гарни мы двинулись ещё выше в горы в монастырь Гегард. Через километров 10-15 дорога
                        закончилась, мы были на месте. Первый монастырь на этом месте был основан в IV веке, на месте
                        пещерного источника, которым почитался ещё во времена язычества. Поэтому монастырь раньше
                        назывался Айреванк, что означает пещерный монастырь. Дальнейшая судьба монастыря была очень
                        богатой, он разрушался и возводился вновь, неоднократно достраивался.</p>
                    <img src={gegard023} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Сейчас Гегард это целый монастырский комплекс, включающий в себя как отдельно стоящие сооружения
                        и помещения в них, так и выдолбленные в скалах святилища, так или иначе связанные с главным
                        зданием храма. Название Гегард или как его ещё называют Гегардаванк означает монастырь копья,
                        название происходит от копья "судьбы", которым, как считается, пронзили тело Иисуса Христа на
                        кресте, его как утверждают в Армению привёз апостол Фаддей, проповедовавший здесь, и копье
                        долгое время хранилось в храме. Сейчас копьё хранится в музее Эчмядзина, главного храма Армении.
                        Внутреннее пространство аскетичное, как в большинстве храмов в республике. В храме в последние
                        годы всегда очень много туристов, это одно из самых популярных мест для посещения в Армении. Но
                        я помню как пару раз я был в храме, когда посетителей было не много, и меня буквально
                        завораживала почти мистическая обстановка внутри него.</p>
                    <img src={gegard_inter024} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>В храме есть помещение в котором прямо из скалы течёт источник, это то вамое помещение которое
                        когда-то было пещерой, и с которого всё начиналось. И все местные стараются подойти к нему и
                        умыться, а некоторые даже набирают воды из него и забирают с собой.</p>
                    <p>Надвигался вечер, поэтому осмотрев храм и окрестности, налюбовавшись видом на ущелье, мы
                        отправились в обратный путь.</p>
                    <img src={gegard_away025} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Вечером к нам в Ереван должна была приехать моя супруга, и уже завтра мы вчетвером планировали
                        отправиться в очень насыщенную двухдневную поездку на юг страны, в область Сюник.</p>
                    <p>Как я уже анонсировал в предыдущей части своего повествования, сегодня мы собирались поехать на
                        юг страны в Сюникский марз. План был такой: сначала едем в Араратскую долину, в монастырь
                        Хор-Вирап, у Нахичевани берём восточней и едем в монастырь Нораванк, затем посещаем целебный
                        курорт Джермук, дальше спускаемся южнее и делаем остановку в древней "обсерватории" Караундж
                        после чего ночуем в городе Горис. На следующий день нас должны были ждать Хнзореск и конечная
                        точка нашего путешествия Татев. После чего, мы должны были вернуться в Ереван.</p>
                    <p>Утром, позавтракав, мы отправились в дорогу, и быстро достигли первой цели - монастырь Хор-Вирап.
                        Строение расположено на холме рядом с турецкой границей. Хор-Вирап знаменит тем, что здесь в
                        начале новой эры была армянская столица Арташат, и в ней располагалась темница, где за свои
                        христьянские взгляды сидел Святой Григорий Просветитель, человек благодаря которому Армения в
                        301 году приняла христьянство.</p>
                    <img src={khorVirap026} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Тут же открывается вид на Арарат, но, к сожалению, далеко не всегда он предстаёт во всём своём
                        величии. Вот и в этот день он был прикрыт дымкой облаков.</p>
                    <img src={ararat027} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Далее направляемся в Нораванк. На трассе, возле какой-то деревушки, берём потрясающе вкусных
                        персиков. Находим нужный поворот и движемся по дороге внутри впечатляющего ущелья.</p>
                    <img src={to_noravank028} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Подъезжаем на место по крутому серпантину. Осматриваем комплекс. Нораванк - это две церкви и одна
                        часовня. Главная церковь имеет своеобразную архитектуру. Вид со второго этажа открывается
                        внушительный.</p>
                    <img src={noravank_church029} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Ощущения какой-то сакральности в этом месте не ощущаешь. Слишком оно какое-то солнечное,
                        радостное (причём я был здесь уже в третий раз, но ощущения от этого места не меняются). Вид
                        здесь просто замечательный. Красноватый оттенок ущелья, и своеобразная растительность этому
                        способствуют.</p>
                    <img src={noravank_view030} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>После Нораванка направляемся на рекреационный курорт Джермук. Место знаменитое своими
                        минеральными водами. Приблизительно через час мы уже там. Идём искать здешний водопад. Сначала
                        прогулялись по одному из ущелий в его поисках.</p>
                    <img src={djermuk_walking031} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Следующая дорога приводит по назначению. Мы у водопада, но увы, он практически в "отключенном"
                        состоянии. Дело в том, что в Армении многие реки используются для получения электричества, и
                        поэтому природной красотой водопадов можно любоваться лишь изредка. Нам не повезло, но всё равно
                        место красивое.</p>
                    <img src={djermuk_waterfall032} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>После фотографирования у водопада едем к санаторию, где из кранов течёт минеральная водичка.
                        Каждый кран - это разная по температуре и степени газации минеральная вода. Пробуем и набираем с
                        собой.</p>
                    <img src={djermuk_mineral033} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Погода в горах меняется быстро, бывает, заедешь на какую-нибудь горку, а температура за бортом на
                        несколько градусов ниже. Пока мы пили воду, заморосил дождик. Делаем последние фото и
                        выдвигаемся дальше.</p>
                    <img src={djermuk_away034} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Едем в сторону города Сисиан, недалеко от которого место, куда мы хотели попасть - Караундж.
                        Набор расставленных по полю камней с отверстиями в множестве из них, которые современная
                        историография относит в ориентировочный диапазон от третьего до шестого тысячелетия до нашей
                        эры. Но по дороге в Сисиан окончательно портится погода, начинается гроза, хотя на небе
                        радуга.</p>
                    <img src={road_to_sunik} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Решаем, что в Караундж нам сегодня дорога не лежит, и едем сразу в Горис искать ночлег. Караундж
                        же оставляем на обратную дорогу.</p>
                    <p>Заезжаем в черту города, останавливаемся у первого придорожного отеля. И нас сразу всё
                        устраивает. Один дабл стоил около 1,5 тысяч рублей. Всё довольно прилично, даже одноразовые
                        тапочки имеются. Договариваемся поужинать прямо в отеле. Кушаем и идём спать. Завтра нас ждут
                        новые приключения.</p>
                    <p>Проснувшись утром в отеле, решили сразу отправиться в путь, так как список намеченного на сегодня
                        был внушительным, к тому же нам предстояла обратная дорога в Ереван.</p>
                    <p>В Горисе закупаем провианту, в одном из немногих магазинов работающих в утренний час. И едем в
                        древний Хндзореск - пещерный город. Ехать совсем недалеко. Село Хндзореск состоит из верхней
                        новой части, расположенной рядом с трассой, и старой необитаемой теперь части, находящегося
                        непосредственно в ущелье. Люди стали селиться здесь с незапамятных времён, и жили там до
                        середины XX века. Т.е. лишь в советское время под давлением администрации люди с неохотой начали
                        оставлять насиженные места и подниматься наверх в новый город.</p>
                    <p>На подъезде к Хндзореску у нас случается небольшая неприятность, после прохождения одного из
                        лежачих полицейских, наша Kia начинает издавать неприятный звук - писк, переходящий в скрежет.
                        Похоже на то, что тормозная колодка после отпускания педали тормоза отходит от диска не
                        полностью. Правда, если раскрутить диск, двигаясь со скоростью более 50 км/ч то звук исчезал.
                        Поэтому пока решили не обращать на это внимание.</p>
                    <p>Мы заехали в село, и, двигаясь вдоль главной улицы постепенно спускались вниз к ущелью. Через
                        какое-то время дорога стала слишком крутой и неровной, чтобы по ней можно было уверено двигаться
                        на легковом автомобиле. Мы решили остановиться и дальше продолжить путь пешком. Но прежде
                        немного перевести дух и перекусить.</p>
                    <img src={hnzoresk_break035} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>На склонах ущелья множество выдолбленных в скалах помещений, больших и малых. Которые когда-то
                        были самостоятельными жилищами, а потом стали чем-то вроде кладовых при домах которые здесь
                        когда-то стояли. Здесь же сохранилась уже недействующая, но не заброшенная глазом туристов
                        часовня.</p>
                    <img src={hnzoresk_temple036} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Наконец, мы подошли к навесному мосту соединяющему два склона ущелья.</p>
                    <img src={hnzoresk_bridge037} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Перейдя на другую сторону ущелья, мы попали в кафе и по совместительству музей хранящий некоторые
                        страницы памяти этих мест. Нас встретил его хозяин, показал некоторые старые фотографии и
                        здешние реликвии. Он сказал, что сам родился здесь в "старом городе", и ещё помнит каким он был.
                        Ребята взяли в кафе по мороженному, и мы отправились в обратный путь.</p>
                    <img src={hnzoresk_cafe038} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Поднявшись наверх к машине, мы изрядно взмокли. Но время нас подгоняло, поэтому утолив жажду из
                        наших запасов мы отправились дальше. Нас ждал Татев. Минут 30 и мы на месте. Наша программа была
                        здесь следующей: поездка на самой длинной в мире пассажирской канатной дороге "Крылья Татева" к
                        Татевскому монастырю, осмотр его, и возвращение обратно к машине тем же способом.</p>
                    <p>На парковке перед канатной дорогой мы поняли, что туристов очень много, и сразу пошли покупать
                        билеты. Стоимость одного билета в обе стороны была 6 евро. Т.к. желающих прокатиться было много,
                        и в уходящем трамвайчике для нас небыло места, мы пошли ждать следующего в местном кафе. Через
                        полчаса наше время подошло, и мы выстроились в очередь перед турникетом. Наконец, нас стали
                        запускать, и трамвай заполнился людьми. Мы тронулись.</p>
                    <p>Канатная дорога имеет длину 5,7 км, с наибольшей высотой 320 метров. При этом на протяжении пути
                        имеются всего три опоры. Ощущение полёта присутствует.</p>
                    <img src={tatev_wings039} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Оказавшись у монастыря, идём осматривать его территорию. Здесь и древняя маслобойня и конюшни и
                        сейсмоустойчивый столб увенчанный крестом и небольшие часовни и множество подземных ходов и
                        конечно сам монастырь.</p>
                    <img src={tatev_temple040} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Стоит ли говорить, что вид на долину реки Воротан - хорош!</p>
                    <img src={tatev_view040} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Когда всё осмотрено, дожидаемся трамвайчика и едем обратно к машине. Здесь мы ещё не закончили, в
                        долине есть ещё природный мостик "Сатани Камурдж" (Сатанинский мост) он представляет собой
                        проторенную водой часть скального массива на дне ущелья. Спуститься туда решаюсь только я, мои
                        спутники не изъявляют такого желания. Спускаться надо, держась за прибитый к скале канат, а
                        камни у воды гораздо более скользкие чем лёд. Хотя как показала практика, ничего сложного в том,
                        чтобы туда пробраться нет.</p>
                    <p>Время уже поджимает, но мы не как не можем расстаться с этим местом и останавливаемся ещё
                        небольшой дозорной колокольни, увиденной нами. Всё, теперь нас ждёт последняя точка нашего
                        путешествия по югу Армении - Караундж.</p>
                    <p>Ехать не долго, обратно по трассе на Сисиан минут 30. Караундж или как его ещё называют
                        Зорац-Карер это одно из самых загадочных мест в Армении. Точно не ясно ничего, ни возраст, ни
                        происхождение, ни назначение. Ясно только, что это поле, усеянное более чем двуустами
                        базальтовыми камнями, многие из которых имеют круглые сквозные отверстия в верхней своей части.
                        В центральной части поля камни образуют овал, внутри которого каменный курган.</p>
                    <img src={karaunge_kurgan041} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Как я уже писал, возраст камней ориентировочно датируется диапазоном от третьего до шестого
                        тысячелетия до н.э. Предпринимались попытки доказательства, что комплекс использовался как
                        обсерватория.</p>
                    <img src={karaunge_stones042} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Время уже к вечеру, едем в Гавар, чтобы завести туда мою жену и заодно повидаться с моим сыном,
                        который был здесь уже больше двух месяцев.</p>
                    <p>Последний из перевалов перед озером Севан - Селимский. Здесь же Караван-сарай - средневековая
                        гостиница.</p>
                    <img src={karavansarai043} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>В Гавар приезжаем уже ближе к сумеркам. Вижу во дворе сына, и меня накрывает волна эмоций.
                        Бабушка и дедушка Джульетты устраивают нам небольшое, по армянским меркам, застолье. Время уже
                        позднее и надо ехать, за окном уже совсем темно. С трудом прощаюсь с сыном и прыгаю в машину. От
                        Гавара до Еревана около 90 километров и большая часть пути проходит по хорошей многополосной
                        дороге, минусом является наличие на ней большого количества скоростных камер.</p>
                    <p>Через час мы в Ереване. В городе вновь напоминает о себе тормозная колодка, которая продолжает
                        издавать скрежет на низких скоростях. Было желание на следующий день поехать в прокат и как-то
                        решить эту проблему, но опасаясь заминки, мы решаем пока машину придержать у себя, ведь уже
                        завтра мы отправляемся в солнечную Грузию.</p>
                    <p>Сегодня отправляемся в неизведанное. Никто из нас ещё не был в Грузии, и мы не знаем, что от неё
                        ждать. Но у нас есть план, мы едем в курортный город Батуми на берег Чёрного моря. Но сначала
                        нам нужно пересечь границу, дорога к которой лежит через армянский город Гюмри. Вот по
                        направлению к нему мы и движемся.</p>
                    <p>Выезжаем из города, здесь идёт реконструкция моста, немного теряемся, со второго раза выбираем
                        правильное направление объезда. Движемся дальше, реконструкция мостом не ограничивается, она
                        касается и самой дороги, и на довольно протяжённом отрезке, на вскидку больше 10 километров. И
                        на большей части этого участка стоит ограничение скорости в 40 км/ч. Это ад, так как дорога хоть
                        сужается местами, но покрытие остаётся ровным. В общем, из-за трафика, идущего позади нас,
                        появляется полицейская машина, включает проблесковый маячок и просит нас остановиться (в Армении
                        полиция может зафиксировать скорость движения прямо на ходу, и им не нужно стоять в "кустах").
                        Выхожу из машины, демонстрирую документы. Мы двигались со скоростью 59 км/ч, при разрешённых
                        сорока. Полицейский тут же достаёт прайс распечатанный на бумажке, где указано, какой штраф,
                        соответствует каждому диапазону превышения скорости. Нашему привышению соответствует цифра в
                        50000 драм! Я, пугаясь, переспрашиваю, сколько?? На что один из полицейских говорит, что видит,
                        что я армянин, поэтому давай, мол, посчитаем, что ты превысил скорость лишь на 10 км/ч, по
                        прайсу это 25000 драм (на тот момент это чуть больше 2000 руб.) Мне ничего не остаётся, как
                        пойти в машину за деньгами. "Добрый" полицейский спросил меня, куда мы едем? Думаю, что не стоит
                        распространяться на счёт Батуми, говорю, что едем в Гюмри. Он спрашивает: "В Батуми
                        собираетесь?" Пока я удивлялся его прозорливости, он рассказывает, что мы не разочаруемся, и что
                        в Батуми очень красиво. "Как в Европе" - кинул он заключении, и мы распрощались.</p>
                    <p>Через часок мы проезжаем Гюмри и приближаемся к государственной границе. Километров за 15-20 до
                        неё дорога становиться отвратительной, часто приходится двигаться по обочине из-за не засыпанных
                        на проезжей части ям. И вот, наконец, вдали виднеется КПП. На армянской стороне очереди
                        практически нет. Проходим паспортный контроль, документов на машину, оформленных в прокате с нас
                        не спросили. Пограничник предупредил нас, что нам могут впаять штраф, если мы у грузинского КПП
                        будем не пристёгнуты. Садимся в машину и пристёгиваемся, движемся к грузинам. На их КПП, помимо
                        национального, висит иакже флаг ЕС. Здесь очередь побольше. Сидим ждём. Подходит очень бойкий
                        грузинский пограничник, говорит, что пассажиры проходят границу пешком. Ребята удаляются в
                        здание погранслужбы. Я продолжаю сидеть в машине. Через некоторое время пограничник подходит ко
                        мне и говорит, чтобы я тоже отдал свои документы в здании. Я оставляю машину и иду туда. Мне
                        ставят необходимые отметки. Мы замечаем прямо здесь же валютный обменник, и решаем поменять
                        немного денег на грузинские лари. Лари стоят на тот момент приблизительно пол-евро. Вернувшись в
                        машину, я пытаюсь дождаться очереди из стоящих передо мной автомобилей, но пограничник кричит
                        мне, что если я прошёл контроль то могу проезжать через свободный пост. Проблема в том, что
                        свободного поста я не вижу, тогда я удивлённо смотрю на работника погранконтроля, а он смотрит
                        на меня как на идиота и показывает на дорогу которая ведёт через пункт во встречном направлении.
                        Киваю головой и еду на встречную, проезжаю пункт, и меня тормозит другой пограничник. Я говорю,
                        что мне сказали проехать через этот пункт. Он что-то кричит по-грузински другому погранцу, ему
                        что-то отвечают и он нас пропускает. Как хорошо, что язык межнационального общения на
                        постсоветском пространстве всё ещё русский.</p>
                    <p>Наконец, граница пройдена, и мы движемся по Грузии. Первые километры от границы качество
                        дорожного покрытия также не радует. Мы проезжаем первые приграничные селения с русскими
                        названиями типа Ефремовка, Гореловка. В них на некоторых электрических столбах свиты огромные
                        аистиные гнёзда. Замечаем заправку Лукойл, в Армении таких нет, заезжаем туда. Заправщик заметив
                        наши номера пытается заговорить с нами по-армянски, но поняв с кем имеет дело переходит на
                        неуверенный русский. В приграничном районе здесь проживает много армян, наш заправщик видимо из
                        их числа. Заливаем полный бак. Цены на бензин в Грузии были примерно такими же, как и в Армении,
                        на наши деньги немного дороже 40 рублей за литр горючего марки Super. Движемся дальше. Проезжаем
                        область Ахалкалаки. Дорога крайне живописная, вокруг горы, с которых стекают ручьи, позже
                        впадающие в горные речки. На берегах рыбачат местные. Встречаются интересные постройки типа
                        средневековых крепостей. Растительности значительно больше, чем в среднем по Армении.</p>
                    <img src={georgia_road_castle044} alt="Грузия 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Доезжаем до Ахалцихе, сначала ничего не обычного, малоэтажная застройка, узкие улочки,
                        пробираемся сквозь них по навигатору трассу. Очередной поворот, и тут совсем неожиданная
                        картина: на холме большой средневековый замок, со сторожевыми башнями и куполами каких-то
                        построек на его территории. Решаем, что мы обязательно заедем сюда на обратном пути в Армению и
                        всё осмотрим.</p>
                    <img src={ahalcihe_castle045} alt="Грузия 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Пока же движемся дальше на Батуми, по расчётам до него от Еревана чуть больше 600 км, но мы
                        совершенно не знаем, с чем можем столкнуться на пути, к тому же нам ещё нужно будет найти там
                        жилье для ночёвки. Поэтому остановок почти не делаем.</p>
                    <p>Достигаем знаменитого курорта Боржоми. Здесь есть производственные территории завода минеральных
                        вод Боржоми, река, какие-то санаторные постройки. Подробного осмотра мы не делаем.</p>
                    <img src={borjomi046} alt="Грузия 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Дальше едем на Хашури, а затем поворачиваем на Кутаиси. Конечно, есть дорога и короче, через горы
                        и по гравийной дороге. Но как нам удалось выяснить в Интернет, дорога эта очень некомфортная и
                        медленная, она больше подходит владельцам автомобилей с повышенным клиренсом. Поэтому мы едем в
                        объезд, это может быть дольше, зато спокойней.</p>
                    <p>Манера вождения грузин на трассе агрессивная, скоростной режим не соблюдается почти ни кем. Да и
                        соблюсти его довольно трудно, т.к. трасса в отличном состоянии, но по сути мы постоянно
                        находимся в пределах населённого пункта, т.к. знак о конце одного селения тут же меняется знаком
                        о начале другого. Тут почти нет "пустого" пространства, все территории возле дороги заселены. И
                        естественно двигаться по трассе на протяжении десятков и десятков километров со скоростью
                        городского цикла невозможно. Но мы, не зная повадок местных полицейских, поначалу старались
                        скорость не превышать. Тем более, что полицейских машин на дорогах мы видели достаточно, а в
                        каждом мало-мальски крупном поселении есть вот такой полицейский участок.</p>
                    <img src={police_dep047} alt="Грузия 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>В одном из городков встречающихся нам на пути, на развязке мы свернули не на ту дорогу, и наш
                        навигатор перестроился на другой маршрут и повёл нас дорогой через Поти. А это крюк, который нам
                        был совсем не нужен. Мы попытались спросить дорогу у прохожих, но их советы нас только больше
                        запутали.</p>
                    <img src={sign_to_poti048} alt="Грузия 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Тогда мы решили уточнить правильный путь у полицейских, чья машина стояла на полупустом вечернем
                        перекрёстке. Сначала они попытались объяснить на словах, но обнаружилось их плохое знание
                        русского языка, и увидев моё замешательство они приняли поистине благородное решение сопроводить
                        нас до нужно поворота. Полицейские включили мигалку и тронулись, мы, пристроившись за ними через
                        3 минуты были уже на нужной дороге. Они остановились у обочины, мы, притормозив, поблагодарили
                        их за помощь и вышли на финальный отрезок нашего пути.</p>
                    <img src={road_to_batumi049} alt="Грузия 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Стало смеркаться, на одном из поворотов справа по борту закончилась лесополоса, мы въехали на
                        небольшой мостик и увидели море. Правда, этот вид тут же скрылся от нас за очередной широкой
                        стеной деревьев, а новый поворот ещё сильнее отдалил нас от большой воды.</p>
                    <p>Очень скоро совсем стемнело. На очередной развязке вновь пропускаем нужный поворот, и наш
                        навигатор сбивает маршрут. Дорога узкая и почти без обочины, по краям металлические отбойники,
                        развернуться, здесь не получиться, тут же видем указатель на Батуми, решаем ехать по нему.
                        Попадаем в Кабулети. Это курортный город спутник Батуми, вдоль его главной улицы тянутся
                        бесконечные кафе и прочие увеселительные заведения. Дорогу беспорядочно переходят люди, вокруг
                        народные гуляния. Дорога из-за крайне малой скорости движения казалась нам очень долгой.</p>
                    <p>Наконец, дорога закончилась, и мы выбрались из Кабулети. Дальше путь лежал по очень извилистому
                        серпантину, мы спускались к морю. Начался дождь, мы подбирались к месту нашего назначения.</p>
                    <p>И вот постепенно мы въехали в город. В Батуми стояла непривычная для нас влажность, открыв окно,
                        мы почувствовали себя в бане. Несмотря на довольно сильный дождь, было душно. Проехав по городу,
                        мы достигли его центра, и стали искать место для ночлега. В нескольких отелях нам отказали, не
                        было мест. Потолкавшись в пробках, мы увидили высокую башню Radison, и подумали, что в таком
                        большом здании наверняка найдётся место для троих человек.
                        Но вырулив на набережную, увидели ещё один отель, и решили попытать счастья здесь. Места были,
                        но ценник за один номер был 100$ за ночь. Несмотря на это, учитывая очень приличный вид отеля и
                        нашу усталость от дороги, мы пришли к консенсусу и остановились здесь. Отель Адмирал стал нашим
                        домом на 2 ночи.</p>
                    <p>Разместившись в номерах, мы отправились в город перекусить. Но найти место, где можно было
                        отужинать оказалось тоже не просто. Заведений общепита было не так много, как ожидалось, и
                        большинство переполнены. Наконец, четвёртое или пятое встретившееся место нам подошло, и для нас
                        даже нашёлся свободный столик. Мы покушали и направились в отель отдыхать. Завтра нам предстоял
                        осмотр Батуми.</p>
                    <p>На следующее утро, позавтракав в отеле (завтрак входил в стоимость), мы отправились на пляж.</p>
                    <img src={prepare_to_swim050} alt="Грузия 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Идём к ближайшему месту пригодному для купания, параллельно осматриваемся.</p>
                    <img src={batumi_town051} alt="Грузия 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>В Батуми на пляже галька, заходить не очень комфортно. Вода в конце августа просто парное молоко.
                        Накупавшись, прогулялись по прибрежному бульвару, носящему имя города.</p>
                    <img src={batumi_bulevard052} alt="Грузия 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Батуми надо признать красивым городом. В последнее время он активно застраивался, современная
                        архитектура сочетается со старыми отреставрированными постройками и постройками под старину.</p>
                    <img src={batumi_square053} alt="Грузия 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Вообще застройка довольно разнообразная, есть обычные панельные дома, есть малоэтажная застройка,
                        есть здания, имитирующие различные стили старой Европы, есть даже небольшая площадь напоминающая
                        площадь Сан-Марко в Венеции.</p>
                    <img src={batumi_mosaic054} alt="Грузия 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Есть в Батуми и церкви, мы встретили две: одну армянскую, одну православную грузинскую. Она как
                        по архитектуре, так и по внутреннему убранству очень напоминает греческие. Всё-таки церкви в
                        России сильно отличаются от того, что можно увидеть в других странах с православной христьянской
                        традицией.</p>
                    <img src={batumi_church055} alt="Грузия 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Вечером решаем ещё раз сходить искупаться, но начинается дождь и поднимается ветер. Видимо сезон
                        дождей здесь уже начался. Несмотря на это, идём к морю оставляем одежду мокнуть на берегу и
                        прыгаем в воду. Из-за того, что на улице похоладало вода кажется просто горячей. Мы долго
                        купаемся и дурачимся, ловя на себе усилившиеся волны у берега. На сушу выходить совсем не
                        хочется. Наверно, после часа купания мы пересиливаем себя, и забрав вымокшую одежду идём
                        сушиться в отель.</p>
                    <p>Вечером идём перекусить. Хотим поесть истинно грузинской кухни. Поиски подходящего места вновь
                        проходят не быстро, просто удивителен потенциал развития сектора общественного питания в таком
                        многолюдном туристическом месте как Батуми. Наконец, находим что-то похожее на харчевню с
                        национальной кухней, скромно, но в целом неплохо.</p>
                    <img src={batumi_dinner056} alt="Грузия 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Дорога к отелю после ужина. По сути, повод ещё раз насладиться разнообразием красоты Батуми, его
                        влажным морским воздухом, завтра утром мы уезжаем.</p>
                    <img src={batumi_last_night057} alt="Грузия 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Проснувшись утром, мы позавтракали в отеле, сдали ключи от номеров и отправились в обратный путь.
                        Батуми провожал нас прохладой и дождём. Обратный путь по Грузии оказался на порядок легче и
                        быстрее. Мы поехали в объезд Кабулети, что сэкономило нам много времени, к тому же загруженность
                        на трассах в утренний час была гораздо меньшей. Спустя пару часов пути мы спокойно остановились
                        в придорожной харчевне в горах и не спеша перекусили. Ещё через пару часов мы уже были Ахалцихе,
                        в крепости которую мы обещали себе осмотреть.</p>
                    <p>Крепость изначально была построена Грузинами, но в XIV веке она была захвачена Османскими
                        войсками и обустроена под свои нужды. В XIX веке в результате русско-турецких войн город вошёл в
                        состав Российской Империи и строительство возобновилось. В 2011 году началась реставрация
                        крепости, в 2012 году крепость открыла свои двери туристам.</p>
                    <img src={rabat_towers058} alt="Грузия 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Понятно, что имея такую историю, крепость имеет очень эклектичное содержание.</p>
                    <img src={rabat_osman_style058} alt="Грузия 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Интересно, что некоторые помещения в крепости натурально используются под офисы, пишут, что в
                        2013 году на территории крепости был организован избирательный участок.</p>
                    <p>Гулять здесь одно удовольствие, глазу всегда есть за что зацепиться.</p>
                    <img src={rabat_walls059} alt="Грузия 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Взабравшись, на дозорную башню можно наблюдать весь город.</p>
                    <img src={rabat_view060} alt="Грузия 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>За стенами крепости позади дозорной башни можно увидеть эпического вида мост через ущелье.</p>
                    <img src={rabat_bridge061} alt="Грузия 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p> На территории крепости есть также музей, посвящённый её истории, мы наскоро забежали и туда.
                        После чего продолжили свой путь.</p>
                    <p>Когда мы были у границы с Арменией, уже смеркалось, и сильно похолодало (было около 15 градусов).
                        Машин на Грузинской стороне было не так много, но двигались они медленно. Я прошёл формальный
                        досмотр машины, открыв багажник, и готовился показать все необходимые документы в окошко, когда
                        подойдёт моя очередь. Но машину передо мной никак не хотели пропускать. Мои друзья уже давно
                        прошли в нейтральную зону пешком и мёрзли, т.к. температура в горах ещё немного понизилась. Я
                        спросил у таможенника, почему мы не движемся, на что он ответил, что нужно подождать т.к. есть
                        проблемы. Наконец, виновника пробки пропустили, и я быстро прошёл контроль. Подобрав озябших
                        ребят еду к армянскому посту. Здесь всё гораздо быстрей, нас всех фотографируют на веб-камеру
                        прямо в машине. Жалуемся на проволочки у грузинского поста, армянский таможенник подшучивает над
                        своими грузинскими коллегами, мол они же важные как ЕС. Всё мы опять в Армении.</p>
                    <p>После первых десятков километров движения по Армении окончательно стемнело, и мы двигались по
                        извилистым горным дорогам при свете луны и собственных фар. Kia постепенно спускалась к столице
                        Армении, а за бортом постепенно теплело. И когда в двенадцатом часу вечера мы были в Ереване,
                        наш термометр в машине показывал 29 градусов Цельсия.</p>
                    <p>Таким было наше путешествие в Батуми, интересное и разнообразное. Нам очень понравилось. И
                        напоследок выложу видео, которое мы сняли по итогам поездки в Грузию. Тем, кто читал
                        внимательно, будут интересны некоторые детали отражённые в нашей съёмке.</p>
                    <p>После нашей грузинской поездки, мы решили дать себе день отдыха и остаться в Ереване. К тому же
                        пришло время решить-таки вопрос с машиной. Проблема с скрежетом тормозной колодки не куда не
                        исчезла, и мы позвонили в прокат по этому вопросу. Когда я изложил проблему, мне сообщили, что
                        такая проблема уже была с машиной, и теперь повторилась вновь. Мне также предложили привести
                        машину к ним, и если им не удастся за один час справиться с проблемой, то нам заменят автомобиль
                        на аналогичный по классу. Проблема была только в том, что мы позвонили им в воскресение, а в
                        этот день механик у них не работает, поэтому нужно было подъехать в прокат завтра. Мы
                        договорились на утро завтрашнего дня, т.к. завтра мы запланировали последний наш маршрут на
                        север Армении.</p>
                    <p>Ну, а на сегодня мы спланировали поход на фермерский развал неподалёку от нашего дома, который к
                        слову работал только по выходным, а также решили сходить на монумент посвящённый памяти геноциду
                        армян в Османской империи. Холм, где расположен мемориальный комплекс, называется Цицернакаберд.
                        Вольный перевод этого названия - гнездо ласточки. Мы осмотрели холм, сам монумент был закрыт
                        строительными лесами, видимо проходил его текущий ремонт. Мы немного походили по парку возле
                        спортивно-концертного комплекса, расположенного рядом и вернулись домой.</p>
                    <img src={erevan_sport_complex062} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Вечером к нам присоединилась Джульетта, приехавшая из Гавара, идём гулять по городу. В центре у
                        "Лебединого озера" по выходным часто проходят народные гуляния, концертная программа, люди
                        веселятся, мы тоже. Завтра мы все вместе должны будем отправиться в путь, главное чтобы не
                        случилось никаких проволочек с машиной.</p>
                    <img src={erevan_dance063} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Наступило утро понедельника. Мы с женой поехали в прокат. Мы планировали разобраться с машиной до
                        полудня, чтобы успеть всё, что задумали сделать сегодня. Приехав на место, мы ещё раз объяснили,
                        в чём проблема. У нас забрали ключи, и обещали позвонить нам через час. Мы пошли в ближайший
                        супермаркет и взяли себе кое-что перекусить и позавтракали рядом в скверике. Постепенно прошёл
                        час, но нам никто не позвонил, и мы пришли в офис проката сами. Там нам сказали, что нашу Kia
                        починить не удалось, и нам предоставят машину того же класса. "Тот же класс", как выяснилось,
                        означало, что нам дадут автомобиль в кузове седан с автоматической коробкой передач. Но
                        поскольку я внимательно изучил не слишком богатый автопарк данного проката, когда бронировал
                        Kia, я был к этому готов. Оформив необходимые документы, нам дали ключи от Chevrolet Aveo.
                        Конечно, данный автомобиль был реально ниже классом чем Kia Cerato, но как выяснялось, своё
                        отставание он психологически нивелировал своей неплохой комплектацией. В машине был даже
                        круиз-контроль, которым я впоследствии очень активно и с удовольствием пользовался.</p>

                    <p>Получив в распоряжение исправный транспорт, мы заехали на квартиру, наскоро собрали вещи и
                        отправились в дорогу. План был следующий: сначала отправляемся в Степанованский дендропарк,
                        затем движемся в город Дилижан, где и ночуем, а на следующий день едем в место под названием
                        Ластивер, и в завершении программы едем на Озеро Севан - кушать знаменитый кебаб из раков.</p>
                    <p>Итак, мы двигались на север. Путь наш лежал через предгорье Арагаца и небольшой город Апаран.
                        Этот город знаменит, прежде всего, своей родниковой водой и своей городской пекарней. В пекарне,
                        в классический армянских печах (тандырах) готовят всевозможную выпечку от лаваша множества видов
                        до различных хачапури и сдобы. Всё исключительно свежее и можно подобрать себе в дорогу
                        что-нибудь по вкусу. Мы, конечно, так и сделали, после чего продолжили движение в
                        северо-восточном направлении и достигли местечка Гюлягарак, неподалёку от которого и
                        располагался дендропарк.</p>
                    <img src={dendro_intro064} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Вообще, дендропарк был создан в 30-е годы двадцатого века польским биологом Эдмондом Леоновичем,
                        который посетив Армению, был влюблён в её природу и решил остаться здесь. Работая над созданием
                        дендропарка, он жил здесь же, в доме который находиться на его территории, здесь же он и был
                        похоронен. Дендропарк представляет собой десятки гектар леса, в том числе декоративных деревьев
                        в основном хвойных. Здесь представлены растения различных стран с нескольких континентов.</p>
                    <img src={dendro_way065} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Было приятно пройтись по этому горному лесу, и подышать прекрасным воздухом, в окружении редких
                        деревьев.</p>
                    <img src={dendro_trees066} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Не так далеко от дендропарка находится полуразрушенная средневековая крепость Лори Берд, зная
                        это, мы решили туда заглянуть. На краю ущелья стоят развалины этой крепости, наиболее
                        сохранившиеся её части это ворота и остатки стен примыкающих к ним. Также рядом находится
                        полуразрушенная, а если быть более точным полувостановленная церковь. Но наибольший интерес у
                        нас вызвало ущелье, разрезающее холм Лори Берда.</p>
                    <img src={lori_berd_mountain067} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>На его дне течёт небольшая горная речка, и построен изящный мостик, переводящий через неё.
                        Спуститься туда можно при наличии определённой ловкости без особого труда, хотя нужно быть
                        осторожным, чтобы не упасть на осыпающемся грунте крутой поверхности склона.</p>
                    <img src={lori_berd_bridge068} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Интересно, что пока мы были в Лори Берд, на территории крепости местные жители вели заготовку
                        сена на зиму. Было забавно наблюдать, как трактор нагруженный сеном потом выезжал через
                        средневековые крепостные ворота.</p>
                    <p>Возвращаясь по дороге из Лори Берда на трассу мы заметили указатель на некие Коммунистические
                        пещеры, и заинтригованные свернули по указателю. Подъехав к краю очередного ущелья, мы увидели
                        заросший бурьяном узкий спуск.
                        В самом начале спуска на скале был вырезан барельеф, который однозначно объяснял происхождение
                        названия этого места. А может и был вырезан по причине того, что место нарекли именно так.</p>
                    <img src={comunism_cave069} aalt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Узкая тропинка привела нас лишь к небольшому горному роднику, а из пещер мы заметили лишь
                        несколько крохотных гротов.</p>
                    <img src={comunism_cave_waterfall070} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Вечерело, и, вернувшись к машине, мы отправились прямиком в город Дилижан. Туда мы добрались уже
                        в темноте, остановились в придорожном отеле, который был по сути уже за городом. Отель назывался
                        Нирвана, и проживание в нём стоило чуть дороже, чем в отеле где мы остановились, будучи в
                        Горисе, к слову условия здесь были чуть хуже.</p>
                    <p>На подъезде к Дилижану я почувствовал себя плохо, не могу сказать точно, в чём была причина,
                        вероятно рацион, который являлся для меня не совсем традиционным помноженный на количество
                        съедаемого ежедневно, да в купе с постоянным кружением в серпантинах армянских гор сделали своё
                        дело. В гостинице у меня начался озноб, я не измерял, но по ощущениям температура была не меньше
                        38. Я принял душ и сразу лёг спать.</p>
                    <p>Утро разбудило яркими лучами летнего солнца, несмотря на то, что календарная осень уже началась.
                        Я обнаружил, что температура у меня спала, но самочувствие по-прежнему было неважным. Мы
                        покинули гостиницу и направились в соседний город Иджеван, где ребята посетили магазин, а я
                        аптеку, прихватив там активированного угля. Мы направлялись в Ластивер. Место интересное своими
                        природными сокровищами, такими как чистые горные реки, пещеры и леса. Вообще тот регион Армении,
                        где мы сейчас находились, является наиболее богатым растительностью по сравнению с другими
                        частями страны. Не долгий путь по трассе и мы подъезжаем к месту назначения. Последний отрезок
                        пути идёт по голому щебню, и мы упираемся в очень приличного вида территорию гостиницы. Здесь
                        дорога заканчивается, оставляем машину здесь дальше идём пешком.</p>
                    <p>Нам предстоит спуститься в ущелье, и сделать это придётся по извилистым тропкам, которые тянуться
                        вдоль скал.</p>
                    <img src={lastiver_way071} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Тропинки, по началу, не проявляют постоянства, они то опускаются вниз, то вновь поднимаются.
                        Вокруг нас лес застилающий склон горы.</p>
                    <img src={lastiver_forest072} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Под конец спуск становиться круче, иногда нам приходиться помогать друг другу чтобы сделать его
                        безопасней. И вот мы на дне ущелья. Здесь мы обнаруживаем какие-то деревянные постройки, как
                        потом выяснится для ночлега туристов, а также беседки и небольшую барную стойку. Здесь же у
                        навеса стояло ружъё, рыболовные снасти, наколотые дрова, одним словом целое хозяйство.</p>
                    <img src={lastiver_cafe073} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Нас встречает хозяин всего этого заведения, который вместе с братом и другом построил всё это и
                        теперь встречает здесь туристов, которые, как он сказал, приезжают сюда со всего мира. Мы
                        спросили, что можно заказать в его кафе, на что он сказал, что сейчас он может предложить нам
                        травяной чай и овощной салат. Мы охотно согласились. Тогда он достал огурцов, помидоров и перцев
                        и предложил моей жене их самой порезать, а меня послал за водой для чая, которая текла из
                        торчащего в скале глиняного кувшина, а по происхождению была родниковой. Всё это привело нас в
                        восторг.</p>
                    <p>Пока Джульетта резала салат хозяин заварил чай из чабреца с мятой, к слову исключительно вкусный,
                        и рассказал, что обычно туристы заранее созваниваются и согласовывают с ним свой приезд, и тогда
                        он приносит сюда продуктов и устраивает им тут целую программу с экскурсиями, ужином, парилкой
                        (печка буржуйка у реки, накрытая парниковой плёнкой в несколько слоёв) и купанием в речке.</p>
                    <p>Немного перекусив свежего салата, мы пошли осматривать окрестности. Река текущая здесь
                        представляет собой исключительное зрелище, ниспадающей с гор по невысоким порогам прозрачной
                        воды.</p>
                    <img src={lastiver_river074} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Хозяин лагеря рассказал нам, что здесь водится особый вид речной форели, продающийся за большие
                        деньги, но они ловят её только для себя.</p>
                    <p>Мы поднялись на несколько порогов вверх по реке, и получили истинное удовольствие от красивого
                        вида девственной природы.</p>
                    <img src={lastiver_waterfall075} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Затем мы с нашим гидом отправились смотреть пещеры, которые были расположены несколько выше
                        лагеря на склоне горы. Происхождение пещер остаётся тёмным, но, по всей видимости, её
                        использовали в своих целях множество людей на протяжении не одной тысячи лет. Собственно пещер
                        было две. Одна была совершенно нетипична, чтобы войти в ней необходимо было лезть наверх по
                        коридору похожему на кротовую нору, и будь мы покрупнее у нас могло бы и не получиться в виду
                        физического диаметра прохода.</p>
                    <img src={lastiver_cave_way076} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Наш проводник рассказал нам, что в этой пещере они живут зимой, обогреваясь буржуйкой. Здесь было
                        окно, лежак и настенный музей, где были выставлены артефакты найденные им и его близкими
                        неподалёку.</p>
                    <img src={lastiver_cave_artefacts077} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>После осмотра этой пещеры мы попрощались с нашим спутником, которому мы ещё в лагере оплатили
                        небольшую сумму за обед, экскурсию и сувениры, которые делает его друг из глины и дерева. Вторую
                        пещеру, стоявшую неподалёку от тропинки ведущей наверх, мы осматривали уже самостоятельно. Здесь
                        был обычный широкий вход, позволявший проходить в пещеру в полный рост и с гордо поднятой
                        головой. В ней было несколько небольших комнат, на стенах были вырезаны замысловатые образы.</p>
                    <img src={lastiver_cave_wall078} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Осмотрев вторую пещеру, мы вышли на тропу над ней и отправились в обратный путь. Идти в гору
                        тяжело, хозяин лагеря говорил нам, что особо ленивые просят у него позвонить в ближайшую деревню
                        и от туда присылают человека с осликом, верхом на котором такой человек может подняться в гору.
                        Пешком у нас ушло на это минут 50.</p>
                    <p>После Ластивера у меня заметно улучшилось самочувствие, видимо сказались хорошие впечатления. Мы
                        сели в машину и отправились к озеру Севан. Но по пути заглянули в город Дилижан, который вчера
                        проезжали поздно вечером. Наскоро посмотрев на "Старый город" (так называют несколько хорошо
                        отреставрированных старых домов образующих небольшую улицу) мы продолжили путь к месту основного
                        назначения.</p>
                    <img src={dilijan_old079} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Итак, мы на Севане возле одноимённого полуострова, который некогда был островом, а на нём
                        находится монастырский комплекс Севанавнк. Озеро находится на высоте 1900 метров, вода в нём
                        холодная даже в конце лета, и имеет тёмно-синий цвет.</p>
                    <img src={sevan808} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Мы поднялись к монастырям, а затем спустились к берегу, и сели в одну из здешних закусочных. Мы с
                        женой уже были в ней в прошлом году, и знали, что тут подают отличную рыбу (форель и сиг) и
                        фирменный кебаб из раковых шеек. В этом году ничего не изменилось, и мы насладились трапезой,
                        заказав еду по своему вкусу.</p>
                    <p>Завтра у нас был последний день аренды машины. Мы знали, что здесь на неподалёку есть аквапарк,
                        почему бы нам не воспользоваться этим днём, чтобы не съездить сюда и позагорать? А сейчас, мы
                        подвозим мою супругу до соседнего Гавара, и возвращаемся в Ереван.</p>
                    <p>Наступил последний день наших покатушек. Мы, как и планировали, направились снова на Севан в
                        Аквапарк, находящийся при гостиничном комплексе недалеко от Севанаванка.</p>
                    <p>Мы заехали на территорию гостиницы, и купив билеты зашли на территорию аквапарка. Аквапарк имеет
                        выход на берег озера, где располагается разная инфраструктура, направленная на отдых и
                        развлечения гостей комплекса.</p>
                    <img src={aquapark081} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>Мы заняли лежаки и стали греться на солнышке. Жарко на Севане бывает редко, всё-таки прохладный
                        воздух с гор делает погоду. Солнце жарит, но дышать свежо, этот обманчивый эффект с нами злую
                        шутку. Накатавшись с горок, и накупавшись в холодной воде бассейна, мы вновь и вновь
                        отправлялись на лежак греться. В итоге мы сильно сгорели, как выяснилось позже.</p>
                    <p>Проведя в аквапарке почти весь день, мы напоследок решили пройти по нашим вчерашним следам,
                        заехав ещё раз на полуостров и заодно покушав свежей рыбки.</p>
                    <p>В Ереване зашли в аптеку за средством от ожогов. Често говоря, таким краснокожим я ещё никогда не
                        был. Кожа на ногах потом болела ещё две недели.</p>
                    <img src={pantenol083} alt="Армения 2014" className="img-fluid travel__img" loading="lazy"/>
                    <p>На следующий день ездили сдавать машину в прокат. Готовился к тому, что придётся съездить в
                        полицию, как мы договаривались, но не пришлось, все бумажные вопросы решили без моего
                        присутствия. Приятно.</p>
                    <p>У нас оставалось пару дней в Ереване, которые мы провели в прогулках по городу, а также навестили
                        моих родственников в Ереване. Мы прощались с Арменией, и это как всегда было немного грустно, но
                        это только больше подогревало желание вернуться сюда вновь. По крайней мере, в Армении осталось
                        ещё много мест, которые мне лично хотелось бы посетить. А Грузия вообще лишь чуть-чуть
                        приоткрыла нам свои красоты, тем лучше... Я обещаю вернуться.</p>
                    <p>И напоследок несколько видео с поездки, в т.ч. бонусный видео рассказ который делал Алексей
                        Украинский (очень нравится!).
                        До новых путешествий!</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-3">
                    <iframe className="travel__iframe"
                            src="https://www.youtube.com/embed/VIT9lTJxzzc"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className="col-md-3">
                    <iframe className="travel__iframe"
                            src="https://www.youtube.com/embed/EcMaNsU8v9w"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className="col-md-3">
                    <iframe className="travel__iframe"
                            src="https://www.youtube.com/embed/QjDiBqM0ZEw"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className="col-md-3">
                    <iframe className="travel__iframe"
                            src="https://www.youtube.com/embed/x1Tcyh4yamo"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
            </div>
        </div>
    );
};

export default Zakavkaz;
import React from 'react';
import i001 from '../../../images/portugal/001.jpg';
import i002 from '../../../images/portugal/002.jpg';
import i003 from '../../../images/portugal/003.jpg';
import i004 from '../../../images/portugal/004.jpg';
import i005 from '../../../images/portugal/005.jpg';
import i006 from '../../../images/portugal/006.jpg';
import i007 from '../../../images/portugal/007.jpg';
import i008 from '../../../images/portugal/008.jpg';
import i009 from '../../../images/portugal/009.jpg';
import i010 from '../../../images/portugal/010.jpg';
import i011 from '../../../images/portugal/011.jpg';
import i012 from '../../../images/portugal/012.jpg';
import i013 from '../../../images/portugal/013.jpg';
import i014 from '../../../images/portugal/014.jpg';
import i015 from '../../../images/portugal/015.jpg';
import i016 from '../../../images/portugal/016.jpg';
import i017 from '../../../images/portugal/017.jpg';
import i018 from '../../../images/portugal/018.jpg';
import i019 from '../../../images/portugal/019.jpg';
import i020 from '../../../images/portugal/020.jpg';
import i021 from '../../../images/portugal/021.jpg';
import i022 from '../../../images/portugal/022.jpg';
import i023 from '../../../images/portugal/023.jpg';
import i024 from '../../../images/portugal/024.jpg';
import i025 from '../../../images/portugal/025.jpg';
import i026 from '../../../images/portugal/026.jpg';
import i027 from '../../../images/portugal/027.jpg';
import i028 from '../../../images/portugal/028.jpg';
import i029 from '../../../images/portugal/029.jpg';
import i030 from '../../../images/portugal/030.jpg';
import i031 from '../../../images/portugal/031.jpg';
import i032 from '../../../images/portugal/032.jpg';
import i033 from '../../../images/portugal/033.jpg';
import i034 from '../../../images/portugal/034.jpg';
import i035 from '../../../images/portugal/035.jpg';
import i036 from '../../../images/portugal/036.jpg';
import i037 from '../../../images/portugal/037.jpg';
import i038 from '../../../images/portugal/038.jpg';
import i039 from '../../../images/portugal/039.jpg';
import i040 from '../../../images/portugal/040.jpg';
import i041 from '../../../images/portugal/041.jpg';
import i042 from '../../../images/portugal/042.jpg';
import i043 from '../../../images/portugal/043.jpg';
import i044 from '../../../images/portugal/044.jpg';
import i045 from '../../../images/portugal/045.jpg';
import i046 from '../../../images/portugal/046.jpg';
import i047 from '../../../images/portugal/047.jpg';
import i048 from '../../../images/portugal/048.jpg';
import i049 from '../../../images/portugal/049.jpg';
import i050 from '../../../images/portugal/050.jpg';
import i051 from '../../../images/portugal/051.jpg';
import i053 from '../../../images/portugal/053.jpg';
import i054 from '../../../images/portugal/054.jpg';
import i055 from '../../../images/portugal/055.jpg';
import i056 from '../../../images/portugal/056.jpg';
import i057 from '../../../images/portugal/057.jpg';
import i058 from '../../../images/portugal/058.jpg';
import i059 from '../../../images/portugal/059.jpg';
import i060 from '../../../images/portugal/060.jpg';
import i061 from '../../../images/portugal/061.jpg';
import i062 from '../../../images/portugal/062.jpg';
import i063 from '../../../images/portugal/063.jpg';
import i064 from '../../../images/portugal/064.jpg';
import i065 from '../../../images/portugal/065.jpg';
import i066 from '../../../images/portugal/066.jpg';
import i067 from '../../../images/portugal/067.jpg';
import i068 from '../../../images/portugal/068.jpg';
import i069 from '../../../images/portugal/069.jpg';
import i069_1 from '../../../images/portugal/069_1.jpg';
import i070 from '../../../images/portugal/070.jpg';
import i071 from '../../../images/portugal/071.jpg';
import i072 from '../../../images/portugal/072.jpg';
import i073 from '../../../images/portugal/073.jpg';
import i074 from '../../../images/portugal/074.jpg';
import i075 from '../../../images/portugal/075.jpg';
import i076 from '../../../images/portugal/076.jpg';
import i077 from '../../../images/portugal/077.jpg';
import i078 from '../../../images/portugal/078.jpg';
import i079 from '../../../images/portugal/079.jpg';

const Portugal = () => {
    return (<>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Наступила календарная весна, и для нас, как и в прошлом году, пришло время для путешествий.
                            Изначально нами планировалась поездка в Румынию, однако, трудности логистического свойства
                            изменили наши планы, и страной нашего туристского интереса стала – Португалия.</p>

                        <p>Состав путешествующих, как и в прошлом марте: я, жена и Алексей Кириллов, поджидающий нас в
                            столице государства Российского.</p>

                        <p>Начали, как водится, с покупки биллетов на самолёт. В Москву мы с супругой должны были лететь
                            «Победой», а оттуда в Лиссабон португальской TAP Portugal.</p>

                        <p>Далее оформление виз. Португальское посольство у нас только в Москве, а самый близкий
                            сертифицированный посольством визовый центр «VFS», находился в Екатеринбурге. Что ж едем
                            туда, и
                            подаём документы на визу. Наши паспорта с португальскими визами приходят с доставкой в
                            Челябинск
                            где-то дней через десять. Довольно оперативно. Теперь остаётся только ждать 10 марта, день
                            нашего вылета в Москву.</p>

                        <p>Как уже было сказано, в Москву мы с женой летим «Победой». Как ни странно, авиакомпания
                            исправляется, теперь на борт можно брать рюкзаки, самые настоящие, с двумя лямками, лишь бы
                            ваша
                            ручная кладь помещалась во всей своей совокупности в габариты мерного ящика, который стоит
                            рядом
                            со стойкой регистрации. Поскольку у нас оплачено одно багажное место, то и проблем с ручной
                            кладью не возникает. Прилетаем утром во Внуково, где нас уже любезно ожидает Алексей, и мы
                            едем
                            на такси к нему домой. Вылет в Лиссабон только завтра утром и у нас есть время, чтобы
                            немного
                            прогуляться по Москве, и даже поспать ночью по-человечески. А с утра неспешно подняться,
                            собраться и выехать уже в Домодедово, чтобы вылететь в столицу далёкой Португалии.</p>

                        <p>Утром в аэропорту регистрируемся на рейс и ждём вылета. Как я уже писал, летим португальской
                            TAP
                            Portugal. Забегая вперёд, скажу, что ни к работе авиакомпании, ни к работе аэропорта в
                            Лиссабоне
                            у меня не осталось ни единого вопроса. Всё чётко, удобно и дружелюбно. Никаких лишних
                            вопросов
                            ни к нам лично, ни к нашему багажу не было. Ни у кого даже в мыслях не было как-то
                            злоупотребить
                            наделёнными правами. Единственное неприятное обстоятельство в пятичасовом полёте – узкие
                            ряды
                            кресел в самолёте. Это конечно не трансконтинентальный перелёт, но в таких креслах устаёшь
                            быстро.</p>

                        <p>Разница во времени с Москвой в Португалии – 3 часа. Поэтому приземляемся в Лиссабоне мы ещё
                            до
                            полудня. Довольно быстро проходим таможню, выходим из аэропорта и тут же натыкаемся на
                            станцию
                            метрополитена. Да, это приятное удобство португальской столицы, здесь из аэропорта в город
                            можно
                            уехать прямо на метро. Что мы и делаем, покупаем карточку с системой «запинга» (это когда
                            деньги
                            можно кидать на карту, а затем списывать за поездки), сама карточка стоит пол-евро, а одна
                            поездка стоит 1.30 евро. Эту карточку можно использовать также для поездок на трамвае и
                            автобусе. Держим путь до Santa Apolonia – это конечная синей ветки (Azul). Станция является
                            локальным транспортным хабом, т.к. совмещена с вокзалом и портом.</p>

                        <p>Первое, на что обращаешь внимание, приезжая в какую-нибудь морскую страну – это воздух. Этот
                            пьянящий сладковато-свежий запах от растительного разнообразия, растворён здесь в мельчайших
                            частицах сильно увлажнённого воздуха. Особенно сильный эффект проявляется, когда приезжаешь
                            в такую страну из Челябинска. Второе, на что обращаешь внимание, попадая в какую-нибудь
                            европейскую, желательно морскую страну – это отсутствие грязи. Причём не только грязи, но и
                            пыли. Брусчатка под ногами блестит и скрипит под ногами как натёртый паркет. Если вы,
                            подойдя к дороге, встретите ограждение и проведёте по нему пальцем, то увидите, что на
                            пальце не
                            останется никакого налёта. Совсем. И это повергает в некий шок.</p>

                        <p>Что расстроило, конкретно в Португалии, так это отношение населения к выгулу своих питомцев.
                            Многие за ними не убирают вовсе, и это особенно дико, когда наблюдаешь вокруг почти
                            стерильную
                            чистоту.</p>

                        <p>В Португалии на улицах, даже чаще чем у нас яблони, растут цитрусовые деревья. И можно взять
                            упавший апельсин или сорвать лимон к чаю. Причём количество эфирных масел в свежем цитрусе
                            не сравнимо с цитрусом из магазина, и запах явно ощущается даже без разрезания плода. Очень
                            здорово.</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12"><img src={i001} className="img-fluid travel__img" loading="lazy"
                                                    alt="Альфама"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Мы находимся в районе Альфама, одном из старейших в Лиссабоне. Здесь каждый дом хранит
                            историю.
                            Застройка выполнена в едином стиле, но каждый дом уникален своей отделкой, многие дома
                            облицованы знаменитой португальской плиткой с преобладанием синих тонов – «азулежу». В одном
                            из таких домов мы и поселились.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img src={i002} className="img-fluid travel__img" loading="lazy"
                                                           alt=""/>
                    </div>
                    <div className="col-md-6 pl-md-1"><img src={i003} className="img-fluid travel__img" loading="lazy"
                                                           alt="Альфама"/></div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Квартира в этом доме была двухуровневая, и находилась на третьем этаже и в мансарде над ним,
                            где окна уже были на крыше. Как выяснится, это было наше самое удобное и уютное жилище за
                            всё время нахождения на территории Португалии.</p>

                        <p>Центрального отопления в Португалии нет. Для отопления нашей квартиры использовались, любезно
                            предоставленные масляные обогреватели, по одному в каждой спальне. А для обогрева прихожей,
                            плавно переходящей в кухню, маленькая «вертушка». К тому же Лиссабон был самым тёплым местом
                            из тех, что мы собирались посетить в этой поездке и от холода в квартире мы не страдали.</p>

                        <p>Ещё на окна, вместо штор, в Португалии принято устанавливать ставни. Они могут быть внешние
                            или внутренние, пластиковые или деревянные, но они обязательный атрибут местного жилища. Вот
                            и у нас, во всех местах, где мы жили, были ставни, которые, к тому же, исполняли роль
                            дополнительной защиты от ветра. В принципе, довольно удобная штука.</p>

                        <p>В первый день, по приезду, мы как водится, решили осмотреть окрестности. Слонялись по
                            улочкам, смотрели, попадавшиеся на пути случайные достопримечательности. Потом, пошёл дождь,
                            и мы укрылись в маленькой кафешке. Заодно перекусили.</p>

                        <p>Пожалуй, самое заметное место в португальской кухне занимает – «Бакальяу». В принципе,
                            Бакальяу – это засушенная и очень солёная треска. Но если вы увидите в меню какого-нибудь
                            заведения Бакальяу, то это может быть всё что угодно. Например, некая запеканка с картофелем
                            и треской, либо мелко порубленная треска с бобовыми, либо традиционный пирожок с картофелем
                            и треской. А может и совсем не треской, поскольку у берегов Португалии треска не ловится,
                            однако само слово –Бакальяу, по-португальски означает именно «треска».</p>

                        <p>После обеда дождь ослаб, а затем совсем прекратился, и мы отправились дальше на покорение
                            вечерней Альфамы.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12"><img src={i004} className="img-fluid travel__img" loading="lazy"
                                                    alt="Альфама"/></div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Но дойдя до Национального пантеона Португалии (Церковь Святой Энграсии), мы стали
                            свидетелями покорения многочисленных фотографов женщиной в красном.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img src={i005} className="img-fluid travel__img" loading="lazy"
                                                           alt="Альфама"/></div>
                    <div className="col-md-6 pl-md-1"><img src={i006} className="img-fluid travel__img" loading="lazy"
                                                           alt="Альфама"/></div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Когда стемнело, мы неспешно побрели обратно к дому, по пути любуясь красотой и
                            разнообразием города.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img src={i007}
                                                           className="img-fluid travel__img" loading="lazy"
                                                           alt="Альфама"/>
                    </div>
                    <div className="col-md-6 pl-md-1"><img src={i008}
                                                           className="img-fluid travel__img" loading="lazy"
                                                           alt="Альфама"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>На следующий день гуляния по городу продолжились с тех мест, которые мы
                            осматривали уже вечером. Теперь мы вглядывались в них при солнечном свете.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img src={i009}
                                                           className="img-fluid travel__img" loading="lazy"
                                                           alt="Лиссабон"/>
                    </div>
                    <div className="col-md-6 pl-md-1"><img src={i010}
                                                           className="img-fluid travel__img" loading="lazy"
                                                           alt="Лиссабон"/>
                    </div>
                </div>
            </div>

            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Все тротуары в Лиссабоне выложены брусчатым камнем, иногда контрастных
                            оттенков, иногда образуя узоры, в центре на узких улочках он есть даже на
                            проезжей части, но тротуары даже в отдалении от центра неизменно брусчатые.
                            Мы не видели ни одного заасфальтированного или выложенного бетонной плиткой
                            тротуара в Лиссабоне, и это придаёт городу уюта и красоты, чувствуется
                            забота о пешеходах. Хотя тротуары в центре подчас такие узкие, что два
                            человека не могут разойтись, не выйдя на проезжую часть, которая, к слову,
                            тоже узка, да ещё и делит иногда пространство с трамвайной линией. Однако
                            когда пешеход оказывается на проезжей части, водители к этому относятся
                            спокойно. На узких улицах исторического центра пешеходы часто идут на
                            красный, или переходят дорогу там, где им удобно. Старые (исторические)
                            трамваи протискиваются иногда в миллиметровые щели между стоящими на обочине
                            машинами, причём водители трамваев делают это совершенно без нервов и на
                            приличной скорости.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img src={i011}
                                                           className="img-fluid travel__img" loading="lazy"
                                                           alt="Лиссабон"/>
                    </div>
                    <div className="col-md-6 pl-md-1"><img src={i012}
                                                           className="img-fluid travel__img" loading="lazy"
                                                           alt="Лиссабон"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>А вот ещё одних участников движения – велосипедистов здесь мало, да и
                            инфраструктуры для них типа велодорожек, минимум. Вероятно, сказывается
                            холмистый ландшафт города и, возможно, менталитет жителей. Зато
                            процветает такой общественный транспорт как «мото тук-тук», заточенный
                            под узкие улицы и проблемы с парковкой.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img src={i014}
                                                           className="img-fluid travel__img" loading="lazy"
                                                           alt="Лиссабон"/>
                    </div>
                    <div className="col-md-6 pl-md-1"><img src={i013}
                                                           className="img-fluid travel__img" loading="lazy"
                                                           alt="Лиссабон"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>На трамвае добираемся до Торговой Площади (Праса-ду-Комерсиу), здесь когда-то стоял дворец,
                            уничтоженный землетрясением, а теперь площадь
                            с аркой и статуей короля Жозе I в её центре.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12"><img src={i015}
                                                    className="img-fluid travel__img" loading="lazy" alt="Лиссабон"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Отсюда открывается вид на место впадения реки Тежу в океан, а также на мост «25 апреля»,
                            соединяющий Лиссабон с Алмадой. Мост
                            был построен Американской мостостроительной компанией, видимо, поэтому он очень похож на
                            знаменитый мост в Сан-Франциско.
                            Изначально мост был назван именем премьер-министра Салазара, однако после революции
                            «гвоздик» 25 апреля 1974, мост был
                            переименован в честь этого события.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12"><img src={i016}
                                                    className="img-fluid travel__img" loading="lazy" alt="Лиссабон"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Мы проходим через триумфальную арку на пешеходную улицу и постепенно поднимаемся до площади
                            Росиу. Официально площадь
                            носит название императора Педру IV, однако, исторически эта область называлось именно Росиу,
                            и по сей день заведения
                            вокруг площади, отели и даже неподалёку расположенный вокзал, носят в себе это название.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12"><img src={i017}
                                                    className="img-fluid travel__img" loading="lazy"
                                                    alt="Лиссабон"/></div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Кстати, в Лиссабоне достаточно часто можно натолкнуться на личностей, из толпы никак не
                            выделяющихся,
                            предлагающих купить у них травку: «Гашиш? Марихуана?», - негромко вопрошают они. Вежливо
                            отказываемся.</p>

                        <p>Движемся на северо-запад и оказываемся на широком бульваре (Avenida da Liberdade) с узорной
                            мостовой и
                            большими пальмами. </p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12"><img src={i018}
                                                    className="img-fluid travel__img" loading="lazy"
                                                    alt="Лиссабон"/></div>
                </div>
            </div>

            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Здесь неподалёку решаем пообедать. В Португалии вообще, насколько я понял, не особо
                            заморачиваются о
                            внешнем виде заведений общепита. Здесь в основном распространены небольшие заведения, с
                            очень простой
                            и не очень новой мебелью, а также общим убранством.Такое среднее заведение, скорее
                            соответствует по
                            внешнему облику нашей средней столовой. Впрочем на готовку самих блюд это никак не
                            сказывается.</p>

                        <p>После обеда двинулись по бульвару дальше. Доходим до кольца и уходим на запад, здесь
                            начинается частный
                            сектор и здесь можно посмотреть на акведук, построенный в XVIII веке, и уходящий к парку.
                            Основной пролёт его составляет 18 километров, а высота самой высокой арки 65 метров. </p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12"><img
                        src={i019} className="img-fluid travel__img" loading="lazy"
                        alt="Лиссабон"/></div>
                </div>
            </div>

            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Мы спускаемся к магистрали шумящей внизу, чтобы посмотреть на пролёт акведука снизу, а далее
                            устремляемся немного восточнее на авеню имени армянского промышленника Галуста Гюльбенкяна
                            (да
                            без армян и здесь не обошлось), ведущее к парку и музею его же имени. Кстати, его же именем
                            также называлась улица в Ереване, на которой мы снимали квартиру, во время путешествия в
                            Армению-Грузию в 2014 году.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img
                        src={i020}
                        className="img-fluid travel__img" loading="lazy" alt="Лиссабон"/></div>
                    <div className="col-md-6 pl-md-1"><img
                        src={i021}
                        className="img-fluid travel__img" loading="lazy" alt="Лиссабон"/></div>
                </div>
            </div>

            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>В музее представлена богатейшая коллекция произведений искусства со всего света,
                            которую Гюльбенкян собирал в течении жизни, будучи магнатом, а в конце жизни пожертвовал
                            её Португалии.</p>

                        <p>В сам музей мы не пошли, но отдохнули в парке с уточками, черепашками, прудиками, и
                            всевозможной растительностью, не обделённой даже бамбуковыми зарослями.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img
                        src={i022}
                        className="img-fluid travel__img" loading="lazy" alt="Лиссабон"/></div>
                    <div className="col-md-6 pl-md-1"><img
                        src={i023}
                        className="img-fluid travel__img" loading="lazy" alt="Лиссабон"/></div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>После досуга в парке просто слоняемся по городу, в месте, где работает фуникулёр,
                            пешочком поднимаемся на возвышенность, откуда ещё раз обозреваем эту часть
                            города с высоты. </p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img
                        src={i024}
                        className="img-fluid travel__img" loading="lazy" alt="Лиссабон"/></div>
                    <div className="col-md-6 pl-md-1"><img
                        src={i025}
                        className="img-fluid travel__img" loading="lazy" alt="Лиссабон"/></div>
                </div>
            </div>

            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>На следующий день запланирована поездка в Синтру. Утром, с вокзала
                            Санта-Аполония, отправляемся туда на электричке, предварительно купив
                            проездную карту в автомате. Будьте внимательны, эта карта зелёного
                            цвета, а валидация поездки происходит у входа на перрон. В
                            поездах и электричках, а также в междугородних автобусах всегда
                            работают контролёры. Со всеми остановками ехать до Синтры минут
                            сорок. Здесь расположено множество достопримечательностей, среди
                            которых в основном замки и поместья знати, в одно из таких поместий мы и
                            отправились.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12"><img
                        src={i026}
                        className="img-fluid travel__img" loading="lazy" alt="Синтра"/>
                    </div>
                </div>
            </div>

            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Итак, дворцово-парковый комплекс Кинта да Регалейра, носит
                            название по имени баронессы АленРегалейра, приобретшей эти земли
                            в 1840 году. Поместье приобрело свой внешний вид при следующем
                            владельце, меценате, коллекционере и масоне Карвалью
                            Монтейру. Именно благодаря его задумкам и воле, мы наблюдаем
                            здесь всё это великолепие.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12"><img
                        src={i027}
                        className="img-fluid travel__img" loading="lazy" alt="Синтра"/>
                    </div>
                </div>
            </div>

            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>А это великолепие состоит собственно из дворца в
                            эклектичном стиле и расположенном на склоне
                            саде, благодаря чему он является как-бы
                            многоуровневым. В этом саду находится множество объектов
                            интереса, среди которых часовня, всевозможные
                            башенки, разнообразная растительность и прочее облагороженное
                            пространство.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1">
                        <img
                            src={i029}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Синтра"/></div>
                    <div className="col-md-6 pl-md-1">
                        <img
                            src={i028}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Синтра"/></div>
                </div>
            </div>

            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Но самое интересное, по моему мнению, это гроты
                            пронизывающие склон. В одном таком месте, где
                            струйка стекающей воды образует заводь, можно
                            по камушкам пробраться вгрот, который ведёт на
                            дно своеобразного колодца, а по его
                            стенкам идёт винтовая лестница, поднявшись по
                            которой оказываешься на самом верхнем уровне
                            сада. Великолепная задумка, стоявшая
                            немалых трудов создателям, но эффект от
                            прохождения этого места замечательный. </p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div
                        className="col-md-6 pr-md-1">
                        <img
                            src={i030}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Синтра"/></div>
                    <div
                        className="col-md-6 pl-md-1">
                        <img
                            src={i031}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Синтра"/></div>
                </div>
            </div>

            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Вообще, Кинта да Регалейра оказалась
                            приятным и интересным местом,
                            достойным посещения и туда из Синтры
                            можно прогуляться пешком.</p>

                        <p>После длительного променада мы
                            отправились пообедать, и попали,
                            в общем, в неплохое заведение, которое
                            могу и вам посоветовать:
                            Incomum by Luis Santos. Находится в
                            цоколе здания, совсем недалеко от
                            станции, куда приходит электричка.
                            В обед здесь за скромные деньги (что
                            то около 13-14 Евро), подают аж три
                            блюда: первое, второе и десерт.
                            Причём у каждого есть альтернатива на
                            выбор, т.е. можно выбрать из двух
                            подающихся сегодня супов, двух горячих
                            блюд и двух десертов. К тому же
                            заведение это довольно новое, судя
                            по «нормальному» убранству интерьера,
                            а обслуживание быстрое, несмотря на
                            обилие народу. Короче отобедали мы,
                            что называется «от пуза» и отправились
                            на автобусную станцию, покупать
                            билет до мыса Рока. </p>

                        <p>Автобус туда подошёл скоро, но был переполнен. Ехать из Синтры довольно долго около 40 минут, по-моему, и дорога изобилует резкими поворотами. К концу пути мне даже стало дурно, возможно укачало, плюс духота переполненного автобуса, хотя ранее за мной такого не замечалось. Ну да ладно, на месте отдышался. </p>

                        <p>И надо сказать было чем, сильный ветер с океана – постоянный спутник этого места. А само это место является самой западной оконечной точкой Евразийского континента. Океан.</p>

                        <p>Я никогда прежде его не видел. Но мощь его ощущается сразу, и она впечатляет. Насмотревшись на могучие волны с высокого мыса. Мы отправились прямиком к воде, на дикий пляж Урса (Ursa beach). И если меня спросят, какое главное впечатление у меня осталось от Португалии, то я назову это место.</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div
                        className="col-md-6 pr-md-1">
                        <img
                            src={i032}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Мыс Рока"/>
                    </div>
                    <div
                        className="col-md-6 pl-md-1">
                        <img
                            src={i033}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Мыс Рока"/>
                    </div>
                </div>
            </div>

            <br/>


            <div className="container">
                <div className="row">
                    <div
                        className="col-md-12">
                        <p>Туда ведут многочисленные козьи тропки, которые затем сливаются в один довольно крутой спуск на мелкий жёлто-коричневый песок, за широкой полосой которого бурлит океан. Стихия бьётся о многочисленные рифы и скальные клыки, которые разбросаны вдоль побережья, добавляя эффекта видимому. Чистый восторг, нет других чувств и мыслей, когда ты оказываешься здесь. Ты просто застываешь заворожённый, как-бы не веря в то, где находишься, а затем пытаешься доказать себе реальность происходящего и пробуешь взаимодействовать с местом. Бежишь по мокрому песку, оставляя глубокие следы, подходишь к воде и трогаешь её пенные языки, будто увлекающие тебя за собой в бурлящее океанское чрево, дышишь этим солёным воздухом.</p>
                    </div>
                </div>
            </div>


            <div
                className="container-fluid">
                <div className="row">
                    <div
                        className="col-md-6 pr-md-1">
                        <img
                            src={i034}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Пляж Урса"/>
                    </div>
                    <div
                        className="col-md-6 pl-md-1">
                        <img
                            src={i035}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Пляж Урса"/>
                    </div>
                </div>
            </div>

            <br/>


            <div className="container">
                <div className="row">
                    <div
                        className="col-md-12">
                        <p>Эпичности происходящему добавляет пустынность места, на пляже нет никого кроме нас. Оглядевшись, мы видим широкий ручей, он течёт с порога на высоком склоне и впадает прямо в океан, над которым почти такими же цветами, формами и масштабами нависает сплошная облачность.</p>
                    </div>
                </div>
            </div>


            <div
                className="container-fluid">
                <div className="row">
                    <div
                        className="col-md-6 pr-md-1">
                        <img
                            src={i036}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Пляж Урса"/>
                    </div>
                    <div
                        className="col-md-6 pl-md-1">
                        <img
                            src={i037}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Пляж Урса"/>
                    </div>
                </div>
            </div>

            <br/>


            <div
                className="container">
                <div
                    className="row">
                    <div
                        className="col-md-12">
                        <p>После скорого свидания с океаном, возвращаемся к мысу, чтобы сесть на автобус до Синтры. Здесь уже посвободней, даже удаётся присесть. Меня к концу поездки опять укачало, хотя уже меньше, но нам предстоит ещё и электричка. Что за новая напасть? Всё таки, путешествовать на машине гораздо удобней.</p>

                        <p>На следующий день опять гуляем по Лиссабону. Катаемся на историческом трамвае, смотрим город. Потом на метро и современном трамвае едем к монастырю Жиронимуш, однако, по приезду, начинается
                            проливной дождь, который не прекращается даже после попытки переждать его за обедом в кафе. Приходится менять планы, и мы едем в район вокзала Ориенти, к парку наций, чтобы посетить Лиссабонский океанариум. </p>
                    </div>
                </div>
            </div>

            <div
                className="container-fluid">
                <div
                    className="row">
                    <div
                        className="col-md-6 pr-md-1">
                        <img
                            src={i038}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Лиссабон"/>
                    </div>
                    <div
                        className="col-md-6 pl-md-1">
                        <img
                            src={i039}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Лиссабон"/>
                    </div>
                </div>
            </div>

            <br/>


            <div
                className="container">
                <div
                    className="row">
                    <div
                        className="col-md-12">
                        <p>К нему направляемся по набережной, откуда открывается вид на мост «Васко да Гама», на тот момент он был самым длинным мостом в Европе. Его длина составляет 17,2 км, и его конец теряется от взора в туманной дымке Тежи. </p>
                    </div>
                </div>
            </div>


            <div
                className="container-fluid">
                <div
                    className="row">
                    <div
                        className="col-md-12">
                        <img
                            src={i040}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Лиссабон"/>
                    </div>
                </div>
            </div>

            <br/>


            <div
                className="container">
                <div
                    className="row">
                    <div
                        className="col-md-12">
                        <p>Океанариум
                            являет
                            собой
                            четыре
                            зоны:
                            представляющих
                            флору
                            и
                            фауну
                            северной
                            Атлантики,
                            Тихого
                            океана,
                            Антарктической
                            береговой
                            линии
                            и
                            Индийского
                            океана
                            тропических
                            широт.
                            В
                            каждом
                            секторе
                            создана
                            температура
                            и
                            влажность
                            характерная
                            для
                            этих
                            мест.
                            Все
                            эти
                            зоны
                            расположены
                            вокруг
                            главного
                            аквариума,
                            осмотр,
                            которого,
                            можно
                            производить
                            с
                            двух
                            уровней,
                            на
                            нижнем
                            из
                            которых
                            зрелище
                            более
                            эффектно,
                            поскольку
                            здесь
                            наблюдается
                            экспозиция
                            как-бы
                            из
                            глубины.</p>
                    </div>
                </div>
            </div>


            <div
                className="container-fluid">
                <div
                    className="row">
                    <div
                        className="col-md-6 pr-md-1">
                        <img
                            src={i041}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Лиссабон"/>
                    </div>
                    <div
                        className="col-md-6 pl-md-1">
                        <img
                            src={i042}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Лиссабон"/>
                    </div>
                </div>
            </div>

            <br/>


            <div
                className="container">
                <div
                    className="row">
                    <div
                        className="col-md-12">
                        <p>Здесь
                            обитают
                            множество
                            видов
                            водоплавающих,
                            включая
                            акул,
                            скатов,
                            мурен
                            и
                            прочих
                            морских
                            тварей.
                            Океанариум
                            нам
                            понравился.</p>
                    </div>
                </div>
            </div>


            <div
                className="container-fluid">
                <div
                    className="row">
                    <div
                        className="col-md-6 pr-md-1">
                        <img
                            src={i043}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Лиссабон"/>
                    </div>
                    <div
                        className="col-md-6 pl-md-1">
                        <img
                            src={i044}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Лиссабон"/>
                    </div>
                </div>
            </div>
            <br/>
            <div
                className="container-fluid">
                <div
                    className="row">
                    <div
                        className="col-md-6 pr-md-1">
                        <img
                            src={i045}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Лиссабон"/>
                    </div>
                    <div
                        className="col-md-6 pl-md-1">
                        <img
                            src={i046}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Лиссабон"/>
                    </div>
                </div>
            </div>

            <br/>

            <div
                className="container">
                <div
                    className="row">
                    <div
                        className="col-md-12">
                        <p>На
                            следующее
                            утро
                            мы
                            попрощались
                            с
                            нашей
                            Лиссабонской
                            квартирой
                            и
                            отправились
                            на
                            вокзал,
                            чтобы
                            сесть
                            на
                            заранее
                            выкупленные
                            (ещё
                            в
                            Челябинске)
                            места
                            в
                            поезде,
                            направляющемся
                            в
                            Каштелу-Бранку.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Каштелу-Бранку это такой своеобразный райцентр в самом сердце Португалии, с населением около
                            50
                            тысяч. Здесь располагается автовокзал, из которого можно попасть в самые различные места –
                            большие города и маленькие деревушки. Нашей целью была как раз одна из таких крохотных
                            деревень,
                            с названием Монсанту. Мы купили билеты в кассе автовокзала, но нам сообщили, на неуверенном
                            английском, что автобус идёт до посёлка Иданья-а-Нова, где мы с нашими билетами должны были
                            пересесть на другой автобус, идущий уже до места нашего назначения.</p>

                        <p>Автобус шёл курсом на северо-запад и вскоре мы достигли Иданья-а-Нова, здесь располагалась
                            школа,
                            куда, судя по всему, ездили детишки из всех соседних деревень, некоторые административные
                            здания, а также автостанция. Здесь мы пересели на автобус до Монсанту и продолжили движение
                            на
                            северо-запад, в горы, практически до испанской границы.</p>

                        <p>Интересно, что даже в этом захолустье, в автобус «проникли» контролёры, и провели свою
                            проверку
                            билетов. Вскоре, из окна мы увидели большой холм, который начал штурмовать наш автобус, что
                            означало, что мы почти на месте. Конечная автобуса в историческом центре деревни с
                            населением
                            около 1000 человек. И хотя место, где мы планировали остановиться на ночь, располагалось
                            уровнем
                            ниже, мы решили сначала осмотреть достопримечательности, т.к. у нас было всего пару часов до
                            сумерек.</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img src={i047} className="img-fluid travel__img" loading="lazy"
                                                           alt="Монсанту"/></div>
                    <div className="col-md-6 pl-md-1"><img src={i048} className="img-fluid travel__img" loading="lazy"
                                                           alt="Монсанту"/></div>
                </div>
            </div>


            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Что собой представляет исторический центр Монсанту? Это укрепление, где роль крепостных стен
                            часто выполняют скалы. Здесь всё из камня: дома, дороги, ограждения. Это место стоит на
                            камнях и буквально выросло из них. Главной достопримечательностью Монсанту являются
                            огромные, «разбросанные» по всей его площади валуны, округлой формы. Многие из этих валунов,
                            люди приспособили, за невозможностью сдвинуть их с места. То тут, то там огромные камни
                            служат стенами для домов, их крышами, или даже постаментами, на которых эти дома стоят.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img src={i049} className="img-fluid travel__img" loading="lazy"
                                                           alt="Монсанту"/></div>
                    <div className="col-md-6 pl-md-1"><img src={i053} className="img-fluid travel__img" loading="lazy"
                                                           alt="Монсанту"/></div>
                </div>
            </div>


            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Этот симбиоз природного камня с продуктами строительного ремесла выглядит очень необычно
                            и причудливо. Поскольку Монсанту располагается на холме, поселение представляет собой
                            «слоёный пирог» из серых камней и рыжеватых крыш, приправленных зеленью старых мхов и
                            редкими деревьями. На самой вершине холма располагалась крепость правителей, от которой
                            сейчас остались только полуразрушенные стены и некоторые мелкие строения. Со стен этих
                            открывается чудесный вид на окрестности.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img src={i051} className="img-fluid travel__img" loading="lazy"
                                                           alt="Монсанту"/></div>
                    <div className="col-md-6 pl-md-1"><img src={i050} className="img-fluid travel__img" loading="lazy"
                                                           alt="Монсанту"/></div>
                </div>
            </div>


            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>После покорения вершины холма, мы зашли в крохотную харчевню отобедать. А после
                            Бакальяу в уютном аутентичном местечке, мы пошли на спуск в новую часть деревни,
                            чтобы заселиться в забронированный нами дом. Спуск шёл по серпантину мимо акаций и
                            пробковых деревьев, со снятой со стволов корой. В Монсанту, несмотря на то, что это
                            деревня, очень чисто, скотина, пасущаяся на склонах, не загоняется во дворы домов, а
                            остаётся там на ночь, охраняемая специально обученными собаками-пастухами.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img src={i054}
                                                           className="img-fluid travel__img" loading="lazy" alt="Монсанту"/></div>
                    <div className="col-md-6 pl-md-1"><img src={i055}
                                                           className="img-fluid travel__img" loading="lazy" alt="Монсанту"/></div>
                </div>
            </div>


            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Написав агенту, который занимался сдачей дома, мы стали дожидаться хозяина.
                            Стояли мы у церкви, которую использовали как ориентир. Минут через пятнадцать, к
                            нам вышел добродушный мужчина в возрасте, слегка навеселе, и не слова ни
                            понимающий по-английски. Он проводил нас до дома, показал все его помещения, и
                            жестами стал объяснять, как пользоваться дверными замками и как топить камин,
                            который служил единственным источником отопления в двухэтажном доме, не считая
                            маленькой вертушки в одной из спален. Его объяснение это нехитрого занятия
                            сводилось к жесту бросания полена и сопровождалось произносимым им звуком «Вшш».
                            Дом был очень чистым, хорошо оборудованным и обставленным, имел две ванные
                            комнаты, но воспользоваться всем этим благополучием нам не пришлось из-за
                            скоротечности нашего здесь пребывания, а также из-за холода, который напрочь
                            отбивал желание принимать душ.</p>

                        <p>Оставив вещи в доме, мы отправились в местный магазинчик на площади, и закупились
                            продуктами к ужину. А потом отправились ещё немного прогуляться. Сначала мы
                            забрели на тропу, которая вывела нас в редкий лесок, за которым находилась
                            старая заброшенная часовня.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12"><img src={i056} className="img-fluid travel__img" loading="lazy"
                                                    alt="Монсанту"/></div>
                </div>
            </div>


            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Тут мы постояли на камнях, и посмотрели в сторону собирающегося заката. А
                            затем снова отправились в исторический центр, войдя в него через ворота,
                            видимо бывшие главным входом сюда во времена крепости. Туристов в эту пору
                            практически не было, и мы бродили по улочкам одни.</p>

                        <p>Когда стемнело, мы отправились обратно к дому, сорвали с дерева свежий и
                            ароматный лимон и пошли ужинать.</p>

                        <p>Когда солнце село в доме стало совсем прохладно, и мы развели камин, который
                            находился внизу в студии, совмещающей в себе прихожую, гостиную и кухню.
                            Тепло от камина, по идее, через специальный воздуховод должно было поступать
                            наверх в пару спален. Но этот процесс происходил крайне вяло, и стало
                            понятно, что так дом не согреть. Тем более, что ночью дрова уже подкидывать
                            будет некому. Поэтому перед сном мы загрузили в камин топлива под завязку и
                            отправились спать. Лично я, прямо в одежде, сбросив с себя лишь куртку.</p>

                        <p>Рано утром мы встали, чтобы успеть на восьмичасовой автобус. Пропустить его
                            было никак нельзя, т.к. следующий был только в пять часов вечера. Мы быстро
                            собрались, оставили ключи в дверях, и пошли на главную площадь, которая
                            находилась рядом с единственной дорогой. Автобус, ехавший из Иданья-а-Новы,
                            сначала добрался до конечной в историческом центре Монсанту, а затем
                            вернулся на площадь, где его ждали не только мы, но и множество школьников,
                            отправляющихся утром на уроки в Иданья-а-Нову.</p>

                        <p>Билеты у нас уже были куплены, поэтому мы спокойно добрались до автовокзала в
                            Иданье, а там пересели на автобус уже до Каштелу-Бранку.</p>

                        <p>В Каштелу-Бранку мы должны были сесть на автобус до Порту, но до его
                            оправления у нас было пару часов, и мы решили немного прогуляться по
                            городу.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img src={i057}
                                                           className="img-fluid travel__img" loading="lazy" alt="Каштелу-Бранку"/>
                    </div>
                    <div className="col-md-6 pl-md-1"><img src={i058}
                                                           className="img-fluid travel__img" loading="lazy" alt="Каштелу-Бранку"/>
                    </div>
                </div>
            </div>


            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Сперва, мы отправились в горку, на которой возвышались остатки старых
                            замковых стен. Здесь открывается вид на город, про который можно
                            сказать, что он тихий и старый, но не ветхий. Он был основан в XIII
                            веке, сейчас в нём живут в основном люди в возрасте, молодёжи на улицах
                            не много. Как чётко отмечено на сайте управления по туризму Португалии:
                            Каштелу-Бранку это город соразмерный человеку. Весьма точное
                            определение, тут нет высоких зданий и широких дорог, тут удобно и
                            уютно. </p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img src={i059}
                                                           className="img-fluid travel__img" loading="lazy"
                                                           alt="Каштелу-Бранку"/></div>
                    <div className="col-md-6 pl-md-1"><img src={i060}
                                                           className="img-fluid travel__img" loading="lazy"
                                                           alt="Каштелу-Бранку"/></div>
                </div>
            </div>


            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Здесь есть главный собор, есть парк, в котором в марте ещё сыро и не
                            очень ухожено, но уже через месяц, пожалуй, будет намного приятней и
                            красивее. Здесь есть и места общественного питания (правда не все
                            работают в утренние часы), в том числе и паштелларии, в одной из
                            которых, мы и перекусили, что называется, на дорожку.</p>

                        <p>После ланча, движемся на автовокзал и садимся в автобус, на пути у
                            которого будет множество остановок. Водитель будет заезжать в каждый
                            более-менее крупный населённый пункт, чтобы высадить/забрать
                            пассажиров. Но спустя несколько часов мы всё же оказываемся в Порту.
                            Автостанция находится совсем недалеко от места наших будущих
                            апартаментов, которые станут для нас домом на несколько следующих
                            дней.</p>

                        <p>Высадившись, мы спешим по прямой улице прямо до нашего дома, памятуя
                            об опоздании в Лиссабоне, которое обернулось для нас томительным
                            ожиданием в неизвестности. Идти долго не приходится, около 10-15
                            минут и мы у нужного здания. Информируем арендодателя, ждём у входа
                            в подъезд. Он уже в квартире, но почему-то заставляет себя
                            подождать. Наконец, спустя минут десять, спускается нас встретить.
                            Парня зовут Жоау, Жаоу Кошта. Это приветливый, болтливый «хозяин»,
                            который с удовольствием рассказывает, нам про город, и про
                            апартаменты. Неприятность заключается в том, что после нашей тёплой
                            Лиссабонской квартиры, здесь прохладней, т.к. в Порту в принципе
                            климат прохладней, но помимо этого, Жоау прицепил два масляных
                            радиатора, на коротких цепочках в лоджии за кухней, и хочет денег,
                            чтобы их отцепить. Сходимся на том, что мы попробуем переночевать
                            без отопления, и в случае необходимости оплатим его завтра. Жоау
                            уходит в соседнюю квартиру встречать, новых гостей, кстати, тоже из
                            России.</p>

                        <p>В первый вечер, назло дерзкому португальцу, мы обогреваем лоджию, но
                            из-за расположения кухни, тепло в остальные комнаты не доходит. Спим
                            в холодине. На следующее утро решаем заплатить наглецу, за
                            возможность двигать два обогревателя по трёхкомнатной квартире за
                            собой.</p>

                        <p>С утра выясняется ещё одна неприятность, котёл способен согреть воду
                            для приёма душа только для одного человека в сорок минут. Ну,
                            максимум на двух, если работать мочалкой в спортивном режиме.</p>

                        <p>С третьей проблемой мы столкнулись, когда уже оплатили использование
                            обогревателей, договорившись с Жоау, что оставим деньги в квартире,
                            а он днём, пока мы гуляем, придёт и натопит помещение к нашему
                            приходу. Когда мы пришли действительно в прихожей чувствовался
                            определённый уют, который в прочем до далёкой кухни не доходил, но
                            это только половина беды. А вторая половина заключалась в старой
                            проводке, которая ну никак не позволяла, включать плиту или чайник,
                            параллельно с двумя радиаторами. При попытке согреть не только
                            воздух в помещении, но ещё и ужин, нам стабильно выбивало «пробки».
                            Благо автомат находился прямо в квартире, и никуда бежать было не
                            нужно. Описание нашего жилища на этом считаю можно завершить, и
                            перейти к описанию собственно города Порту.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img src={i063}
                                                           className="img-fluid travel__img" loading="lazy" alt="Порту"/>
                    </div>
                    <div className="col-md-6 pl-md-1"><img src={i064}
                                                           className="img-fluid travel__img" loading="lazy" alt="Порту"/>
                    </div>
                </div>
            </div>


            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Порту – город, безусловно, интересный. Он меньше Лиссабона, но от
                            этого концентрация мест атракции становится только выше. К тому
                            же он значительно более разнообразный, т.к. находится
                            непосредственно на океанском побережье. Ещё из сравнительных
                            особенностей, можно выделить, что Порту, как будто, просторнее
                            Лиссабона. Что, кстати, выражается и в транспортных
                            предпочтениях, машины здесь не сплошь малолитражки метр на метр,
                            встречаются даже внедорожники, а по дороге вдоль побережья реки
                            Дуэро, плавно переходящей в океанское побережье, богатеи
                            устраивают дефиле, на Порше, Феррари и Ламбо – тридцати,
                            сорокалетней давности, в прекрасном состоянии, здесь видимо это
                            считается особым шиком. Можно сказать, что в Порту, в отличие от
                            столицы, виден некий классовый разрыв среди населения.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12"><img src={i070}
                                                    className="img-fluid travel__img" loading="lazy" alt="Порту"/></div>
                </div>
            </div>


            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Общественный транспорт представлен автобусом, примерно такими
                            же ретро-трамваями как Лиссабоне, а также метро, которое
                            представляет из себя скорее современный трамвай, по большей
                            части передвигающейся по улицам, и редко уходящий под землю.
                            Кстати, с этим трамваем-метро всё не так просто. Веток здесь
                            немного, а те, что есть на большей части пути дублируют друг
                            друга, оплата производится по зонам, в зависимости от
                            дальности поездки и очень дорога. Так один билет, дающий
                            возможность нам проехать толи через три толи через четыре
                            зоны, нам обошёлся в 2.20 Евро.</p>

                        <p>В отделке домов здесь также часто используют «азулежу», может
                            не так часто как в лиссабонской Альфаме, но при отделке
                            церквей или других общественных мест (типа холла вокзала Шао
                            Бенту), часто можно увидеть целые пано из таких плиток,
                            изображающих какой-то исторический сюжет. </p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img
                        src={i061} className="img-fluid travel__img" loading="lazy"
                        alt="Порту"/></div>
                    <div className="col-md-6 pl-md-1"><img
                        src={i074} className="img-fluid travel__img" loading="lazy"
                        alt="Порту"/></div>
                </div>
            </div>


            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Вообще архитектура здесь самобытна и местами
                            величественна, например кафедральный собор Клеригуш в
                            барочном стиле.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img
                        src={i065} className="img-fluid travel__img" loading="lazy"
                        alt="Порту"/></div>
                    <div className="col-md-6 pl-md-1"><img
                        src={i066} className="img-fluid travel__img" loading="lazy"
                        alt="Порту"/></div>
                </div>
            </div>


            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Недалече от собора, можно найти заведение
                            изготавливающее «премиальное» Бакальяу в виде
                            пирожков. Специально обученная девушка за витриной
                            заманивает посетителей чарующим процессом
                            приготовления и упаковки небольших пирожков с рыбой,
                            и, если я не ошибаюсь, сыром.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img
                        src={i068} className="img-fluid travel__img" loading="lazy"
                        alt="Порту"/></div>
                    <div className="col-md-6 pl-md-1"><img
                        src={i067} className="img-fluid travel__img" loading="lazy"
                        alt="Порту"/></div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Одним из мест, произведших наибольшее впечатление
                            в Порту, стал маяк в месте впадения реки Дуэро в
                            океан. Во-первых, мы подходили к нему по ходу
                            течения реки, приближаясь к чему-то огромному и
                            величественному, что создавало определённый
                            психологический эффект. Во-вторых, эффект был не
                            только психологический. Огромные волны,
                            разбивающиеся о волнорезы и стены пирса, на
                            котором находился маяк, вызывали восторг не
                            только у меня, выросшего в лесостепной зоне, но
                            и у большинства туристов. Запрет на подход к
                            маяку обозначали заграждения из бетонных блоков
                            и сигнальной ленты, а также предупреждения об
                            опасности волн. Но эти запреты никого не
                            останавливали, все хотели приблизиться, а кто-то
                            даже прикоснутся к этой эпической картине,
                            которая представляла собой стихию и человека в
                            его первобытном благоговении перед ней. Картину
                            дополняли шум и запах океана, создавая состояние
                            в котором хочется оставаться как можно
                            дольше.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img
                        src={i069}
                        className="img-fluid travel__img" loading="lazy" alt="Порту"/></div>
                    <div className="col-md-6 pl-md-1"><img
                        src={i069_1}
                        className="img-fluid travel__img" loading="lazy" alt="Порту"/></div>
                </div>
            </div>

            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>В Порту существует настоящий классический
                            рынок – называется Больян. Он популярен как
                            среди горожан, так и среди туристов. Здесь
                            продаются в основном местные продукты, а
                            также сувениры. Цены здесь не завышены.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12"><img
                        src={i062}
                        className="img-fluid travel__img" loading="lazy" alt="Порту"/></div>
                </div>
            </div>


            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Есть в Порту и излюбленная туристами
                            променадная и одновременно обеденная
                            улица вдоль северного берега реки Дуэро,
                            в районе моста, носящего название
                            Понти-ди-Дон-Луиш I. Мост этот красивый
                            металлический двухуровневый. Построен
                            был в конце XIX века по проекту ученика
                            Эйфеля - Теофила Сейрига. Нижний уровень
                            в данный момент используется для
                            движения автомобилей, а верхний для
                            скоростного трамвая (он же метро).</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img
                        src={i071}
                        className="img-fluid travel__img" loading="lazy" alt="Порту"/></div>
                    <div className="col-md-6 pl-md-1"><img
                        src={i072}
                        className="img-fluid travel__img" loading="lazy" alt="Порту"/></div>
                </div>
            </div>

            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Поесть на этой улице у нас не
                            получилось. Очень многолюдно, и
                            ждать пришлось бы очень долго.
                            Поэтому мы перешли на южный берег
                            реки и отобедали здесь. Местность
                            тут более спокойная, и не столь
                            туристическая.</p>

                        <p>Пообедав, мы решили съездить на
                            стадион Драгао, являющийся домашним
                            стадионом ФК Порту. Далековато, да и
                            учитывая не самую простую систему
                            общественного транспорта в городе,
                            решили воспользоваться Uber, благо
                            на набережной был общедоступный
                            Wi-Fi. Через пару минут к нам
                            подъехал беленький Seat, с женщиной
                            за рулём, и мы доехали до места с
                            комфортом. Правда цена оказалась
                            несколько дороже, чем изначально
                            рассчитала система. Ну да ладно.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12"><img
                        src={i073}
                        className="img-fluid travel__img" loading="lazy" alt="Порту"/></div>
                </div>
            </div>


            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>В стадионе располагается музей
                            клуба, куда мы, к сожалению, не
                            пошли, а также Porto Official
                            Store, где мы прикупили
                            сувениров.</p>

                        <p>От стадиона до нашего дома
                            оказалось совсем недалеко, и мы
                            с удовольствием прогулялись по
                            вечернему городу.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 pr-md-1"><img
                        src={i076}
                        className="img-fluid travel__img" loading="lazy" alt="Порту"/>
                    </div>
                    <div className="col-md-6 pl-md-1"><img
                        src={i075}
                        className="img-fluid travel__img" loading="lazy" alt="Порту"/>
                    </div>
                </div>
            </div>

            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Спустя три дня проведённых в
                            Порту, мы прощались с ним
                            солнечным утром, по дороге
                            на железнодорожную станцию
                            Компанья, куда должен был
                            подойти поезд до
                            Лиссабона.</p>

                        <p>Через, примерно, три часа мы
                            прибыли на вокзал Ориенти в
                            Лиссабоне, где сдали свои
                            вещи в камеру хранения и
                            отправились гулять, т.к.
                            вылет в Москву у нас
                            планировался только поздно
                            вечером.</p>

                        <p>Так как в Лиссабоне нам не
                            удалось осмотреть один из
                            красивейших в Португалии
                            монастырей – Жеронимуш, то
                            мы отправились к нему. Благо
                            погода, на этот раз,
                            располагала к
                            прогулкам. </p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12"><img
                        src={i077}
                        className="img-fluid travel__img" loading="lazy"
                        alt="Порту"/></div>
                </div>
            </div>


            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>В монастыре, помимо двух
                            королей, покоится Васко
                            да Гама. Именно здесь,
                            по легенде, он и его
                            команда провели ночь
                            перед отплытием в
                            Индию. </p>

                        <p>В окрестностях монастыря
                            множество красивых мест.
                            Прежде всего, это
                            набережная, на которой
                            расположен памятник
                            первооткрывателям, и
                            средневековый форт
                            Торри-ди-Белен.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div
                        className="col-md-6 pr-md-1">
                        <img
                            src={i078}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Лиссабон"/></div>
                    <div
                        className="col-md-6 pl-md-1">
                        <img
                            src={i079}
                            className="img-fluid travel__img" loading="lazy"
                            alt="Лиссабон"/></div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>После неспешной прогулки и обеда, мы плавно направились обратно у Ориенти, где в ТРК напротив совершили последние покупки, а затем на метро отправились в аэропорт.</p>

                        <p>Таким было знакомство с далёкой страной, на окраине материка, с богатым прошлым и по-своему очаровательным настоящим. Adeus Portugal!</p>

                        <p>Ну, и напоследок,
                            традиционное видео
                            из поездки:</p>
                    </div>
                </div>
            </div>

        <div className="container">
            <div className="col-md-12">
                <div className="row">
                    <p><br/></p>
                    <div className="travel__iframeWrap">
                        <iframe
                            className="travel__iframe"
                            src="https://player.vimeo.com/video/263618260?h=7a3c8e0b6f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                            frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen title="Португалия 2018"></iframe>
                    </div>
                </div>
            </div>
        </div>


        </>


    );
};

export default Portugal;
import React from 'react';
import varna_01 from '../../../images/bulgaromania/varna_01.jpg';
import varna_02 from '../../../images/bulgaromania/varna_02.jpg';
import varna_03 from '../../../images/bulgaromania/varna_03.jpg';
import varna_04 from '../../../images/bulgaromania/varna_04.jpg';
import varna_05 from '../../../images/bulgaromania/varna_05.jpg';
import varna_06 from '../../../images/bulgaromania/varna_06.jpg';
import varna_07 from '../../../images/bulgaromania/varna_07.jpg';
import airport_varna from '../../../images/bulgaromania/airport_varna.jpg';
import aston640x480 from '../../../images/bulgaromania/aston640x480.mp4';
import backinbulgaria from '../../../images/bulgaromania/backinbulgaria.jpg';
import bran_01 from '../../../images/bulgaromania/bran_01.jpg';
import brasov_01 from '../../../images/bulgaromania/brasov_01.jpg';
import brasov_02 from '../../../images/bulgaromania/brasov_02.jpg';
import brasov_03 from '../../../images/bulgaromania/brasov_03.jpg';
import brasov_04 from '../../../images/bulgaromania/brasov_04.jpg';
import brasov_05 from '../../../images/bulgaromania/brasov_05.jpg';
import brasov_06 from '../../../images/bulgaromania/brasov_06.jpg';
import brasov_07 from '../../../images/bulgaromania/brasov_07.jpg';
import brasov_08 from '../../../images/bulgaromania/brasov_08.jpg';
import bucharest_01 from '../../../images/bulgaromania/bucharest_01.jpg';
import bucharest_02 from '../../../images/bulgaromania/bucharest_02.jpg';
import bucharest_03 from '../../../images/bulgaromania/bucharest_03.jpg';
import bucharest_04 from '../../../images/bulgaromania/bucharest_04.jpg';
import bucharest_05 from '../../../images/bulgaromania/bucharest_05.jpg';
import bucharest_06 from '../../../images/bulgaromania/bucharest_06.jpg';
import castelul_01 from '../../../images/bulgaromania/castelul_01.jpg';
import castelul_02 from '../../../images/bulgaromania/castelul_02.jpg';
import mordor_carp from '../../../images/bulgaromania/mordor_carp.jpg';
import peles_01 from '../../../images/bulgaromania/peles_01.jpg';
import piteshti_01 from '../../../images/bulgaromania/piteshti_01.jpg';
import piteshti_02 from '../../../images/bulgaromania/piteshti_02.jpg';
import retro_01 from '../../../images/bulgaromania/retro_01.jpg';
import retro_02 from '../../../images/bulgaromania/retro_02.jpg';
import retro_03 from '../../../images/bulgaromania/retro_03.jpg';
import retro_04 from '../../../images/bulgaromania/retro_04.jpg';
import rimetea_01 from '../../../images/bulgaromania/rimetea_01.jpg';
import rimetea_02 from '../../../images/bulgaromania/rimetea_02.jpg';
import shumen_01 from '../../../images/bulgaromania/shumen_01.jpg';
import sibiu_01 from '../../../images/bulgaromania/sibiu_01.jpg';
import sibiu_02 from '../../../images/bulgaromania/sibiu_02.jpg';
import sibiu_03 from '../../../images/bulgaromania/sibiu_03.jpg';
import sibiu_04 from '../../../images/bulgaromania/sibiu_04.jpg';
import sibiu_05 from '../../../images/bulgaromania/sibiu_05.jpg';
import sighishoara_00 from '../../../images/bulgaromania/sighishoara_00.jpg';
import sighishoara_01 from '../../../images/bulgaromania/sighishoara_01.jpg';
import sighishoara_02 from '../../../images/bulgaromania/sighishoara_02.jpg';
import sighishoara_03 from '../../../images/bulgaromania/sighishoara_03.jpg';
import sighishoara_04 from '../../../images/bulgaromania/sighishoara_04.jpg';
import sighishoara_05 from '../../../images/bulgaromania/sighishoara_05.jpg';
import sighishoara_06 from '../../../images/bulgaromania/sighishoara_06.jpg';
import trans_f_01 from '../../../images/bulgaromania/trans_f_01.jpg';
import trans_f_02 from '../../../images/bulgaromania/trans_f_02.jpg';
import trans_f_03 from '../../../images/bulgaromania/trans_f_03.jpg';
import trans_f_04 from '../../../images/bulgaromania/trans_f_04.jpg';
import turda_01 from '../../../images/bulgaromania/turda_01.jpg';
import turda_02 from '../../../images/bulgaromania/turda_02.jpg';
import turda_03 from '../../../images/bulgaromania/turda_03.jpg';
import turda_04 from '../../../images/bulgaromania/turda_04.jpg';
import turda_05 from '../../../images/bulgaromania/turda_05.jpg';
import turda_06 from '../../../images/bulgaromania/turda_06.jpg';
import turda_07 from '../../../images/bulgaromania/turda_07.jpg';
import zp_01 from '../../../images/bulgaromania/zp_01.jpg';


const Bulgaromania = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Сентябрь 2018. И мы с семьёй оправляемся в отпуск. План такой: летим в Москву, а от туда в
                            болгарский курортный городок - Варна. Пару дней там акклиматизируемся, снимаем тачку и едем
                            в Румынию. В загадочную и столь богатую на достопримечательности область Трансильвания.</p>

                        <p>Вся логистика, как обычно начиналась с покупки билетов до места и получение визы. Что
                            Болгария, что Румыния - страны ЕС, но ещё не входя в шенгенскую зону. Приятный момент в том,
                            что с Болгарской визой можно ненадолго заехать в Румынию, и некоторые другие сопредельные
                            страны, такие как: Сербия, Черногорие и даже Хорватия. Поэтому мы едем в Уральскую столицу
                            за Болгарской визой, которая, к слову, стоит даже дороже шенгенской. В итоге нам с женой
                            одобряют визу аж на целый год, а ребёнку на полгода.</p>

                        <p>Подходит 6 сентября, и мы летим в Москву. А затем регистрируемся на рейс в Варну и летим
                            туда. Летим на S7. И тут хочется сказать пару тёплых слов от души. Несмотря на то, что
                            билеты были бюджетными, мы летели на просторном A320, с дополнительным туалетом в центре
                            салона. Нас поили, кормили, подавали прессу. А ребёнку предложили детское меню, и подарили
                            целый рюкзак с подарками. Такой подход нам очень понравился. И трёх часов полёта мы не
                            заметили.</p>

                        <p>В Болгарии время московское. Проходим таможню. Меняем немного евро на местные левы, стоимость
                            которых, примерно половина от общеевропейской валюты, и выходим на улицу. Здесь в очереди
                            выстроились автомобили местных таксистов. Самая популярная служба в Варне это такси Триумф.
                            Убера здесь нет, хотя вроде как должно работать приложение Maxim. В общем садимся мы в
                            минивэнчик Триумфа и едем до спального района Аспарухово. Как ни странно получается довольно
                            бюджетно, где-то 12 лев.</p>

                        <p>Вокруг соц. застройка, но мы возле дома построенного недавно. Номера дома не видно, и жена
                            решает спросить у местных, правильно ли нас доставили. Местной оказывается наша сибирская
                            соотечественница, которая в лучшие времена приобрела здесь жильё для летнего отдыха на море,
                            а теперь из-за курса жалуется на дорогивизну. Ждём хозяина нашей съёмной квартиры -
                            Джулиана, коротая время за разговором. Наша первая знакомая немного вводит нас в курс дела и
                            знакомит с обстановкой.</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6"><img src={varna_01} className="img-fluid travel__img" loading="lazy"
                                                   alt="Пляж Аспарухово. Варна."/></div>
                    <div className="col-md-6">
                        <p>Прощаемся с соотечественницей, и почти сразу подходит Джулиан, он держит в руках тарелку
                            инжира и вручает её нам. Показывает апартаменты и прощается. Мы же немного отдохнув
                            отправляемся на ближайший пляж. Идти до него не долго, дорога идёт мимо парка. Общественный
                            пляж оказывается пустынным, все зонтики сложены, лежаки перевёрнуты. В небе переменная
                            облачность, но не холодно. Градуса 23-24. Вода же теплее, пляж песочный, поэтому с
                            удовольствием купаемся. Вскоре становится пасмурно и начинается дождь, который, впрочем,
                            быстро прекращается, и мы идём к дому.</p>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <p>В Болгарии письменный язык очень понятен русскому глазу. Мало того, что корни, во многм
                            те же, да ещё и для начертания используется кириллица. Когда болгары говорят, становится
                            не очень понятно, а иногда не понятно совсем. Скорее всего, виной тому специфический
                            балканский акцент, ну и понятно, что живой язык претерпел сильные изменения и отдалился
                            от славянской речи. Хотя русский язык понятен всем людям старшего поколения, молодые же
                            могут вас не понять, даже не смотря на схожесть языков. В одном заведении, нам таки
                            пришлось перейти с официантом на английский.</p>
                    </div>
                    <div className="col-md-6"><img src={varna_02} className="img-fluid travel__img" loading="lazy"
                                                   alt="Легкости перевода"/></div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>На&nbsp;следующее утро, по&nbsp;совету нашей вчерашней новой знакомой, едем
                            на&nbsp;пляж Галата. Чтобы добраться туда нужно попасть в&nbsp;частный сектор
                            окраине города. Туда от&nbsp;ближайшей к&nbsp;нам остановки &laquo;Народни
                            будители&raquo; едет 17&nbsp;автобус. Вообще, транспорт в&nbsp;Варне достаточно
                            современный. Присутсвуют автобусы и&nbsp;троллейбусы. Все они оборудованы
                            электронными терминалами для покупки билетов, как я&nbsp;понял, существует даже
                            какое-то приложение где можно оплатить поездку онлайн, но&nbsp;при этом на&nbsp;всех
                            маршрутах в&nbsp;салоне зачем-то присутсвуют кондуктора. Ходит транспорт достаточно
                            точно по&nbsp;расписанию, хотя интервал на&nbsp;некоторых маршрутах достаточно
                            длителен. При этом популярные электронные карты, в&nbsp;том числе гугл карты,
                            на&nbsp;момент нашей поездки вообще ничего не&nbsp;знали об&nbsp;общественном
                            транспорте Варны, поэтому пришлось строить маршруты и&nbsp;искать информацию
                            в&nbsp;другом месте. Нас неплохо выручал сайт wikiroutes.info</p>

                        <p>На&nbsp;самих остановках информация о&nbsp;прибытии транспорта есть
                            не&nbsp;на&nbsp;всех остановках, в&nbsp;Аспарухово так вообще не&nbsp;было даже
                            остановочных комплексов. Только знак остановки у&nbsp;обочины. По&nbsp;пути
                            в&nbsp;Галату автобус взбирается на&nbsp;холм, чтоб попасть на&nbsp;пляж нужно выйти
                            на&nbsp;предпоследней остановке &laquo;Галата Център&raquo;. Далее мимо мемориала
                            генералу Андранику (наши в&nbsp;городе), через супермаркет местной сети CBA,
                            до&nbsp;юговосточной окраины посёлка. Здесь начинаются ступени к&nbsp;пляжу. Около
                            пятиста ступеней вниз, в&nbsp;тени окружающих тропу деревьев
                            и&nbsp;мы&nbsp;на&nbsp;месте.</p>
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6"><img src={varna_03}
                                                   className="img-fluid travel__img" loading="lazy" alt="Пляж Галата"/>
                    </div>
                    <div className="col-md-6">
                        <p>Пляж очень хороший. Не оборудованный, что несомненно плюс. Песок, практически полное
                            отсутсвие камней на входе. Не слишком людно, что соответсвенно положительно
                            сказывается на качестве воды. Кстати, в советское время этот пляж считался
                            нудистским. И до сих пор одного-двух престарелых последователей данной культуры
                            можно встретить в левой оконечности пляжа, загарающих и купающихся в чём мать
                            родила.</p>
                        <p>Подьем наверх довольно энергозатратен, однако, сюда ходят и довольно пожилые
                            пляжники.</p>
                        <p>После пляжа едем домой немного перекусить, чтобы вечером отправиться на осмотр центра
                            Варны.</p>
                        <p>До центра можно добраться практически на любом маршруте, в зависимости от того куда
                            нужно конкретно попасть. Центр, правда, не большой, поэтому, главное проехать
                            Аспарухов мост, а дальше можно дойти и пешком. Самые популяные маршруты автобусов из
                            Аспарухово, это 12, 17, 17а.</p>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <p>Пожалуй, главное красное пятно на туристической карте города, это стоящий прямо в
                            центре Успенский собор болгарской православной церкви. Собор был построен в
                            конце XIX века, как символ освобождения от османского владычества. Собор красив
                            и величественен, и если четсно, мы не ожидали увидеть здесь столь внушительную
                            постройку.</p>

                        <p>И вообще, Варна нас приятно удивила. Мы ожидали увидеть небольшой курортный и
                            портовый город без особого упора на всей остальной городской инфраструктуре. Тем
                            более, что Болгария имеет репутацию беднейшей страны ЕС. Однако, здесь есть
                            неплохой центр, местами с исторической застройкой, есть большая пешеходная зона
                            в десяток кварталов, которая переходит в огромный приморский парк, с большим
                            потенциалом развития. В центре, на многих улицах организованы велодорожки,
                            удобные остановочные комплексы, транспорт, который ходит до поздна и по
                            расписанию. И город продолжает развиваться.</p>
                    </div>

                    <div className="col-md-6"><img src={varna_04}
                                                   className="img-fluid travel__img" loading="lazy"
                                                   alt="Успенский собор"/></div>
                </div>
            </div>

            <br/>

            <div className="container-fluid">
                <div className="row">

                    <div className="col-md-8"><img src={varna_05}
                                                   className="img-fluid travel__img" loading="lazy" alt="Варна"/></div>

                    <div className="col-md-4">
                        <p>Собственно, высадившись возле Успенского собора мы и пошли в сторону
                            побережья, чтобы осмотреть главные места притяжения как туристов, так и
                            местных жителей. Т.к. в парке множесто людей гуляют с детьми, иногда
                            проводятся какие-то мероприятия. А возле пляжной полосы, протянулись
                            традиционные ресторанчики с видом на море.</p>
                    </div>


                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">

                    <div className="col-md-12">
                        <p>На следующий день всё повторилось. Сначала мы едем в Галату на пляж. А
                            после обеда в центр. В этот раз посещяем армянскую церьковь в Варне,
                            гуляем по парку, выходим на набережную. Затем на автобусе до дома. На
                            следующий день нам предстоит большая дорога.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <img src={varna_06} className="img-fluid travel__img" loading="lazy"
                             alt="Варна"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>На следующее утро, я еду в центр за машиной, которую предварительно
                            забронировал. Прокатный авто решил брать у местной фирмы Top Rent a
                            car, т.к. условия у них были получше чем у международных фирм, парк
                            машин был велик, и присутсвовал офис в городе. Автомобиль брал самый
                            дешёвый, из допов только бустер для ребёнка и 50 евро за выезд за
                            границу Болгарии.</p>

                        <p>В общем, досталось мне красная Фиеста, на которой я и отправился за
                            семьей в Аспарухово. После пересечения моста, ошибся с одним
                            поворотом, и тут же очутился на автостраде уносившей вон из города.
                            Разворотов, как водится никаких не было, пришлось ехать километров 5
                            до первого поворота налево, и заезжать в город через уже знакомую
                            Галату. А поскольку половина Аспарухово была перекрыта из-за
                            дорожных работ, пришлось ещё минут 20 повертеться по дворам,
                            добираясь до места.</p>

                        <p>Загрузив машину вещами, мы, наконец, выселились из квартиры и начали
                            свой путь. Ещё немного повертевшись по кварталу, мы таки вышли, на
                            мост, который сегодня я уже пересекал в третий раз, а затем на выезд
                            из города в северном направлении. Наш маршрут лежал на границу с
                            Румынией за городом Русе.</p>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <p>По дороге до Румынии мы цепляем глазами самый краешек Балкан.
                            Трасса же ведёт, в основном, через поля и невысокие холмы.
                            Качество дорог - очень не дурственное, разметка, карманы для
                            остановок. Качество её традиционно начинает падать с
                            приближением границы.</p>
                    </div>

                    <div className="col-md-9"><img src={varna_07}
                                                   className="img-fluid travel__img" loading="lazy" alt="Болгария"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Проезжаем Русе. На окраине замечем будку с виньетками и
                            обменником. Покупаем виньетку. Минимальный срок действия - 7
                            дней. Мы как раз планируем быть столько. Совсем недорого,
                            по-моему меньше тысячи рублей на наши деньги. Виньетка
                            электронная, машина просто вносится в базу и всё.</p>

                        <p>
                            Дальше Болгарский таможенный пункт. В нашей очереди автобус
                            тормозит процесс проезда. Но минут через 15 доходит наш
                            черёд. Отдаю паспорта всех членов семьи. Печати проставлены
                            и мы движемся дальше в нейтральную зону. По мосту пересекаем
                            главную европейскую реку Дунай, и подъезжаем к Румынскому
                            посту. Здесь русский уже никто не понимает. Хуже то, что и
                            английский не понимают тоже. Однако, границу проходим
                            довольно быстро, но приходится оплатить какую-то пошлину в
                            размере, тоже что-то около десяти евро.
                        </p>

                        <p>На этом пограничный переход окончен. Мы в Валахии!</p>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Румынская земля встречает нас узенькими дорогами плохого качества. А пейзаж становится совсем
                            унылым. Вокруг безликие степи, иногда попадающиеся по пути небогатые деревни, хотя, иногда,
                            с национальным калоритом.</p>

                        <p>Постепенно дороги становятся чуть лучшего качества, ближе к Бухаресту даже выехали на
                            автостстраду. Затем заехали в сам город, о чем почему-то не говорила ни одна табличка.</p>

                        <p>Дороги в Бухаресте довольно широкие, а вот движение кошмарное. Не то, чтобы водители ездят
                            как хотят, такое впечатление, что они тут совсем не знают как нужно ездить. В таких
                            условиях, всеми традиционно подключается "четвёртая педаль" клаксона, что и наблюдалось в
                            Румынской столице. Заезд на парковку задним ходом прямо со второй полосы проезжей части,
                            постоянное выдавливание с полосы, поджимание сзади, нежелание запускать на поворотную
                            полосу, какого только хамства мы не натерпелись в первые же минуты езды по Бухаресту. И это
                            при том, что нас явно можно было выделить из потока как не местных - болгарские номера,
                            наклейки автопроката, но нет, никакой тебе снисходительности и понимания. Реально, движение
                            здесь гораздо хуже чем в Ереване. Что это, нелюбовь к болгарам, или вообще к туристам?
                            Пробираясь к месту нашего постоя, мы задавались только такими вопросами.</p>

                        <p>Добраться до нашей гостиницы тоже было не так-то просто. Разные карты путались в показаниях
                            на счёт адреса её местонахождения. А обозначение на местности отеля тоже было не лучшим, и
                            указатель несвоевременным и плохо читаемым. Нам с огромным трудом удалось найти место, где
                            просто можно было встать и осмотреться. При том, что был ещё разгар дня, и это был спальный
                            район, встать было попросту негде, всё было заставлено машинами, как на улице так и во
                            дворах. Повертевшись мы, всё таки, нашли небольшой угол и остановились. Поскольку на улице
                            где мы находились было организовано одностороннее движение, мне пришлось идти на разведку
                            пешком. Вернувшись к последнему повороту, я, всё таки, отыскал нужное место, обозначил его
                            точкой на карте и отправился обратно к машине. Затем мы, игнорируя навигатор, который не
                            знал об одностороннем движении, обогнули квартал и, наконец, подъехали к нужному зданию. На
                            наше счастье, здесь было предусмотрено несколько парковочных мест для посетителей и мы с
                            радостью заняли одно из них.</p>

                        <p>Войдя внутрь здания, оказалось, что здесь попросту не ресепшена, и вообще нет никакого
                            админимтратора. Единственный сотрудник которого мы встретили - была уборщица, но с ней мы не
                            нашли общего языка. Единственное, что мы поняли, что нужно подождать. Девушка же пошла
                            кому-то названивать, и спустя пятнадцать минут самолично вручила нам ключ.</p>

                        <p>После небольшого отдыха и перекуса в номере, мы отправились покорять Бухерест.</p>

                        <p>Чтобы добраться до центра, нам нужно было сначала дойти до метро, а сделать это удобнее всего
                            было на трамвае. Не найдя сходу обменник, мы воспользовались банкоматом и вперед на
                            остановку. Но как оказалось купить билет на трамвай ни на остановке, ни рядом с ней, ни даже
                            в самом трамвае было нельзя. Ни тебе кондуктора, ни тебе аппарата с билетиками наподобии
                            варненского здесь не было. Пришлось ехать несколько остановок зайцами.</p>

                        <p>Если говорить о трамваях, то они здесь довольно усталые, но это не какие-то исторические
                            ценности наподобии лиссабоских вагонов, обычные непримечательные трамваи времён социализма.
                            Правда ходят они часто и до поздна.</p>

                        <p>Добравшись до метро, спускаемся и покупаем билеты. Здесь всё стандартно касса у турникета.
                            Купить можно минимум две поездки. Метро здесь отличное, разветвлённое, чистое, и поезда
                            новые и удобные, вот они как-раз похожи на те, что мы видели в Лиссабоне.</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4">
                        <p>Высадившись в центре, мы вышли на свет и осмотрелись. Первое впечатление, что мы в слегка
                            запущеном Будапеште. Прекасные величественные здания и зачастую рядом с ними обшарпанное,
                            неказистое жильё низкого качества. Помимо наличия в центре подобной мешанины, отношение к
                            самим историческим зданиям подчас вызывает реминисценцию российской глубинки: обвалившийся
                            фасад, на котором красуются кондиционеры и тарелки спутниковых антенн.</p>
                    </div>

                    <div className="col-md-8">
                        <img src={bucharest_01} className="img-fluid travel__img" loading="lazy" alt="Бухарест"/>
                    </div>
                </div>
            </div>
            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>В целом, центр Бухареста всё же небезинтересен. И оказавшемуся здесь впервые туристу,
                            безусловно, можно будет найти что посмотреть.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <img src={bucharest_02} className="img-fluid travel__img" loading="lazy" alt="Бухарест"/>
                    </div>
                </div>
            </div>

            <br/>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Главным же местом интереса в Бухаресте, бесспорно является Дворец Парламента,
                            построенный при Чаушеску. Конечно, он привлекает не своей архитектурой, а
                            колоссальными размерами. 270х240 метров стороны здания (т.е. периметр более
                            километра), 86 метров высота, 92 метра глубина. На его строительство только мрамора
                            ушло 1 млн. кубометров. Если прибавить к этому хотя бы вес металоконструкций и
                            бронзы для декора (700 тыс. тонн), а также дерева для паркета и стеновых панелей
                            (ушло 200 тыс. кубометров), масса здания уже превзойдёт массу пирамиды Хеопса. Во
                            дворце 1100 комнат, и оно считается самым тяжёлым административным зданием в мире.
                            (Ниже фото Парламента с его меньшей стороны)</p>
                        <p>Кстати, рядом с парламентом находится сквер, в котором расположен детский городок.
                            Его размеры, конечно, меньше дворцовых, но тоже впечатляют. На такой площадке можно
                            запросто затеряться детям любого возраста ;-)</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <img src={bucharest_03} className="img-fluid travel__img" loading="lazy"
                             alt="Дворец Парламента Румынии"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <p>От Дворца Парламента идёт улица, где на разделительной полосе проезжей части в
                            ряд установлены небольшие фонтаны, которые в тёмное время суток ещё и
                            подсвечиваются. По этой улице мы шли в сторону ближайшей станции метро. Но до
                            возвращения домой, нужно было ещё обязательно поужинать. И мы нашли ресторан в
                            историческом центре, с названием, что-то вроде постоялый дом Манука (Restaurant
                            Hanu' lui Manuc). Заведение это тоже имело какую-то историю и было очень
                            многолюдно. Правда и мест для размещения здесь было валом. Трапезничающих
                            размещали как в самом здании, так и на верандах, кроме того, здание своими
                            стенами образовывало двор, в котором были установлены столы, за которыми также
                            располагались гости.</p>
                        <p>Что касается кормёжки, то она здесь, как впрочем и везде в Румынии, довольно
                            простая и без изысков. Местами не дурно приготовлена, но особых восторгов не
                            вызывает.</p>
                    </div>

                    <div className="col-md-6">
                        <img src={bucharest_04} alt="Hanu' lui Manuc"
                             className="img-fluid travel__img" loading="lazy"/>
                    </div>

                </div>
            </div>

            <br/>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>После ужина, идём на метро, а затем на трамвай, который несмотря на время
                            (около одиннадцати вечера), прекрасно ходил, а мы за него опять не
                            заплатили. Кстати, несмотря на неплохую работу транспорта в Бухаресте, люди
                            упорно его недолюбливают и предпочитают использовать личные автомобили,
                            которыми заставлено всё. Если вы думаете, что парковка на газоне или
                            тротуаре это исключительно российская беда, то вы не были в Бухаресте.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <img src={bucharest_05} className="img-fluid travel__img" loading="lazy"
                             alt="парковки в Бухаресте"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Вообще с движением, как я уже писал, здесь творится что-то неладное.
                            Однажды, пришлось наблюдать довольно странную вещь, при работающих
                            светофорах, на нескольких перекрёстках подряд, стояли регулировщики и
                            полностью дублируя сигналы светофоров, буквально проталкивали
                            транспортные потоки, при этом работали они больше голосом и мимикой, чем
                            жезлом. Короче, если вы случайно оказались за рулем в городе Бухаресте -
                            будьте осторожны!</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>На следующее утро, благополучно выселевшись из отеля, мы отправились
                            прямиком в центр, где оставив автомобиль на платной стоянке, ещё немного
                            побродили по городу. Прежде всего посетили место нашего постоянного
                            фетиша - армянскую церковь. Этот автомобиль на церковном дворе, видимо
                            кого-то из работников. Обратите внимание на номер. С таким номером всем
                            армянам сразу станет ясно, что едет соотечественник.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <img src={bucharest_06} className="img-fluid travel__img" loading="lazy"
                             alt="армянская церковь в Бухаресте"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>После посещения церкви, мы ещё с часок поглазели на
                            местное <del>безобразие</del> великолепие, и потихоньку покатились
                            на выход из города. На выезде из которого, как и на въезде, тоже не
                            было замечено никакой таблички. Поэтому, где Бухарест заканчивался,
                            а где начинался, это точно не было понятно. Лишь проехав по прямой
                            дороге добрый полтинник мы начали более-менее ощущать, что мы где-то
                            за городом. А спустя ещё некоторое время пути на север, картина
                            начала преображаться. Перед нами виднелись карпатские горы, а за
                            ними была Трансильвания.
                        </p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Вообще, для большинства людей, не бывавших в Румынии, Трансильвания является местом
                            загадочным, окутанным тайнами. В принципе, это неудивительно, понятно откуда это берётся.
                            Но, ко всему этому ореолу добавляет свои пять рублей сама природа этих мест. Взять хотя бы
                            форму южных Карпат. Меня, как человека знакомого с творчеством <del>Стокера</del> Толкина,
                            всегда напрягала форма горной гряды, опоясывающяя Мордор. Ну, что за неестественная форма..?
                            Всегда думал я. Однако, ясли присмотреться к южным Карпатам, не беря во внимание ориентацию
                            по сторонам света, мы можем заметить нечто похожее.
                        </p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <img src={mordor_carp} alt="карты Мордора и юга Карпат" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Не знаю, вдохнавлялся ли английский писатель романом ирландского писателя, но это
                            определённо добавляло веселья в нашу дорогу. Мы ехали в натуральный Мордор, да ещё и с
                            настоящим <del>графом</del> князем Дракулой ;-) Кстати, отец Влада III Цепеша, являлся
                            рыцарем ордена Дракона, откуда и прозвище Дракула. Что тоже даёт некотрую ассоциацию с
                            героями Толкина.
                        </p>

                        <p>Мы двигались по старому средневековому пути соединяющему Валахию и Трансильванию. Проехав
                            городок Синая, мы направились к месту нашей первой остановки - замку Пелеш. Этот замок
                            был основан первым королём объеденённого княжества Валахии и Молдавии, которого так и
                            звали Кароль I, и он был родом из Пруссии. Надо сказать, что в это самое время
                            Трансильвания была ещё в составе Австро-Венгерской империи, и новообразованной Румынии
                            (во главе с Каролем I) не принадлежала.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <img src={peles_01} alt="Замок Пелеш" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Что можно сказать о замке Пелеш. Замок этот дивной красоты и находится он в
                            живописном районе Карпат. Название замку даёт протекающая рядом речка.</p>

                        <p>По территории замка можно ходить и любоваться ей совершенно бесплатно, но если вы
                            хотите попасть внутрь, то необходимо будет присоединиться к экскурсионной
                            группе.</p>

                        <p>Следующим местом аттракции был замок Бран. Известный также как замок Дракулы.
                            Название прочно прилепилось к этому сооружению, хотя сам господарь Влад Цепеш здесь
                            бывал лишь во время своих военных походов, используя замок не как фортификационное
                            сооружение, а как ночлежку. Строительство замка было произведено местными жителями в
                            конце XIV века для защиты от турок и как опорный пункт охраны важного
                            транскарпатского торгового пути.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <img src={bran_01} alt="Замок Бран" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>К сожалению, в замок мы не попали, т.к. посетителей туда пускают только до шести
                            часов вечера. Мы же немного не успели к этому времени, и нам пришлось
                            довольствоваться его видами издалека.</p>

                        <p>После Брана мы отправились к месту нашего первого пристанища в Трансильвании - к
                            городу Брашов. Вообще-то Брашов входит в десятку крупнейших городов Румынии, но
                            нас интересовала лишь его небольшая часть, а именно исторический центр. Тем
                            более, что непосредственно в нём располагалось и место нашего ночлега. Очень
                            удобно. Значит едем туда.</p>

                        <p>Главная улица исторического центра Брашова - это узкая улица, состоящая из трёх
                            полос, где, что ожидаемо, толком и остановится не получится.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <img src={brasov_01} alt="Брашов" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>И&nbsp;тут мы&nbsp;натыкаемся на&nbsp;парковку. Понятно она платная,
                            но&nbsp;мы&nbsp;к&nbsp;этому готовы. Прайс полностью на&nbsp;румынском,
                            среди прочего видим на&nbsp;нём цифру 6. Уточняем у&nbsp;вооружённого
                            охранника: &laquo;Сикс лей фо&nbsp;уан дэй?&raquo;. Как нам кажется,
                            он&nbsp;даже это понимает с&nbsp;трудом, но&nbsp;в&nbsp;итоге выдавливает
                            из&nbsp;себя: &laquo;Йес&raquo;, и&nbsp;качает головой. Мы&nbsp;наивно
                            удивляемся дешевизне, ставим машину и&nbsp;уходим. Оплата паркомату
                            по&nbsp;выезду.</p>

                        <p>Идём искать &laquo;наш дом&raquo; по&nbsp;адресу. Оказывается это место прямо
                            на&nbsp;главной площади, прямо напротив ратуши и&nbsp;готической Чёрной
                            церкви. Здесь очень симпатично. Есть где покушать и&nbsp;прогуляться перед
                            сном. Мы&nbsp;кушаем и&nbsp;гуляем. Осмотр достопримечательностей оставляем
                            на&nbsp;завтра.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <img src={brasov_02} alt="Брашов" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>С&nbsp;утра завтракаем и&nbsp;идём на&nbsp;осмотр центра. Здесь
                            и&nbsp;Чёрная церковь и&nbsp;средневековые ворота, служившие когда-то
                            для оборонительных целей. Тут, по&nbsp;слухам, и&nbsp;самая узкая улочка
                            Европы&nbsp;&mdash; &laquo;Strada Sforii&raquo;. Правда на&nbsp;ней идёт
                            ремонт и&nbsp;туда не&nbsp;попасть. Есть также несколько пешеходных улиц
                            по&nbsp;которым просто приятно прогуляться.</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="multi-image row">
                    <div className="col-md-4">
                        <img src={brasov_03} alt="Брашов" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                    <div className="col-md-4">
                        <img src={brasov_04} alt="Брашов" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                    <div className="col-md-4">
                        <img src={brasov_05} alt="Брашов" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>В общем центр оставляет приятное впечатление. Здесь уже не Валахия,
                            это типичная Европа с саксонскими корнями. Это конечно сказывается
                            на&nbsp;популярности города у&nbsp;туристов. Если в&nbsp;Бухаресте
                            их&nbsp;почти не&nbsp;было видно, то&nbsp;здесь приезжих очень
                            много. Особенно популярны эти&nbsp;места, естественно,
                            у&nbsp;немцев.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <img src={brasov_06} alt="Брашов" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Исторический центр Брашова находится между двух холмов.
                            С&nbsp;юго-западной части находится гора Тымпа на&nbsp;которую
                            можно подняться по&nbsp;канатной дороге. Её&nbsp;высота около
                            900&nbsp;метров. С&nbsp;северо-запада находится холм попроще,
                            и&nbsp;гораздо доступней в&nbsp;пешеходном режиме. На&nbsp;нём
                            находятся несколько бывших фортификационных сооружений.
                            В&nbsp;частности, Чёрная и&nbsp;Белая башни. Мы&nbsp;были
                            на&nbsp;Белой, и&nbsp;от&nbsp;туда открывается весьма неплохой
                            вид на&nbsp;город.</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="multi-image row">
                    <div className="col-md-6">
                        <img src={brasov_07} alt="Брашов" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                    <div className="col-md-6">
                        <img src={brasov_08} alt="Брашов" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>После покорения высот, мы&nbsp;спустились по&nbsp;дороге
                            до&nbsp;здания местной школы, где было настолько многолюдно,
                            что мы&nbsp;даже потеряли нашу маму. А&nbsp;когда вновь
                            нашли, то&nbsp;пошли обедать, а&nbsp;затем ещё немного
                            побродили по&nbsp;городу.</p>
                        <p>Нагулявшись, мы&nbsp;неспешно побрели к&nbsp;автостоянке, где
                            ночевал наш автомобиль. Здесь нас ожидал пренеприятнейший
                            сюрприз. Подъехав к&nbsp;паркомату, мы&nbsp;засунули
                            в&nbsp;него парковочную карту, и&nbsp;тот показал нам совсем
                            не&nbsp;ту&nbsp;сумму на&nbsp;которую мы&nbsp;расчитывали.
                            Сумма за&nbsp;парковку превышала ожидаемую на&nbsp;два
                            порядка, и&nbsp;составляла что-то около 110&nbsp;лей.
                            Оказалось, что сумма была указана не&nbsp;за&nbsp;день,
                            а&nbsp;за&nbsp;час, а&nbsp;вчерашний охранник
                            не&nbsp;понимал по&nbsp;английски вообще ничего.
                            В&nbsp;итоге, парковка машины в&nbsp;центре Брашова обошлась
                            нам дороже, чем наше собственное проживание. Обидно, да!</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Мы&nbsp;двигались к&nbsp;месту нашей следующей остановки.
                            Родине князя Цепеша Дракулы&nbsp;&mdash; городу Сигишоара.
                            Ехать было недалеко, всего чуть более ста километров.
                            А&nbsp;местность тут очень живописная, поэтому дорога была
                            только в&nbsp;радость.</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <img src={sighishoara_00}
                             alt="Дорога на Сигишоару" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Ранним вечером&nbsp;&mdash; около шести часов
                            мы&nbsp;были на&nbsp;месте. И&nbsp;снова наше стойбище
                            находилось в&nbsp;старинном доме в&nbsp;старом центре
                            с&nbsp;видом на&nbsp;часовую башню.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col">
                        <img src={sighishoara_01}
                             alt="Сигишоара" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>На&nbsp;этот раз припарковались прямо на&nbsp;улице.
                            Парковка после 17-00 бесплатная и&nbsp;паркоматы
                            не&nbsp;работают. Поэтому было решено просто
                            оставить машину на&nbsp;ночь,
                            а&nbsp;на&nbsp;следующее утро заплатить
                            за&nbsp;сутки, так как нам нужно было ещё
                            осматривать город. Благо на&nbsp;паркомате довольно
                            доступно было написано, что это недорого.
                            На&nbsp;этот раз&nbsp;&mdash; правда недорого!</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Отдохнув немного в&nbsp;номере с&nbsp;дороги,
                            мы&nbsp;пошли немного прогуляться по&nbsp;самым
                            ближайшим окрестностям. После заката
                            мы&nbsp;насладились загадочным полумраком,
                            а&nbsp;на&nbsp;утро нас ждал не&nbsp;менее
                            загадочный туман, столь гармонирующий со&nbsp;всем
                            антуражем этого места.</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="multi-image row">
                    <div className="col-md-6">
                        <img src={sighishoara_02}
                             alt="Сигишоара" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                    <div className="col-md-6">
                        <img src={sighishoara_03}
                             alt="Сигишоара" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>После завтрака туман рассеялся,
                            и&nbsp;мы&nbsp;пошли на&nbsp;дальнейший осмотр
                            достопримечательностей. Нашли кое-что
                            интересное. Возле ратуши стоит скульптура
                            с&nbsp;головой Дракулы на&nbsp;постаменте,
                            неподалёку можно найти дом, где как считается
                            он&nbsp;вырос.</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <img src={sighishoara_04}
                             alt="Сигишоара" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Тут и&nbsp;несколько церквей, крупнейшая
                            из&nbsp;которых&nbsp;&mdash; православная,
                            имени Святой Троицы. И&nbsp;вообще весь
                            исторический центр, расположенный
                            на&nbsp;холме выглядит хорошо.</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="multi-image row">
                    <div className="col-md-6">
                        <img
                            src={sighishoara_05}
                            alt="Сигишоара" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                    <div className="col-md-6">
                        <img
                            src={sighishoara_06}
                            alt="Сигишоара" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>После обеда потихоньку выдвигаемся.
                            Следующая остановка&nbsp;&mdash; самая
                            северная точка нашего маршрута город
                            Турда.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col">
                        <img
                            src={turda_01}
                            alt="Турда" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Несколько улиц в&nbsp;центре также выглядят неплохо. Однако ночевать нам здесь предстоит
                            в&nbsp;спальном районе, в&nbsp;этакой местной хрущевке. После двух предыдущих обиталищ
                            с&nbsp;главными достопримечательностями на&nbsp;расстоянии вытянутой
                            руки&nbsp;&mdash; контраст просто колоссальный! Зато паркуемся по-российски привычно:
                            во&nbsp;дворике на&nbsp;свободном месте и&nbsp;бесплатно.</p>
                        <p>Вечером таримся в&nbsp;ближайшем супермаркете и&nbsp;идём спать без затей.</p>
                        <p>Наутро в&nbsp;планах богатая программа, хотя, забегая вперёд, в&nbsp;планы придётся вносить
                            не&nbsp;одну коррективу. Но&nbsp;первый пункт предсказуем&nbsp;&mdash; едем в&nbsp;соляную
                            пещеру, что совсем рядом с&nbsp;городом.</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="multi-image row">
                    <div className="col-md-6">
                        <img
                            src={turda_02}
                            alt="Соляная пещера в Турде" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                    <div className="col-md-6">
                        <img
                            src={turda_03}
                            alt="Соляная пещера в Турде" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Salina Turda&nbsp;&mdash; это месторождение соли, добыча которой на&nbsp;этом месте
                            происходила с&nbsp;незапамятных времён, ещё до&nbsp;прихода римлян. При Габсбургах шахта
                            особенно разрослась, и&nbsp;к&nbsp;концу XIX века глубина одной из&nbsp;штолен достигла
                            108&nbsp;метров. В&nbsp;1932 году шахту закрыли из-за низкой рентабельности.
                            А&nbsp;в&nbsp;1992 году открыли для туристического посещения и&nbsp;как оздоровительный
                            комплекс (в&nbsp;качестве естественного соляного ингалятора). В&nbsp;2009&nbsp;году,
                            за&nbsp;счёт европейских денег шахту благоустроили, и&nbsp;сейчас она представляет собой
                            действительно нетривиальное зрелище, а&nbsp;также парк развлечений и&nbsp;музей истории.</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="multi-image row">
                    <div className="col-md-6">
                        <img
                            src={turda_04}
                            alt="Соляная пещера Турды" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                    <div className="col-md-6">
                        <img
                            src={turda_05}
                            alt="Соляная пещера Турды" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>


            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Внизу располагаются разные развлекательные штуки, типа пинг-понга, бильярда и&nbsp;даже
                            небольшого колеса обозрения. А&nbsp;на&nbsp;самом дне шахты есть небольшое озерцо, где можно
                            покататься на&nbsp;лодочках. Температура в&nbsp;шахте около двенадцати градусов.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>После посещения шахты мы&nbsp;по&nbsp;идее уже взяли курс на&nbsp;юг, чтобы к&nbsp;ближе
                            вечеру достигнуть Сибиу. Но&nbsp;увидели по&nbsp;дороге ущелье, к&nbsp;которому решили
                            подъехать поближе. Здесь оказался природный заповедник, где оставив небольшую плату
                            на&nbsp;входе можно прогуляться в&nbsp;ущелье вдоль горной реки. Здесь свежо, красиво
                            и&nbsp;мы&nbsp;с&nbsp;удовольствием и&nbsp;незаметно провели здесь пару часов.</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="multi-image row">
                    <div className="col-md-6">
                        <img
                            src={turda_06}
                            alt="Ущелье рядом с Турдой" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                    <div className="col-md-6">
                        <img
                            src={turda_07}
                            alt="Ущелье рядом с Турдой" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Покинув это место, мы, по&nbsp;сути, стали возвращаться, и&nbsp;теперь поступательно
                            и&nbsp;неизменно двигались только в&nbsp;южном направлении.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>В&nbsp;начале своего обратного пути мы&nbsp;остановили автомобиль в&nbsp;деревеньке Риметея.
                            Расположенное в&nbsp;очень живописном уезде Альба, оно также радует своеобразной
                            архитектурой строений присущих здешним местам.</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="multi-image row">
                    <div className="col-md-6">
                        <img src={rimetea_01} alt="Риметея. Уезд Альба. Румыния." className="img-fluid travel__img" loading="lazy"/>
                    </div>
                    <div className="col-md-6">
                        <img src={rimetea_02} alt="Риметея. Уезд Альба. Румыния." className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>К&nbsp;вечеру, но&nbsp;ещё засветло прибываем в&nbsp;один из&nbsp;культурных центров
                            Румынии город Сибиу. Город также является центром трансильванских саксонцев в&nbsp;чём
                            нам сразу и&nbsp;предстоит убедиться.</p>
                        <p>Мы&nbsp;едем на&nbsp;адрес нашего предполагаемого ночлега и&nbsp;заезжаем в&nbsp;некий
                            коттеджный посёлок на&nbsp;окраине города. Навигатор приводит нас к&nbsp;домику
                            с&nbsp;крупной надписью WOLF на&nbsp;белом фасаде здания. У&nbsp;входа находится машина,
                            рядом с&nbsp;ней мужчина, и&nbsp;тут&nbsp;же из&nbsp;дома выходит женщина.</p>
                        <p>Мы&nbsp;направляемся к&nbsp;женщине, и&nbsp;по-английски пытаемся уточнить у&nbsp;неё
                            правильно&nbsp;ли мы&nbsp;приехали. На&nbsp;что получаем ответ по-немецки с&nbsp;редким
                            вкраплением английских слов, общий смысл которых мы&nbsp;понимаем
                            как: &mdash;&nbsp;&laquo;Мы&nbsp;вам звонили, почему вы&nbsp;не&nbsp;брали
                            трубку?&raquo;. Звоним риелтору чей контакт был указан на&nbsp;букинге. Здесь
                            с&nbsp;английским всё лучше и&nbsp;нам объясняют, что в&nbsp;доме нет воды и&nbsp;нас
                            не&nbsp;заселят. Господа германцы предлагают нам записать пароль от&nbsp;wi-fi чтобы
                            иметь возможность забронировать себе другое жильё на&nbsp;ночь. Тётенька демонстрирует
                            мне бумажку с&nbsp;паролем, однако зачем-то считает нужным продекламировать все символы,
                            причём по-немецки. Её&nbsp;муженёк, понимая, что для нас это пытка, решает помочь
                            в&nbsp;лучшем понимании одного из&nbsp;символов, и&nbsp;после того как супруга
                            в&nbsp;конце произносит&nbsp;&mdash; &laquo;Зеро&raquo;,
                            он&nbsp;выговаривает&nbsp;&mdash; &laquo;Нолл&raquo;. Эхо войны.</p>
                        <p>Пока мы&nbsp;пытаемся законнектиться к&nbsp;точке доступа, благо сигнал которой ловиться
                            и&nbsp;на&nbsp;улице, потомки саксонцев прыгают в старенькую Ауди и&nbsp;спешно
                            ретируются.</p>
                        <p>Понимая, что ничего приличного сейчас за&nbsp;нормальные деньги всё равно
                            не&nbsp;забронируешь, мы&nbsp;выбираем просто первое попавшиеся место не&nbsp;слишком
                            далеко от&nbsp;центра и&nbsp;едем туда.</p>
                        <p>Место это оказывается в&nbsp;частном секторе. Мы&nbsp;паркуем машину на&nbsp;свободное
                            место перед чужим домом и&nbsp;идём к&nbsp;воротам. Ни&nbsp;на&nbsp;воротах,
                            ни&nbsp;на&nbsp;фасаде дома никаких опознавательных знаков нет. Ни&nbsp;вывески,
                            ни&nbsp;даже адреса. Калитка заперта.</p>
                        <p>Набираем на&nbsp;номер указанный в&nbsp;бронировании. Хозяйка говорит по-английски. Уже
                            хорошо. Говорим, что только забронировали жильё, стоим на&nbsp;месте и&nbsp;пытаемся
                            зайти внутрь. Хозяйка предлагает найти почтовый ящик на&nbsp;стене ограды. Ок. Затем
                            предлагает заглянуть внутрь и&nbsp;нащупать ключ от&nbsp;калитки. Done. После чего она
                            говорит что нужно открыть калитку, положить ключ на&nbsp;место в&nbsp;ящик, зайти
                            во&nbsp;двор и&nbsp;захлопнуть калитку. Сделано. Затем, я&nbsp;уже не&nbsp;помню как,
                            открываем входную дверь в&nbsp;дом и&nbsp;хозяйка предлагает подняться на&nbsp;второй
                            этаж и&nbsp;называет номер комнаты. Заходим в&nbsp;комнату, но&nbsp;в&nbsp;комнате
                            оказываются двое пожилых людей. Sorry. Девушка в&nbsp;трубке, говорит, что забыла про
                            них, и&nbsp;предлагает подняться на&nbsp;третий этаж в&nbsp;комнату справа. Bingo. Квест
                            пройден. Всё-таки родина Мурешана не&nbsp;балует гостей радушными приёмами.</p>
                        <p>На&nbsp;следующий день идём смотреть город. Сибиу оказался очень интересным местом
                            со&nbsp;своеобразной архитектурой. Отличительной особенностью которой является
                            присутствие на&nbsp;улицах симпатичных домиков с&nbsp;&laquo;глазками&raquo;.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <img src={sibiu_01} alt="Сибиу. Румыния." className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>А&nbsp;ещё наличием так называемого &laquo;моста Врунов&raquo;. Легенда которого
                            в&nbsp;том, что если человек, стоя на&nbsp;этом мосту соврёт,
                            то&nbsp;тот&nbsp;обвалится. Поскольку мост стоит здесь с&nbsp;1859&nbsp;года,
                            легенда сама является большим враньём.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <img src={sibiu_02} alt="Сибиу. Румыния." className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Вообще в&nbsp;Сибиу много интересного, и&nbsp;жизнь здесь кипит, причём
                            не&nbsp;только туристическая.</p>
                    </div>
                </div>
            </div>


            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="owl-carousel owl-theme">
                            <div><img src={sibiu_03} alt="Сибиу. Румыния." className="img-fluid travel__img" loading="lazy"/></div>
                            <div><img src={sibiu_04} alt="Сибиу. Румыния." className="img-fluid travel__img" loading="lazy"/></div>
                            <div><img src={sibiu_05} alt="Сибиу. Румыния." className="img-fluid travel__img" loading="lazy"/></div>
                        </div>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>К&nbsp;сожалению, у&nbsp;нас не&nbsp;было достаточного времени для подробного
                            знакомства с&nbsp;городом, хотя он&nbsp;того, безусловно, заслуживает.
                            Мы&nbsp;же отправились дальше. Следующая остановка &laquo;Глиняный замок
                            долины фей&raquo;.</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="multi-image row">
                    <div className="col-md-6">
                        <img src={castelul_01} alt="Castelul de lut" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                    <div className="col-md-6">
                        <img src={castelul_02} alt="Castelul de lut" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>На&nbsp;местном наречии это место называется &laquo;Castelul de&nbsp;Lut
                            Valea Z&acirc;nelor&raquo;. И&nbsp;служит это место сугубо
                            в&nbsp;зрелищных интересах туристов. Никакой смысловой или исторической
                            поддержки у&nbsp;этого места нет, просто симпатичное место в&nbsp;горах,
                            где можно попить чего-нибудь, расположившись рядом с&nbsp;горной речкой
                            и&nbsp;полазать по&nbsp;необычному домику. Вход на&nbsp;территорию стоит
                            что-то около пяти лей.</p>
                        <p>После посещения Замка фей, мы&nbsp;вплотную приблизились
                            к&nbsp;кульминационному моменту нашего румынского путешествия,
                            а&nbsp;именно преодолению горного шоссе Трнансфэгэраш, ведущего через
                            перевал, наивысшая точка которого находится на&nbsp;высоте
                            2034&nbsp;метра.</p>
                        <p>Дорога строилась при Чаушеску для военных целей. Теперь&nbsp;же это
                            туристический маршрут с&nbsp;видами потрясающей красоты.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <img src={trans_f_01} alt="Трнансфэгэраш" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <video autoPlay muted loop className="travel__video" loading="lazy">
                            <source
                                src={aston640x480}
                                    type="video/mp4"/>
                        </video>
                    </div>

                    <div className="col-md-9">
                        <p>Местные с&nbsp;целью простого перемещения по&nbsp;стране этой дорогой
                            не&nbsp;пользуются, в&nbsp;силу её&nbsp;сложности и&nbsp;долгого
                            прохождения. Поэтому движение по&nbsp;ней очень неспешное
                            и&nbsp;созерцательное. И&nbsp;к&nbsp;этому надо быть готовым,
                            учитывая весьма ограниченные возможности для совершения обгонов.</p>
                        <p>Во&nbsp;время нашего прохождения Транфэгэраша, на&nbsp;ней
                            присутствовали разрозненные группы туристов на&nbsp;суперкарах. Вот
                            кому было попроще в&nbsp;плане обгонов на&nbsp;трассе. При этом они
                            часто останавливались и&nbsp;фотографировали окрестности.
                            Основная&nbsp;же масса автотуристов вряд&nbsp;ли использовала
                            передачи выше третьей.</p>
                        <p>Самое высокое место на&nbsp;трассе находится недалеко от&nbsp;пика
                            Молдовяну, где рядом с&nbsp;дорогой располагается небольшое красивое
                            озеро. Это озеро Балеа, с&nbsp;зеркальной гладью воды.</p>
                        <p>С&nbsp;холма рядом с озером открывается вид на&nbsp;весь только что
                            проделанный на&nbsp;подъёме путь, и&nbsp;он&nbsp;хорош.</p>
                        <p>Мы&nbsp;также использовали это место, чтобы немного отдохнуть
                            и&nbsp;перекусить, чем Бог послал.</p>
                        <p>Это самое нагруженное место маршрута. Здесь буквально нужно ждать
                            очереди, чтобы сделать удачный кадр. Парковка в&nbsp;сезон также
                            может стать здесь проблемой.</p>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container-fluid">
                <div className="multi-image row">
                    <div className="col-md-6">
                        <img src={trans_f_02}
                             alt="Трнансфэгэраш" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                    <div className="col-md-6">
                        <img src={trans_f_03}
                             alt="Трнансфэгэраш" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Дальше начинается спуск, и&nbsp;мы&nbsp;постепенно
                            перемещаемся в&nbsp;Валахию. Почти в&nbsp;самом низу
                            остановились у&nbsp;плотины Видрару, у&nbsp;которой
                            собирается довольно живописное озеро.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <img src={trans_f_04}
                             alt="Трнансфэгэраш" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Ночевать собираемся в&nbsp;городе Питешти,
                            на&nbsp;подъезде к&nbsp;которому движение вновь
                            становиться плотным и&nbsp;нервным. Добро пожаловать
                            в&nbsp;Валахию. Благо в&nbsp;сам город нам глубоко
                            заезжать не&nbsp;нужно, наш ночлег расположен уже почти
                            за&nbsp;городом, на&nbsp;территории
                            гостинично-ресторанного комплекса, где наряду
                            с&nbsp;номерами можно поселиться в&nbsp;маленьких уютных
                            домиках. В&nbsp;одном из&nbsp;таких домиков
                            мы&nbsp;и&nbsp;заночевали, предварительно поужинав
                            на&nbsp;открытом корте с&nbsp;живой румынской
                            музыкой.</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="multi-image row">
                    <div className="col-md-6">
                        <img src={piteshti_01}
                             alt="Питешти" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                    <div className="col-md-6">
                        <img src={piteshti_02}
                             alt="Питешти" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Всё. На&nbsp;следующее утро мы&nbsp;пересекаем Дунай
                            и&nbsp;заезжаем обратно в&nbsp;Болгарию.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <img src={backinbulgaria}
                             alt="мост через Дунай" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>После прохождения всех пограничных контролей мы&nbsp;наконец оказались на&nbsp;территории
                            Болгарии, где, прежде чем съехать на&nbsp;дорогу общего пользования, нашу машину обработал
                            каким-то раствором специальный поливальный аппарат, установленный на&nbsp;выходе
                            с&nbsp;КПП.</p>
                        <p>На&nbsp;пути в&nbsp;Варну заехали в&nbsp;город Шумен посмотреть на&nbsp;остатки местной
                            крепости, первые камни которой заложили ещё фракийцы. Там немного размялись и&nbsp;отдохнули
                            от&nbsp;дороги.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <img src={shumen_01} alt="Шуменская крепость" className="img-fluid travel__img" loading="lazy"/>
                    </div>
                </div>
            </div>

            <br/>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>Ближе к&nbsp;вечеру приехали в&nbsp;Варну. На&nbsp;последний отрезок отпуска у&nbsp;нас
                                были забронированы апартаменты в&nbsp;Золотых песках, что в&nbsp;двадцати минутах езды
                                от&nbsp;самого города. Здесь туристическая зона, с&nbsp;гостиницами, ресторанами,
                                аквапарками и&nbsp;все обращаются друг к&nbsp;другу по-русски.</p>
                            <p>От&nbsp;нашего дома до&nbsp;моря было 5&nbsp;минут ходьбы, а&nbsp;погода по-прежнему
                                радовала.</p>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <img src={zp_01} alt="Золотые пески" className="img-fluid travel__img" loading="lazy"/>
                        </div>
                    </div>
                </div>

                <br/>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <p>На&nbsp;следующее утро поехали сдавать машину. Никаких проблем не&nbsp;возникло, всё
                                    отлично.</p>
                                <p>В&nbsp;оставшиеся 3&nbsp;дня в&nbsp;Варне отдыхали от&nbsp;приключений, просто гуляя
                                    по&nbsp;городу или купаясь в&nbsp;море.</p>
                                <p>Как я&nbsp;уже писал общественный транспорт в&nbsp;Варне устроен неплохо, работает
                                    допоздна и&nbsp;до&nbsp;Золотых песков можно добраться тоже на&nbsp;нём. Туда ходят
                                    сразу несколько автобусов, т.к. маршрут очень популярный среди туристов,
                                    в&nbsp;частности из&nbsp;города можно воспользоваться 9, 109, 409
                                    и&nbsp;51&nbsp;маршрутом. Правда, насколько мы&nbsp;поняли, не&nbsp;в&nbsp;сезон эти
                                    маршруты сокращают.</p>
                                <p>В&nbsp;один из&nbsp;оставшихся дней, пока жена ходила по&nbsp;магазинам,
                                    мы&nbsp;с&nbsp;сыном в&nbsp;одном из&nbsp;ТРК посетили так называемый Ретро музей.
                                    Это оказался довольно неплохой музей с&nbsp;экспонатами эпохи социализма, здесь
                                    кажется было всё от&nbsp;настоящих автомобилей до&nbsp;детских игрушек. Я&nbsp;узнал
                                    многое, что было и&nbsp;у&nbsp;меня лично и&nbsp;вообще получил большое удовольствие
                                    от&nbsp;посещения этого места. Расположен этот музей в&nbsp;&laquo;Гранд
                                    Молле&raquo; в&nbsp;Варне.</p>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div id="carouselExampleFade" className="carousel slide carousel-fade"
                                     data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img src={retro_01} alt="Ретро музей. Варна." className="img-fluid travel__img" loading="lazy"/>
                                        </div>
                                        <div className="carousel-item">
                                            <img src={retro_02} alt="Ретро музей. Варна." className="img-fluid travel__img" loading="lazy"/>
                                        </div>
                                        <div className="carousel-item">
                                            <img src={retro_03} alt="Ретро музей. Варна." className="img-fluid travel__img" loading="lazy"/>
                                        </div>
                                        <div className="carousel-item">
                                            <img src={retro_04} alt="Ретро музей. Варна." className="img-fluid travel__img" loading="lazy"/>
                                        </div>
                                    </div>
                                    <button className="carousel-control-prev" type="button"
                                            data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button"
                                            data-bs-target="#carouselExampleFade" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br/>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>В&nbsp;день вылета уезжали рано утром. Таксист рассказал о&nbsp;жизни
                                        в&nbsp;Варне, о&nbsp;сезонной зависимости города, о&nbsp;том, что многие уехали
                                        на&nbsp;заработки заграницу, но&nbsp;и&nbsp;о&nbsp;том, что город в&nbsp;целом
                                        развивается. Ругал прошлого мэра, и&nbsp;рассказал о&nbsp;варненской традиции
                                        складывать камни возле мэрии, если люди недовольны качеством её&nbsp;управления
                                        городом. Говорил, что во&nbsp;время работы прошлого мэра скопился целый курган,
                                        и&nbsp;только после этого мэр ушёл. Меня сильно удивило, что камни никто
                                        не&nbsp;убирал. Ну&nbsp;что&nbsp;ж, это неплохая традиция, работающая как чёткая
                                        обратная связь для управляющего.</p>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <img src={airport_varna} alt="Аэропорт. Варна." className="img-fluid travel__img" loading="lazy"/>
                                </div>
                            </div>
                        </div>

                        <br/>

                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>Таким было наше осеннее путешествие. Более 1500 километров по&nbsp;двум
                                            своеобразным странам. Тёплое радушие болгарского народа и&nbsp;прекрасная
                                            загадочная Трансильвания. Море, горы и&nbsp;дороги.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="container d-none d-sm-block">
                                <div className="col-12">
                                    <div className="row">
                                        <p><br/></p>
                                        <div className="travel__iframeWrap">
                                            <iframe className="travel__iframe"
                                                    src="https://www.youtube.com/embed/Jja296r6IuU?rel=0&amp;showinfo=0"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container d-block d-sm-none">
                                <div className="col-12">
                                    <div className="row">
                                        <p><br/></p>
                                        <div className="travel__iframeWrap">
                                            <iframe
                                                className="travel__iframe"
                                                src="https://www.youtube.com/embed/Jja296r6IuU?rel=0&amp;showinfo=0"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br/>


                            </>
    );
};

export default Bulgaromania;
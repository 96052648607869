import React, {useState} from 'react';
import './PostFilter.css';
import Input from "../../UI/Input/Input";

const PostFilter = ({filterQuery, setFilterQuery}) => {

    return (
        <div className="container">
         <Input
            placeholder="Поиск..."
            value={filterQuery}
            onChange={ev => setFilterQuery(ev.currentTarget.value)}
         />
        </div>
    );
};

export default PostFilter;
import React from 'react';
import averkina001 from '../../../images/averkina/001.jpg';
import averkina002 from '../../../images/averkina/002.jpg';
import averkina003 from '../../../images/averkina/003.jpg';
import averkina004 from '../../../images/averkina/004.jpg';
import averkina005 from '../../../images/averkina/005.jpg';
import averkina006 from '../../../images/averkina/006.jpg';
import averkina007 from '../../../images/averkina/007.jpg';
import averkina008 from '../../../images/averkina/008.jpg';
import averkina009 from '../../../images/averkina/009.jpg';
import averkina010 from '../../../images/averkina/010.jpg';
import averkina011 from '../../../images/averkina/011.jpg';
import averkina013 from '../../../images/averkina/013.jpg';
import averkina014 from '../../../images/averkina/014.jpg';
import averkina015 from '../../../images/averkina/015.jpg';
import averkina016 from '../../../images/averkina/016.jpg';
import averkina017 from '../../../images/averkina/017.jpg';

const Averkina = () => {
    return (
        <div className="container">
           <div className="row">
               <div className="col-12">
                   <p>
                       Август 2022. Старые друзья позвали на природу. Оказалось, что мы едем в Саткинский район Челябинской области, для того, чтобы посетить карстовую пещеру, которая именуется как Аверькиева яма (Аверкина яма). Расположена на склоне левого берега реки Ай.
                   </p>
                   <img src={averkina001} alt="Аверкина яма" className="img-fluid travel__img"/>
               </div>
           </div>
            <div className="row">
                <div className="col-md-9">
                    <p>
                        В общем ехать надо до селения под названием Старая Пристань. По идее, не доезжая до этого места нужно повернуть на лесную дорогу, по которой можно с комфортом добраться почти до самой пещеры. Но мы простых путей не ищем, и по незнанию заезжаем в саму Старую Пристань и продвигаемся в самую западную её точку, пока дорога не упирается в кусты. Переодеваемся, берём снаряжение, которое мужики приготовили для спуска.
                    </p>
                </div>
                <div className="col-md-3">
                    <img src={averkina002} alt="Аверкина яма" className="img-fluid travel__img"/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <img src={averkina003} alt="Аверкина яма" className="img-fluid travel__img"/>
                </div>
                <div className="col-md-9">
                    <p>
                        Дальше рубимся по очень плохо натоптанной тропе около километра, после чего она тоже заканчивается и нам приходится лезть по склону на ту самую дорогу, где можно было проехать на автомобиле. По ней ещё нужно пройти метров семьсот.
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-9">
                    <p>
                        По пути встречается питьевой ключ. Очень кстати, ибо жарко.
                    </p>
                </div>
                <div className="col-md-3">
                    <img src={averkina004} alt="Аверкина яма" className="img-fluid travel__img"/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <img src={averkina005} alt="Аверкина яма" className="img-fluid travel__img"/>
                </div>
                <div className="col-md-9">
                    <p>
                        Недалеко за ним от основной дороги вверх по склону уходит тропа. Поднимаемся по ней и оказываемся у искомого объекта.
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <p>
                        Про мифологию этого места говорить нет смысла, кому интересно можете почитать в интернете.
                    </p>
                    <img src={averkina006} alt="Аверкина яма" className="img-fluid travel__img"/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <p>
                        Мои спутники в подобных спусках уже опытные, я же в подобной ситуации в первый раз и не умею обращаться со снаряжением. Но почему-то есть уверенность в своих силах.
                    </p>
                    <img src={averkina007} alt="Аверкина яма" className="img-fluid travel__img"/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <p>
                        Ребята крепят снаряжение для спуска и одевают амуницию. Дырка выглядит глубокой, дна не видно даже близко.
                    </p>
                </div>
                <div className="col-md-4">
                    <img src={averkina008} alt="Аверкина яма" className="img-fluid travel__img"/>
                </div>
                <div className="col-md-4">
                    <img src={averkina009} alt="Аверкина яма" className="img-fluid travel__img"/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <img src={averkina010} alt="Аверкина яма" className="img-fluid travel__img"/>
                </div>
                <div className="col-md-9">
                    <p>
                        Как потом окажется вертикальный спуск порядка двадцати метров. Начинаем спуск по очереди. Самые опытные идут последними, самые неопытные вторыми.
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <img src={averkina011} alt="Аверкина яма" className="img-fluid travel__img"/>
                </div>
                <div className="col-md-4">
                    <img src={averkina013} alt="Аверкина яма" className="img-fluid travel__img"/>
                </div>
                <div className="col-md-4">
                    <p>
                        После середины вертикального спуска отверстие расширяется и ногами стенок уже коснуться не получится.
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <p>
                        Внизу около 5 градусов круглый год. По тогдашней погоде перепад около 25 градусов. Здесь очень влажно, ноги липнут к глинистой почве, а на камнях очень скользко.
                    </p>
                    <img src={averkina014} alt="Аверкина яма" className="img-fluid travel__img"/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <p>
                        Отправляемся на исследование залов пещеры. Общая их протяжённость около 100 метров. В зал справа от входа приходится лезть через узкий лаз. Все заляпались в <del>говне</del> грязи.
                    </p>
                    <img src={averkina015} alt="Аверкина яма" className="img-fluid travel__img"/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-9">
                    <p>
                        Начинаем подниматься. Подъём даётся очень непросто. Всё-таки, тут нужна и сноровка, и физическая выносливость. Спасибо Максиму, если бы не он, мы бы не выбрались. Он как самый молодой просто вытащил нас одной своей ногой. В итоге на спуск, осмотр пещеры и подъём из неё у нас ушло почти 3 часа. Я даже подзамёрз к концу пребывания внизу. Благо на солнышке быстро оттаял.
                    </p>
                </div>
                <div className="col-md-3">
                    <img src={averkina016} alt="Аверкина яма" className="img-fluid travel__img"/>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <p>
                        Далее идём обратно к машине, переодеваемся, пакуемся, едем на местный пляж. Окунаемся в реку, водичка неплохая. Быстро перекусываем в лесу неподалёку и в обратный путь. Интересный в целом опыт.
                    </p>
                    <img src={averkina017} alt="река Ай" className="img-fluid travel__img"/>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <p>
                        Небольшое видео по итогам:
                    </p>
                    <div className="travel__iframeWrap">
                        <iframe
                            className="travel__iframe"
                            src="https://player.vimeo.com/video/739154080?h=184e4fa242&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                            frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen title="averkina"></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Averkina;
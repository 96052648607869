import React, {useRef} from 'react';
import './TrackItem.css';


const TrackItem = ({item}) => {
    const audio = useRef();
    const progress = useRef();

    const handleClickPlay = () => {
        audio.current.play()
        const audioPlay = setInterval(function () {
            let audioTime = Math.round(audio.current.currentTime)
            let audioLength = Math.round(audio.current.duration)
            progress.current.style.width = (audioTime * 100) / audioLength + '%';
        }, 10)

    }

    const handleClickPause = () => {
        audio.current.pause()
    }

    return (
        <div className="trackItem__card">
            <div className="container">
                <div className="row gx-2 d-flex">
                    <div className="trackItem__pictureWrap col-md-1">
                        <img src={item.picture} className="trackItem__picture img-fluid"
                             alt="изображение сингла/альбома"/>
                    </div>

                    <audio ref={audio} src={item.audio}/>
                    <div className="trackItem__trackItself col-md-9">
                        <div className="trackItem__name">
                            {item.name} <span className="trackItem__year">{item.year}</span>
                        </div>
                        <div className="trackItem__progressWrap">
                            <div
                                ref={progress}
                                className="trackItem__progress"
                            />
                        </div>
                    </div>

                    <div className="trackItem__controls col-md-2">
                        <div className="trackItem__ctrlsWrap">
                            <button
                                className="trackItem__ctrlBtn"
                                onClick={handleClickPlay}
                            />
                            <button
                                className="trackItem__ctrlBtn pause"
                                onClick={handleClickPause}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrackItem;
import React from 'react';
import './ReverseBtn.css';

const ReverseBtn = ({onClick}) => {
    return (
        <div className="container reverseBtn_wrap">
            <button
                className="reverseBtn"
                onClick={onClick}
            >
                Обратный порядок
            </button>
        </div>
    );
};

export default ReverseBtn;
import React from 'react';
import './MobileMnu.css';
import {Link} from "react-router-dom";

const MobileMnu = ({mobileMnu, setMobileMnu}) => {
    const handleMnuClose = () => {
        setMobileMnu(false);
        document.body.classList.remove('lock');
    }

    return (
        <div
            onClick={handleMnuClose}
            className={mobileMnu ? "mobileMnu__window active" : "mobileMnu__window"}
        >
            <div
                onClick={ev => ev.stopPropagation()}
                className="mobileMnu__content"
            >
                <button
                    onClick={handleMnuClose}
                    className="mobileMnu__closeBtn"
                />
                <nav className="mobileMnu__nav">
                    <Link
                        onClick={handleMnuClose}
                        to="/travel"
                        className="mobileMnu__navLink"
                    >
                        Поездки
                    </Link>
                    <Link
                        onClick={handleMnuClose}
                        to="/quotes"
                        className="mobileMnu__navLink"
                    >
                        Цитаты
                    </Link>
                    <Link
                        onClick={handleMnuClose}
                        to="/music"
                        className="mobileMnu__navLink"
                    >
                        Музыка
                    </Link>
                </nav>
            </div>
        </div>
    );
};

export default MobileMnu;
import React, {useState} from 'react';
import './Music.css';
import TrackItem from "../../components/TrackItem/TrackItem";
import nineties from '../../assets/audio/90s.mp3';
import atlantis from '../../assets/audio/atlantis.mp3';
import faith from '../../assets/audio/faith.mp3';
import fatum from '../../assets/audio/fatum.mp3';
import filosofia from '../../assets/audio/filosofia.mp3';
import friend from '../../assets/audio/friend.mp3';
import gaspar from '../../assets/audio/Gaspar.mp3';
import he from '../../assets/audio/he.mp3';
import life from '../../assets/audio/life.mp3';
import mayakovsky from '../../assets/audio/mayakovsky.mp3';
import mudrie from '../../assets/audio/mudrie.mp3';
import no_time from '../../assets/audio/no_time.mp3';
import roads from '../../assets/audio/roads.mp3';
import silence from '../../assets/audio/silence.mp3';
import silver_thread from '../../assets/audio/silver_thread.mp3';
import stress from '../../assets/audio/stress.mp3';
import rethinking from '../../assets/audio/rethinking.mp3';
import loneliness from '../../assets/audio/loneliness.mp3';
import rubicon from '../../assets/audio/beyond_the_rubicon_of_love.mp3';
import lines_about_love from '../../assets/audio/lines_about_love.mp3';
import bulgaria_pic from '../../assets/img/bulgaria.jpg';
import everyone_album_pic from '../../assets/img/everyone_album.jpg';
import fatum_pic from '../../assets/img/fatum.jpg';
import mudrie_pic from '../../assets/img/mudrie.jpg';
import silence_pic from '../../assets/img/silence.jpg';
import loveAbout_pic from '../../assets/img/love_about.jpg';
import unreleased from '../../assets/img/unreleased.jpg';
import rethinking_pic from '../../assets/img/rethinking.jpg';

const Music = () => {
    const tracks = [
        {
            id: 21, name: "Переосмысление", audio: rethinking, picture: rethinking_pic, year: 2024
        },
        {
            id: 20, name: "Чему учили мудрые", audio: mudrie, picture: mudrie_pic, year: 2019
        },
        {
            id: 19, name: "Маяковский в Болгарии", audio: mayakovsky, picture: bulgaria_pic, year: 2019
        },
        {
            id: 18, name: "Фатум", audio: fatum, picture: fatum_pic, year: 2018
        },
        {
            id: 17, name: "Молчание", audio: silence, picture: silence_pic, year: 2015
        },
        {
            id: 16, name: "Строки о любви (версия)", audio: lines_about_love, picture: loveAbout_pic, year: 2008
        },
        {
            id: 15, name: "Одиночество", audio: loneliness, picture: loveAbout_pic, year: 2008
        },
        {
            id: 14, name: "За рубикон любви", audio: rubicon, picture: loveAbout_pic, year: 2008
        },
        {
            id: 13, name: "Атланитида", audio: atlantis, picture: loveAbout_pic, year: 2008
        },
        {
            id: 12, name: "Дороги", audio: roads, picture: unreleased, year: 2008
        },
        {
            id: 10, name: "Технологии стресса", audio: stress, picture: unreleased, year: 2008
        },
        {
            id: 9, name: "Гаспар Рикке", audio: gaspar, picture: everyone_album_pic, year: 2007
        },
        {
            id: 8, name: "Философия", audio: filosofia, picture: everyone_album_pic, year: 2007
        },
        {
            id: 7, name: "Вера", audio: faith, picture: everyone_album_pic, year: 2007
        },
        {
            id: 6, name: "Он", audio: he, picture: everyone_album_pic, year: 2007
        },
        {
            id: 5, name: "Не существует времени", audio: no_time, picture: everyone_album_pic, year: 2007
        },
        {
            id: 4, name: "Жизнь", audio: life, picture: everyone_album_pic, year: 2007
        },
        {
            id: 3, name: "По серебряной нити", audio: silver_thread, picture: everyone_album_pic, year: 2007
        },
        {
            id: 2, name: "Друг", audio: friend, picture: everyone_album_pic, year: 2007
        },
        {
            id: 1, name: "Детство в 90-е", audio: nineties, picture: everyone_album_pic, year: 2007
        },
    ]

    const [active, setActive] = useState(false);

    return (
        <div className="container">
                    <h1 className="music__h1">Трек лист</h1>
                    {
                        tracks.map(item => <TrackItem
                            key={item.id}
                            item={item}
                            active={active}
                            setActive={setActive}
                        />
                        )
                    }
        </div>
    );
};

export default Music;
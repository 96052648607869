import React from 'react';
import sevkaveksp_001 from '../../../images/sevkaveksp/sevkaveksp_001.JPG';
import sevkaveksp_002 from '../../../images/sevkaveksp/sevkaveksp_002.JPG';
import sevkaveksp_003 from '../../../images/sevkaveksp/sevkaveksp_003.JPG';
import sevkaveksp_004 from '../../../images/sevkaveksp/sevkaveksp_004.JPG';
import sevkaveksp_005 from '../../../images/sevkaveksp/sevkaveksp_005.JPG';
import sevkaveksp_006 from '../../../images/sevkaveksp/sevkaveksp_006.JPG';
import sevkaveksp_007 from '../../../images/sevkaveksp/sevkaveksp_007.JPG';
import sevkaveksp_008 from '../../../images/sevkaveksp/sevkaveksp_008.JPG';
import sevkaveksp_009 from '../../../images/sevkaveksp/sevkaveksp_009.JPG';
import sevkaveksp_010 from '../../../images/sevkaveksp/sevkaveksp_010.JPG';
import sevkaveksp_011 from '../../../images/sevkaveksp/sevkaveksp_011.JPG';
import sevkaveksp_012 from '../../../images/sevkaveksp/sevkaveksp_012.JPG';
import sevkaveksp_013 from '../../../images/sevkaveksp/sevkaveksp_013.JPG';
import sevkaveksp_014 from '../../../images/sevkaveksp/sevkaveksp_014.JPG';
import sevkaveksp_015 from '../../../images/sevkaveksp/sevkaveksp_015.JPG';
import sevkaveksp_016 from '../../../images/sevkaveksp/sevkaveksp_016.JPG';
import sevkaveksp_017 from '../../../images/sevkaveksp/sevkaveksp_017.JPG';
import sevkaveksp_018 from '../../../images/sevkaveksp/sevkaveksp_018.JPG';
import sevkaveksp_019 from '../../../images/sevkaveksp/sevkaveksp_019.JPG';
import sevkaveksp_020 from '../../../images/sevkaveksp/sevkaveksp_020.JPG';
import sevkaveksp_021 from '../../../images/sevkaveksp/sevkaveksp_021.JPG';
import sevkaveksp_022 from '../../../images/sevkaveksp/sevkaveksp_022.JPG';
import sevkaveksp_023 from '../../../images/sevkaveksp/sevkaveksp_023.JPG';
import sevkaveksp_024 from '../../../images/sevkaveksp/sevkaveksp_024.JPG';
import sevkaveksp_025 from '../../../images/sevkaveksp/sevkaveksp_025.JPG';
import sevkaveksp_026 from '../../../images/sevkaveksp/sevkaveksp_026.JPG';
import sevkaveksp_027 from '../../../images/sevkaveksp/sevkaveksp_027.JPG';
import sevkaveksp_028 from '../../../images/sevkaveksp/sevkaveksp_028.JPG';
import sevkaveksp_029 from '../../../images/sevkaveksp/sevkaveksp_029.JPG';
import sevkaveksp_030 from '../../../images/sevkaveksp/sevkaveksp_030.JPG';
import sevkaveksp_031 from '../../../images/sevkaveksp/sevkaveksp_031.JPG';
import sevkaveksp_032 from '../../../images/sevkaveksp/sevkaveksp_032.JPG';
import sevkaveksp_033 from '../../../images/sevkaveksp/sevkaveksp_033.JPG';
import sevkaveksp_034 from '../../../images/sevkaveksp/sevkaveksp_034.JPG';
import sevkaveksp_035 from '../../../images/sevkaveksp/sevkaveksp_035.JPG';
import sevkaveksp_036 from '../../../images/sevkaveksp/sevkaveksp_036.JPG';
import sevkaveksp_037 from '../../../images/sevkaveksp/sevkaveksp_037.JPG';
import sevkaveksp_038 from '../../../images/sevkaveksp/sevkaveksp_038.JPG';
import sevkaveksp_039 from '../../../images/sevkaveksp/sevkaveksp_039.JPG';
import sevkaveksp_040 from '../../../images/sevkaveksp/sevkaveksp_040.JPG';
import sevkaveksp_041 from '../../../images/sevkaveksp/sevkaveksp_041.JPG';
import sevkaveksp_042 from '../../../images/sevkaveksp/sevkaveksp_042.JPG';
import sevkaveksp_043 from '../../../images/sevkaveksp/sevkaveksp_043.JPG';
import sevkaveksp_044 from '../../../images/sevkaveksp/sevkaveksp_044.JPG';
import sevkaveksp_045 from '../../../images/sevkaveksp/sevkaveksp_045.JPG';
import sevkaveksp_046 from '../../../images/sevkaveksp/sevkaveksp_046.JPG';
import sevkaveksp_047 from '../../../images/sevkaveksp/sevkaveksp_047.JPG';
import sevkaveksp_048 from '../../../images/sevkaveksp/sevkaveksp_048.JPG';
import sevkaveksp_049 from '../../../images/sevkaveksp/sevkaveksp_049.JPG';
import sevkaveksp_050 from '../../../images/sevkaveksp/sevkaveksp_050.JPG';
import sevkaveksp_051 from '../../../images/sevkaveksp/sevkaveksp_051.JPG';
import sevkaveksp_052 from '../../../images/sevkaveksp/sevkaveksp_052.JPG';
import sevkaveksp_053 from '../../../images/sevkaveksp/sevkaveksp_053.JPG';
import sevkaveksp_054 from '../../../images/sevkaveksp/sevkaveksp_054.JPG';
import sevkaveksp_055 from '../../../images/sevkaveksp/sevkaveksp_055.JPG';
import sevkaveksp_056 from '../../../images/sevkaveksp/sevkaveksp_056.JPG';
import sevkaveksp_057 from '../../../images/sevkaveksp/sevkaveksp_057.JPG';
import sevkaveksp_058 from '../../../images/sevkaveksp/sevkaveksp_058.JPG';
import sevkaveksp_059 from '../../../images/sevkaveksp/sevkaveksp_059.JPG';
import sevkaveksp_060 from '../../../images/sevkaveksp/sevkaveksp_060.JPG';
import sevkaveksp_061 from '../../../images/sevkaveksp/sevkaveksp_061.JPG';
import sevkaveksp_062 from '../../../images/sevkaveksp/sevkaveksp_062.JPG';
import sevkaveksp_063 from '../../../images/sevkaveksp/sevkaveksp_063.JPG';
import sevkaveksp_064 from '../../../images/sevkaveksp/sevkaveksp_064.JPG';
import sevkaveksp_065 from '../../../images/sevkaveksp/sevkaveksp_065.JPG';
import sevkaveksp_066 from '../../../images/sevkaveksp/sevkaveksp_066.JPG';
import sevkaveksp_067 from '../../../images/sevkaveksp/sevkaveksp_067.JPG';
import sevkaveksp_068 from '../../../images/sevkaveksp/sevkaveksp_068.JPG';
import sevkaveksp_069 from '../../../images/sevkaveksp/sevkaveksp_069.JPG';
import sevkaveksp_070 from '../../../images/sevkaveksp/sevkaveksp_070.JPG';
import sevkaveksp_071 from '../../../images/sevkaveksp/sevkaveksp_071.JPG';
import sevkaveksp_072 from '../../../images/sevkaveksp/sevkaveksp_072.JPG';
import sevkaveksp_073 from '../../../images/sevkaveksp/sevkaveksp_073.JPG';
import sevkaveksp_074 from '../../../images/sevkaveksp/sevkaveksp_074.JPG';
import sevkaveksp_075 from '../../../images/sevkaveksp/sevkaveksp_075.JPG';
import sevkaveksp_076 from '../../../images/sevkaveksp/sevkaveksp_076.JPG';
import sevkaveksp_077 from '../../../images/sevkaveksp/sevkaveksp_077.JPG';
import sevkaveksp_078 from '../../../images/sevkaveksp/sevkaveksp_078.JPG';
import sevkaveksp_080 from '../../../images/sevkaveksp/sevkaveksp_080.JPG';
import sevkaveksp_081 from '../../../images/sevkaveksp/sevkaveksp_081.JPG';
import sevkaveksp_082 from '../../../images/sevkaveksp/sevkaveksp_082.JPG';
import sevkaveksp_083 from '../../../images/sevkaveksp/sevkaveksp_083.JPG';
import sevkaveksp_084 from '../../../images/sevkaveksp/sevkaveksp_084.JPG';
import sevkaveksp_085 from '../../../images/sevkaveksp/sevkaveksp_085.JPG';
import sevkaveksp_086 from '../../../images/sevkaveksp/sevkaveksp_086.JPG';
import sevkaveksp_087 from '../../../images/sevkaveksp/sevkaveksp_087.JPG';
import sevkaveksp_088 from '../../../images/sevkaveksp/sevkaveksp_088.JPG';
import sevkaveksp_089 from '../../../images/sevkaveksp/sevkaveksp_089.JPG';
import sevkaveksp_090 from '../../../images/sevkaveksp/sevkaveksp_090.JPG';
import sevkaveksp_091 from '../../../images/sevkaveksp/sevkaveksp_091.JPG';
import sevkaveksp_092 from '../../../images/sevkaveksp/sevkaveksp_092.JPG';
import sevkaveksp_093 from '../../../images/sevkaveksp/sevkaveksp_093.JPG';
import sevkaveksp_094 from '../../../images/sevkaveksp/sevkaveksp_094.JPG';
import sevkaveksp_095 from '../../../images/sevkaveksp/sevkaveksp_095.JPG';
import sevkaveksp_096 from '../../../images/sevkaveksp/sevkaveksp_096.JPG';
import sevkaveksp_097 from '../../../images/sevkaveksp/sevkaveksp_097.JPG';
import sevkaveksp_098 from '../../../images/sevkaveksp/sevkaveksp_098.JPG';
import sevkaveksp_099 from '../../../images/sevkaveksp/sevkaveksp_099.JPG';
import sevkaveksp_100 from '../../../images/sevkaveksp/sevkaveksp_100.JPG';
import sevkaveksp_101 from '../../../images/sevkaveksp/sevkaveksp_101.JPG';
import sevkaveksp_102 from '../../../images/sevkaveksp/sevkaveksp_102.JPG';
import sevkaveksp_103 from '../../../images/sevkaveksp/sevkaveksp_103.JPG';
import sevkaveksp_104 from '../../../images/sevkaveksp/sevkaveksp_104.JPG';
import sevkaveksp_105 from '../../../images/sevkaveksp/sevkaveksp_105.JPG';
import sevkaveksp_106 from '../../../images/sevkaveksp/sevkaveksp_106.JPG';
import sevkaveksp_107 from '../../../images/sevkaveksp/sevkaveksp_107.JPG';
import sevkaveksp_108 from '../../../images/sevkaveksp/sevkaveksp_108.JPG';
import sevkaveksp_109 from '../../../images/sevkaveksp/sevkaveksp_109.JPG';
import sevkaveksp_110 from '../../../images/sevkaveksp/sevkaveksp_110.JPG';

const Sevkaveksp = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <p>
                        В июне 2021 года состоялась наша очередная отпускная поездка. Не удовлетворённые качеством осмотра достопримечательностей Дагестана, мы решили посетить его вновь. Однако, чтобы хватило времени на всё, мы решили сэкономить его прилетев на место на самолёте. Тем более что в этот раз жена составила кольцевой маршрут, пролегающий помимо Дагестана по таким республикам как Чечня, Ингушетия и Северная Осетия. В общем предстоящие две недели обещали быть интересными и насыщенными на события.
                    </p>
                    <p>
                        Приятным бонусом перелёта, стало то, что он совершался без пересадок. Да, прямо из Челябинска мы прилетели в аэропорт Махачкалы. Хотя до Махачкалы там даже дальше чем до Каспийска, в котором мы тоже будем проживать, но обо всём по порядку.
                    </p>
                    <p>
                        Приземлившись и едва успев сесть в такси, начался дождь. Оказалось, мы попали в редкие для Дагестана дождливые дни. Водитель привёз нас в арендованные нами в Махачкале апартаменты. Новый многоквартирный дом, но у нас был отдельный вход и первый этаж. Был уже вечер и лил дождь, поэтому решили, что сегодня мы уже никуда не пойдём, а закажем еду, воспользовавшись доставкой. Через час мы уже кушали шашлык и смотрели ролики про Дагестан. А потом легли отдыхать.
                    </p>
                    <p>
                        Следующий день был временем прогулок по Махачкале. Благо распогодилось. Пошли из дома пешком. По проспекту Расула Гамзатова с его тенистым бульваром гулять приятно. Дошли до главной площади, побродили по скверам. Заглянули на базар. Вот это базар! Свежие ягоды, фрукты, овощи, зелень, сладости, урбеч, горская сушёная колбаса… Всё это местное и по легким для «северянина» ценам. Обратно поехали на такси, есть накупленное.
                    </p>

                    <div id="mahach_01" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#mahach_01" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#mahach_01" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_001} className="d-block w-100" loading="lazy" alt="Махачкала"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_002} className="d-block w-100" loading="lazy" alt="Махачкала"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#mahach_01" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#mahach_01" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Обратно в центр вечером. Посетили окрестности Джума мечети, которую не успели увидеть в прошлом году. Потом на городской пляж. Здесь яблоку негде упасть. Особый колорит Дагестанских пляжей – борцы тренирующиеся и спаррингующиеся не песке. На турники, также обязательный атрибут местных пляжей стоит очередь. Завтра в путь.
                    </p>

                    <div id="mahach_02" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#mahach_02" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#mahach_02" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_003} className="d-block w-100" loading="lazy" alt="Махачкала"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_004} className="d-block w-100" loading="lazy" alt="Махачкала"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#mahach_02" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#mahach_02" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        На следующий день, нам предстояло взять забронированную машину в аренду и отправиться по намеченному маршруту. Что мы и сделали. Приехали в соседний Каспийск, где в одной автомастерской подписали договор и забрали ключи от видавшего виды Соляриса. Впрочем, этот авто ещё не знал, что ему предстоит в наших руках )) Вообще, с арендой машин на время нашего пребывания в Дагестане было туговато. Конторы вроде бы есть, но возможность бронировать дают не охотно, условия не очень прозрачные или для нас неудобные (то лимит по километражу, то невозможность выехать за пределы Дагестана). Наш белый Солярис на питерских номерах, с практически не пропускающей свет левой фарой и гремящими передними стойками, и удалённым катализатором скорее всего в прошлом усердно работал в такси. Но это ничего, главное условия его аренды позволяли нам ехать по запланированному маршруту. И мы его начали.
                    </p>
                    <p>
                        Первая точка нашего притяжения лежала в горах, где мы по задумке должны были отыскать наивысшую смотровую точку на Сулакский каньон. Но поплутав по горным дорогам, мы вроде бы нашли нужную тропу, однако проехать до конца по ней не решились из-за низкого клиренса, и как выясниться позже не зря. А пока, устроив небольшой пикник в горах, мы повернули назад на трассу и доехали до другой смотровой площадки на Сулак возле Дубков. Поездки на катерах, которые предлагались здесь, были для нас уже недоступны по времени, так как нам нужно было в Чечню, ночевать нам предстояло в городе Грозный.
                    </p>

                    <div id="dag_beginning" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#dag_beginning" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#dag_beginning" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#dag_beginning" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_005} className="d-block w-100" loading="lazy" alt="Дагестан"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_006} className="d-block w-100" loading="lazy" alt="Дагестан"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_007} className="d-block w-100" loading="lazy" alt="Дагестан"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#dag_beginning" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#dag_beginning" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Дорога на Грозный особенно на Чеченской стороне отличная. Последние несколько десятков километров это шестиполосная автострада. Сразу чувствуется, что в республику вкладываются. Это касается не только дорог, но и инфраструктуры в целом, даже просто поля вдоль трасс выглядят ухоженными. Памятуя о прошлогоднем досмотре и регистрации на границе с Дагестаном, мы всё ждали нечто подобное и в Чечне, однако, ничего подобного здесь не было. Полиции тут на дорогах в достатке, но никто нас не останавливал до самого Грозного.
                    </p>
                    <p>
                        Что ещё бросается в глаза в Чеченской республике это наличие даже в небольших населённых пунктах совершенно новых мечетей, построенных с большим размахом. А в городе Аргун, по сути, являющемся пригородом Грозного, в центре выстроен деловой центр, состоящий из нескольких небоскребов, а рядом суперсовременная мечеть «Сердце Матери», которая лично мне вообще понравилась более всех мной виденных мечетей. Отдельных слов заслуживает парк разбитый рядом с храмом. Великолепный ландшафтный дизайн и, наверное, сотня различных видов деревьев, кустарников и других растений. Прекрасное место.
                    </p>

                    <div id="argun" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#argun" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#argun" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#argun" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_009} className="d-block w-100" loading="lazy" alt="Аргун"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_010} className="d-block w-100" loading="lazy" alt="Аргун"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_008} className="d-block w-100" loading="lazy" alt="Аргун"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#argun" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#argun" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Уже стемнело, когда мы прибыли к нашей гостинице в г. Грозный. Припарковавшись, мы пошли заселяться в номер. И на reception я получил ни то замечание,  ни то предупреждение о том, что в Чечне из-за религиозно-культурных особенностей не принято ходить в шортах. На мой вопрос, почему же в Дагестане при той же религии я не получал ни замечаний, ни немых упрёков в свой адрес, парень администратор уточнил – из-за культурных особенностей Чечни. Ок.
                    </p>
                    <p>
                        На следующий день отправились на осмотр Грозного. Проехались по проспекту Путина, где на многих зданиях висят его портреты, вместе с портретами Кадырова отца и сына. Осмотрели одну из крупнейших мечетей в Европе – «Сердце Чечни». Рядом также располагается Сити, состоящий из нескольких небоскрёбов. Попили кваску у местной женщины. Кстати, если женщины в Дагестанских городах одеты либо в хиджаб, либо в традиционный наряд, либо в сдержанном виде по-светски, то Чечне в основном, как я понял, традиционно – просто платок, верх с длинным рукавом и длинная юбка.
                    </p>

                    <div id="grozniy" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#grozniy" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#grozniy" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#grozniy" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#grozniy" data-bs-slide-to="3"
                                    aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#grozniy" data-bs-slide-to="4"
                                    aria-label="Slide 5"></button>
                            <button type="button" data-bs-target="#grozniy" data-bs-slide-to="5"
                                    aria-label="Slide 6"></button>
                            <button type="button" data-bs-target="#grozniy" data-bs-slide-to="6"
                                    aria-label="Slide 7"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_011} className="d-block w-100" loading="lazy" alt="Грозный"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_012} className="d-block w-100" loading="lazy" alt="Грозный"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_013} className="d-block w-100" loading="lazy" alt="Грозный"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_014} className="d-block w-100" loading="lazy" alt="Грозный"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_015} className="d-block w-100" loading="lazy" alt="Грозный"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_016} className="d-block w-100" loading="lazy" alt="Грозный"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_017} className="d-block w-100" loading="lazy" alt="Грозный"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#grozniy" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#grozniy" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Рядом с Грозный Сити через дорогу строится здание Ахмат-Тауэр – 102 этажное здание с проектной высотой 435 метров. Управление строительством осуществляет московская компания, за архитектурно-конструкторские работы отвечают две американские фирмы. Есть данные о том, что это будет первый небоскрёб в Европе построенный в зоне сейсмической активности.
                    </p>

                    <img src={sevkaveksp_018} alt="Грозный" className="travel__img img-fluid" loading="lazy"/>

                    <p>
                        Выселившись из гостиницы, мы снова отправились в путь. Мы двигались в горы в сторону Ингушетии, приближаясь к одному из красивейший маршрутов, когда-либо видимых мной лично – той самой дороге в Джейрахском районе Ингушетии и достопримечательностях раскинувшихся вдоль неё.
                    </p>

                    <div id="djeirah_1" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#djeirah_1" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#djeirah_1" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#djeirah_1" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#djeirah_1" data-bs-slide-to="3"
                                    aria-label="Slide 4"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_019} className="d-block w-100" loading="lazy" alt="Джейрахский район. Ингушетия."/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_020} className="d-block w-100" loading="lazy" alt="Джейрахский район. Ингушетия."/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_021} className="d-block w-100" loading="lazy" alt="Джейрахский район. Ингушетия."/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_022} className="d-block w-100" loading="lazy" alt="Джейрахский район. Ингушетия."/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#djeirah_1" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#djeirah_1" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Тут и великолепные зелёные горы, и виднеющиеся в дали заснеженные вершины, и горные реки, родники и водопады, тут старые фамильные сторожевые башни и даже древний храм Тхаба-Ерды в Джейраско-Ассинском заповеднике, как считается подаренным Ингушскому народу царицей Тамарой, ознаменовавшим давнюю Грузино-Ингушскую дружбу.
                    </p>

                    <div id="djeirah_3" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#djeirah_3" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#djeirah_3" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#djeirah_3" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#djeirah_3" data-bs-slide-to="3"
                                    aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#djeirah_3" data-bs-slide-to="4"
                                    aria-label="Slide 5"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_023} className="d-block w-100" loading="lazy" alt="Джейрахский район. Ингушетия."/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_024} className="d-block w-100" loading="lazy" alt="Джейрахский район. Ингушетия."/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_025} className="d-block w-100" loading="lazy" alt="Джейрахский район. Ингушетия."/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_026} className="d-block w-100" loading="lazy" alt="Джейрахский район. Ингушетия."/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_027} className="d-block w-100" loading="lazy" alt="Джейрахский район. Ингушетия."/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#djeirah_3" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#djeirah_3" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Древних поселений со сторожевыми башнями здесь множество это и Таргим и Вовнушки и Эгикал. Вот возле последнего нам повстречался местный смотритель Зияутдин, он угостил нас чаем из самовара и немного рассказал про местную историю. Оказалось, Ингуши называют себя – Галгай (только учтите, произносится это не так как пишется, т.к. в русском просто нет таких букв), в честь своего первого прародителя. А означает это имя буквально – строитель башен. Так что подобные сооружения на Кавказе это изобретение именно ингушского народа. Также он рассказал, что где-то высоко в горах над Эгикалом находится мегалитическое сооружение, служившее домом для их предка основателя. Приятный, образованный и добрый парень. Спасибо ему за угощение и беседу!
                    </p>

                    <div id="djeirah_4" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#djeirah_4" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#djeirah_4" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_028} className="d-block w-100" loading="lazy" alt="Джейрахский район. Ингушетия."/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_029} className="d-block w-100" loading="lazy" alt="Джейрахский район. Ингушетия."/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#djeirah_4" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#djeirah_4" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Далее едем до места нашего пребывания – курорта Армхи. Пока едем продолжаем часто останавливаться и делать снимки. Ибо красиво!
                    </p>
                    <p>
                        Сейчас, пересматривая эти снимки, ловлю себя на мысли: "Неужели я видел всю эту нереальную красоту!" ;-)
                    </p>

                    <div id="djeirah_5" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#djeirah_5" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#djeirah_5" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#djeirah_5" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#djeirah_5" data-bs-slide-to="3"
                                    aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#djeirah_5" data-bs-slide-to="4"
                                    aria-label="Slide 5"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_030} className="d-block w-100" loading="lazy" alt="Джейрахский район. Ингушетия."/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_031} className="d-block w-100" loading="lazy" alt="Джейрахский район. Ингушетия."/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_032} className="d-block w-100" loading="lazy" alt="Джейрахский район. Ингушетия."/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_033} className="d-block w-100" loading="lazy" alt="Джейрахский район. Ингушетия."/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_034} className="d-block w-100" loading="lazy" alt="Джейрахский район. Ингушетия."/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#djeirah_5" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#djeirah_5" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Интересно, что тема родовых оборонительных башен настолько важна для Ингушей в культурном смысле, что даже некоторые мечети делают с куполами характерной архитектуры.
                    </p>

                    <img src={sevkaveksp_035} className="travel__img img-fluid" alt="Ингушетия"/>

                    <p>
                        Армхи, пожалуй, самый известный курорт Ингушетии. По сути, он занимает целый горный склон, где есть гостинично-ресторанно-рекреационный корпус, вверх по склону ещё один корпус с открытым бассейном, а на самом верху склона большой ресторан к которому ведёт как обычная серпантинная так и канатная дорога. В общем, территориально довольно масштабно.
                    </p>

                    <p>
                        Номер нам достался довольно потрёпанный, но вид из окна изменял впечатление от него на положительное. В целом же корпус в отличном состоянии, в стоимость проживания входил также завтрак, к слову отличный выбор кушаний, а также час сауны в день.
                    </p>

                    <img src={sevkaveksp_036} className="travel__img img-fluid" alt="Армхи"/>

                    <p>
                        Кстати, факт: Ингуши, по крайней мере, которые встречались нам, говорят по-русски почти без акцента. Причём, если бы речь шла о Дагестанцах, то это было бы объяснимо, всё-таки русский это язык межнационального общения, внутри самой республики. Однако, у жителей Дагестана акцент выражен более явно, чем у Ингушей, хотя последние между собой говорят на своём языке.
                    </p>
                    <p>
                        На следующий день пошли в небольшой поход к Ляжгинскому водопаду. Тропа к нему начинается совсем неподалёку от Армхи, что очень удобно по таймингу посещения здешних красот. Сам водопад оказался весьма внушительным. Лично я ожидал традиционной для середины лета тонкой струйки, стекающей по зализанным камням. Однако, водопад приятно впечатлил.
                    </p>

                    <div id="lyajgy" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#lyajgy" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#lyajgy" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#lyajgy" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#lyajgy" data-bs-slide-to="3"
                                    aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#lyajgy" data-bs-slide-to="4"
                                    aria-label="Slide 5"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_037} className="d-block w-100" loading="lazy" alt="Ляжгинский водопад"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_038} className="d-block w-100" loading="lazy" alt="Ляжгинский водопад"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_039} className="d-block w-100" loading="lazy" alt="Ляжгинский водопад"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_040} className="d-block w-100" loading="lazy" alt="Ляжгинский водопад"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_041} className="d-block w-100" loading="lazy" alt="Ляжгинский водопад"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#lyajgy" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#lyajgy" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        После посещения водопада отправились также к расположенным неподалёку башням Эрзи. Это ещё один комплекс сторожевых башен, возможно сохранившихся лучше других. Взобравшись на склон, мы присели на склон, мы присели на травку, чтобы немного отдохнуть. Какого же было наше удивление, когда мы увидели там Зияутдина, которого встретили вчера в Эгикале, а чуть позже и в записи телепередачи «Поедим поедим» в номере гостиницы. Поистине он вездесущий спутник местных гор и хранитель башен. К слову, вид здесь очень хорош, не хуже чем на Эгикале.
                    </p>

                    <div id="erzy" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#erzy" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#erzy" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#erzy" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#erzy" data-bs-slide-to="3"
                                    aria-label="Slide 4"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_042} className="d-block w-100" loading="lazy" alt="Эрзи"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_043} className="d-block w-100" loading="lazy" alt="Эрзи"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_044} className="d-block w-100" loading="lazy" alt="Эрзи"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_045} className="d-block w-100" loading="lazy" alt="Эрзи"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#erzy" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#erzy" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Отдохнув пару ночей в Армхи, снова отправляемся в путь. Двигаемся в сторону Осетии и очень скоро там оказываемся.
                        Сперва едем на место схода печально известного ледника Колка в Кармадонском ущелье.
                    </p>

                    <div id="karmadon" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#karmadon" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#karmadon" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_046} className="d-block w-100" loading="lazy" alt="Кармадонское ущелье"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_047} className="d-block w-100" loading="lazy" alt="Кармадонское ущелье"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#karmadon" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#karmadon" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        А далее у нас на пути Город Мёртвых - Даргавс. Точнее село Даргавс вполне живое, но туристов интересует в основном Даргавский некрополь.
                    </p>

                    <img src={sevkaveksp_048} className="img-fluid travel__img" alt="Даргавс"/>

                    <p>
                        После едем в сторону <del>Орджоникидзе</del> Владикавказа. По пути прекрасные виды.
                        И памятник второй букве осетинского алфавита.
                    </p>

                    <div id="osetia_1" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#osetia_1" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#osetia_1" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_049} className="d-block w-100" loading="lazy" alt="Северная осетия"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_050} className="d-block w-100" loading="lazy" alt="Северная осетия"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#osetia_1" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#osetia_1" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Для того, чтобы отдохнуть с дороги заехали в местечко, где небольшие бассейны
                        заполняют водой из горячих источников. В это время были проблеммы с подачей
                        холодной воды для охлаждения, но все таки зайти в воду было можно.
                    </p>
                    <p>
                        Время ещё было не позднее, поэтому решили прокатится по дороге ведующей в сторону
                        Южной Осетии.
                    </p>

                    <div id="osetia_2" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#osetia_2" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#osetia_2" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_052} className="d-block w-100" loading="lazy" alt="Северная осетия"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_051} className="d-block w-100" loading="lazy" alt="Северная осетия"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#osetia_2" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#osetia_2" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        С овновной дороги свернули куда-то в горы, и началась красота.
                    </p>

                    <div id="osetia_3" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#osetia_3" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#osetia_3" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_053} className="d-block w-100" loading="lazy" alt="Северная осетия"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_054} className="d-block w-100" loading="lazy" alt="Северная осетия"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#osetia_3" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#osetia_3" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        В итоге после довольно продолжительного подъёма, мы упёрлись в дорожный тупик, от
                        которого начинался новый подъём уже по канатной дороге уходящей куда-то в заоблачную неизвестность.
                    </p>

                    <img src={sevkaveksp_055} className="img-fluid travel__img" loading="lazy" alt="Северная осетия"/>

                    <p>
                        К большому нашему сожалению, канатка работала только до 16:00, а мы слегка опоздали.
                        Зато работала ещё кафешка при ней, где мы попили чайку с осетинским пирогом.
                    </p>
                    <p>
                        Возникли мысли даже отыскать жильё неподалёку, чтобы вернутся сюда на следующий день и
                        совершить таки подъём. Но нет... После долгих раздумий, мы всё-таки поехали к забронированному
                        во Владикавказе гостевому домику, где и переночевали.
                    </p>
                    <p>
                        На утро прогулялись по городу. В центре шла масштабная дорожная реконструкция.
                        Но всё равно, центр казался довольно уютным. Кстати, на этот раз Владикавказ оказал на меня
                        значительно лучшее впечатление чем шесть лет назад.
                    </p>
                    <p>
                        Конечно посетили армянскую церьковь, куда же без неё. Но самым главным достоинством
                        города является вид на Кавказский хребет, ведь мы у его подножия.
                    </p>

                    <div id="vladikavkaz" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#vladikavkaz" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#vladikavkaz" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#vladikavkaz" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_056} className="d-block w-100" loading="lazy" alt="Владикавказ"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_057} className="d-block w-100" loading="lazy" alt="Владикавказ"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_058} className="d-block w-100" loading="lazy" alt="Владикавказ"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#vladikavkaz" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#vladikavkaz" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        С этого момента мы начали возвращаться обратно в Дагестан. Сначала опять
                        попали в Ингушетию, где проехали через их новую во всех смыслах столицу Магас, в котором
                        почти все улицы широкие и пересекаются под прямым углом. Затем заехали в Чечню, где опять же
                        каждый небольшой населённый пункт имеет в своём центре колосальных размеров мечеть. Вообще,
                        учитывая что значительная часть территории Чеченской републики находится на равнине, в таких
                        местах чувствуешь себя скорее на Ближнем востоке, нежели на Кавказе.
                    </p>

                    <img src={sevkaveksp_059} className="img-fluid travel__img" loading="lazy" alt="Чечня"/>

                    <p>
                        Но теперь наш маршрут лежал на юг в горы, мы ехали в Чеченский курорт Казеной-Ам. Курорт этот находится у одноимённого озера. А озеро находиться на границе Чечни и Дагестана на высоте почти 1900 метров. Въезд на территорию комплекса охраняется вооружёнными людьми. Курорт построен в 2015 году. Отдыхающих много, в основном местные, но есть и туристы из других регионов России.
                    </p>

                    <div id="chechnya" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#chechnya" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#chechnya" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_060} className="d-block w-100" loading="lazy" alt="Чечня"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_061} className="d-block w-100" loading="lazy" alt="Чечня"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#chechnya" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#chechnya" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Основной корпус гостиницы треугольной формы на 58 номеров. Рядом корпус ресторана. Также на склоне горы располагаются коттеджи разной вместимости и удобств. Есть мангальные зоны, где в том числе, можно приготовить выловленную здесь в озере форель, которая, не стесняясь, плавает косяками прямо у берега. И, собственно, тут есть такая услуга – рыбалка. Ловишь рыбу и сразу готовишь. Есть прокат лодок, которым мы воспользовались, полчаса покатавшись по озеру. Вода очень чистая и прозрачная. Купаться здесь запрещено.
                    </p>

                    <div id="kazenoy" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#kazenoy" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#kazenoy" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#kazenoy" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#kazenoy" data-bs-slide-to="3"
                                    aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#kazenoy" data-bs-slide-to="4"
                                    aria-label="Slide 5"></button>
                            <button type="button" data-bs-target="#kazenoy" data-bs-slide-to="5"
                                    aria-label="Slide 6"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_062} className="d-block w-100" loading="lazy" alt="Казеной-Ам"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_063} className="d-block w-100" loading="lazy" alt="Казеной-Ам"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_064} className="d-block w-100" loading="lazy" alt="Казеной-Ам"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_065} className="d-block w-100" loading="lazy" alt="Казеной-Ам"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_066} className="d-block w-100" loading="lazy" alt="Казеной-Ам"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_067} className="d-block w-100" loading="lazy" alt="Казеной-Ам"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#kazenoy" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#kazenoy" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        После ночи на курорте, снова в путь. От сюда до Дагестана уже можно было и пешком.
                        Но на машине лучше. Постепенно скалы вдоль дороги приобретают характерный для Дагестана
                        песчаный оттенок.
                    </p>

                    <div id="dag_comeback" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#dag_comeback" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#dag_comeback" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#dag_comeback" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#dag_comeback" data-bs-slide-to="3"
                                    aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#dag_comeback" data-bs-slide-to="4"
                                    aria-label="Slide 5"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_068} className="d-block w-100" loading="lazy" alt="Возвращение в Дагестан"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_069} className="d-block w-100" loading="lazy" alt="Возвращение в Дагестан"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_070} className="d-block w-100" loading="lazy" alt="Возвращение в Дагестан"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_071} className="d-block w-100" loading="lazy" alt="Возвращение в Дагестан"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_072} className="d-block w-100" loading="lazy" alt="Возвращение в Дагестан"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#dag_comeback" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#dag_comeback" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Уже днём достигаем Хунзаха, где сразу заселяемся на ночлег. И идём гулять к нашему
                        старому знакомому - водопаду Тобот.
                    </p>

                    <div id="hunzah" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#hunzah" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#hunzah" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#hunzah" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#hunzah" data-bs-slide-to="3"
                                    aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#hunzah" data-bs-slide-to="4"
                                    aria-label="Slide 5"></button>
                            <button type="button" data-bs-target="#hunzah" data-bs-slide-to="5"
                                    aria-label="Slide 6"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_073} className="d-block w-100" loading="lazy" alt="Хунзах"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_074} className="d-block w-100" loading="lazy" alt="Хунзах"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_075} className="d-block w-100" loading="lazy" alt="Хунзах"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_076} className="d-block w-100" loading="lazy" alt="Хунзах"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_077} className="d-block w-100" loading="lazy" alt="Хунзах"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_078} className="d-block w-100" loading="lazy" alt="Хунзах"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#hunzah" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#hunzah" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Переночевав в Хунзахе, отправляемся по окрестностям в поисках достопримечательностей
                        и развлечений. Кое что находим.
                    </p>

                    <div id="hunzah_area" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#hunzah_area" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#hunzah_area" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#hunzah_area" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#hunzah_area" data-bs-slide-to="3"
                                    aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#hunzah_area" data-bs-slide-to="4"
                                    aria-label="Slide 5"></button>
                            <button type="button" data-bs-target="#hunzah_area" data-bs-slide-to="5"
                                    aria-label="Slide 6"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_080} className="d-block w-100" loading="lazy" alt="Хунзахский район"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_081} className="d-block w-100" loading="lazy" alt="Хунзахский район"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_082} className="d-block w-100" loading="lazy" alt="Хунзахский район"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_083} className="d-block w-100" loading="lazy" alt="Хунзахский район"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_084} className="d-block w-100" loading="lazy" alt="Хунзахский район"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_085} className="d-block w-100" loading="lazy" alt="Хунзахский район"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#hunzah_area" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#hunzah_area" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Возвращаемся в Хунзах, заезжаем в родное село Расула Гамзатова Цада. Здесь есть мемориальный комплекс
                        "Журавли" и дом музей семьи Гамзатовых. Посещаем последний.
                    </p>

                    <div id="tsada" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#tsada" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#tsada" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#tsada" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#tsada" data-bs-slide-to="3"
                                    aria-label="Slide 4"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_086} className="d-block w-100" loading="lazy" alt="Село Цада"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_087} className="d-block w-100" loading="lazy" alt="Село Цада"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_089} className="d-block w-100" loading="lazy" alt="Село Цада"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_088} className="d-block w-100" loading="lazy" alt="Село Цада"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#tsada" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#tsada" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Далее едем в ещё одно крупное аварское село Гуниб. Заселяемся в квартире, в самой нижней части селения.
                        А вечером договариваемся идти в поход на гору Маяк. За нами заезжает Ахмед
                        на своём УАЗике и везёт нас максимально высоко в горы, насколько позволяет его техника.
                        Потом поднимаемся пешком и вот мы на вершине. 2352 метра или что-то около того. Закат. Красота.
                    </p>

                    <div id="mayak" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#mayak" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#mayak" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#mayak" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#mayak" data-bs-slide-to="3"
                                    aria-label="Slide 4"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_090} className="d-block w-100" loading="lazy" alt="гора Маяк"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_091} className="d-block w-100" loading="lazy" alt="гора Маяк"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_092} className="d-block w-100" loading="lazy" alt="гора Маяк"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_093} className="d-block w-100" loading="lazy" alt="гора Маяк"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#mayak" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#mayak" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Спускаемся уже затемно. Вот так выглядит Гуниб ночью и утром соответсвенно.
                    </p>

                    <div id="gunib" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#gunib" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#gunib" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#gunib" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_094} className="d-block w-100" loading="lazy" alt="гора Маяк"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_095} className="d-block w-100" loading="lazy" alt="гора Маяк"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_096} className="d-block w-100" loading="lazy" alt="гора Маяк"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#gunib" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#gunib" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Что делать знойным днём в Гунибе? Не знаю, может быть посетить кафе Адат?
                    </p>

                    <div id="adat" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#adat" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#adat" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#adat" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_097} className="d-block w-100" loading="lazy" alt="кафе Адат"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_098} className="d-block w-100" loading="lazy" alt="кафе Адат"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_099} className="d-block w-100" loading="lazy" alt="кафе Адат"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#adat" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#adat" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Ну или заехать в какое-нибудь соседнее аутентичное селение.
                    </p>

                    <img src={sevkaveksp_100} className="img-fluid travel__img" loading="lazy" alt="Гунибский район"/>

                    <p>
                        Ну а ближе к вечеру, подняться в аул-призрак Гамсутль. Предварительно к нему проехав.
                    </p>

                    <div id="gamsutl" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#gamsutl" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#gamsutl" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#gamsutl" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_101} className="d-block w-100" loading="lazy" alt="Гамсутль"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_102} className="d-block w-100" loading="lazy" alt="Гамсутль"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_103} className="d-block w-100" loading="lazy" alt="Гамсутль"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#gamsutl" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#gamsutl" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Во истину богат Гуниб на разные аттракционы.
                    </p>

                    <img src={sevkaveksp_104} className="img-fluid travel__img" loading="lazy" alt="Гамсутль"/>

                    <p>
                        Короче, покинув Гуниб мы постепенно двигались к столичной дагестанской местности.
                    </p>

                    <div id="dag_ending" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#dag_ending" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#dag_ending" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_106} className="d-block w-100" loading="lazy" alt="Дагестан"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_105} className="d-block w-100" loading="lazy" alt="Дагестан"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#dag_ending" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#dag_ending" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        И так бы и ехали, но черт нас дёрнул ещё раз попробовать отыскать в горах
                        наивысшую точку обзора на Сулакский каньон. В результате опасная приобрывная щебёночная
                        тропа шириной в три метра, на которой ещё и пришлось разворачивать машину, обернулась одним проколотым колесом и ещё одним замятым. Поставив
                        запаску, чудом дотянули до первой шиномонтажки, до которой было ни как не меньше километров сорока,
                        и избежали неприятной ночёвки в глухих горах.
                    </p>

                    <div id="sulak_damage" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#sulak_damage" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#sulak_damage" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_107} className="d-block w-100" loading="lazy" alt="Дагестан"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_108} className="d-block w-100" loading="lazy" alt="Дагестан"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#sulak_damage" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#sulak_damage" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        На шиномантажке отстучали колёса и приговорили проколотую шину, превратившуюся в итоге в резиновые лохмотья.
                        Конечной точкой нашего путешествия стал город Каспийск, где мы ещё несколько дней отдыхали
                        от насыщенного графика передвижений, купались в море и вечерами гуляли по набережной.
                    </p>

                    <div id="kaspiysk" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#kaspiysk" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#kaspiysk" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={sevkaveksp_109} className="d-block w-100" loading="lazy" alt="Каспийск"/>
                            </div>
                            <div className="carousel-item">
                                <img src={sevkaveksp_110} className="d-block w-100" loading="lazy" alt="Каспийск"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#kaspiysk" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#kaspiysk" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Оглядываясь назад, могу сказать, что это было одно из самых насыщенных на
                        красивейшие места и самых интересных в культурном отношении наших поездок.
                        А та самая дорога в Джейрахском районе Ингушетии однозначно в топ 3 самых
                        живописных трасс когда-либо увиденных мной, наряду с дорогой в Местию (Грузия)
                        и дорогой Трансфагараш в Румынии.
                    </p>

                </div>
            </div>
        </div>
    );
};

export default Sevkaveksp;
import React from 'react';
import crimea_000 from '../../../images/crimea/crimea_000.jpg';
import crimea_001 from '../../../images/crimea/crimea_001.jpg';
import crimea_002 from '../../../images/crimea/crimea_002.jpg';
import crimea_003 from '../../../images/crimea/crimea_003.jpg';
import crimea_004 from '../../../images/crimea/crimea_004.jpg';
import crimea_005 from '../../../images/crimea/crimea_005.jpg';
import crimea_006 from '../../../images/crimea/crimea_006.jpg';
import crimea_007 from '../../../images/crimea/crimea_007.jpg';
import crimea_008 from '../../../images/crimea/crimea_008.jpg';
import crimea_009 from '../../../images/crimea/crimea_009.jpg';
import crimea_010 from '../../../images/crimea/crimea_010.jpg';
import crimea_011 from '../../../images/crimea/crimea_011.jpg';
import crimea_012 from '../../../images/crimea/crimea_012.jpg';
import crimea_013 from '../../../images/crimea/crimea_013.jpg';
import crimea_014 from '../../../images/crimea/crimea_014.jpg';
import crimea_015 from '../../../images/crimea/crimea_015.jpg';
import crimea_016 from '../../../images/crimea/crimea_016.jpg';
import crimea_017 from '../../../images/crimea/crimea_017.jpg';
import crimea_018 from '../../../images/crimea/crimea_018.jpg';
import crimea_019 from '../../../images/crimea/crimea_019.jpg';
import crimea_020 from '../../../images/crimea/crimea_020.jpg';
import crimea_021 from '../../../images/crimea/crimea_021.jpg';
import crimea_022 from '../../../images/crimea/crimea_022.jpg';
import crimea_023 from '../../../images/crimea/crimea_023.jpg';
import crimea_024 from '../../../images/crimea/crimea_024.jpg';
import crimea_025 from '../../../images/crimea/crimea_025.jpg';
import crimea_026 from '../../../images/crimea/crimea_026.jpg';
import crimea_028 from '../../../images/crimea/crimea_028.jpg';
import crimea_029 from '../../../images/crimea/crimea_029.jpg';
import crimea_030 from '../../../images/crimea/crimea_030.jpg';
import crimea_031 from '../../../images/crimea/crimea_031.jpg';
import crimea_032 from '../../../images/crimea/crimea_032.jpg';
import crimea_033 from '../../../images/crimea/crimea_033.jpg';

const Crimea = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <p>Поездка на полуостров Крым в сентябре 2017 года стала решением вопроса организации летнего отдыха
                        для нашей семьи. В состав путешественников вошли я с женой, наш пятилетний Гриша, а также моя
                        сестра Анна с шестилетней дочкой Машей.</p>

                    <p>В наших планах было немного покататься, преимущественно по южному берегу Крыма, и осесть на пляже
                        где-нибудь на недельку (издержки путешествий с детьми). Для решения первой задачи было решено
                        снять автомобиль на месте, для решения второй договориться со знакомыми моей сестры в Новом
                        Свете.</p>

                    <p>Почему Крым, что мало вариантов для пляжного отдыха? Не мало. Выбор был обусловлен тем, что на
                        полуострове достаточно высокая концентрация природных и исторических достопримечательностей на
                        относительно небольшой территории, а также, по рассказам в Новом Свете в наличии был весьма
                        неплохой для детей песчаный берег, кроме того, в Крым из Челябинска был – внимание: прямой!
                        рейс, за небольшие деньги. Да и просто, я там ни разу не был, почему бы и нет. Что из этого
                        вышло? Сейчас расскажу.</p>

                    <p>Итак, летим. Перевозчик – авиакомпания НордВингс. Очень не дурно, скажу я вам. Особенно после
                        «Победы над здравым смыслом» - можно сказать Божественно. С включённым багажом, с кормёжкой и
                        даже не тесными рядами кресел. А главное мы не летим в Москву, а сразу по месту назначения,
                        через какие-то три часика мы прибываем в Симферополь.</p>

                    <p>Получаем багаж и идём в офис автопроката, здесь же в терминале аэропорта. Я бронировал автомобиль
                        в компании «Крымавтопрокат», и, забегая вперёд, они показали себя с лучшей стороны. Я оплатил
                        остаток суммы за аренду, и мы отправились на стоянку перед терминалом, где находился наш
                        автомобиль. Искомым аппаратом был видавший прекрасные виды Крыма Chevrolet Cruze тёмно-синего
                        цвета, который мы осмотрели с менеджером, о чём и составили акт. К автомобилю, по моей просьбе и
                        за дополнительную плату, прилагались два бустера для деток. Загрузив Cruze под завязку, как
                        багажом, так и пассажирами, двинулись через город на трассу, ведущую на Севастополь, где у нас
                        была забронирована квартира для ночлега. Но прежде мы должны были остановиться в запланированном
                        к осмотру Бахчисарае, где и оказались уже через час.</p>

                    <p>Бахчисарай место надо сказать очень красивое, здесь в районе Ханского дворца дорогой сердцу
                        горный ландшафт и историческая застройка. Припарковаться здесь сложновато, узенькая дорожка и
                        опять же горная местность, плотная застройка этому причина. Можно припарковаться неподалёку за
                        денежку и места маскирующегося под Ханский дворец, мы же припарковались в 5 минутах ходьбы от
                        дворца, для экономии средств и осмотра прилегающей территории. </p>

                    <img src={crimea_000} alt="Бахчисарай" className="img-fluid travel__img"/>


                    <p>Вход на территорию дворцового комплекса естественно оплачивается, но в стоимость входит экскурсия
                        по закрытой территории, где можно ознакомиться с внутренними помещениями дворца, послушать
                        экскурсовода, а также увидеть знаменитый Бахчисарайский фонтан.</p>

                    <img src={crimea_001} alt="Бахчисарай" className="img-fluid travel__img"/>

                    <img src={crimea_002} alt="Бахчисарай" className="img-fluid travel__img"/>


                    <p>Вообще Бахчисарай место весьма интересное, пожалуй, из всех мест Крыма только здесь ощущается
                        какая-то аутентичность, и это связано с национальным колоритом. Дух места здесь сохраняется
                        живым языком и культурой крымских татар, архитектурой, историей места, а также национальной
                        кухней, которую можно испробовать здесь в многочисленных ресторанчиках, что мы с удовольствием и
                        сделали. Кроме того, Бахчисарай, судя по обилию здесь автомобилей из Татарии и Башкирии,
                        выглядел своеобразным местом паломничества людей из этих регионов, имеющих тут либо культурный
                        интерес, либо проживающих здесь родственников.</p>

                    <p>После восстановления сил в тени топчанов одного из заведений, мы двинулись чуть дальше в горы к
                        Свято-Успенскому монастырю. Подъехав к узкой дорожке, ведущей в горы, я захотел оставить машину
                        внизу, но подбежавший паренёк, любезно возвестил, о том, что к монастырю можно подняться на
                        машине и оставить её там. Я нехотя, направил машину наверх. С трудом разъезжаясь со встречными
                        авто, мы приехали к монастырю, но решительно ни одного места наверху не было, кроме того Гриша
                        погрузился в послеобеденный сон, и я, высадив всех бодрствующих на осмотр достопримечательности,
                        и с трудом развернув машину на узеньком пятачке, спустился вниз дожидаться остальных.</p>

                    <img src={crimea_003} alt="Бахчисарай" className="img-fluid travel__img"/>


                    <p>Спустя половину часа, мы уже выезжали из Бахчисарая, взяв курс на Севастополь, где, как я уже
                        говорил, нас ждал ночлег. Заехав в город, который является также отдельной административной
                        единицей, мы долго пробирались, через череду серпантинов, а затем вереницу улиц и проспектов,
                        хранящих его внушительный размер. Мы двигались в Гагаринский район города, к новостройкам, и
                        MapsMe почему-то отказывался находить нужный дом по адресу. Там нас уже ждал молодой человек –
                        Родион, для того, чтобы передать ключи от квартиры и получить денежку за аренду жилья. Пришлось
                        нам вместе с ним по телефону искать место неподалёку, маршрут до которого мы могли проложить.
                        Встретив нас там, Родион сопроводил наш автомобиль до нужного места, с названием Античный
                        бульвар (11), где проводил нас в апартаменты и со знанием дела и душой рассказал нам о местах,
                        которые мы обязательно должны посетить в Севастополе, по его мнению. Интеллигентный и грамотный
                        парень, спасибо ему.</p>

                    <p>С балкона в парадной было видно море, и мы успели насладиться видами заходящего солнца прямо от
                        сюда. Немного отдохнув с дороги, мы сходили в ближайший магазин за продуктами и всё, больше
                        никуда в этот день уже не ходили, оставив осмотр города на завтра.</p>

                    <img src={crimea_004} alt="Севастополь" className="img-fluid travel__img"/>


                    <p>На следующее утро, после завтрака, поехали в Херсонес Таврический. Поскольку было ещё не очень
                        поздно, удалось занять парковочное место возле него. Херсонес это классический полис, основанный
                        ещё древними греками в V веке до н.э. В дальнейшем этот полис, как и весь полуостров, был
                        объектом интереса множества государств и княжеств. Кого здесь только не было: помимо греков,
                        были естественно римляне, которые стали в дальнейшем византийцами, древние русы, хазары,
                        монголы, турки-сельджуки, генуэзцы, снова русские. Вообще это место считается знаковым для
                        русских христиан, т.к. здесь по одной из версий легенды был крещён князь Владимир, а про мотивы
                        взятия им «Корсуни» (Херсона) вообще история тёмная, каждый может исследовать эту легенду сам.
                        Сейчас же в Херсоне можно наблюдать Владимирский собор, построенный в XIX веке в византийском
                        стиле. Кроме собора, на территории есть, конечно, развалины древнего полиса, а также некоторые
                        другие архитектурные конструкции.</p>

                    <img src={crimea_005} alt="Херсонес" className="img-fluid travel__img"/>


                    <p>После осмотра достопримечательностей, идём к машине, но дорогу, вдоль которой припаркованы
                        автомобили, заткнули туристические автобусы. Процедура по эвакуации нашего и некоторых соседних
                        авто с мест парковки напоминала спецоперацию. Но всё завершилось благополучно. Мы едем в центр –
                        гулять по городу.</p>

                    <p>Дойдя до набережной. По совету Родиона, решаемся на морскую экскурсию. Нас грузят на лодки и
                        везут по бухтам, попутно рассказывая о городе, а также о суднах, которые пришвартованы
                        здесь.</p>

                    <img src={crimea_006} alt="Севастополь" className="img-fluid travel__img"/>


                    <p>После морской прогулки, идём по набережной, смотрим, где бы перекусить. Заходим в кафе «Окрошка».
                        Я пытаюсь заказать, собственно, окрошку, но мне говорят, что окрошка есть только на каком-то
                        неизвестном мне кисломолочном продукте, а квас для окрошки есть только сладкий-бутилированный, и
                        его не рекомендует даже сам официант. Вот такое вот кафе «Окрошка». Ну, что поделать, пришлось
                        выбрать что-то другое.</p>

                    <p>Во второй половине дня едем на 35-ю бронебашенную береговую батарею, которая использовалась при
                        обороне Севастополя в годы Великой отечественной, а ныне используется как музейный комплекс.
                        Вход на территорию комплекса бесплатный, здесь можно осмотреть технику военных лет, некоторые
                        единицы вооружения, ДОТы, «Пантеон памяти» и другие объекты. Также здесь водят экскурсии по
                        казематам батареи, которые также бесплатны, но на которые необходимо записываться заранее. Мы же
                        пропустили запись в последнюю группу экскурсантов, и поэтому в казематы не попали.</p>

                    <img src={crimea_007} alt="35-я бронебашенная батарея" className="img-fluid travel__img"/>


                    <p>Ознакомившись с батареей, едем провожать солнышко на мыс Фиолент, что находится на юго-западной
                        оконечности Севастополя, за частным сектором. Виды отсюда чудесные, особенно на закате.</p>

                    <img src={crimea_008} alt="Мыс Фиолент" className="img-fluid travel__img"/>


                    <p>На следующее утро прощаемся с Севастополем и уезжаем в направлении южного берега полуострова. И
                        первая остановка на сегодня – Балаклава. Если попытаться описать этот городок одним словом, то
                        это будет слово – бухта. И действительно, главные места города расположены вокруг ней. Она
                        узкая, длинная и петляющая, поэтому здесь никогда не бывает штормов. По этой причине здесь
                        пришвартовано огромное количество судёнышек. Здесь располагаются развалины Генуэзкой крепости
                        Чембало, здесь есть бывшая база подводных лодок, которая в этот день, к сожалению, не
                        работала.</p>

                    <img src={crimea_009} alt="Балаклава" className="img-fluid travel__img"/>


                    <p>Насмотревшись на красоты бухты, и прогулявшись по набережной, мы отправились в дальнейший путь.
                        Наш путь лежал на юго-восток в сторону Ялты, подъезжая к которой, мы решили заехать, пожалуй, к
                        одной из самых популярных достопримечательностей – Ласточкиному гнезду.</p>

                    <p>Но прежде, чем отправиться к нему, нам необходимо было непременно перекусить. И мы нашли такое
                        место, с видом как раз на это самое гнездо. И перекусили, чем Бог послал. Сервис, надо сказать,
                        довольно плох. Вроде бы курорт, должна быть какая-то конкуренция за клиента, да и вообще, море –
                        солнце, живи да и радуйся. Однако, особой приветливости, да и не особой тоже, здесь замечено не
                        было. И вообще, посредственное качество сервиса стало лейтмотивом почти каждой сферы сервиса, с
                        которым мы сталкивались на протяжении нашей поездки, но не будем забегать вперёд.</p>

                    <p>После обеда, идём к … А вот как правильно назвать то строение, к которому мы идём, даже и не
                        знаю, пусть это будет особняк. В общем, чтобы добраться до этого особняка с замкового типа
                        архитектурой, нужно сначала спуститься по лестнице, а потом снова подняться на сорокаметровую
                        скалу, попутно преодолевая давление многочисленного человеческого трафика и сужающих геометрию
                        пространства лавочников.</p>

                    <img src={crimea_010} alt="Ласточкино гнездо" className="img-fluid travel__img"/>


                    <p>Преодолев маршрут до конца, мы оказываемся на смотровой площадке. Виды здесь красивые, но
                        принципиально не отличающиеся от тех, что можно увидеть с трассы. Сам особняк не поражает
                        особыми изысками архитектуры, да и по размеру является довольно скромным. За вход внутрь берут
                        плату, и как мы поняли, ничего особенно интересного, кроме нескольких картин на стенах там и
                        нет. Возвращаемся тем же путём к машине.</p>

                    <p>Следующим пунктом у нас Ливадийский дворец. Перемещаемся к нему. Здесь была одна из летних
                        резиденций российских императоров, здесь обсуждался послевоенный передел мира на Ялтинской
                        конференции в феврале 1945 года, и здесь рядышком располагался советский санаторий. Дворец, а
                        точнее дворцовый комплекс вместе со своим ландшафтным образом производит очень приятные
                        впечатления.</p>

                    <img src={crimea_011} alt="Ливадия" className="img-fluid travel__img"/>


                    <p>Всё выглядит изящно и ухожено. Одно удовольствие, наверное, было гулять здесь среди этих красот и
                        дышать этим воздухом. Мы же, наскоро осмотрев территорию, отправились дальше, в Ялту. Точнее,
                        Ялту мы просто проезжаем, и едем сразу в Массандру, где планируем остаться на ночь. </p>

                    <img src={crimea_012} alt="Ливадия" className="img-fluid travel__img"/>


                    <p>Сюда мы уже заезжаем затемно. Двигаясь вдоль трассы, мы ищем малозаметный поворот, ведущий в
                        гору, здесь на возвышении и должен располагаться гостевой дом, куда мы хотим попасть. Поворот
                        налево, на неосвещённой трассе мы, конечно, с первого раза пропускаем, и приходится
                        разворачиваться. Со второго раза, находим нужный поворот, теперь уже направо, и движемся наверх.
                        Нужный нам дом по традиции совсем новый, и на картах не обозначен. Двигаясь по склону, пытаемся
                        высмотреть нужный адрес на домах, но, не заметив нужного, упираемся в глухой лес. Звоним хозяину
                        с просьбой встретить нас, но его самого в доме нет, и он ещё раз описав нам нужный дом, говорит,
                        чтобы мы сами отворили ворота, заезжали во двор, заходили в дом, и располагались в обозначенных
                        апартаментах, а он прибудет через часок собрать с нас денежки. Ок. Такой вариант нас вполне
                        устраивает. Заселяемся, оставляем уставших детей с сестрой в номере, мы же с женой едем в
                        магазин за продуктами.</p>

                    <p>Приезжаем обратно, хозяин поспевает за нами, забирает свои деньги, показывает территорию. Внизу
                        дворик и кухня, зона для барбекю, общего пользования. Во дворе столы, лавки, разные объекты для
                        детских активностей. Довольно мило. Но уже не до этого, сил остаётся только на перекус в
                        номере.</p>

                    <p>На рассвете из окна предстаёт красивая картина с видом на деревья и море. И мы идём завтракать во
                        дворик. За оградой рядами растёт виноградник. Шум от дороги почти не слышен. Уезжать не хочется,
                        но надо. Не торопясь собираемся и отправляемся в путь. Сегодня у нас по плану Ай-Петри и
                        Ялта.</p>

                    <img src={crimea_013} alt="Массандра" className="img-fluid travel__img"/>


                    <p>Для начала возвращаемся немного назад, чтобы попасть на крутой серпантин на самую популярную гору
                        полуострова. Склоны массива прикрывает плотная лесная растительность, и двигаться по ним одно
                        удовольствие. Для начала заезжаем посмотреть на почти сто метровый водопад Учан-Су, что в
                        переводе с крымско-татарского означает «летящяя вода». Однако, к сентябрю никакой летящей воды
                        здесь нет, а есть только еле заметная тонкая струйка стекающая со скал.</p>

                    <p>У водопада вдоль трассы стоят пугающие знаки о том, что дорога на Ай-Петри закрыта, но машины
                        едут наверх, и мы, повинуясь стадной эйфории, следуем за ними. По пути наверх делаем пару
                        остановок для осмотра некоторых мест народного притяжения. Наконец мы наверху, где раскинулось
                        широкое плато. Здесь рядом с вершинами плохо оборудованная стоянка для машин и конечная станция
                        канатной дороги.</p>

                    <img src={crimea_014} alt="Ай-Петри" className="img-fluid travel__img"/>


                    <p>Также здесь неподалёку располагаются несколько пещер, в одну из которых мы покупаем билеты на
                        посещение. Здесь в тёмном царстве сталактитов, сталагмитов и сталагнатов – прохладно и скользко,
                        но детям весьма интересно.</p>

                    <img src={crimea_015} alt="Ай-Петри" className="img-fluid travel__img"/>


                    <p>После похода, грузимся в машину и идём на спуск, настолько длинный и изобилующий поворотами, что
                        к его окончанию дурнеет даже мне, привыкшему к таким горным виражам.</p>

                    <p>Наконец, попадаем в Ялту. Насилу припарковавшись, идём обедать, в местную сетевую столовую
                        «По-домашнему». Здесь людно и дорого, но довольно неплохой выбор. После обеда прогуливаемся по
                        набережной.</p>

                    <img src={crimea_016} alt="Ялта" className="img-fluid travel__img"/>


                    <p>Тут хорошо, и хочется остаться, но время подгоняет. Ночевка у нас сегодня в Новом Свете. Но мы
                        знаем, что в Ялте есть армянская церковь, и мы естественно не можем уехать без её посещения. В
                        темпе отправляемся на её поиски. Она совсем недалеко от набережной. Заходим в тихий квартал, и
                        тут на склоне нам открывается она. Надо сказать, я не особо впечатлительный и видел много
                        армянских церквей, но первое впечатление от этой одно из самых внушительных. К ней ведёт широкая
                        крутая лестница, над которой возвышается монументальный храм с самобытными архитектурными
                        линиями, сочетающими округлые элементы с традиционными чёткими вырубленными гранями.</p>

                    <img src={crimea_017} alt="Ялта" className="img-fluid travel__img"/>


                    <p>А внутри одинокий «пастырь» вёл службу, нараспев декламируя армянские тексты писания. Союз
                        гармоний и волшебной акустики помещения настолько одухотворял момент, что приводил душу в
                        трепет, а затем умиротворение. Великолепные ощущения. Но…</p>

                    <p>Надолго забыть о времени, даже в этих стенах не получилось. И вот, мы уже выезжаем из города, в
                        восточном направлении. Ехать около трёх часов, и закат мы встречаем уже в горах. В целом
                        качество дороги вдоль побережья неплохое, оно несколько портится к последней трети пути, что в
                        темноте не очень приятно. Попасть в Новый свет можно только через Судак, куда мы заезжаем уже
                        около девяти вечера.</p>

                    <p>Проехав по Судаку мы поднимаемся на последний серпантин ведущий по рифу, спустившись с которого
                        делаем первые метры по посёлку Новый Свет, и тут…</p>

                    <p>Тут наш автомобиль пару раз «чихнув», глохнет прямо на ходу, и мы резко теряем скорость и
                        останавливаем ход на дороге прямо за автобусной станцией. Попытки вновь завести мотор
                        оказываются тщетными. Что характерно, наша машина мешает нормальному проезду, но, несмотря на
                        включенные аварийные сигналы и постоянное ворчание стартера, от проезжающих слышны лишь
                        претензии к якобы неудачно выбранному месту парковки. Лишь один прохожий предложил помочь
                        оттолкать машину с дороги на место, где она не мешала бы проезду транспорта.</p>

                    <p>Я звоню в аренду и сообщаю о случившемся, мне сообщают, что мастер прибудет примерно через 2-3
                        часа. Я объясняю, что можно приехать и завтра с утра т.к. уже поздно, и ночные встречи совсем не
                        обязательны. Но видимо, опасаясь за сохранность автомобиля на неохраняемой парковке, они
                        настаивают на скорой встрече и возможной подмене автомобиля. Ок.</p>

                    <p>Поскольку, можно сказать мы остановились у знакомых, то нас любезно подбирают возле неисправного
                        авто, и довозят до места. Дети смертельно устали. Расселяемся, и все идут спать. Я же жду звонка
                        из автопроката.</p>

                    <p>Где-то в первом часу ночи мне звонят, и договариваемся о встрече у машины через 15 минут. Я
                        направляюсь на ночное рандеву. Представитель автопроката приезжает вовремя. Поприветствовав
                        меня, мужчина говорит, что попытается завести машину и уехать своим ходом, в худшем случае
                        придётся машину эвакуировать. А пока они будут пытаться починить Cruze, он оставляет мне в
                        пользование сменный автомобиль. Им оказывается довольно свежая Mazda 5, в которой даже имеется
                        третий ряд сидений для детей. Такая подмена меня полностью устраивает, и я, попрощавшись, еду
                        парковать его к месту нашего проживания. С парковкой надо сказать, у нас проблемы, места на
                        территории здесь не предусмотрено, а рядом паркуют свои автомобили жители и постояльцы соседних
                        домов, в том числе многоквартирных. Место мало и рельеф не способствует удобству. Однако, в этот
                        раз мне достаётся таки небольшое местечко.</p>
                    <p>Теперь Новый Свет становиться местом нашего постоянного базирования до окончания отдыха, однако,
                        у нас есть ещё два дня и несколько мест для посещения до момента сдачи машины. И на следующий
                        день мы снова грузимся в наш транспорт, и направляемся в Феодосию.</p>

                    <p>Феодосия интересна, прежде всего, как место переплетения различных культур и как родина
                        Айвазовского.</p>

                    <img src={crimea_018} alt="Феодосия" className="img-fluid travel__img"/>


                    <p>Однако, нам очень не повезло, в музее имени великого художника был выходной, несмотря на четверг.
                        Такого мы предвидеть не могли, и не поинтересовались заранее. Поэтому знакомство с Феодосией
                        ограничилось прогулками по набережной и прилегающим к ней улицам, купанием в море, и посещением
                        очередной столовой «По-домашнему».</p>

                    <img src={crimea_019} alt="Феодосия" className="img-fluid travel__img"/>


                    <p>После неполноценного осмотра Феодосии, мы отправились в район Старого Крыма к монастырю Сурб Хач.
                        Монастырь строился в середине XIV века, с целью сохранения веры. Поскольку Каффа (Феодосия) в те
                        времена была под властью генуэзцев, проводивших политику окатоличивания населения, значительную
                        часть которого в ту пору составляли армяне, часть из них пустилась в горы и основала этот
                        монастырь. Сегодня монастырь действует, кое-где идут реставрационные работы.</p>

                    <img src={crimea_020} alt="Сурб-Хач" className="img-fluid travel__img"/>


                    <p>Туристы и интересующиеся здесь присутствуют в достаточном количестве, но месту это не вредит, и
                        здесь в целом довольно тихо и умиротворённо. Рядом с монастырским комплексом располагаются
                        организованные места для забора родниковой воды, территория граничит с лесом в тени которого,
                        при желании, можно с удовольствием прогуляться.</p>

                    <img src={crimea_021} alt="Сурб-Хач" className="img-fluid travel__img"/>


                    <p>На сегодня всё, едем в Новый Свет на отдых, завтра по программе у нас поездка на водопад.
                    </p>

                    <p>Итак, последний день разъездов, и мы едем на водопад с армянским названием Джур-Джур, который
                        считается одним из самых полноводных на полуострове. Нужно доехать до села Генеральского, хотя
                        УАЗы возят и от самого побережья из Солнечногорского, а возможно и из села Рыбачьего тоже. У
                        Генеральского долго сомневаемся, продолжить путь дальше или взять УАЗ-шаттл до водопада у
                        местных. Некоторые машины уходят под горку, правда, докуда они доезжают неизвестно. Идти пешком
                        с детьми далековато. В итоге, решаем припарковаться здесь, и воспользоваться услугами
                        внедорожной доставки. Чтобы платить было меньше, находим ещё пару желающих добраться до места
                        (да в «сафари» УАЗе есть места даже в багажнике, и мы все помещаемся).</p>

                    <p>Сначала мы атакуем небольшой подъём, а затем начинаем спуск в ущелье. Возможно, нас везут не
                        самой простой дорогой, но сам путь это просто аттракцион. В принципе, даже если у вас имеется
                        внедорожник, то вы вряд ли проедете по этой «дороге» самостоятельно. На пути встречаются
                        торчащие валуны размером с телёнка, глубокие ложбинки, проделываемые дождевой водой, а спуск
                        подчас настолько крутой, что необходимо плотно уцепится за поручень чтобы усидеть на месте. К
                        тому же тропинка узка и в целом вмещает только один автомобиль, поэтому для неё хорошо подходит
                        геометрия 469 УАЗа, и вряд ли что-то более массивное. Иногда я просто не верил глазам, когда мы
                        преодолевали некоторые препятствия, настолько внушительными они смотрелись. Надо сказать, что
                        конечно машины были специально подготовленными, с неплохо завышенной подвеской, именно это и
                        позволяло нам с лёгкостью преодолевать скальные выходы высотой в метр.</p>

                    <p>Приехав на место, водитель высадил нас, и сказал, что приедет за нами через час. Мы пошли
                        осматривать местность. Местность, надо сказать, была живописна. Мы были в лесу, где преобладал
                        бук, воздух был прекрасен и свеж, а впереди под характерные звуки падающих водных масс, нам
                        открывался вид на Джур-Джур. Нельзя сказать, что водопад был очень внушительных размеров, но то,
                        что он был красив – это можно сказать точно.</p>

                    <img src={crimea_022} alt="Джур-Джур" className="img-fluid travel__img"/>


                    <p>Постояв рядом с ним, мы поднялись по оборудованной лестнице наверх, где можно было осмотреть
                        течение реки Улу-Узень до падения с последнего порога. Здесь мы остановились на небольшой
                        привал, после которого, пришло время, идти к месту нашей высадки в ожидании транспорта обратно в
                        село Генеральское.</p>

                    <img src={crimea_023} alt="Джур-Джур" className="img-fluid travel__img"/>


                    <p>После посещения водопада, мы отправились к ещё одной природной достопримечательности – горе
                        Демерджи, в так называемую Долину Приведений, названную так, из-за причудливой формы скал,
                        обусловленной выветриванием. Подъехав к предполагаемой тропе в урочище, мы увидели
                        облагороженную предпринимателем территорию, который кормил посетителей в своём летнем кафе. Этой
                        возможностью поесть мы не преминули воспользоваться, и остались, пожалуй, впервые за время
                        поездки, полностью довольны обедом. Было вкусно, а соотношение ценника и величины порции,
                        заставило вспомнить о местах славящихся своим гостеприимством.</p>

                    <p>После вкусного обеда мы встали на тропу ведущую в урочище и начали подъём. В итоге в долину мы
                        поднялись, и даже осмотрели издалека местные скалы, но далеко пройти с детьми мы не могли, общая
                        площадь урочища около 20 гектар, поэтому если вы хотите подробней ознакомиться с этими местами,
                        то планируйте поход на весь день.</p>

                    <img src={crimea_024} alt="Димерджи" className="img-fluid travel__img"/>


                    <p>Мы же спустились к кафе, возле которого стоял наш автомобиль, и пустились в путь, обратно в Новый
                        Свет.</p>

                    <p>На этом наши маршруты по полуострову считались исполненными. На следующее утро мы сдали машину,
                        подъехавшим за нем сотрудникам автопроката, и всю следующую неделю нам предстояло осесть здесь в
                        Новом Свете, ну может с парой вылазок в соседний Судак.</p>

                    <p>Что можно сказать про Новый Свет по существу. В туристском смысле - место весьма противоречивое.
                        С одной стороны здесь прекрасная природа. Посёлок расположен между двумя скалами в относительно
                        спокойной бухте, с песчаным пляжем и прозрачной водой, окружённый знаменитыми рощами из
                        можжевельника и уникальной сосны Станкевича. Новый Свет имеет также определённую историчность.
                        Историчность эта связана, в основном, с именем князя Льва Голицина (одного из многочисленных
                        представителей рода), который являлся одним из основоположников российского виноделия и основал
                        в этих местах завод шампанских вин. Также, в этих местах бывали на отдыхе, в гостях у князя,
                        император Николай II и знаменитый певец Фёдор Шаляпин. Первый удостоился в Новом Свете пляжа, а
                        второй грота имени себя. «Царский пляж», расположенный за мысом «Капчик», считается заповедной
                        зоной, попасть туда можно только по морю, или по народной эко-тропе, идущей с можжевеловой рощи.
                        Пляж этот мелкогаличный и официально он закрыт из-за опасности оползней. А грот Шаляпина
                        располагается в скале Коба-Кая вдоль так называемой тропы Голицина, соединяющей основной пляж
                        «Зелёной бухты» с мысом Капчик. Акустические возможности этого грота Фёдор Иванович проверял
                        лично, и лишь за это, за гротом закрепилось имя певца. Кроме того, имена Шаляпина и Голицина
                        носят самые проходные улицы населённого пункта.</p>

                    <img src={crimea_025} alt="Новый Свет" className="img-fluid travel__img"/>


                    <p>С другой стороны Новый Свет имеет довольно посредственно развитую инфраструктуру. Вода сюда
                        подаётся лишь в течение пары часов в два дня, за которые жители и службы города должны успеть
                        набрать её в накопительные баки для дальнейшего использования. Вода сверх собранной будет
                        привозной и соответственно дорогой. В посёлке всего одна оставшаяся с советских времён крупная
                        гостиница, люди селятся в основном в съёмном жилье, а из-за отсутствия серьёзной конкуренции,
                        упомянутой проблемы с водой, а также, видимо, в массе своей не очень искушённого и
                        соответственно нетребовательного контингента отдыхающих, получают сервис не высокого качества.
                        Общепит также не на высоте. Точек таких не так много, а приличных ещё меньше. Пару раз
                        сталкивались просто с отсутствием продуктов, тыкая пальцем в меню, получали несколько
                        последовательных отказов от официанта, ссылающегося на недостаток ингредиентов для приготовления
                        блюд и напитков. К тому же, очень дорого. В столовой, «салат» из огурца со сметаной 50 рублей. В
                        сентябре, граммов двести порезанного огурца и ложка сметаны. Посчитайте маржу заведения сами.
                        Нет, в принципе, это объяснимо: моста на полуостров ещё нет, цена большинства продуктов включает
                        цену парома, сезон короток, другой работы здесь почти нет, и турист везёт уже не валюту, и в
                        сравнении с некоторыми зарубежными курортами не так уж дорого, но… не с таким же уровнем
                        сервиса, ей богу! Допустим, я согласен платить столько, сколько вы просите, я понимаю ваше
                        положение и считаю обоснованными ваши запросы, но за это окажите мне услугу по высшему разряду,
                        такую, чтобы я захотел приехать сюда ещё много раз, такую, что бы я мог сказать: «Дааа…, вот
                        помню, в Крыму мы отдыхали, вот где уровень! Не то, что в ваших грециях/турциях/тайландах…» Анн
                        нет, пока, увы.</p>

                    <img src={crimea_026} alt="Новый Свет" className="img-fluid travel__img"/>


                    <p>Во время пребывания в Новом Свете мы с женой решили сходить в поход на гору Куш-Кая (Сокол), взяв
                        экскурсию у распространителей прямо за продовольственным рынком. Куш-Кая является крупнейшим
                        коралловым рифом Европы и имеет высоту 473 метра. Вести туда должна была Татьяна Зотова, местный
                        краевед, проводник по здешним местам, автор книг по истории края. Группа подобралась небольшая –
                        7 человек, около 4 часов вечера мы двинулись в путь. Выйдя из посёлка, свернули мы в холмистую
                        рощу, и пошли на постепенный подъём. Маршрут у нас был, естественно, самым простым из возможных,
                        т.к. группа была разновозрастная и по-разному подготовленная. Так неспешно, мы поднимались
                        кверху, между кустами можжевельника и карликовых сосен.</p>

                    <img src={crimea_028} alt="Куш-Кая" className="img-fluid travel__img"/>


                    <p>Наш гид время от времени останавливала группу, чтобы дать всем немного передохнуть, параллельно
                        рассказывая о местности, о её природе и истории. В один момент дорога проходила через древний
                        мост, под которым был небольшой жёлоб давным-давно высохшего потока. Мост этот, как считается,
                        мог быть построен ещё в античные времена, т.к. в последующие эпохи он здесь попросту никому не
                        был нужен. Так постепенно мы добрались до верхушки рифа. Тропы здесь оканчивались, и несколько
                        десятков метров пришлось карабкаться «лёжа» на скале. Поскольку уклон был не большой, сделать
                        это было не трудно, и все, успешно преодолев препятствие, оказались на самой вершине. Отсюда
                        открывается вид сверху на Новый Свет, Чёрное море, Крымские горы, город Судак, начинающийся с
                        Генуэзской крепости.</p>

                    <img src={crimea_029} alt="Куш-Кая" className="img-fluid travel__img"/>


                    <p>Татьяна рассказала про то, как формировался полуостров. Оказывается, совсем ещё недавно, около 10
                        000 лет назад, полуострова не было. Но, из-за резкого поднятия уровня мирового океана (причины
                        этого явления оставим за рамками данного повествования), вода из Средиземного моря начала
                        переливаться в Чёрное. Выглядело это как работа тысяч Ниагарских водопадов, и в результате, была
                        затоплена обширная береговая зона, оставив возвышенный Крым частью суши и сформировав
                        полуостров. Преинтересная, надо сказать, история. Проведя небольшой семинар и фотосессию на
                        вершине, мы стали потихоньку собираться.</p>

                    <img src={crimea_030} alt="Куш-Кая" className="img-fluid travel__img"/>


                    <p>Тут на вершину подоспела группа туристов, поднявшаяся сюда самостоятельно и, кажется,
                        собиравшаяся здесь же заночевать. Мы же поспешили в обратный путь, т.к. время было закатное. В
                        итоге, не успев спуститься до наступления темноты, последнюю четверть пути мы шагали, включив
                        светодиоды на своих смартфонах. Ощущение от похода у нас осталось самое положительное.</p>

                    <img src={crimea_031} alt="Куш-Кая" className="img-fluid travel__img"/>


                    <p>К сожалению, приятные впечатления вскоре омрачилась моей с супругой болезнью. Пару дней мы
                        провалялись в постели из-за проблем с желудком. И, похоже, это было связано с водой. Либо водой
                        на пляже, всё-таки, в тихой бухте вода может застаиваться, и мало ли что сюда могло принести
                        волнами. Либо вода, которая накапливалась в посёлке, и использовалась для нужд, спровоцировала
                        такой эффект, не являясь проточной. К тому же погода стояла жаркая, все дни, что мы были в
                        Крыму, днём было под 30. Старожилы не припоминали такой погоды в конце сентября. Слава Богу,
                        детей удалось уберечь и они не заболели.</p>

                    <p>Оправившись от болезни, мы всё-таки успели съездить в Судак, посмотреть Генуэзскую крепость, и
                        сводить детей на аттракционы. На единственном новосветском автобусе перебрались в Судак и вышли
                        у крепости, для её осмотра. За крепостными стенами располагались некоторые сохранившиеся
                        хозяйственные и религиозные сооружения. В целом всё как всегда в подобных местах. Порадовало
                        отсутствие новостроя, хотя обратной стороной этой монеты была относительная разреженность
                        объектов на территории крепости. Также здесь располагался «музей пыток», видимо с арсеналом
                        инструментов средневековых истязателей, но туда мы не пошли.</p>

                    <img src={crimea_032} alt="Судак" className="img-fluid travel__img"/>


                    <p>А пошли мы на набережную, пройдя по которой, мы дошли до детского парка, где оставили детей в
                        игровой зоне, а сами воспользовались моментом. Жена воспользовалась им для похода по торговым
                        лавкам, а мы с сестрой для ужина. Решили зайти в какое-то грузинское заведение. Заказали по
                        шашлычку и графинчик лимонада. Порции надо сказать не грузинские, по четыре кусочка мяса,
                        украшенных колечками лука. А лимонад натуральный, по нехитрому рецепту, литр воды, два лимона и
                        сахар, но стоил он 400 рублей – нам показалось это чересчур.</p>

                    <p>Возвращались мы в Новый Свет около восьми часов вечера, прождав на остановке минут тридцать,
                        указанного в расписании автобуса так и не дождались, пришлось брать такси.</p>

                    <p>Подошло время прощаться с Крымом, мы, усевшись в заранее заказанный, через знакомых, микроавтобус
                        ехали в Симферополь, чтобы сесть на самолёт до Челябинска.</p>

                    <img src={crimea_033} alt="Новый Свет" className="img-fluid travel__img"/>


                        <p>Если подводить итог всем впечатлениям от поездки, то в целом, конечно, впечатления
                            положительные.
                            Особенно яркими моментами оказалась красивейшая природа Крыма, по части растительного и
                            ландшафтного разнообразия вообще полуостров удивляет, прежде всего, конечно, его южная
                            часть.
                            Историческое наследие этих мест, также богатейшее, воистину, туристический потенциал этих
                            мест
                            огромен. Но, ситуацию сильно портит общая неразвитость территории, ущербность сервиса и
                            малоконкурентная сфера гостинично-ресторанного бизнеса, а также неотлаженная сфера
                            транспорта.
                            Надеемся, что данные проблемы будут решаться, т.к. повторюсь эти места того стоят.</p>

                        <p>Видеоролик по итогам поездки.</p>
                        <div className="travel__iframeWrap">
                            <iframe className="travel__iframe"
                                    src="https://www.youtube.com/embed/CzZS8j3p5Pg?rel=0&amp;showinfo=0" frameBorder="0"
                                    allow="autoplay; encrypted-media" allowFullScreen></iframe>
                        </div>
                </div>
            </div>
        </div>
);
};

export default Crimea;
import React from 'react';
import {publicRoutes} from "./routing";
import {Route, Routes} from "react-router-dom";

const AppRouter = () => {
    return (
        <Routes>
            {
                publicRoutes.map(item =>
                    <Route
                        path={item.path}
                        element={<item.element />}
                        key={item.path}
                    />
                )
            }
        </Routes>
    );
};

export default AppRouter;
import React from 'react';
import pereslavl_01 from "../../../images/goldenRing/pereslavl_01.jpg";
import pereslavl_02 from "../../../images/goldenRing/pereslavl_02.jpg";
import pereslavl_03 from "../../../images/goldenRing/pereslavl_03.jpg";
import pereslavl_04 from "../../../images/goldenRing/pereslavl_04.jpg";
import pereslavl_05 from "../../../images/goldenRing/pereslavl_05.jpg";
import pereslavl_06 from "../../../images/goldenRing/pereslavl_06.jpg";
import rostov_01 from "../../../images/goldenRing/rostov_01.jpg";
import rostov_02 from "../../../images/goldenRing/rostov_02.jpg";
import rostov_03 from "../../../images/goldenRing/rostov_03.jpg";
import rostov_04 from "../../../images/goldenRing/rostov_04.jpg";
import moose from "../../../images/goldenRing/moose_00.jpg";
import vladimir_01 from "../../../images/goldenRing/vladimir_01.jpg";
import vladimir_02 from "../../../images/goldenRing/vladimir_02.jpg";
import vladimir_03 from "../../../images/goldenRing/vladimir_03.jpg";
import vladimir_04 from "../../../images/goldenRing/vladimir_04.jpg";
import vladimir_05 from "../../../images/goldenRing/vladimir_05.jpg";
import vladimir_06 from "../../../images/goldenRing/vladimir_06.jpg";
import vladimir_07 from "../../../images/goldenRing/vladimir_07.jpg";
import vladimir_08 from "../../../images/goldenRing/vladimir_08.jpg";
import pokrov from "../../../images/goldenRing/pokrovNaNerli_01.jpg";
import suzdal_01 from "../../../images/goldenRing/suzdal_01.jpg";
import suzdal_02 from "../../../images/goldenRing/suzdal_02.jpg";
import suzdal_03 from "../../../images/goldenRing/suzdal_04.jpg";
import svo from "../../../images/goldenRing/SVO_01.jpg";
import fobo from "../../../images/goldenRing/FoBo_01.jpg";
import ran_01 from "../../../images/goldenRing/RAN_01.jpg";
import ran_02 from "../../../images/goldenRing/RAN_02.jpg";
import ran_03 from "../../../images/goldenRing/RAN_03.jpg";
import ran_04 from "../../../images/goldenRing/RAN_04.jpg";


const GoldenRing = () => {
    return (
        <div className="container">

            <div className="row">
                <div className="col-12">
                    <p>
                        В&nbsp;последние выходные апреля, используя дополнительный первомайский свободный день едем с
                        друзьями по&nbsp;&laquo;Золотоу кольцу&raquo;.
                        План простой&nbsp;&mdash; мы&nbsp;с&nbsp;женой вылетаем в&nbsp;Шереметьево, встречаем друзей, забираем арендованную машину
                        и&nbsp;едем по&nbsp;маршруту:
                        Шереметьево&nbsp;&mdash; Переславь Залесский&nbsp;&mdash; Ростов&nbsp;&mdash; Владимир&nbsp;&mdash; Суздаль&nbsp;&mdash; Шереметьево. Сдаём машину там
                        же&nbsp;под вечер следующего дня.
                        1&nbsp;мая днём спокойно отдыхаем в&nbsp;Москве, вечером мы&nbsp;с&nbsp;женой улетаем обратно в&nbsp;Челябинск. Звучит
                        просто, поехали...
                    </p>
                    <p>
                        Итак в&nbsp;Шереметьево встречаем друзей, забираем на&nbsp;парковке терминала&nbsp;Б машину и&nbsp;вперёд. По&nbsp;выезду
                        с&nbsp;парковки почти сразу
                        попадаем на&nbsp;платную дорогу, по&nbsp;которой какое-то время едем свободно, затем попадаем в&nbsp;пробку на
                        съезде. Постепенно минуем&nbsp;её,
                        через несколько часов мы&nbsp;на&nbsp;родине Александра Невского в&nbsp;Переславле Залесском.
                    </p>
                    <p>
                        Кушаем в&nbsp;блинной, вкусными супами и&nbsp;блинами соответсвенно. Едём на&nbsp;осмотр города.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div id="pereslavl" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#pereslavl" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#pereslavl" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={pereslavl_01} className="d-block w-100" alt="Переславль Залесский"/>
                            </div>
                            <div className="carousel-item">
                                <img src={pereslavl_02} className="d-block w-100" alt="Переславль Залесский"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#pereslavl" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#pereslavl" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <img src={pereslavl_03} className="img-fluid travel__img" loading="lazy"
                         alt="Переславль Залесский"/>
                </div>
                <div className="col-md-6">
                    <img src={pereslavl_04} className="img-fluid travel__img" loading="lazy"
                         alt="Переславль Залесский"/>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div id="pereslavl02" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#pereslavl02" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#pereslavl02" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={pereslavl_05} className="d-block w-100" alt="Переславль Залесский"/>
                            </div>
                            <div className="carousel-item">
                                <img src={pereslavl_06} className="d-block w-100" alt="Переславль Залесский"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#pereslavl02" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#pereslavl02" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <p>
                        Ближе к&nbsp;вечеру приезжаем в&nbsp;Ростов. Осматриваем Успенский кафедральный собор и&nbsp;его окрестности.
                        В&nbsp;соборе чувствуется настойщих дух времени. Он&nbsp;настоящий.
                    </p>
                </div>
            </div>

            <div className="col-12">
                <div id="rostov" className="carousel slide" data-bs-ride="true">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#rostov" data-bs-slide-to="0"
                                className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#rostov" data-bs-slide-to="1"
                                aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#rostov" data-bs-slide-to="2"
                                aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#rostov" data-bs-slide-to="3"
                                aria-label="Slide 4"></button>
                    </div>
                    <div className="carousel-inner travel__img">
                        <div className="carousel-item active">
                            <img src={rostov_01} className="d-block w-100" alt="Ростов"/>
                        </div>
                        <div className="carousel-item">
                            <img src={rostov_02} className="d-block w-100" alt="Ростов"/>
                        </div>
                        <div className="carousel-item">
                            <img src={rostov_03} className="d-block w-100" alt="Ростов"/>
                        </div>
                        <div className="carousel-item">
                            <img src={rostov_04} className="d-block w-100" alt="Ростов"/>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button"
                            data-bs-target="#rostov" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button"
                            data-bs-target="#rostov" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <p>
                        Едем дальше ночевка по&nbsp;плану во&nbsp;Владимире. Уже темнеет. Едем в&nbsp;живописной местности среди
                        низкого тумана.
                        Дорогу перебегает лось. Никто не&nbsp;пострадал. ;-)
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <img src={moose} className="img-fluid travel__img" loading="lazy"
                         alt="По дороге во Владимир"/>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <p>
                        Уже заполночь приезжаем во&nbsp;Владимир. Перекусываем в&nbsp;круглосуточном Burger King. Затем
                        самозаселяемся в&nbsp;арендованную квартиру.
                        Наконец-то отдых.
                    </p>

                    <p>
                        На&nbsp;утро выбираемся на&nbsp;завтрак и&nbsp;осмотр достопримечательностей, коих много.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="col-12">
                        <div id="vladimir01" className="carousel slide" data-bs-ride="true">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#vladimir01" data-bs-slide-to="0"
                                        className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#vladimir01" data-bs-slide-to="1"
                                        aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#vladimir01" data-bs-slide-to="2"
                                        aria-label="Slide 3"></button>
                                <button type="button" data-bs-target="#vladimir01" data-bs-slide-to="3"
                                        aria-label="Slide 4"></button>
                                <button type="button" data-bs-target="#vladimir01" data-bs-slide-to="4"
                                        aria-label="Slide 5"></button>
                                <button type="button" data-bs-target="#vladimir01" data-bs-slide-to="5"
                                        aria-label="Slide 6"></button>
                            </div>
                            <div className="carousel-inner travel__img">
                                <div className="carousel-item active">
                                    <img src={vladimir_01} className="d-block w-100" alt="Владимир"/>
                                </div>
                                <div className="carousel-item">
                                    <img src={vladimir_02} className="d-block w-100" alt="Владимир"/>
                                </div>
                                <div className="carousel-item">
                                    <img src={vladimir_03} className="d-block w-100" alt="Владимир"/>
                                </div>
                                <div className="carousel-item">
                                    <img src={vladimir_04} className="d-block w-100" alt="Владимир"/>
                                </div>
                                <div className="carousel-item">
                                    <img src={vladimir_06} className="d-block w-100" alt="Владимир"/>
                                </div>
                                <div className="carousel-item">
                                    <img src={vladimir_07} className="d-block w-100" alt="Владимир"/>
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button"
                                    data-bs-target="#vladimir01" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button"
                                    data-bs-target="#vladimir01" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div id="vladimir02" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#vladimir02" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#vladimir02" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={vladimir_05} className="d-block w-100" alt="Владимир"/>
                            </div>
                            <div className="carousel-item">
                                <img src={vladimir_08} className="d-block w-100" alt="Владимир"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#vladimir02" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#vladimir02" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <p>
                        Время поджимает. Едем в&nbsp;находящийся неподалёку знаменитый храм Покров на&nbsp;Нерли&nbsp;&mdash; 12&nbsp;века
                        постройки.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <img src={pokrov} className="img-fluid travel__img" loading="lazy"
                         alt="Покров на Нерли"/>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <p>
                        Дальше в&nbsp;Суздаль. Уютный город. Смотрим самый центр. Начинатся дождь. Обедаем в&nbsp;Харчевне.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-8">
                    <img src={suzdal_01} className="img-fluid travel__img" loading="lazy"
                         alt="Суздаль"/>
                </div>

                <div className="col-md-4">
                    <img src={suzdal_02} className="img-fluid travel__img" loading="lazy"
                         alt="Суздаль"/>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <img src={suzdal_03} className="img-fluid travel__img" loading="lazy"
                         alt="Суздаль"/>
                </div>
            </div>

            <div className="row">
                <div className="col-md-7">
                    <p>
                        После Суздаля едем обратно в&nbsp;Москву. Поначалу всё было гладко, дорога свободна. Но&nbsp;ближе к
                        Москву встали в&nbsp;просто
                        мёртвую пробку часа на&nbsp;три. Из&nbsp;которых, наверное, около часа просто стояли не&nbsp;двигаясь с&nbsp;места.
                    </p>
                    <p>
                        В&nbsp;итоге, конечно, прорвались. Заехали в&nbsp;стольный град около полуночи. Заправили автомобиль,
                        подождав получасовой &laquo;Учёт&raquo;,
                        который почему-то был на&nbsp;всех АЗС одновременно. По&nbsp;МКАДу доехали, наконец, до&nbsp;Шереметьево, чтобы
                        оставить машину на&nbsp;парковке
                        Терминала&nbsp;Б.
                    </p>
                    <p>
                        Уставшие и&nbsp;довольные, что всё закончилось, поехали по&nbsp;друзьям, чтобы переночевать.
                    </p>
                </div>
                <div className="col-md-5">
                    <img src={svo} className="img-fluid travel__img" loading="lazy"
                         alt="Шереметьево"/>
                </div>
            </div>

            <div className="row">
                <div className="col-md-5">
                    <img src={fobo} className="img-fluid travel__img" loading="lazy"
                         alt="Фобо"/>
                </div>

                <div className="col-md-7">
                    <p>
                        Следующий день почти полностью был посвящён рекреационным процедурам. Мы&nbsp;с&nbsp;Лёхой отъедались
                        вьетнамским супчиком, и
                        неспешно прогуливались по&nbsp;райончику за&nbsp;приятной беседой.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <p>Перед нашим с&nbsp;женой вылетом все простились перед монументальным зданием Российской Академии
                        Наук.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div id="ran" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#ran" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#ran" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#ran" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#ran" data-bs-slide-to="3"
                                    aria-label="Slide 4"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={ran_01} className="d-block w-100" alt="Здание РАН"/>
                            </div>
                            <div className="carousel-item">
                                <img src={ran_02} className="d-block w-100" alt="Здание РАН"/>
                            </div>
                            <div className="carousel-item">
                                <img src={ran_03} className="d-block w-100" alt="Здание РАН"/>
                            </div>
                            <div className="carousel-item">
                                <img src={ran_04} className="d-block w-100" alt="Здание РАН"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#ran" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#ran" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            
            <div className="row">
                <div className="col-12">
                    <p>
                        Нескучная выдалась поездка! Жаль только отдохнуть от&nbsp;неё на&nbsp;следующий день приходось уже на&nbsp;рабочем месте... ;-)
                    </p>
                </div>
            </div>

        </div>
    )
        ;
};

export default GoldenRing;
import React from 'react';
import beloyar_01 from '../../../images/kurgan/beloyar_01.jpg';
import beloyar_02 from '../../../images/kurgan/beloyar_02.jpg';
import karachelskoe_01 from '../../../images/kurgan/karachelskoe_01.jpg';
import karachelskoe_02 from '../../../images/kurgan/karachelskoe_02.jpg';
import vvedenskoe_02 from '../../../images/kurgan/vvedenskoe_02.jpg';
import vvedenskoe_01 from '../../../images/kurgan/vvedenskoe_01.jpg';
import kurgan_01 from '../../../images/kurgan/kurgan_01.jpg';
import kurgan_02 from '../../../images/kurgan/kurgan_02.jpg';
import kurgan_03 from '../../../images/kurgan/kurgan_03.jpg';
import kurgan_04 from '../../../images/kurgan/kurgan_04.jpg';
import kurgan_05 from '../../../images/kurgan/kurgan_05.jpg';
import kurgan_06 from '../../../images/kurgan/kurgan_06.jpg';

const Kurgan = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <p>Апрель 2016. Едем в Курганскую область. До границы между областями неожиданно близко - километров
                        пятьдесят. Дорога, поначалу, по нынешним весенним меркам хорошая. Первый пункт назначения село
                        Белоярское. Ожидаем здесь увидеть полуразрушенную церковь, но видим почти полностью
                        отреставрированную.</p>
                    <img src={beloyar_01} alt="Белоярское" className="img-fluid travel__img"/>
                    <p>Церковь носит имя Флора и Лавра, и на самом деле, работы осталось совсем немного. Храм уже
                        действует, он открыт по воскресениям.</p>
                    <img src={beloyar_02} alt="Белоярское" className="img-fluid travel__img"/>
                    <p>Следующая остановка село Карачельское. Едем к церкви Трёх Святителей. Ситуация очень похожая.
                        Ожидаем увидеть полуразрушенный храм, но видим почти полностью отреставрированный.</p>
                    <img src={karachelskoe_01} alt="Карачельское" className="img-fluid travel__img"/>
                    <p>Да, две церкви очень напоминают друг друга, т.к. они строились в одно время и по одному проекту.
                        На сегодняшний день церковь в Карачельском немного отстаёт от своей Белоярской сестры и ещё не
                        работает. Службы проходят в здании рядом, оно выполняет роль временной обители.</p>
                    <img src={karachelskoe_02} alt="Карачельское" className="img-fluid travel__img"/>
                    <p>Двигаемся дальше в сторону Кургана, дороги всё хуже, достигаем села Введенского. Нам сюда.
                        Церковь Введения во храм Пресвятой Богородицы. Единственная куда удалось зайти. Внутри красивая
                        роспись, видимо частично восстановленная, т.к. под куполом она отсутствует. Снимать в храме
                        запрещено, к сожалению.</p>
                    <img src={vvedenskoe_02} alt="Введенское" className="img-fluid travel__img"/>
                    <p>Возле церкви сказочного вида Святой источник.</p>
                    <img src={vvedenskoe_01} alt="Введенское" className="img-fluid travel__img"/>
                    <p>Достигаем Кургана. Первый мост в сторону центра становиться для нас испытанием, дорожное покрытие
                        практически отсутствует. Дальше лучше. Пробираемся к центру. Оставляем машину у прокуратуры и
                        идём пешком. Первые впечатления положительные. У города сохранилась историческая застройка.
                        Пусть она местами в плачевном состоянии, но она есть, что сразу добавляет сто очков городу, в
                        сравнении, например, с Челябинском. Ощущение, что идёшь по местам давно сложившимся, исторически
                        задействованным, создаёт месту одухотворённость. Хочется относится к этим улицам бережней, с
                        уважением, как к пожилым. И наоборот, когда на месте старых зданий с целью одной лишь
                        экономической выгоды возводят бездушные бетонные стены, которые больше напоминают клетки
                        инкубаторов - это вызывает у меня отвращение. Короче, в этом смысле в Кургане не всё так плохо.
                        Только хочется, чтобы больше ухаживали за этой стариной.</p>
                    <p>А вот и новострой - Богоявленский храм города Кургана. Но такому новострою глаз только
                        радуется.</p>
                    <img src={kurgan_01} alt="Богоявленский храм города Кургана" className="img-fluid travel__img"/>
                    <p>Дальше идём в городской парк. Не знаю, как будет летом, но по весне он оставляет удручающее
                        впечатление. Постепенно доходим до сердца города. Аллея Славы и Вечный огонь.</p>
                    <img src={kurgan_02} alt="Курган" className="img-fluid travel__img"/>
                    <p>Памятник местному полководцу. Там же.</p>
                    <img src={kurgan_03} alt="Курган" className="img-fluid travel__img"/>
                    <p>Площадь Ленина. Ленин присутствует.</p>
                    <img src={kurgan_04} alt="Курган" className="img-fluid travel__img"/>
                    <p>Здесь неподалёку ещё одна церковь, сквер. Это здание Госбанка.</p>
                    <img src={kurgan_05} alt="Курган" className="img-fluid travel__img"/>
                    <p>В целом Курган оставил, лично у меня, неплохое впечатление. Есть депрессивные моменты, но это
                        скорее из-за ранней весны. Если сделать дороги, отремонтировать фасады и благоустроить парки
                        было бы довольно симпатично.</p>
                    <p>Напоследок любопытный пример того, как Курганцы переходят дорогу на перекрёстке, под сорок пять
                        градусов для экономии времени, очень удобно.</p>
                    <img src={kurgan_06} alt="Курган" className="img-fluid travel__img"/>
                </div>
            </div>
        </div>
    );
};

export default Kurgan;
import React, {useState} from 'react';
import {BrowserRouter} from "react-router-dom";
import './App.css';
import AppRouter from "./routing/AppRouter";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";

const App = () => {
    const [mobileMnu, setMobileMnu] = useState(false);

    return (
        <BrowserRouter>
            <Navbar mobileMnu={mobileMnu} setMobileMnu={setMobileMnu}/>
            <AppRouter/>
            <Footer/>
        </BrowserRouter>
    );
};

export default App;
import Main from "../pages/Main/Main";
import Travel from "../pages/Travel/Travel";
import Quotes from "../pages/Quotes/Quotes";
import Music from "../pages/Music/Music";
import VolgaCaspiy from "../pages/TravelPages/VolgaCaspiy/VolgaCaspiy";
import Piter2020 from "../pages/TravelPages/Piter2020/Piter2020";
import Bulgaromania from "../pages/TravelPages/Bulgaromania/Bulgaromania";
import Portugal from "../pages/TravelPages/Portugal/Portugal";
import Crimea from "../pages/TravelPages/Crimea/Crimea";
import Tyumen from "../pages/TravelPages/Tyumen/Tyumen";
import Deneb from "../pages/TravelPages/Deneb/Deneb";
import Transcaucasia from "../pages/TravelPages/Transcaucasia/Transcaucasia";
import Kurgan from "../pages/TravelPages/Kurgan/Kurgan";
import BigArmenia from "../pages/TravelPages/BigArmenia/BigArmenia";
import Zakavkaz from "../pages/TravelPages/Zakavkaz/Zakavkaz";
import Sevkaveksp from "../pages/TravelPages/Sevkaveksp/Sevkaveksp";
import Elbrus2021 from "../pages/TravelPages/Elbrus2021/Elbrus2021";
import Commandline from "../pages/Commandline/Commandline";
import Averkina from "../pages/TravelPages/Averkina/Averkina";
import GoldenRing from "../pages/TravelPages/GoldenRing/GoldenRing";

export const publicRoutes = [
    {path: '/main', element: Main},
    {path: '/travel', element: Travel},
    {path: '/quotes', element: Quotes},
    {path: '/music', element: Music},
    {path: '/volga-caspiy', element: VolgaCaspiy},
    {path: '/piter2020', element: Piter2020},
    {path: '/bulgaromania', element: Bulgaromania},
    {path: '/portugal', element: Portugal},
    {path: '/crimea', element: Crimea},
    {path: '/tyumen', element: Tyumen},
    {path: '/deneb', element: Deneb},
    {path: '/transcaucasia', element: Transcaucasia},
    {path: '/kurgan', element: Kurgan},
    {path: '/bigArmenia', element: BigArmenia},
    {path: '/zakavkaz', element: Zakavkaz},
    {path: '/sevkaveksp', element: Sevkaveksp},
    {path: '/elbrus2021', element: Elbrus2021},
    {path: '/averkina', element: Averkina},
    {path: '/goldenRing', element: GoldenRing},
    {path: '/commandline', element: Commandline},
    {path: '*', element: Main},
]
import React from 'react';
import center_001 from '../../../images/piter/center_001.jpg';
import center_002 from '../../../images/piter/center_002.jpg';
import center_003 from '../../../images/piter/center_003.jpg';
import center_004 from '../../../images/piter/center_004.jpg';
import grand_maket_0 from '../../../images/piter/grand_maket-0.jpg';
import grand_maket_1 from '../../../images/piter/grand_maket-1.jpg';
import grand_maket_2 from '../../../images/piter/grand_maket-2.jpg';
import grand_maket_3 from '../../../images/piter/grand_maket-3.jpg';
import hermitage_01 from '../../../images/piter/hermitage_01.jpg';
import hermitage_02 from '../../../images/piter/hermitage_02.jpg';
import isaac_0 from '../../../images/piter/isaac-0.jpg';
import isaac_1 from '../../../images/piter/isaac-1.jpg';
import isaac_2 from '../../../images/piter/isaac-2.jpg';
import isaac_3 from '../../../images/piter/isaac-3.jpg';
import isaac_4 from '../../../images/piter/isaac-4.jpg';
import lahta_001 from '../../../images/piter/lahta_001.jpg';
import podezd_001 from '../../../images/piter/podezd_001.jpg';
import podezd_002 from '../../../images/piter/podezd_002.jpg';
import pushkin_001 from '../../../images/piter/pushkin_001.jpg';
import submarine_001 from '../../../images/piter/submarine_001.jpg';
import submarine_002 from '../../../images/piter/submarine_002.jpg';
import vitebski_second_1 from '../../../images/piter/vitebski-second-1.jpg';
import vitebski_second_2 from '../../../images/piter/vitebski-second-2.jpg';
import vitebskiy_01 from '../../../images/piter/vitebskiy_01.jpg';
import vitebskiy_02 from '../../../images/piter/vitebskiy_02.jpg';
import zenit_basket from '../../../images/piter/zenit-basket.jpg';

const Piter2020 = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <p>Февраль 2020. Поездка в&nbsp;Питер. Я&nbsp;еду сюда второй раз, спустя 23&nbsp;года. Еду
                        с&nbsp;семьёй,
                        но&nbsp;к&nbsp;нам на&nbsp;месте, на&nbsp;несколько дней присоединится наш старый друг.</p>
                    <p>Раннее утро субботы. Питер встречает нас мягким морским воздухом и&nbsp;отсутсвием снежного
                        покрова. Чуть позже в&nbsp;такси монументальными зданиями Московского проспекта
                        и&nbsp;одноименными воротами.</p>
                    <p>Первая точка высадки&nbsp;&mdash; здание Витебского вокзала. Это старейший вокзал в&nbsp;России,
                        отсюда была пущена первая железнодорожная линия в&nbsp;1837 году при Николае I. Здесь
                        мы&nbsp;с&nbsp;вполне практичной целью&nbsp;&mdash; сдать чемодан в&nbsp;камеру хранения, т.к.
                        заселение в&nbsp;квартиру на&nbsp;Гороховой у&nbsp;нас только днём.</p>
                    <p>Поскольку хождение с&nbsp;фотоаппаратом это прерогатива моей жены, здесь и&nbsp;везде скажем
                        ей&nbsp;спасибо за&nbsp;снимки.</p>
                </div>
                <div className="col-md-6">
                    <div id="vitebsky" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#vitebsky" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#vitebsky" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={vitebskiy_01} className="d-block w-100"
                                     alt="Витебский вокзал"/>
                            </div>
                            <div className="carousel-item">
                                <img src={vitebskiy_02} className="d-block w-100"
                                     alt="Витебский вокзал"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#vitebsky" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#vitebsky" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-6">
                    <div id="grandmaket" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#grandmaket" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#grandmaket" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#grandmaket" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#grandmaket" data-bs-slide-to="3"
                                    aria-label="Slide 4"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={grand_maket_0} className="d-block w-100"
                                     alt="Гранд Макет"/>
                            </div>
                            <div className="carousel-item">
                                <img src={grand_maket_1} className="d-block w-100"
                                     alt="Гранд Макет"/>
                            </div>
                            <div className="carousel-item">
                                <img src={grand_maket_2} className="d-block w-100"
                                     alt="Гранд Макет"/>
                            </div>
                            <div className="carousel-item">
                                <img src={grand_maket_3} className="d-block w-100"
                                     alt="Гранд Макет"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#grandmaket" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#grandmaket" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>

                <div className="col-md-6">
                    <p>Чтобы скоратать время, и&nbsp;заодно развлечь ребёнка едем в&nbsp;
                        <del>Гранд-Маркет</del>
                        ... &laquo;Гранд-Макет&raquo;. Выставочный комплекс в&nbsp;котором представлен сокращённый
                        макет разных уголков России. Здесь по&nbsp;дорогам ездит транспорт, некоторые модели людей
                        и&nbsp;животных совершают определённые для них движения. Периодически включается ночной
                        режим, при котором верхнее освещение в&nbsp;зале затухает, а&nbsp;на&nbsp;макете наоборот
                        загораются фонари вдоль дорог и&nbsp;фары транспортных средств. Представлены территории
                        от&nbsp;Калининграда до&nbsp;Владивостока, но&nbsp;конечно не&nbsp;в&nbsp;полном объёме,
                        а&nbsp;лишь с&nbsp;точки зрения наличия характерных для регионов ландшафтов и&nbsp;строений.
                        В&nbsp;общем тут весьма весело, и&nbsp;не&nbsp;только детям.
                    </p>
                    <p>Здесь мы&nbsp;и&nbsp;встретились с&nbsp;нашим другом Алексеем, с&nbsp;которым посетили
                        выставку, а&nbsp;затем пообедали здесь&nbsp;же на&nbsp;первом этаже выставочного комплекса,
                        употребив за&nbsp;встречу суп и&nbsp;второе. После чего все вместе отправились обратно
                        на&nbsp;Витебский вокзал за&nbsp;нашим чемоданом.</p>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-6">
                    <p>Забрав наш багаж, мы&nbsp;отправились на&nbsp;заселение заказанных площадей.
                        Я&nbsp;с&nbsp;семьёй в&nbsp;квартиру, а&nbsp;Лёха в&nbsp;гостинницу, которая находилась
                        в&nbsp;10&nbsp;минутах хода от&nbsp;нас.</p>
                    <p>Ближе к&nbsp;вечеру&nbsp;же мы&nbsp;собрались на&nbsp;променад по&nbsp;центральным улицам
                        Питера.</p>
                    <p>Ну, что тут сказать, центр здесь конечно очень красивый, и&nbsp;довольно чистый, даже
                        несмотря на&nbsp;погоду, которая здесь в&nbsp;феврале дождливая. В&nbsp;чём&nbsp;же
                        причина? Во-первых влажность не&nbsp;позволяет скапливаться пыли. Во-вторых,
                        в&nbsp;центре здесь по-европейски узкие тротуары, не&nbsp;оставляющие места для газонов
                        со&nbsp;своего края, а&nbsp;значит нет проистекающей от&nbsp;туда грязи. В-третьих общий
                        уровень культуры. Несмотря на&nbsp;почти полное отсутсвие урн на&nbsp;улицах, мусора
                        здесь почти нет. Немного и&nbsp;сабаководов. Скорее всего в&nbsp;отдалённых
                        от&nbsp;центра районах ситуация несколько будет отличаться, но&nbsp;в&nbsp;районах
                        с&nbsp;исторической застройкой, а&nbsp;это довольно солидная часть города всё обстоит
                        именно так.</p>
                </div>

                <div className="col-md-6">
                    <div id="center01" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#center01"
                                    data-bs-slide-to="0" className="active" aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#center01"
                                    data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#center01"
                                    data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#center01"
                                    data-bs-slide-to="3" aria-label="Slide 4"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={center_001} className="d-block w-100"
                                     alt="Центр Петербурга"/>
                            </div>
                            <div className="carousel-item">
                                <img src={center_002} className="d-block w-100"
                                     alt="Центр Петербурга"/>
                            </div>
                            <div className="carousel-item">
                                <img src={center_003} className="d-block w-100"
                                     alt="Центр Петербурга"/>
                            </div>
                            <div className="carousel-item">
                                <img src={center_004} className="d-block w-100"
                                     alt="Центр Петербурга"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#center01" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#center01" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <br/>

            <div className="row">

                <div className="col-md-6">
                    <div id="isaac" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#isaac"
                                    data-bs-slide-to="0" className="active" aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#isaac"
                                    data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#isaac"
                                    data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#isaac"
                                    data-bs-slide-to="3" aria-label="Slide 4"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={isaac_0} className="d-block w-100"
                                     alt="Иссакиевский собор"/>
                            </div>
                            <div className="carousel-item">
                                <img src={isaac_1} className="d-block w-100"
                                     alt="Вид с Исаакиевского собора"/>
                            </div>
                            <div className="carousel-item">
                                <img src={isaac_2} className="d-block w-100"
                                     alt="Вид с Исаакиевского собора"/>
                            </div>
                            <div className="carousel-item">
                                <img src={isaac_3} className="d-block w-100"
                                     alt="Вид с Исаакиевского собора"/>
                            </div>
                            <div className="carousel-item">
                                <img src={isaac_4} className="d-block w-100"
                                     alt="Вид с Исаакиевского собора"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#isaac" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#isaac" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>

                <div className="col-md-6">
                    <p>На&nbsp;следующий день, снова решили выбратся в&nbsp;центр, метр за&nbsp;метром
                        и&nbsp;прямо к&nbsp;храму <del>но&nbsp;сперва</del> ...
                    </p>
                    <p>Взабрались под купол Исаакиевского собора, посмотрели на&nbsp;город сверху.</p>
                    <p>Затем пошли в&nbsp;Петропавловскую крепость. Погуляли. Покушали
                        в&nbsp;&laquo;Петровской&raquo; столовой. Посетили музей космонавтики.</p>
                    <p>Поездка в&nbsp;Царское село была намечена на&nbsp;следующий день.</p>
                </div>

            </div>

            <br/>

            <div className="row">

                <div className="col-md-6">
                    <p>На&nbsp;утро вновь едем на&nbsp;Витебский вокзал и&nbsp;покупаем билеты
                        на&nbsp;электричку до&nbsp;Пушкина. Электричка стоит сравнимо с&nbsp;поездкой
                        на&nbsp;метро. Пока ждём смотрим вокзал подробней.</p>
                </div>

                <div className="col-md-6">
                    <div id="vitebski-second" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#vitebski-second"
                                    data-bs-slide-to="0" className="active" aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#vitebski-second"
                                    data-bs-slide-to="1" aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={vitebski_second_1}
                                     className="d-block w-100" alt="Витебский вокзал"/>
                            </div>
                            <div className="carousel-item">
                                <img src={vitebski_second_2}
                                     className="d-block w-100" alt="Витебский вокзал"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#vitebski-second" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#vitebski-second" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <br/>

            <div className="row">

                <div className="col-md-6">
                    <img src={pushkin_001} alt="Царское село" className="img-fluid travel__img"/>
                </div>

                <div className="col-md-6">
                    <p>Доезжаем до&nbsp;Пушкина на&nbsp;электричке, а&nbsp;затем на&nbsp;автобусе
                        до&nbsp;Царского села. Здесь сад и&nbsp;дворец. Что тут скажешь, красиво.
                        Даже в&nbsp;феврале.</p>
                    <p>Обратно решили добираться на&nbsp;такси до&nbsp;ближайшего метро. Таксист
                        после первого перекрестка, почти на&nbsp;чистом русском жаловался, что
                        мол: &laquo;Некатырые водители не&nbsp;панимают, что такое помэх
                        справа&raquo;, а&nbsp;ещё через два перекрестка благополучно,
                        но&nbsp;с&nbsp;риском для нашего здоровья проехал на&nbsp;красный сигнал
                        светофора.</p>
                    <p>Вечером у&nbsp;нас был прощальный ужин, так как, к&nbsp;нашему сожалению,
                        Лёха был вынужден с&nbsp;нами проститься. Утром он&nbsp;уезжал
                        в&nbsp;Москву.</p>
                </div>

            </div>

            <br/>

            <div className="row">
                <div className="col-md-6">
                    <p>Теперь мы&nbsp;продолжили шатания по&nbsp;городу втроем. Жене удалось
                        проникнуть в&nbsp;некоторые дворы и&nbsp;парадные.</p>
                </div>

                <div className="col-md-6">
                    <div id="podezd" className="carousel slide"
                         data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#podezd"
                                    data-bs-slide-to="0" className="active" aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#podezd"
                                    data-bs-slide-to="1" aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={podezd_001}
                                     className="d-block w-100" alt="Центр Петербурга"/>
                            </div>
                            <div className="carousel-item">
                                <img src={podezd_002}
                                     className="d-block w-100" alt="Центр Петербурга"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#podezd"
                                data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon"
                                                          aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#podezd"
                                data-bs-slide="next">
                                                    <span className="carousel-control-next-icon"
                                                          aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-6">
                    <img src={lahta_001} alt="Лахта центр" className="img-fluid travel__img"/>
                </div>

                <div className="col-md-6">
                    <p>Потом поехали на&nbsp;берег Финского залива, посмотреть самое высокое
                        здание в&nbsp;Европе&nbsp;&mdash; Лахта-центр.</p>
                    <p>Вечером поехали на&nbsp;Васильевский остров. Прогулялись. Перекусили
                        в&nbsp;одной из&nbsp;булочных Ф.&nbsp;Вольчека, которых
                        в&nbsp;Питере, наверное больше сотни. Но&nbsp;места трудно найти
                        почти в&nbsp;каждой, сильно уж&nbsp;они популярны.</p>
                </div>
            </div>

            <div className="row">

                <div className="col-md-6">
                    <p>На&nbsp;следующий день, опять сланялись по&nbsp;центру. А&nbsp;затем поехали к&nbsp;подлодке
                        Д-2 &laquo;Народоволец&raquo; времён ВОВ. Сначала на&nbsp;метро, затем на&nbsp;трамвае. Билет
                        на&nbsp;трамвай стоил- 50&nbsp;руб. К&nbsp;слову, обратно до&nbsp;метро ехали уже на&nbsp;такси,
                        оказалось, на&nbsp;троих так даже дешевле, чем на&nbsp;общественном транспорте.</p>

                </div>

                <div className="col-md-6">
                    <div id="submarine" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#submarine" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#submarine" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={submarine_001} className="d-block w-100"
                                     alt="Подлодка Д2"/>
                            </div>
                            <div className="carousel-item">
                                <img src={submarine_002} className="d-block w-100"
                                     alt="Подлодка Д2"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#submarine" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#submarine" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <br/>

            <div className="row">

                <div className="col-md-6">
                    <img src={hermitage_01} alt="Эрмитаж" className="img-fluid travel__img"/>
                </div>

                <div className="col-md-6">
                    <p>На&nbsp;следующее утро наступил четверг, и&nbsp;у&nbsp;нас был намечен поход в&nbsp;Эрмитаж.
                        В&nbsp;этот день проход в&nbsp;Зимний дворец был бесплатен. Впрочем, ожидание в&nbsp;очереди
                        не&nbsp;было столь безнадёжным как я&nbsp;ожидал. Не&nbsp;прошло и&nbsp;часа, как
                        мы&nbsp;вошли внутрь.</p>

                    <p>Из&nbsp;своего прошлого посещения музея, я&nbsp;отчётливо помнил только Мадонну Литту
                        от&nbsp;Леонардо, поэтому интересно было поблуждать по&nbsp;залам. Особенно интересны лично
                        мне были залы древнего востока и&nbsp;азии.</p>

                    <p>Спустя&nbsp;3,5 часа мы&nbsp;поняли, что всех залов нам&nbsp;все равно не&nbsp;обойти
                        и&nbsp;спустились вниз, напоследок осмотрев Египетский.</p>

                </div>


            </div>

            <br/>

            <div className="row">
                <div className="col-md-6">
                    <p>Вечером нас с&nbsp;сыном ждал подарок. Жена презентовала нам билеты на&nbsp;матч
                        баскетбольной Евролиги. И&nbsp;мы&nbsp;с&nbsp;ним отправились на&nbsp;Сибур Арену, где
                        местный Зенит принимал клуб Альба из&nbsp;Берлина.</p>
                    <p>Признаться, не&nbsp;смотря на&nbsp;свой почти уже 25&nbsp;летний стаж баскетбольного
                        болельщика, на&nbsp;таком масштабном баскетбольном соревновании я&nbsp;присутсвовал
                        впервые. Поэтому впечателения были самые положительные. Организация матча была проведена
                        на&nbsp;великолепном уровне, жаль только, что наши в&nbsp;итоге уступили гостям,
                        не&nbsp;смотря на&nbsp;мощное начало.</p>
                </div>

                <div className="col-md-6">
                    <img src={zenit_basket} alt="БК Зенит" className="img-fluid travel__img"/>
                </div>
            </div>


            <br/>

            <div className="row">

                <div className="col-md-12">
                    <p>Последний день в&nbsp;Питере, мы&nbsp;решили разделиться. Сначала жена с&nbsp;сыном
                        посещали океанариум, пока я&nbsp;прогуливался до&nbsp;Александро-Невской Лавры.
                        А&nbsp;затем я&nbsp;с&nbsp;сыном отправился в&nbsp;вояж по&nbsp;ещё
                        не&nbsp;изученным станциям метро и&nbsp;тем, что над ними, пока супруга посещала
                        Мариинский театр.</p>

                    <p>Вообщем, каждому по&nbsp;потребностям, такой он&nbsp;Питер. Один из&nbsp;приятнейших
                        городов мира, и&nbsp;точно мой самый любимый в&nbsp;России. Я&nbsp;думаю, это наша
                        не&nbsp;последняя встреча, т.к.&nbsp;мы&nbsp;осмотрели далеко не&nbsp;всё что
                        хотели, да&nbsp;и&nbsp;просто насладиться уже увиденным здесь было&nbsp;бы
                        неплохо.</p>
                </div>


            </div>

            <div className="row">
                <div className="col-md-12">
                    <img src={hermitage_02} alt="Питер" className="img-fluid travel__img"/>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-md-12">
                    <p>Ну&nbsp;и&nbsp;напоследок, небольшая видео-зарисовочка с&nbsp;поездки.</p>
                </div>
            </div>

            <div className="row">
                <div className="travel__iframeWrap">
                    <iframe className="travel__iframe"
                            src="https://player.vimeo.com/video/393449939" frameBorder="0"
                            allow="autoplay; fullscreen" allowFullScreen></iframe>
                </div>
            </div>

        </div>
    );
};

export default Piter2020;
import React from 'react';

const Commandline = () => {
    return (
        <>
            <div className="h2 ms-1 mt-2">Cmd</div>
            <div className="container">
                <blockquote className="blockquote text-end">
                    <p className="mb-3">dir /b>filelist.txt</p>
                    <footer className="blockquote-footer">выводит имена всех файлов в папке в текстовый файл</footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">dir /s>filelist.txt</p>
                    <footer className="blockquote-footer">выводит имена всех файлов в папке, включая подпапки в
                        текстовый файл
                    </footer>
                </blockquote>
            </div>

            <div className="h2 ms-1 mt-2">Powershell</div>
            <div className="container">
                <blockquote className="blockquote text-end">
                    <p className="mb-3">C:\Users\username\Documents\WindowsPowershell\profile.ps1</p>
                    <footer className="blockquote-footer ">по этому пути хранится профиль powershell</footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">Set-ExecutionPolicy Unrestrictable</p>
                    <footer className="blockquote-footer">выдать разрешение на ауск команд в профиле</footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">Set-Alias -name far -Value "C:\Program Files\Far Manager\Far.exe"</p>
                    <footer className="blockquote-footer">задаёт алиас с именем far со значением указанным после value
                    </footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">function mkt set - location "D:\Mortal Kombat Trilogy"</p>
                    <footer className="blockquote-footer">задаёт функцию с указанным именем для перемещения в указанное
                        местоположение. После название функции текст заносится в фигурные скобки.
                    </footer>
                </blockquote>
            </div>


            <div className="h2 ms-1 mt-2">Linux console</div>
            <div className="container">
                <blockquote className="blockquote text-end">
                    <p className="mb-3">touch</p>
                    <footer className="blockquote-footer">создать файл</footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">mkdir</p>
                    <footer className="blockquote-footer">создать папку</footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">sudo su</p>
                    <footer className="blockquote-footer">для начала сеанса суперпользователя в терминале</footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">&</p>
                    <footer className="blockquote-footer">для запуска программы без привязки процесса к консоли нужно в
                        конце
                        прибавить символ
                    </footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">sudo fdisk -l</p>
                    <p className="mb-3">df -h /home</p>
                    <footer className="blockquote-footer">информация о дисках /*list*/ /*disk filesystem -human readable
                        -path*/
                    </footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">iconv -f cp1251 -t utf-8 -o out.txt filename.txt</p>
                    <footer className="blockquote-footer">конвертация файла из кодирровки cp1251 в utf-8 c записью в
                        файл
                        out.txt
                    </footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">zip -r mydir.zip mydir</p>
                    <footer className="blockquote-footer">заархивировать папку, для файла соответсвенно без ключа,
                        распаковать -
                        unzip
                    </footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">zip -s 100m -r mydir.zip mydir</p>
                    <footer className="blockquote-footer">заархивировать папку, с разбиением архива на тома по 100Мб
                    </footer>
                </blockquote>

            </div>


            <div className="h2 ms-1 mt-2">Imagemagick</div>
            <div className="container">
                <blockquote className="blockquote text-end">
                    <p className="mb-3">magick convert -resize 100X200 *.jpg</p>
                    <footer className="blockquote-footer">размерность ресайза (можно в %)</footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">magick convert -resize 100 *.jpg</p>
                    <footer className="blockquote-footer">размерность ресайза по ширине</footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">magick convert -resize X200 *.jpg</p>
                    <footer className="blockquote-footer">размерность ресайза по длинне</footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">magick convert -resize 100X200! *.jpg</p>
                    <footer className="blockquote-footer">размерность ресайза без сохранения соотношения сторон</footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">magick mogrify -resize 960X *.jpg</p>
                    <footer className="blockquote-footer">ресайз с шириной в 960 пикселей всех длейпегов в папке с
                        перезаписью
                        файлов
                    </footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">magick @myimages.txt mymovie.gif</p>
                    <footer className="blockquote-footer">выводит из текстового файла с именами изображений гифку
                    </footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">magick convert -delay 120 -loop 0 *.jpg out.gif</p>
                    <footer className="blockquote-footer">создаёт гифку из джейпегов в папке</footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">magick convert -quality 75 resize 75% *.jpg mypdf.pdf</p>
                    <footer className="blockquote-footer">из всех джейпегов в папке создаёт многостраничный пдф с
                        изменением
                        качества и размера исходных изображений
                    </footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">magick montage -geometry +0+0 1.jpg 2.jpg out.jpg</p>
                    <footer className="blockquote-footer">сливает вместе два изображение один к одному</footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">magick montage -geometry +3+3 1.jpg 2.jpg out.jpg</p>
                    <footer className="blockquote-footer">сливает вместе два изображение один к одному с рамкой в 3
                        пикселя
                    </footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">magick import image.png</p>
                    <footer className="blockquote-footer">делает скриншот выбранной области</footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">magick convert -crop 100%x25% 1.png cropped.png</p>
                    <footer className="blockquote-footer">разрезает файл по вертикали на четыре файла</footer>
                </blockquote>
            </div>

            <div className="h2 ms-1 mt-2">FFMPEG</div>
            <div className="container">
                <blockquote className="blockquote text-end">
                    <p className="mb-3">ffmpeg -i InputVideo.avi -vcodec copy -acodec copy -ss 0:35:00 -t 0:00:5
                        OutputVideo.avi</p>
                    <footer className="blockquote-footer">вырезать нужный кусок видео без перекодирования</footer>
                </blockquote>

                <blockquote className="blockquote text-end">
                    <p className="mb-3">ffmpeg -i "input_audio_file.mp3" -f segment -segment_time 3600 -c copy
                        output_audio_file_%03d.mp3</p>
                    <footer className="blockquote-footer">разделить аудио файл на равные части указанной длины</footer>
                </blockquote>
            </div>
        </>

);
};

export default Commandline;
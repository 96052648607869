import React from 'react';
import arm2015_001 from '../../../images/bigArmenia/arm2015_001.jpg';
import arm2015_002 from '../../../images/bigArmenia/arm2015_002.jpg';
import arm2015_003 from '../../../images/bigArmenia/arm2015_003.jpg';
import arm2015_005 from '../../../images/bigArmenia/arm2015_005.jpg';
import arm2015_006 from '../../../images/bigArmenia/arm2015_006.jpg';
import arm2015_007 from '../../../images/bigArmenia/arm2015_007.jpg';
import arm2015_007_2 from '../../../images/bigArmenia/arm2015_007_2.jpg';
import arm2015_008 from '../../../images/bigArmenia/arm2015_008.jpg';
import arm2015_009 from '../../../images/bigArmenia/arm2015_009.jpg';
import arm2015_010 from '../../../images/bigArmenia/arm2015_010.jpg';
import arm2015_011 from '../../../images/bigArmenia/arm2015_011.jpg';
import arm2015_012 from '../../../images/bigArmenia/arm2015_012.jpg';
import arm2015_012_2 from '../../../images/bigArmenia/arm2015_012_2.jpg';
import arm2015_014 from '../../../images/bigArmenia/arm2015_014.jpg';
import arm2015_014_1 from '../../../images/bigArmenia/arm2015_014_1.jpg';
import arm2015_015 from '../../../images/bigArmenia/arm2015_015.jpg';
import arm2015_016 from '../../../images/bigArmenia/arm2015_016.jpg';
import arm2015_017 from '../../../images/bigArmenia/arm2015_017.jpg';
import arm2015_018 from '../../../images/bigArmenia/arm2015_018.jpg';
import arm2015_019 from '../../../images/bigArmenia/arm2015_019.jpg';
import arm2015_019_2 from '../../../images/bigArmenia/arm2015_019_2.jpg';
import arm2015_021 from '../../../images/bigArmenia/arm2015_021.jpg';
import arm2015_022 from '../../../images/bigArmenia/arm2015_022.jpg';
import arm2015_023 from '../../../images/bigArmenia/arm2015_023.jpg';
import arm2015_024 from '../../../images/bigArmenia/arm2015_024.jpg';
import arm2015_024_2 from '../../../images/bigArmenia/arm2015_024_2.jpg';
import arm2015_025 from '../../../images/bigArmenia/arm2015_025.jpg';
import arm2015_026 from '../../../images/bigArmenia/arm2015_026.jpg';
import arm2015_027 from '../../../images/bigArmenia/arm2015_027.jpg';
import arm2015_028 from '../../../images/bigArmenia/arm2015_028.jpg';
import arm2015_029 from '../../../images/bigArmenia/arm2015_029.jpg';
import arm2015_030 from '../../../images/bigArmenia/arm2015_030.jpg';
import arm2015_031 from '../../../images/bigArmenia/arm2015_031.jpg';
import arm2015_032 from '../../../images/bigArmenia/arm2015_032.jpg';
import arm2015_033 from '../../../images/bigArmenia/arm2015_033.jpg';
import arm2015_034 from '../../../images/bigArmenia/arm2015_034.jpg';
import arm2015_035 from '../../../images/bigArmenia/arm2015_035.jpg';
import arm2015_036 from '../../../images/bigArmenia/arm2015_036.jpg';
import arm2015_037 from '../../../images/bigArmenia/arm2015_037.jpg';
import arm2015_037_2 from '../../../images/bigArmenia/arm2015_037_2.jpg';
import arm2015_037_3 from '../../../images/bigArmenia/arm2015_037_3.jpg';
import arm2015_037_4 from '../../../images/bigArmenia/arm2015_037_4.jpg';
import arm2015_038 from '../../../images/bigArmenia/arm2015_038.jpg';
import arm2015_039 from '../../../images/bigArmenia/arm2015_039.jpg';
import arm2015_040 from '../../../images/bigArmenia/arm2015_040.jpg';
import arm2015_041 from '../../../images/bigArmenia/arm2015_041.jpg';
import arm2015_042 from '../../../images/bigArmenia/arm2015_042.jpg';
import arm2015_043 from '../../../images/bigArmenia/arm2015_043.jpg';
import arm2015_044 from '../../../images/bigArmenia/arm2015_044.jpg';
import kavkaz from '../../../images/bigArmenia/kavkaz.jpg';

const BigArmenia = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <p>В конце апреля 2015 года, я всё таки решил (а обстоятельства этому способствовали) сделать то,
                        что давно хотел, а именно совершить путешествие на своём автомобиле из своего города Челябинска
                        в Армению.</p>
                    <p>Помимо осуществление своего давнего желания, мне нужно было попасть в Армению, с целью наведения
                        мостов и пробы почв в деле совместной организации там туристической компании (но рассказ не об
                        этом). Долго определяясь с точной датой выезда, я готовил автомобиль к дальней поездке. Проводил
                        плановое техническое обслуживание. Заменил все фильтра, масла и другие технические жидкости,
                        поменял передние тормозные колодки и некоторые другие мелочи на которые мне указали на
                        диагностике. В общей сложности вложил около 15 т.р. с работой. Мои летние шины, также уже были
                        не в лучшем состоянии, но на них решил пока сэкономить, т.к. был весьма ограничен в средствах, а
                        ехал я минимум на месяц, и не знал точно, с чем придётся столкнуться в течении этого
                        времени.</p>
                    <p>Определившись с датой примерно за неделю до выезда, мы с супругой стали искать мне попутчика. Всё
                        таки для меня такое дальнее путешествие совершалось впервые, и нужна была какая-то подстраховка
                        в пути. В идеале попутчик должен был обладать правами на вождение автомобилем, чтобы мы смогли
                        менять друг друга за рулём и меньше уставать. За 2 дня до выезда такой попутчик всё таки
                        нашёлся, им был один из моих Х-юродных братьев Серёга. Он, кстати, уже совершал с семьёй
                        подобное путешествие, поэтому дорога обещала быть лёгкой и понятной.</p>
                    <p>На следующий день после получения от него согласия, я взял у него документы и поехал в свою
                        страховую компанию, чтобы записать его в страховку. И на пути в страховую, о ужас, у меня из под
                        капота повалил пар, так что аж стёкла мгновенно запотели. Надо сказать, что ничего подобного у
                        меня с машиной никогда за почти 8 лет службы не происходило. Самой страшной поломкой до сих пор
                        была гибель задних стоек подвески, из за чего машина на кочках раскачивалась как маленький
                        парусник на морских волнах. Я дотянул до ближайшей парковки, чтобы не усложнять жизнь другим
                        участникам движения, и в общем то не имел представления, что с машиной. Но в этот день была
                        пятница, страховая закрывалась через 30 минут, а по выходным их офис не работал. Оставив машину
                        на парковке, я пошёл в Аско пешком, благо до их офиса оставалось километра 1.5-2. Зайдя в офис я
                        занял место в очереди, которая закончилась как раз на мне, т.к. менеджер сказала, что её рабочий
                        день закончился, и я последний человек которого она примет сегодня.</p>
                    <p>Заплатив 400 рублей, и вписав Серёгу в страховку, настало время понять, что делать с машиной,
                        ведь завтра нам нужно было выезжать. Я позвонил ещё одному своему родственнику, который хорошо
                        разбирается в устройстве автомобилей. Когда он приехал, мы обнаружили, что антифриз, который мне
                        залили на этой неделе, почти весь вытек. Но все шланги вроде были в порядке. Появился вариант с
                        поломкой печки, т.к. на коврике переднего пассажира, виднелась какая-то влага.</p>
                    <p>Взяв дистиллированной воды из ближайшего магазина, и залив его бачок, мы потихоньку выдвинулись в
                        сторону мастерской. Где наши опасения подтвердились. Лопнул радиатор печки. Мы поехали к
                        магазину, чтобы купить новый, а также 8 литров антифриза, вместо утекших. Поскольку было уже
                        около восьми часов вечера, нашей машиной в этот день уже никто не занимался. Починка займёт
                        половину следующего дня, поэтому поездку придётся отложить до утра воскресения.</p>
                    <p>Забрав машину из сервиса ближе к вечеру в субботу, решили выдвигаться из города в 6 утра
                        следующего дня. Мне оставалось собрать вещи в дорогу, и надеяться, что мой автомобиль больше не
                        принесёт неприятных неожиданностей. </p>
                    <p>Ранним утром в воскресение я попрощался с семьёй и отправился в путь. Заехав за Серёгой, мы
                        направились на выход из города. Двигаясь по трассе М5 нас встречал лёгкий туман. Надо сказать,
                        что к концу апреля снег в Челябинске уже давно сошёл, и я естественно давно «переобулся» в
                        летнюю резину, тем более, что впереди лето, а ехал я на юг. Какого же было наше удивление, когда
                        подбираясь к Уральскому хребту, в районе Златоуста, мы попали в самую настоящую метель. Причём
                        это был не просто снег с дождём, тут же таявший на дороге, на почве здесь лежал снежный покров
                        высотой в 20-30 сантиметров.</p>
                    <img src={arm2015_001} alt="Уральский хребет вернул нас в зиму" className="img-fluid travel__img"/>
                    <p>На одном из перевалов мы попали в пробку, движение из за аварии впереди почти не двигалось. Там
                        мы потеряли минут сорок. Проехав, место аварии мы двигались уже быстрей, но вдруг при торможении
                        на одном из спусков услышали скрежет, издаваемый одной из колодок, очень похожий на тот, что я
                        слышал год назад, когда путешествовал по Армении и Грузии на арендованной Kia Cerato. Если
                        честно, я тогда не знал, что и делать, машина как будто сопротивлялась долгой дороге,
                        представляя нам всё новые неприятные сюрпризы. А ведь мы были от дома всего в паре сотен
                        километров, т.е. до места назначения нам оставалось примерно 2800 км.</p>
                    <p>Остановившись и осмотрев машину, мы не заметили ничего необычного ни с одной из колодок, и решили
                        двигаться не спеша дальше. Буквально через несколько километров пути, неприятный звук пропал, и
                        мы выдохнули. Видимо виной всему был небольшой камешек попавший между тормозным диском и
                        колодкой.</p>
                    <p>Снег постепенно прекратился. Тем временем, мы доехали до Аши, а затем въехали в Башкирию.
                        Удаляясь от Уральского хребта, снега вокруг становилось всё меньше, и наконец, мы окончательно
                        вернулись в весну.</p>
                    <p>Несмотря на то, что со мной был попутчик уже совершавший такое путешествие, мы решили двигаться
                        по навигатору, установленному в моём телефоне. Хотя дорога, по крайней мере, до Самары не
                        вызывала у меня никаких вопросов. Тем не менее, Navitel иногда, вёл нас в обход главных трасс,
                        видимо полагая, что так короче. Оставив позади Уфу, мы заправились на Башнефти. АЗС данной
                        компании, как можно догадаться преобладают в данном регионе.</p>
                    <p>Залив полный бак мы двинулись дальше. По дороге нас провожали, стоящие в полях вдоль трассы
                        станки, качающие нефть. В целом, качество покрытия дорог пока не вызывало никаких нареканий, как
                        сказал Серёга, с этим у нас не будет проблем вплоть до Саратовской области.</p>
                    <img src={arm2015_002} alt="Башкирия" className="img-fluid travel__img"/>
                    <p>Постепенно Башкирия закончилась, к этому времени Серёга уже успел сменить меня за рулём, и я смог
                        отдохнуть. Поэтому когда наша дорога, не долгое время, лежала уже по территории Татарстана, за
                        рулём уже был снова я. Увлёкшись обгоном фур, я не заметил знак запрета на обгон в одном из
                        мест. Разметка в этих местах отсутствовала и это сыграло со мной злую шутку. Совершив обгон,
                        через пару сотен метров я увидел перед собой поднятый жезл инспектора ГИБДД, Сославшись на
                        отсутствие разметки, мы дали понять инспектору, что надо как то выходить из положения, ведь нас
                        ждёт долгий путь. Он выразил удовлетворение отсутствием споров с нашей стороны, и заполнил
                        протокол, якобы на отсутствие детского кресла при перевозки детей. Мы распрощались и двинулись
                        дальше.</p>
                    <p>В Татарии нашими спутниками вдоль дорог по-прежнему были станки, качающие нефть, и пейзаж с
                        небольшими холмами, всё больше уплощающийся в равнину. После Татарии мы захватили небольшой
                        кусочек Оренбургской области, а затем въехали в Самарскую. Сменившись по дороге ещё раз, конец
                        дня за рулём проводил снова я. На подъезде к Самаре, и без того неплохая дорога, превратилась в
                        просто таки идеальное полотно широкой трассы. Мы держали путь на Тольятти, и в общем то уже
                        решили искать подходящее место для ночёвки.</p>
                    <img src={arm2015_003} alt="Самарская область. Дорога на Тольятти."
                         className="img-fluid travel__img"/>
                    <p>Проехав город Жигулёвск, около половины девятого вечера, мы увидели большой стояночно-гостиничный
                        комплекс. На парковке которого стояло больше десятка фур, и столько же легковых машин. Зайдя
                        внутрь, мы увидели большую столовую с хорошими диванчиками, за которой располагался ресепшн,
                        возле него находился банкомат Сбербанка, который мне был как раз нужен. Номер на двоих стоил,
                        если я не ошибаюсь, 1800 рублей, и мы решили остановиться здесь. И честно говоря не зря, номер
                        был очень приличен, с хорошей отделкой, мебелью, душевой и Wi-Fi. Я спустился поужинать в
                        столовую, выяснилось, что здесь она работает с 4 утра, что очень удобно, для тех кто хочет
                        выехать пораньше. Вернувшись в номер я принял душ, и тут же уснул. Мы договорились проснуться в
                        5:30, чтобы в 6 утра уже продолжить свой путь.</p>
                    <p>Утром позавтракав яичницей, мы двинулись в путь, и по ещё не загруженным дорогам «разделались» с
                        Самарской областью. Как только мы въехали в Саратовскую область, началось то, о чем Серёга меня
                        предупреждал, а именно качество дорог заметно ухудшилось, и фактически всю эту область нас
                        сопровождали заплатки, кочки, ямки, ухабы.</p>
                    <img src={arm2015_005} alt="Саратовская область" className="img-fluid travel__img"/>
                    <p>Саратовскую сменила Волгоградская область, и дорога стала заметно ровней и проще. Увидев Волгу,
                        мы остановились немного полюбоваться, на открывающийся вид, и поразмяться.</p>
                    <img src={arm2015_006} alt="Река Волга" className="img-fluid travel__img"/>
                    <p>Постепенно мы доехали до города Волгограда, и это один из тех немногих городов России, на нашем
                        пути, которые нельзя было объехать. К тому же мы хотели посетить Мамаев Курган, поэтому въехав в
                        город мы сразу направились к нему. Благо найти его было не трудно, мы сразу установили с ним
                        визуальный контакт, а дальше следовали по указателям.</p>
                    <p>Припарковавшись у мемориала, я сразу понял, что, пожалуй, недооценил его размеры. Это был,
                        действительно самый большой монумент, который мне доводилось видеть. Возвышаясь над Волгой и
                        городом, выглядел он действительно впечатляюще.
                    </p>
                    <img src={arm2015_007} alt="Мамаев Курган" className="img-fluid travel__img"/>
                    <p>В Волгограде было уже жарко. Здесь мы впервые почувствовали близость юга. Но большим количеством
                        свободного времени мы не располагали, в этот день мы планировали доехать до Элисты (республика
                        Калмыкия), поэтому вскоре направились к машине и продолжили путь.</p>
                    <p>Ориентируясь по навигатору мы выехали на длинную, почти прямую улицу, дорожное покрытие на
                        которой было в ужасном состоянии. И самое плохое, что эта улица не кончалась, мы проехали по ней
                        более 30 км!, и только после этого свернули с неё на выход из города. Дороги за Волгоградом были
                        значительно лучше, чем в самом городе.</p>
                    <p>Мы двигались в сторону Калмыкии, и вскоре попали в бескрайнюю степь во всех направлениях, сквозь
                        которую, прямая как стрела, лежала дорога на Элисту. Качество покрытия здесь было также на
                        высоте, поэтому можно было спокойно пристроится за местными водителями и надавить на педаль,
                        сокращая время до нашего ориентировочного места сегодняшнего базирования - столицы Калмыкии.</p>
                    <p>Интересно, что несмотря на, обилие в общем то благодатной почвы, степи вдоль дороги было
                        совершенно пусты и ничем не засеяны. Они использовались исключительно для выпаса скота, в
                        основном лошадей. На подъезде к Элисте было около восьми вечера, уже смеркалось. Мы хотели уже
                        подыскать какой-нибудь придорожный отельчик, и отдохнуть, но ничего так и не увидели до въезда в
                        город. Плутать по самой Элисте в поисках ночлега у нас тоже не было особого желания, тем более,
                        что уже окончательно стемнело. И мы решили, что как обычно это бывает, на выезде точно
                        что-нибудь найдётся. Но это стало нашей серьёзной ошибкой. Потому как, на выезде также ничего
                        подходящего не было, а дорога становилась всё хуже и хуже. Я окончательно выдохся, и Серега меня
                        заменил за баранкой. Мы решили двигаться до ближайшего населённого пункта. Я же пока откинулся в
                        кресле и решил слегка подремать. И тут последовал мощный удар, мы словили глубокую яму диаметром
                        метра полтора. Припарковавшись на обочине, мы осмотрели машину, правый передний диск был сильно
                        замят, колесо спущено. Прямо перед нами стоял эвакуатор, загружавший Mercedes, который похоже
                        постигла та же участь, что и нас. Рядом с ним были полицейские, видимо с их помощью и вызывался
                        эвакуатор.</p>
                    <img src={arm2015_007_2} alt="Серёга снимает колесо, на трассе за Элистой"
                         className="img-fluid travel__img"/>
                    <p>Мы сняли колесо, и поставили докатку. Полицейские сказали, что шиномонтаж от этого места
                        километрах в десяти. Мы двинулись дальше, спокойно и стараясь объезжать все ямы. Особое
                        коварство дороги заключалось в том, что участки относительно ровной дороги внезапно
                        заканчивались просто таки лунными кратерами, которые в темноте можно было заметить в лучшем
                        случае метров за десять.</p>
                    <p>Мы проехали уже более 30 км., но никакой шиномонтажки не заметили, как не увидели вообще ничего
                        кроме одинокой АЗС, но бензин нам был не нужен. Решили ехать до ближайшего населённого пункта -
                        села Арзгир Ставропольского края, до которого с места нашей поломки было чуть более 100
                        километров. Но как выяснялось, там шиномонтаж круглосуточно не работал, ночлега мы тоже не
                        увидели.</p>

                    <p>Тогда решили добраться до города Будёновска. Это был ближайший от Арзгира город, где наверняка,
                        должны были быть хоть какие-то гостиницы. Проехав ещё километров 70, мы въехали в город. Тут же
                        заметили несколько шиномнтажек, которые были закрыты. Но нам уже было не до них, так как время
                        перевалило за полночь, а в пути мы были с 6 утра. Я попытался найти гостиницы используя Google
                        Maps, и тот мне выдал таких целых две. Приехав по первому адресу, мы увидели шикарный двор с
                        фонтаном и статуями, ко входу вела каменная лестница, подводившая к двери с двух сторон,
                        описывая нестрогий полукруг. Во дворе мигали разноцветные лампочки и фонарики, и мне стало
                        боязливо за ценник, который нам здесь озвучат. Зайдя во внутрь, и подойдя на ресепшн, мы никого
                        там не увидели, и лишь хорошо осмотревшись, мы заметили в огромном холле на одном из диванов
                        спящую сотрудницу. Разбудив её мы объяснили ей, что ищем 2-х местный номер, на что она сообщила
                        нам, что такой номер есть и стоит он 2800 рублей за ночь. Тратить такие деньги за ночлег мы себе
                        позволить не могли поэтому, извинившись отправились искать второй отель.</p>
                    <p>Отыскав второй отель, мы позвонили в закрытую дверь, которую отворил охранник. На ресепшене мы
                        повторили свой запрос. Сотрудница сообщила нам, что есть один номер который нам подходит, и
                        стоит он 2500 рублей. Тогда я спросил нет ли чего-нибудь попроще? На что она ответила: «А какую
                        сумму вы готовы заплатить?». Короче мы сторговались за 2000 рублей, с завтраком. И мы посчитали
                        это удачей, т.к. мы в общем-то не знали куда податься дальше. Машину было разрешено было кинуть
                        прямо у гостиницы, на узкой парковочке, делившей место с одной из полос дороги, у которой стояли
                        несколько гостевых машин, а также потрёпанный Mercedes S класса из 90-х (типа «Кабан»), внутри
                        которого сидели 2 тревожных молодых человека, как мы поняли это тоже была своего рода охрана.
                    </p>
                    <p>Номер был маленький. Две кровати занимали почти всё его пространство, но удобства были в номере,
                        хотя вода из под крана издавала столь неприятный запах, что принимать душ там совсем не
                        хотелось. В общем, я набрал пару строк семье, воспользовавшись местным Wi-Fi, я уснул
                        замертво.</p>
                    <p>Проснувшись на следующее утро часов в 8, мы позавтракали, и поехали искать шиномонтажку. Искать
                        долго не пришлось, свернув на соседнюю улицу мы сразу увидели то, что нам нужно. Хозяин был
                        армянин из Карабаха, с тяжёлой судьбой, которую он нам навязчиво изложил во время работы над
                        нашим колесом. Точнее двумя нашими колёсами, потому как на заднем с той же стороны мы также
                        обнаружили небольшое замятие, которое лучше было устранить. Цена нас приятно удивила, за 2
                        колеса с нас взяли всего 500 рублей. Рядом с шиномонтажной мастерской находилась мойка, которой
                        мы тоже решили воспользоваться. И здесь нас приятно удивили, за 200 рублей полностью вымыв
                        автомобиль, коврики, и натерев шины чернителем. Причём подъехав к воротам мойки, у нас
                        уважительно взяли ключи и загнали автомобиль во влажный бокс самостоятельно.</p>
                    <img src={arm2015_008} alt="Мойка в Будёновске" className="img-fluid travel__img"/>
                    <p>Вообще надо сказать, чем ближе к югу России мы ехали тем более приветливыми казались люди.
                        Конечно, я слышал, про некоторую суровость людей и особенно правоохранитлей в Калмыкии, но я не
                        стал создавать себе предубеждений, и забегая вперёд, на обратном пути от Калмыкии у меня
                        остались только положительные эмоции. Кстати, уровень доверия людей друг к другу лично я
                        почувствовал на автозаправках. Так например, двигаясь на юг, до Волгограда, на просьбу заправить
                        полный бак девушки-операторы всегда просили оставить примерную сумму наличными, либо списывая
                        заведомо большую сумму с карты с последующим возвратом, некоторые вообще недоумевая продолжали
                        спрашивать сколько конкретно литров вам надо. А начиная с Волгограда, на подобную просьбу
                        реагировали нормально, заправляя бак под завязку, а уже потом озвучивая получившуюся сумму.</p>
                    <p>Из Будёновска мы выехали около 11 утра, задав маршрут на Владикавказ. До него нам оставалось
                        меньше 300 километров, тогда мы ещё не знали что на этот путь мы положим почти весь день.</p>
                    <p>Понятно, что вчерашняя вынужденная остановка в Будёновске, сказалась на то, что в Северную Осетию
                        мы въезжали, что называется не с «парадного входа», что в дальнейшем весьма негативно сказалось
                        на нашем движении по республике, но об этом позже.</p>
                    <p>Ставропольский край вызвал приятные впечатления, здесь как будто совершенно не было ни кусочка
                        брошенной земли. Вся она возделывалась, а та что не возделывалась была чьим то личным
                        хозяйством, и даже за их границами частных участков, земля облагораживалась клумбами,
                        декоративными кустарниками, ухоженными деревьями. На полях усердно трудилась сельхозтехника,
                        которая работала даже ночью, в чём мы убедились ещё вчера, делая первые километры по дорогам
                        края. Кстати, качество дорог, несмотря на то, что мы, как я уже говорил, двигались, зачастую, не
                        по самым главным путям не вызывало нареканий.
                    </p>
                    <p>Наконец, мы достигли большого блок-поста за которым лежала республика Северная Осетия. И несмотря
                        на то, что никаких очередей на нём не было, он больше напоминал КПП на государственной границе.
                        Проехать его без остановки было нельзя, и помимо досмотра транспорта, на нём производилась
                        тщательнейшая проверка документов как водителей так и пассажиров.</p>
                    <p>Серега предупреждал меня, что в Осетии придётся отдавать деньги на постах и с этим нужно
                        смериться. Рассчитывая бюджет путешествия я даже закладывал 3000 рублей на прохождение
                        республики в качестве оброка. Но в ближайшие несколько часов мы поймём, что недооценили жадность
                        местных «блюстителей порядка».</p>
                    <p>На посту нам сказали припарковаться в сторонке, и идти с документами на проверку. Мы сначала
                        поднялись по лестнице где сотрудник сверил все документы, потом мне сказали спуститься вниз и
                        зайти в стоящую рядом будочку. Серёга, там уже успел побывать и ждал меня на улице. В будке
                        сидел полный мужчина в белой форменной рубашке с погонами. Он сообщил мне, что у меня в
                        страховке указаны неверные сведения. При продлении страховки, в ней мне указали старый номер
                        моего водительского удостоверения, которое я менял года два тому назад. Косяк АСКО стоил мне
                        2000 рублей, но удивительным для меня стало то, что с Серёги тоже попросили денег, за
                        неоплаченный штраф, а ведь он вообще был на месте пассажира, когда мы подъехали к посту. Но
                        законы РФ здесь похоже не работали. Судя по тому, что полиция без стеснения вымогала деньги, все
                        вышестоящие были в курсе, и кормились с этого, а Москва похоже просто не хотела наводить здесь
                        порядок не желая портить отношения с республикой. К тому же мы, не зная нравы местных
                        «правоохранителей», не рискнули идти с ними в конфронтацию, в дали от дома, и решили, что
                        проблем будет меньше если мы согласимся на их условия.</p>
                    <img src={arm2015_009} alt="Республика Северная Осетия" className="img-fluid travel__img"/>
                    <p>В окружении красивых предгорных полей мы двинулись дальше к Владикавказу. Но радость от
                        прекрасного окружения, продолжалась недолго. Перед нами был ещё один блок-пост. Но если
                        предыдущий выглядел именно как пост, то этот представлял из себя просто деревянную будку 1.5х1.5
                        метра, несколько вооружённых человек в чёрной форме и шлагбаум. Нас остановил полный суетливый
                        человек, и представился Зазой. Прямо так и сказал: «Меня зовут Заза, я из Грузии, куда едете?»,
                        к наглому и самоуверенному тону его прилагался хитрый и пытливый взгляд. Я ответил, что мы едем
                        в Армению, на что Заза сказал: «А армяне? Ну армяне у нас обычно магарыч оставляют, давай 1000
                        рублей и проезжай». Я опешил от такой наглости, и пустился в торг, в итоге сговорились на 600
                        рублях, и мы продолжили путь, с большой тревогой о том, что нас ждёт впереди, ведь мы только
                        начали движение по Осетии, но лично я уже недосчитался 2600 рублей и мелкие купюры
                        кончались.</p>
                    <p>Буквально ещё через десяток-другой километров, картина повторилась: будка, вооружённые люди,
                        шлагбаум, причём на этот раз он был опущен до половины дороги, оставляя свободным встречный
                        проезд, между которым виднелась сплошная линия разметки. Однако, местных это совсем не смущало,
                        они торопливо объезжали препятствие, и почти без остановки двигались дальше. Нас же естественно
                        остановили, я продемонстрировал документы вооруженному тонкогубому мужчине с хитрым взглядом и
                        наигранной агрессией в голосе. Он вскользь глянул на права, и затем пробормотал: «Артём, борзеть
                        то не надо, зачем пересекать сплошную линию?». К такому повороту я был явно не готов, и лишь
                        успел выговорить контр-вопрос а как мне было сюда ещё заехать? Мужчина ответил мне на это что-то
                        невнятное, через зубы, а потом объяснил, что такое нарушение карается лишением прав. Короче
                        говоря мне пришлось оставить здесь ещё 2500 рублей.</p>
                    <p>Мы в ужасе двигались дальше. Дорога пошла в горы по не очень крутому серпантину. Мы проехали ещё
                        пару десятков километров, и вот перед нами ещё одно препятствие в виде горного блок-поста. К нам
                        двигался высокий, худощавый человек в чёрной форме и с автоматом. Его добрые глаза выражали
                        спокойствие, и у меня появилась надежда, на то, что этот пункт мы проедем без потерь. На
                        небольшом пригорке по традиции была установлена будка, куда меня попросили отнести документы,
                        что я и сделал. Посмотрев на документы мне сказали, что мы можем ехать. Я был даже несколько
                        удивлён, и очень счастлив от этих простых слов. Мы продолжили движение.</p>
                    <img src={arm2015_010} alt="На подъезде к Владикавказу" className="img-fluid travel__img"/>
                    <p>Спустившись с перевала, мы постепенно въезжали в город Владикавказ перед нами уже виднелась
                        широкая стена Кавказских гор. На одном из перекрёстков мы не смогли последовать подсказке
                        навигатора из за ремонта дороги. Свернув наугад за основным потоком, мы подождали пока навигатор
                        перестроит маршрут. Естественно он предложил свернуть на следующем перекрёстке и продолжить
                        движение в прежнем направлении, что мы и попытались сделать, но упёрлись в «кирпич» и 2 бетонных
                        блока установленных посреди дороги, инстинктивно я повернул на совершенно свободную проезжую
                        часть слева, которая описывала полукруг, и спустя несколько метров понял, что оказался на
                        односторонней дороге со встречным движением. Ко мне уже бежал инспектор полиции, параллельно
                        сигнализируя жезлом и свистком, чтобы я припарковался возле их поста, который оказался здесь же
                        рядом. Я был в полном отчаянье. Буквально всё сегодня было против нас. Моё нарушение снова
                        подпадало под лишение. Я пытался объяснить инспектору, что мы заблудились, и чтобы он вошёл в
                        положение и проявил сочувствие к приехавшим издалека гостям. Мои объяснения вызвали отклик, в
                        весьма вежливом, спокойном инспекторе, но он объяснил мне, что здесь повсюду установлены камеры,
                        которые регулярно просматривают, и если он просто отпустит меня, то его потребуют поделиться,
                        резонно полагая, что он взял себе на карман. Пришлось заплатить ещё 2500 рублей, а протокол был
                        выписан за не пристёгнутый ремень.
                    </p>
                    <p>Психологически я был опустошён. Но моё опустошение сменилось на нервную ярость, когда через
                        буквально 300 метров мы увидели ещё один пост полиции, на котором останавливали большинство
                        проезжающих автомобилей. Естественно не оставили без внимания и нас. Я зашёл большое для поста
                        одноэтажное здание с множеством комнат. Документы нужно было подать в окошко под широкой
                        стеклянной перегородкой за которой сидело несколько сотрудников за компьютерами. Документы у
                        меня взяла женщина и стала что-то набивать на своём ПК. Через пару минут, она сообщила мне, что
                        в «базе» написано, что «моя страховка находится у страховщика». Я был в недоумении. «Что это
                        значит?», спросил я. Тут подошёл сотрудник, который меня остановил на дороге, и пояснил, что это
                        значит, что моя страховка является недействительной. «Как это недействительной?», выпалил я, «Я
                        по ней уже почти год езжу, и никаких нареканий по этому поводу ни у кого не было». Сотрудник
                        пояснил, что в базе должны быть указаны чёткие даты страховки, а у меня лишь надпись «Находится
                        у страховщика». В мыслях я проклял АСКО. Он также порекомендовал, чтобы я позвонил в страховую и
                        выяснил этот момент. Что я и попытался сделать. Однако, позвонив по бесплатному телефону я попал
                        на автоответчик, который упорно твердил чтобы я ждал на линии. Пришлось позвонить в Челябинский
                        офис. Но пока я надиктовывал номер ФИО и номер страхового свидетельства очень нерасторопной
                        сотруднице, у меня закончились деньги. Я пошёл в машину, чтобы позаимствовать телефон у Сергёги.
                        Набрав номер ещё раз я повторил свой вопрос оператору, но девушка упорно не хотела услышать то
                        фамилию, то цифры номера свидетельства. Понимая всю шаткость, положения, я почти перешёл на
                        крик: «Девушка, я сейчас в Осетии, и меня сейчас арестуют, будьте, пожалуйста, внимательней!».
                        Этой фразой я немало позабавил сотрудника сидевшего рядом, в кабинете, куда он специально
                        пригласил меня для совершения этого звонка. Девушка в телефоне, наконец, собралась и правильно
                        записала мои данные, а затем сообщила, что всё должно быть в порядке, у ней моя страховка
                        отображается как действующая. Что я и сообщил сотруднику. Тогда он отвёл меня снова к окошечку,
                        и попросил свою коллегу вновь «пробить» мою страховку. На, что она сообщила, что у них сейчас
                        проблемы с сетью, и она не может выполнить его просьбу. Не зная, что делать сотрудник, видимо
                        поняв моё состояние, решил меня отпустить, что я незамедлительно и сделал. Признаюсь нет ничего
                        хуже, когда в твои планы вмешиваются против твоей воли, и ты совершенно бессилен, что либо
                        сделать против этого. Мы чувствовали себя беспомощно. Но отступать было слишком поздно.</p>
                    <p>Наконец, мы въехали в город. Центр Владикавказа в целом сохранил старину и выглядел довольно
                        симпатично. Т.к. Сереге нужно было ехать обратно в Челябинск самостоятельно, но уже на своей
                        машине, то он решил таки оплатить штраф «висевший» на нём. Мы отыскали банк и оплатили штраф с
                        помощью терминала. Я также снял немного наличных денег.</p>
                    <p>Тем временем, на часах было уже около шести вечера, но оставаться на ночь в Осетии нам очень не
                        хотелось. И мы решили, что попробуем перейти Российско-Грузинскую границу, и доехать до Тбилиси,
                        где, возможно, и остаться на ночь.</p>
                    <img src={arm2015_011} alt="Владикавказ. Парк." className="img-fluid travel__img"/>
                    <p>Мы постепенно выехали из города и доехали до Нижнего Ларса, где заправились на последней перед
                        границей АЗС. Как ни странно, мы не встретили на выезде из Владикавказа и далее по дороге ни
                        одного поста, чему были несказанно рады. Мы поднимались в горы, на улице уже были сумерки когда
                        мы достигли Верхнего Ларса и пограничного пункта. Мы встали в очередь, которая была не большой и
                        двигалась довольно бодро. Я чувствовал себя в цивилизованном месте, здесь были федеральные
                        пограничники, а не местные республиканские полицейские. На окошке где сидели женщины,
                        проверяющие документы, высели объявления типа: «Подарки и деньги не предлагать, все вопросы
                        решаются исходя из действующего законодательства РФ». Я сразу успокоился. Перед границей нас
                        остановил мужчина (армянин) и попросил провезти через границу парня и женщину. У них кончалась
                        временная регистрация, и необходимо было ещё раз пересечь границу для её возобновления. Мы
                        охотно согласились. Проверка документов прошла быстро и без проблем. Достигнув нейтральной зоны
                        мы выпустили наших попутчиков и двинулись к грузинскому КПП. Как и ожидалось там всё прошло
                        также без проблем, особенно радует то, что при въезде на территорию Грузии не требуется
                        оформлять страховку, и платить за ввоз автомобиля. Не требуется платить вообще ничего, а про
                        взятки здесь вообще лучше забыть.</p>
                    <p>Уже окончательно стемнело. Двигаясь уже по территории Грузии мы поднимались выше в Кавказские
                        горы. К сожалению, из-за схода сели, дороги в горах местами практически не было, кое-где
                        приходилось пробираться по насыпанному щебню.</p>
                    <p>После очередного спуска нас остановил полицейский. Остановил, а сам зашёл здание поста. Подождав
                        немного, я направился к посту, чтобы уточнить причину остановки сам. Оказалось, что
                        останавливали вообще всех, нужно было просто подождать, пока по узкой горной дороге впереди
                        проедет крупный большегруз. Удивило, что вновь подъехавшие машины, пытавшиеся пролезть вперёд,
                        твёрдо посылались полицией в конец очереди. Цивилизация!</p>
                    <p>Постояв минут 15-20, мы продолжили движение. В горах было холодно, я вспомнил о вышедшей из строя
                        и вовремя починенной печке, здесь она нам пригодилась. Постепенно мы начали спускаться с гор.
                        Над нами было низкое звёздное небо, а вокруг массивные горы со снежным одеянием. Мне было очень
                        жаль, что мы мало чего могли рассмотреть в ночной тьме.</p>
                    <p>К этому времени я окончательно выдохся, т.к. за весь день мы так не разу и не сменились за рулём.
                        И Серега, как пионер, как всегда был готов заменить уставшего товарища. Он сообщил, что в
                        принципе т.к. весь день «отдыхал» может ехать без проблем всю ночь. И он действительно выглядел
                        бодрым, поэтому было решено ехать пока Серёга не устанет.</p>
                    <p>Я переместился на пассажирское кресло, откинул его, подложил подушечку под голову и отрубился.
                        Проснулся я уже когда мы ехали по Тбилиси вдоль реки Куры. Проблема была в том, что навигатор
                        пытался вывести на дорогу, которой «не было». Видимо в этом месте была реконструкция, но Навител
                        не успел обновить свои карты. Мы обратились к таксисту, с вопросом как выйти из города в сторону
                        Армении. Он сказал, что как раз едет в этом направлении, и предложил ехать за ним. Мы так и
                        сделали. Таксист ехал быстро как и большинство грузинских водителей, мы еле за ним поспевали. В
                        итоге спустя 15 минут «погони» за ним, на одном из поворотов он махнул нам рукой в одном из
                        направлений, а сам скрылся в другом. Мы же продолжив движение в указанном направлении, в итоге
                        выехали на ту же набережную откуда начали движение за нашим проводником. Делать было нечего мы
                        поймали ещё одного таксиста, и попросили провести нас на нужную трассу за деньги. Причём
                        естественно за рубли, т.к. лари у нас не было, и разменивать их ночью было проблематично.
                        Таксист сначала озвучил какую-то дикую сумму в 1500 рублей. На, что мы предложили свои
                        окончательные 500. Молодой таксист позвонил своему отцу, видимо уточнить сколько это будет 500
                        рублей на местную валюту, а заодно уточнить оптимальный маршрут. Короче, он вывел нас на нужную
                        трассу и мы продолжили движение в сторону Армении. Я снова уснул, и проснулся в каком-то селении
                        где нас остановил полицейский, видимо настороженный нашей неуверенной и острожной ездой, резко
                        контрастирующей со стилем вождения местных водителей. Он подошёл к машине, и заглянув в окно,
                        спросил: «Пили сеУодня?». На что я спросонья, шутливо, но почти правдиво ответил, что мы даже не
                        ели! Он предложил Серёге «дунуть» алкометр, что тот и сделал, после чего полицейский пожелал
                        счастливого пути, и мы поехали дальше.</p>
                    <p>Было уже около четырёх утра когда мы подъезжали к Садахло, где расположен пограничный переход из
                        Грузии на территорию Армении. Я сменил Серёгу за рулём. В это время граница ожидаемо почти
                        пуста. Проходим оба КПП. Цель нашего трехдневного марафона почти достигнута, но эмоции радости
                        отсутствуют. На это есть несколько причин, первая это то, что несмотря на то, что формально мы
                        на территории Таможенного союза, но за ввоз машины мы должны заплатить (за месяц 25 000 драм),
                        кроме того, необходимо ещё заплатить где-то половину этой суммы за страховку. Добрым словом
                        вспомнив Грузию, и посплетничав со страховым агентом о коррупции в государственном масштабе,
                        едем в село Еранос, что на Севане, где Серёга сходит у бабушки с дедушкой, а я перекусив в
                        гостеприимном доме отправляюсь в Ереван.</p>
                    <p>Прощаюсь с Серёгой у «ворот» в Еранос, и еду вдоль Севана. Впервые еду по Армении в одиночестве,
                        странное чувство. Проехав поворот на Гавар, понимаю, что несмотря на то, что ночью я дремал,
                        глаза всё равно закрываются. Съезжаю с трассы и сплю полчаса. После этой процедуры голова
                        заметно яснеет, и я продолжаю путь. Дорога мне хорошо знакома, по ней я ездил много раз, поэтому
                        навигатор использую лишь как помощника напоминающего о скоростном режиме. В Армении с этим
                        строго, повсюду камеры, но они мне с российскими номерами, как потом выяснится не страшны, зато
                        страшны патрули полиции, которые могут вычислить скорость, двигаясь как по ходу моего движения
                        так и по встречной.</p>
                    <p>Вот я уже в Ереване, пользуясь навигатором доезжаю до улицы Аршакунянц, дальше дорога до
                        Нораговита по памяти. Всё я на месте. На часах около 2 дня, итого дорога заняла у меня около
                        трёх с половиной дней. Но мне даже как-то не хочется надолго останавливаться. Что и говорить,
                        дорога затягивает.</p>
                    <p>Дальше меня ждут почти месяц поездок по Армении и Нагорно-Карабахской республике. С делами,
                        касающимися налаживанию контактов и прокладке маршрутов для новорождённой туристической компании
                        «Бабушка Вартануш», занимающейся организацией туров по Армении и НКР, и рассказывающей о ней
                        устами колоритнейших персонажей, коими являются обычные и самые разноликие граждане этих
                        республик, заразительно любящих их и готовые показать их с самых разных ракурсов.</p>
                    <p>Подробное описание всех этих дел не входит в мои планы, но я просто обязан кратко рассказать о
                        наших поездках с точки зрения путешественника. Ведь мы побывали во множестве ещё нехоженых нами
                        мест, и приоткрыли для себя Армению ещё раз, чтобы поделиться с Вами, и очертить планы для
                        будущих открытий...</p>
                    <p>Итак, первым нашим направлением было восточное побережье озера Севан, деревенька Шоржа, известное
                        своими пляжами. Говорят это самое солнечное место в Армении, потому оно наиболее благоприятно
                        для купания в прохладных водах Севана. Здесь же неподалёку находится заповедник, часть которого
                        укрывает небольшую базу отдыха с домиками для семейного отдыха на самом берегу озера. Гюхапет
                        Шоржы (по-русски наиболее подходит слово - Глава) по имени Мгер, бывший боевик, принял нас в
                        своём небольшом кафе, и за гостеприимным столом рассказал о жизни в селе и чудесных людях его
                        населяющих, а затем даже познакомил нас с некоторыми. Показал он также и своё хозяйство.
                    </p>
                    <img src={arm2015_012_2} alt="Хозяйство в Шорже" className="img-fluid travel__img"/>
                    <p>Затем мы отправились на место стоянки известного судна «Киликия», точной копии торгового судна
                        XIII века, времён Киликийского царства. К сожалению, его команды там мы не нашли, зато
                        полюбовались на сам корабль, и чудесный вид бухты. </p>
                    <img src={arm2015_012} alt="Судно Киликия" className="img-fluid travel__img"/>
                    <p>Поблагодарив Мгера и его семью, мы отправились южнее вдоль берега озера, в селение Цапатах, где у
                        нас было ещё пару встреч с местными жителями. Околдованные местных радушием, мы не заметили как
                        наступили сумерки. В Армении темнеет довольно быстро. А ведь у нас была назначена встреча ещё в
                        Арегуни на юге-востоке Севана. Мы выдвинулись туда, но проехав несколько километров, дорога
                        просто «кончилась», превратившись в россыпь разновеликих «кратеров» на остатках асфальта.
                        Ситуация усугублялась окончательно спустившимся мраком ночи, что усложняло поиск наиболее ровных
                        участков для продвижения. Мы позвонили Араму, ждавшему нас в Арегуни и перенесли встречу. Сами
                        же повернули назад, однако под Шоржей «словили» яму, и вновь моё переднее колесо, но уже с
                        другой стороны, оказалось на ободе. Я полез за докаткой и домкратом. Местность была довольно
                        глухой. Когда я снимал спущенное колесо, мимо проезжал покрытый ржавчиной ЕРАЗик, оттуда вышел
                        парень и предложил помощь, я вежливо сообщил, что помощь не требуется. Однако, парень всё равно
                        помог с заменой колеса, и попрощавшись уехал. В Ереван мы приехали ближе к полуночи. Следующий
                        день было решено отдать под починку колеса. Ну а через день нам надо было «покончить» с Севаном,
                        и мы отправились к Араму в Арегуни, по дороге заехав в Мартуни.</p>
                    <p>Эта дорога огибала Севан с западной стороны, и у южной его части совершенно преображалась. По её
                        краям росли преимущественно хвойные деревья за которыми слева виднелась сияющая лазурь озера.
                        Приближаясь к Арегуни мы познали истинную широту Севана, именно здесь, как нигде он напоминал
                        море. А ведь именно так часто армяне называют свой Севан. На трассе у поворота на Арегуни нас
                        уже ждал Арам, человек свободно владеющий русским и английским языками, начитанный и
                        эрудированный, он тоже был боевиком во времена острой фазы армяно-азербайджанского конфликта в
                        начале девяностых. Теперь он фермер, ведёт своё хозяйство с которым он нас с удовольствием
                        познакомил. Арам похвастался также местным климатом, рассказав, что здесь в Арегуни растёт даже
                        миндаль, а всё прочее, что скажем в Араратской долине уже отплодоносило, здесь только начинает
                        давать урожай. Более богатый в растительном плане край в Армении только в Арцахе (так называют
                        армяне Карабах), отметил Арам. Вообще из всех мест в Армении где мы побывали за время этого
                        путешествия, здесь было как-то наиболее спокойно и душевно, по моей субъективной эмоциональной
                        оценке.</p>
                    <p>По возвращению в Ереван мы стали строить планы на наши дальнейшие передвижения. И следующей на
                        очереди у нас была недальняя поездка в Гарни. Мы ехали к Амалии, девушке живущей в небольшом
                        посёлке не доезжая нескольких километров до Гарни. Этот посёлок в советские времена был известен
                        тем, что выпускал некоторые детали для космической промышленности. Причём создан он был как
                        экспериментальная база для одного армянского конструктора, который и разработал эти детали. Но с
                        развалом СССР, естественно, всё это оказалось не востребовано и пришло в запустение. Посёлок
                        этот стоит в красивом месте на краю ущелья, на дне которого течёт река Азат.</p>
                    <img src={arm2015_014} alt="Ущелье реки Азат" className="img-fluid travel__img"/>
                    <p>Амалия - идейная девушка, она гордиться что была дружна с главным конструктором завода при его
                        жизни, а теперь хочет превратить своё родное село в эко-посёлок, с применением современных
                        природосберегающих технологий. Сама Амалия веб-разработчик и работает дома, поэтому у неё
                        остаётся время для воплощения своих идей. Живёт Амалия вроде бы в типовом двухэтажном доме, на
                        несколько квартир на подъезд, однако у каждого такого дома в место двора - сад, в котором каждый
                        житель этого дома может что-то посадить, да и просто провести время.</p>
                    <img src={arm2015_014_1} alt="Сад Амалии" className="img-fluid travel__img"/>
                    <p>С Амалией мы отправились сначала в деревню Гарни, где она привела нас в дом своей бабушки, с
                        прекрасным садом и старым тундыром, для выпечки лаваша и прочих сдобных изделий. А затем мы
                        отправились в ущелье реки Азат, к преддверию Касахского заповедника, чтобы посмотреть на
                        «каменный орган». Так называются скалы из опускающихся базальтовых труб имеющих вид правильных
                        шестиугольников. Завораживающее и очень красивое зрелище.</p>
                    <img src={arm2015_015} alt="Ущелье реки Азат" className="img-fluid travel__img"/>
                    <p>После ущелья мы вернулись в деревню, чтобы купить знаменитой гарнинской гаты - традиционной
                        сладкой выпечки. А затем, мы посетили дом местного художника, бережно сохранившего старину
                        своего отчего дома, а скорее даже очага с естественной вытяжкой в виде отверстия в кровле. Этот
                        очаг он хранит как музей, дом же в котором живёт его семья сейчас нам показала его жена
                        Рузанна. </p>
                    <img src={arm2015_016} alt="Дом художника" className="img-fluid travel__img"/>
                    <p>Это по настоящему со вкусом убранный дом, в котором отдельный этаж занимает художественная
                        мастерская, достопримечательностью которой, помимо картин, является огромная коллекция виниловых
                        пластинок, которые регулярно крутятся здесь уже на современном проигрывателе. Вид отсюда
                        открывается прямо на древний храм Гарни, одну из главных достопримечательностей Армении.</p>
                    <img src={arm2015_017} alt="Дом художника" className="img-fluid travel__img"/>
                    <p>Следующая наша поездка совмещала сразу два направления, и была самой дальней и длительной. Для
                        начала мы ехали к Татеву, в деревню Алидзор, чтобы найти там нужных нам людей, добрых, открытых,
                        весёлых, любящих свою страну и готовых поделиться этой любовью с гостями. А затем, мы должны
                        были отправиться в Арцах (Нагорно-Карабахскую республику), но обо всём по порядку.</p>
                    <p>Для начала мы выехали из Еревана, и через Араратскую долину отправились на юг преодолевать
                        многочисленные перевалы. Перевал в области Вайоц-Дзор, был особенно хорош, со снежными пятнами
                        на вершинах, он был прекрасен и свеж, своим холодным и таким чистым воздухом. Спустившись с него
                        мы достигли области Сюник, куда нам было и надо.</p>
                    <img src={arm2015_018} alt="Перевал перед Сюником" className="img-fluid travel__img"/>
                    <p>Доехав то канатной дороги, мы не упустили возможности ещё раз на ней прокатиться, и в который раз
                        полюбоваться на один из самых величественных монастырей Армении. После посещения которого, мы
                        зашли в рядом стоящее кафе, где разговорились с владельцем, и тот предложил свозить нас на своём
                        раритетном УАЗ-69 на смотровую точку, откуда открывается чудесный вид на монастырь. И
                        действительно вид отсюда открывается чудесный.</p>
                    <img src={arm2015_021} alt="Татев" className="img-fluid travel__img"/>
                    <p>Мы попрощались с нашим новым знакомым, и по канатной дороге вернулись к машине. Затем мы
                        отправились в Алидзор, где нам нужно было посетить пару домов. После чего мы отправились к
                        конечной точке нашего сегодняшнего путешествия, в местечко названное Арснадзор, в гости к его
                        хозяину Гарику. Мы знали, что места здесь везде живописные, но такого мы и сами не ожидали
                        увидеть. За символической деревянной оградкой располагалась зона отдыха, с беседками, клумбами и
                        даже клеткой с павлином, а на самом краю ущелья, стояли маленькие деревянные домики, больше
                        напоминавшие бочки. И в каждом было окно с видом на ..., нет не с видом, просто с самим ущельем
                        реки Воротан. Потрясающе живописно. И нам предстояло здесь переночевать. В каждом домике было по
                        2-3 спальных места, всё за исключением розеток и матрасов было сделано из дерева. И, честно
                        говоря, я никогда ещё не спал в более экзотическом помещении. Гарик угостил нас сытным ужином, и
                        быстро стемнело. Мы пошли отдыхать. Завтра по плану нам предстоял переезд в Арцах.</p>
                    <img src={arm2015_019} alt="Арснадзор" className="img-fluid travel__img"/>
                    <p>На следующий день, хорошенько выспавшись и позавтракав в беседке с захватывающим видом на ущелье
                        мы, попрощавшись с персоналом отправились в путь. Для начала мы решили заехать в находящееся
                        неподалёку местечко под названием Сатани-Камурдж или Чёртов мост. Это место представляет собой
                        выточенный водами реки Вортан небольшой скальный массив, превращённый течением в природный мост.
                        Я уже не раз бывал в этом месте, но всё никак не мог обследовать его целиком, ведь здесь помимо
                        собственно природного моста, можно было увидеть и небольшой грот, со стекающими по сталактитам
                        каплями минеральной воды. В этот раз я был полон решимости найти-таки проход в это место.</p>
                    <p>Подъехав к месту, мы прошли каменные ванночки с целебными водами для желающих искупаться в них и
                        подошли к месту спуска к руслу реки. Спускаться не высоко, но делать это нужно по отвесной
                        скале. Специально для этой цели здесь закреплён канат, эту процедуру я уже проделывал в прошлом
                        году, но попрыгав по нескольким очень скользким валунам находящихся в реке, так и не понял где
                        был вход в грот. В этот раз спустившись по канату я встретил внизу трёх человек, закатывающих
                        брюки: один местный житель, и двое иностранцев, как потом выяснялось голландцев, мужчина и
                        женщина. Женщина даже пыталась говорить по-русски. Армянин тут же ошарашил меня вопросом, куда я
                        собрался? И получив ответ, сказал что мне туда одному нельзя, мол течение сейчас очень сильное.
                        Он сказал что он охранник, хотя на самом деле был просто проводником берущим деньги с любопытных
                        туристов. Он озвучил ценник в 1000 драм, и я сразу согласился. Между прочим, скажу, что без его
                        помощи я вряд ли бы догадался где был лаз в грот, а без этого знания, у меня были все шансы быть
                        унесённым течением. Мы шли по камням, потом по мелким местам реки, которые знал наш проводник.
                        Вода была холодная, ноги просто-таки сводило. Далее мелкие места кончались, о чём кстати никто
                        из нас бы не догадался, проводник сказал, что глубина дальше была более 3 метров. Тут мы вылезли
                        из реки и встали рядом с небольшим углублением в скале, в котором был низкий лаз, высотой около
                        50 см. Там необходимо было немного проползти, и оказаться в месте в которым мы и хотели
                        оказаться. Здесь вода была уже тёплая, так что можно было погреть ноги, а также насладиться
                        причудливым видом места с видом на бурлящую реку.</p>
                    <img src={arm2015_019_2} alt="Сатани-Камурдж" className="img-fluid travel__img"/>
                    <p>Сделав несколько снимков на телефон, я начал возвращаться назад. Голландцы стоя у подъёмного
                        каната одевались. Я разговорился с мужчиной, и тот узнав, что я из Челябинска немало удивился и
                        заинтересовался. Сказал, что год назад уже планировал путешествие в наш край, но что-то не
                        срослось. Начал спрашивать, что можно посмотреть у нас в области.</p>
                    <p>После исполнения моего давнего желания (осмотра грота Сатани-Камурдж), мы выдвинулись из Татева в
                        сторону Нагорно-Карабахской республики. Постепенно горы вдоль дороги сдали зеленее,
                        растительности стало больше, а дорога превратилась почти в идеально ровную. Наконец, мы достигли
                        границы с Арцахом. Она представляла из себя небольшой пост, и несколько лежачих полицейских на
                        дороге. Нас никто не попытался остановить, просто у дороги никого не было, но мы решили
                        остановиться сами, чтобы расспросить, что нужно для выезда, т.к. слышали, что в Степанакерте
                        необходимо будет поставить отметку в МИДе. Нас попросили предоставить свои паспорта, и выдали по
                        бумажке, в которую необходимо было потом поставить отметку в Степанакерте, после чего мы
                        продолжили свой путь. Проехав Шуши мы постепенно спускались к Степанакерту - столице Карабаха,
                        где нас должен был встретить человек по имени Михак. Только въехав в город мы остановились у
                        обочины, чтобы позвонить Михаку, и уточнить куда нам подъехать, и тут поняли, что наши телефоны
                        здесь попросту не ловили. Оказывается сотовые операторы разделяли Армению с Арцахом роумингом,
                        который ещё надо было подключать. И как только мы поняли, что никому дозвониться мы не сможем, с
                        нами поравнялся чёрный Опель, и водитель окликнул нас, это был Михак. Вообще наш новый знакомый
                        был военнослужащим, а ныне работал в местном МЧС.</p>
                    <p>Мы поехали за ним, Михак должен был помочь нам с жильём в Степанакерте, и мы сразу поехали
                        смотреть такое место. Первое, которое мы посмотрели нам не особо понравилось, но у нашего
                        провожатого было в запасе для нас ещё одно, которое находилось за городом. Он сказал, что оно
                        получше первого и мы заочно согласились на него, а пока будучи в Степанакерте мы решили немного
                        перекусить и заодно познакомится. По дороге Михак остановился, чтобы купить Жингялов Хац - это
                        фирменное карабахское блюдо, представляющее собой лепёшки с различной зеленью, говорят её там с
                        десяток видов. Мы заняли беседку в кафе у знакомого Михака, и повели разговор. Казалось, наш
                        новый знакомый был просто таки олицетворением всех мужских добродетелей, он был очень адекватен
                        и аккуратен в своих суждениях, даже по отношению к своим оппонентам, было видно, что он понимает
                        цену слова, и у него оно дорого стоит, это само по себе внушает доверие и безмерное уважение.
                        Вообще у меня сложилось стойкое впечатление, что я как-будто бы знаю Михака очень давно, и самое
                        интересное, что он сообщил мне тоже самое.</p>
                    <p>Надо отдельно сказать, про местный жингалов-хац, в Татеве мы уже пробовали его, и он нам не очень
                        понравился, но местный арцахский это совсем другое дело, он был очень хорош. Проведя прекрасный
                        вечер, в отличной компании, наметив планы на ближайшие пару дней, мы отправились на место нашей
                        ночёвки. Наш сталкер, сопроводил нас до маленькой частной гостиницы в 10-15 км от города, где мы
                        и заночевали.</p>
                    <p>На следующее утро у нас были большие планы, т.к. было 9 мая - в Карабахе это двойной праздник,
                        день победы в Великой отечественной войне, и день освобождения Шуши в 1992 году. Мы отправились
                        на площадь Степанакерта, где уже начинался парад. Наряду с военнослужащими, в параде активнейшее
                        участие принимали дети в военной форме.</p>
                    <img src={arm2015_022} alt="Степенакерт. Парад Победы." className="img-fluid travel__img"/>
                    <p>Затем процессия из местных жителей, официальных лиц и детей с огромным флагом Нагорного Карабаха
                        отправилась к мемориальному комплексу и кладбищу героям Карабахской войны. На улицах просто в
                        воздухе стояло единение и всеобщая радость, которая передавалась я думаю всем гостям столицы,
                        среди которых было множество иностранных журналистов (в основном европейского вида) и простых
                        гражданских лиц с разных частей света. </p>
                    <img src={arm2015_024} alt="Степенакерт. Парад Победы." className="img-fluid travel__img"/>
                    <p>Дети там и тут озвучивали какие-то весёлые кричалки, пели песни. В небе кружил самолёт с флагом,
                        в общем царила атмосфера очень важного для людей праздника.</p>
                    <img src={arm2015_023} alt="Степенакерт. Парад Победы." className="img-fluid travel__img"/>
                    <p>Возложив цветы к мемориалу, мы отправились на кладбище героев, там мы заметили седого старичка -
                        музыканта, который расчехлил свою скрипку и с большим чувством принялся играть какую-то
                        протяжную и очень проникновенную мелодию. Вокруг него сразу столпились журналисты и простые
                        зеваки. Но музыкант на это и рассчитывал, он играл как будто вёл проповедь, двигаясь между
                        могильными плитами. В завершении дедушка не сдержался, опустил смычок и заплакал. Всё это
                        выглядело очень драматично, почти театрально, хотя и не срежиссировано.</p>
                    <img src={arm2015_024_2} alt="Проповедь музыканта" className="img-fluid travel__img"/>
                    <p>После столь сильных чувств, мы вернулись к площади чтобы немного перевести дух и перекусить, на
                        веранде у отеля «Армения». После чего нам предстояла встреча с Михаком, с которым мы должны были
                        отправиться в Шуши.</p>
                    <img src={arm2015_025} alt="Отель Армения" className="img-fluid travel__img"/>
                    <p>Быстро перекусив, мы поняли, что у нас есть ещё немного времени, и решили съездить к памятнику
                        «Татик у Папик», что в переводе означает - бабушка и дедушка. Этот памятник был установлен здесь
                        в советское время, как памятник долгожителям, ведь именно в Нагорном-Карабахе тогда проживало
                        рекордное в Союзе число людей старше 100 лет.</p>
                    <img src={arm2015_026} alt="Татик у Папик" className="img-fluid travel__img"/>
                    <p>Через час мы встретились с Михаком и его другом, и все вместе отправились в Шуши, в гости в Саро.
                        Саро живёт в Шуши и бережно хранит старину своего дома. Он с неохотой ставит новые двери и окна,
                        и только в случаях крайней необходимости. Он считает кощунственным замену старых предметов на
                        новые, т.к. они несут дух эпохи в которой были сделаны, являются связующими звеньями с
                        накопленным жизненным опытом, просто овеществлённой памятью, делающей богаче их владельца и всех
                        окружающих. Для человека живущего в современном городе «без истории», такая позиция может
                        показаться паранойей, но для людей уважающих традиции, это прямое общение с предками через их
                        вещи. Саро, также как Арам из Арегуни владел английским, был очень эрудирован и влюблён в землю
                        своего проживания. Он устроил нам экскурсию по своему дому и саду, где главной
                        достопримечательностью был старый орешник, которому, видимо, была не одна сотня лет. У него даже
                        было своё имя - «Тортилла».</p>
                    <img src={arm2015_027} alt="Дом Саро" className="img-fluid travel__img"/>
                    <p>Погостив у Саро и обсудив с ним все интересующие нас вопросы, мы поехали к шушинской церкви имени
                        Святого Христа Всеспасителя, которая выделялась из всех армянских церквей тем, что была
                        построена из белого камня.</p>
                    <img src={arm2015_028} alt="Церковь в Шуши" className="img-fluid travel__img"/>
                    <p>Посетив храм, мы вернулись в Степанакерт, и встретившись с ещё одним нужным человеком, заглянули
                        в одну таверну. Ну, не могли мы ещё раз не попробовать знаменитого местного жингалов-хац. И
                        попробовали, а затем отправились в нашу гостиницу. Приехав туда уже затемно, мы не обнаружили
                        там никого, однако, дверь был отперта, печь натоплена, а у входа лежал ключ от гаража, куда мы
                        ставили свой автомобиль в прошлую ночь. Представьте придорожный мотель, одиноко стоящий на
                        отшибе, без охраны, незапертый ночью, спокойно дожидающийся своих постояльцев. В Арцахе это
                        возможно.</p>
                    <p>На следующий день мы планировали покинуть Карабах, но с утра решили посетить знаменитую церковь
                        Гандзасар, известную тем, что по преданию в её алтаре была захоронена голова Иоана Крестителя.
                        Но всё это было торопях, т.к. уже по дороге нам позвонил Михак, и сообщил, что МИД, где мы
                        должны были поставить отметки в бумагах выданных нам на границе, в тот день работал до 13-00.
                        Быстро осмотрев Гандзасар мы поспешили в Степанакерт.</p>
                    <img src={arm2015_029} alt="Гандзасар" className="img-fluid travel__img"/>
                    <p>В столице человек Михака проводил нас МИДа, где мы заполнили анкеты, и поставили отметки. После
                        чего мы позвонили Михаку и поблагодарили этого щедрого безотказного человека, который возился с
                        нами 3 дня, по доброте своей души. Затем мы отправились в обратный путь, в Ереван.</p>
                    <img src={arm2015_030} alt="Арцах" className="img-fluid travel__img"/>
                    <p>По пути назад, после города Шуши трасса встретила нас просто непроглядным туманом. Горный
                        серпантин просматривался буквально на пару метров во все стороны. С таким плотным туманом я
                        никогда не встречался. Но видимо, здесь такие не редкость. На обратном пути мы сделали всего
                        одну остановку в Горисе, где перекусили. Не поздним вечером, мы были в Ереване. Ещё одно
                        направление нами было освоено, и нам оставалась всего одна дальняя поездка в регион Лори, регион
                        богатый своими монастырями, и конечно природными красотами. Об этом подробнее ниже...</p>
                    <p>Итак, после приезда из Карабаха, мы пару дней побыли в Ереване, после чего отправились на север
                        Армении в регион Лори, где в Одзуне нас ждал священник Тер-Ованес, который обещал показать нам
                        церковь, и пару гостевых домиков для наших туристических дел. Мы ехали через Дилижан, затем по
                        дороге через село с красноречивым названием Фиолетово, здесь был участок очень плохой дороги, и
                        мы отметили, что хорошо бы не оказаться здесь после захода солнца по пути назад.
                    </p>
                    <p>Мы проезжали через реку Дебед, приток знаменитой Куры, и остановились полюбоваться на бурлящий
                        поток и подышать горным воздухом.</p>
                    <img src={arm2015_031} alt="Река Дебед" className="img-fluid travel__img"/>
                    <p>Вскоре мы были в Одзуне. В церкви нас встретил Тер-Ованес, большой Человек с душой ребёнка.
                        Вообще образ священника, лично у меня не вязался, со столь самоироничной и простой личностью
                        какой был Тер-Ованес. Он показал нам монастырь, который к слову считается одним из древнейших в
                        Армении, а стало быть и в мире. Рассказ его был подробным, он знал про каждый камушек и каждый
                        остаток барельефа в своей церкви, кроме того он продемонстрировал нам акустические возможности
                        помещения, исполнив несколько строчек церковного произведения.</p>
                    <img src={arm2015_032} alt="Одзун" className="img-fluid travel__img"/>
                    <p>После этого Тер-Ованес пригласил нас к себе домой, он сел в белую Ниву и сказал следовать за ним,
                        но долго ехать не пришлось, его дом находился рядом с монастырём. Он познакомил нас со своей
                        женой и двумя детьми, которых попросил выступить перед гостями. Дочь спела церковную песню, а
                        сын прочитал стихи. Затем мы поехали с ним посмотреть несколько домов в Одзуне, которые в
                        дальнейшем могли бы принимать гостей. Тер-Ованес с охотой провёл нас по таким местам в Одзуне,
                        зная о своей фотогеничности постоянно просил сфотографировать его и сделать общее фото на
                        память. Я признаться впервые общался с таким весёлым и простодушным священником, и честно
                        говоря, мне показалось это правильно, когда человек занимающийся своей и общественной
                        духовностью бодр духом и жизнерадостен, заряжая остальных своим оптимизмом и умением жить.
                        Именно таким и должен быть священник, чтобы люди тянулись к нему и подспудно получали какие-то
                        знания о вечном, через открытое общение с добрым, простым человеком.</p>
                    <img src={arm2015_033} alt="Тер-Ованес и я" className="img-fluid travel__img"/>
                    <p>Попрощавшись с Тер-Ованесом мы решили, что будучи в Лори мы не можем не посетить также монастыри
                        Ахпат и Ахтала, и поехали к ним. Доехав до Ахпата пошёл сильный дождь, что делало невозможным
                        нормальный осмотр монастыря. Мы зашли в расположенное рядом с сувенирными рядами возле монастыря
                        помещение, которое оказалось небольшой частной гостиницей, однако, выразив желание поесть с
                        дороги, мы нашли правильный отклик со стороны хозяев. Они накрыли нам стол из простых
                        традиционных продуктов которые у них были. А мы были этому несказанно рады. Пообедав мы решили,
                        что подробный осмотр Ахпата отменяется из за непрекращающегося дождя, а в Ахталу мы уже попросту
                        не успевали, т.к. время было уже около половины шестого вечера, тем более я уже бывал в этих
                        местах, а проехать плохой участок трассы возле Фиолетово хотелось засветло. Мы отправились в
                        обратный путь.</p>
                    <img src={arm2015_034} alt="Ахпат" className="img-fluid travel__img"/>
                    <p>Погодные условия не давали ехать быстро, в итоге мы достигли злополучного участка уже в сумерках,
                        но слава Богу, на этот раз обошлось без приключений. Остановившись в Дилижане, чтобы прикупить
                        чего-нибудь сладкого для засыпающего мозга, мы стали подниматься по серпантину на выход из
                        Тавушского марза, и тут мы поняли что-такое нулевая видимость на самом деле. Вокруг уже давно
                        стемнело, а в горах стоял плотный туман. Поскольку мы двигались по серпантину, то и никаких
                        встречных огней нам не было видно, в общем было весело. Но на подъезде к тунелю, означающему
                        конец региона Тавуш погода сжалилась и видимость немного прояснилась. Дальше дорога уже по
                        хорошей широкой дороге до Еревана. Мы на месте.</p>
                    <p>Всё, основные направления которые мы запланировали, освоены. Остались некоторые дела в Ереване и
                        поездка на фестиваль Долмы (армянское традиционное блюдо) в Сардарапат, но это недалеко. Когда
                        дело дошло до этого мы взяли с собой одну знакомую с тремя детьми, которая хотела побывать в
                        Сардарапате, а тут такая оказия. В общем мы двигались через Вагаршапат, где свернули немного не
                        там, в итоге навигатор повёл нас закаулками, но вскоре вывел на трассу. Правда нам нужно было
                        свернуть налево, а между ограждениями разделяющими стороны дороги виднелись две сплошные линии
                        разметки. Ехать искать разворот было неохота, тем более, что дорога была совершенно пустая. Я
                        решил повернуть с нарушением правил, и как водится в тот же момент увидел движущуюся нам на
                        встречу из за поворота полицейскую машину. Сначала была надежда, что блюстители не заметили
                        моего манёвра, но тут же полицейский автомобиль заморгал нам фарами, и включил проблесковый
                        маячок. Я остановился на обочине, полицейские остановились позади. Я вышел из машины один из
                        полицейских подал руку, чтобы поздороваться. Вообще, надо сказать, что только в Армении (по
                        крайней-мере я встречал только здесь) полицейские при исполнении подают руку для приветствия.
                        Сотрудник спросил меня где я проживаю, на что я ответил, что я здесь в гостях. Тогда меня
                        попросили назвать адрес моих родственников, где я проживал. Я был в недоумении, тогда мне
                        пояснили, что когда я оплачу штраф, права мне придут по почте на указанный адрес. Такие проблемы
                        с вызволением прав накануне отъезда в Россию ну никак не входили в мои планы, поэтому я начал
                        ссылаться на то, что права вообще-то изымаются обычно по решению суда, и что мне скоро уезжать.
                        И тут из машины мне на помощь вышли девушки, поулыбавшись полицейским, и рассказав о цели нашего
                        визита в Армению, а именно знакомство всего мира с культурой Армении и её замечательными людьми.
                        Все эти речи подействовали на мужчин предсказуемо, и они вернув мне права пожелали нам
                        счастливого пути.</p>
                    <p>До меня только тогда дошло, что все эти камеры на дорогах Армении вообще-то не имели на меня
                        никакого действия, т.к. присылать штраф мне было попросту некуда. То же касалось и платных
                        парковок в центре Еревана. Когда мы однажды пытались заплатить за такую парковку, то связавшись
                        с оператором и задав ему вопрос о том, как правильно ввести российский регистрационный номер,
                        получили ответ, что по смс оплатить за иностранный номер невозможно и необходимо оплатить за
                        парковку в любом банке. Тогда мы не поленились, дошли до ближайшего банка, но там нам сказали,
                        что оплатить можно только за месяц, а это нам естественно было не нужно. Тогда меня посетило
                        сомнение, что с иностранцев спросу за это не будет, это и подтвердилось при выезде из страны. Но
                        об этом позже.</p>
                    <img src={arm2015_035} alt="Сардарапат" className="img-fluid travel__img"/>
                    <p>Мы доехали до Сардарапата, припарковавшись у монумента созданного в память о сражении, которое
                        проходило здесь в 1918 году (в этом сражении армянские войска остановили наступающую турецкую
                        армию, сохранив тем самым остаток родины от полного уничтожения). Однако, никакого фестиваля мы
                        не увидели, и вообще народу здесь почти не было. В любом случае, в наши планы входило осмотреть
                        местный музей, что мы с удовольствием и сделали. После чего, сделав пару звонков, мы выяснили,
                        что фестиваль проходит не в Сардарапате а в Мусалере, на самом выезде из Еревана. Мы поспешили
                        обратно.</p>
                    <img src={arm2015_036} alt="Фестваль долмы в Мусалере" className="img-fluid travel__img"/>
                    <p>К сожалению, мы успели почти к самому концу фестиваля и не продегустировали блюда всех
                        участников, хотя это скорее всего было невозможно. Кроме того, мы хотели поговорить с поварами с
                        целью привлечения их к некоторым идеям связанным с гастрономическим туризмом, но толком не
                        успели это сделать. Однако, с одним поваром мы всё таки пообщались, это был как раз победитель
                        этого фестиваля.</p>
                    <img src={arm2015_037} alt="Победитель фестваля долмы в Мусалере"
                         className="img-fluid travel__img"/>
                    <p>Немного перекусив уже поостывшей долмы, мы вернулись в Ереван. Эта поездка была последней перед
                        нашим отъездом из Армении. Через пару дней я в одиночку пущусь в обратный путь до Челябинска. А
                        пока, последние покупки перед отъездом, спешное прощание с родственниками.</p>
                    <p>Мой отпуск подходит к концу, мне пора уезжать, но мне ещё необходимо заехать в город Гавар, для
                        того, чтобы навестить родственников моей жены. Я решаю, что возвращаться в Ереван уже не буду,
                        теперь с каждым днём я буду всё севернее и севернее, пока не приеду домой.</p>
                    <p>В Гаваре я встречаю родственников, и вместе мы пытаемся найти мне попутчика в Россию. Но увы, все
                        кто собирался ехать, уже купили билеты на самолёт. Исполнив свою миссию в Гаваре, я еду по
                        направлению к Грузинской границе. Снова Дилижан, Иджеван и даллее вдоль границы с Азербайджаном
                        на север.</p>
                    <img src={arm2015_037_2} alt="На границе с Азербайджаном" className="img-fluid travel__img"/>
                    <p> К вечеру прибываю в Ноемберян. Решаю, заночевать здесь, чтобы завтра засветло пройти Грузию, и
                        заночевать уже на Российской территории. Судя по всему гостиница в Ноемберяне одна, по крайней
                        мере мне таки сказали местные. Еду туда, сторговываюсь за 5000 драм, за номер без удобств и
                        завтрака. Меня здесь всё устраивает, чистый номер с хорошим Wi-Fi, к тому же выбора у меня всё
                        равно нет. Ужинаю тем, что мне положили с собой гаварские родственники, вспоминаю их добрым
                        словом.</p>
                    <img src={arm2015_037_3} alt="Ужин в Ноемберянской гостинице" className="img-fluid travel__img"/>
                    <p>Кстати, те деньги, что я отдал за номер были по сути были последними моими армянскими деньгами,
                        кроме тех на которые я с утра планировал заправиться. Наличных рублей у меня тоже не было.
                        Оставались лишь немного свободной мелочи, на которую я вечером купил себе воды в дорогу. Узнав
                        куда я еду, работник отеля дал мне ещё одну бутылку в подарок. В общем в Грузии я
                        останавливаться нигде не планировал, и надеялся, что проеду её без проблем. Дальше была пугающая
                        Осетия, и учитывая оскудевшие запасы на карточке, меня она немного волновала.</p>
                    <p>Рано утром я выселился из номера, заехал на стоящую рядом заправку и двинулся к границе в
                        Садахло. Максимум через полчаса я был там. Я помнил, что на выезде необходимо было оставить
                        комиссионный взнос брокеру, которому я платил за ввоз машины. Заранее отложенные 5000 драм
                        оставляю ему и прохожу границу.</p>
                    <p>Двигаюсь по Грузии, оказывается до Тбилиси всего 100 км, которые я прохожу чуть больше чем за
                        час. В Грузии похоже и вправду скоростной режим на трассах никто не соблюдает, я еду с потоком.
                        Памятуя о том, что по дороге в Ереван в столице Грузии мы заплутали я еду по другому навигатору
                        с картами OSM. И приложив немного интуиции выезжаю из Тбилиси без особых проблем. Выезжаю на
                        скоростной хай-вей, но тут внезапно замечаю, что навигатор проложил мой маршрут до Владикавказа
                        через Цхинвал. О боже, мне туда совсем не надо! На автомагистрали установлены указатели на
                        Батуми, Поти. Это на западе страны, а навигатор предательски говорит, что мне нужно свернуть
                        направо. Да он показывает на Цхинвал, но это направление всё равно более верное, чем направление
                        к Чёрному морю. И тут как раз съезд с трассы, я поворачиваю. Но вскоре понимаю, что эта дорога
                        ведёт обратно в Тбилиси. Долго ищу разворот и снова выхожу на хай-вей. Вижу на обочине
                        патрульную машину, здесь на автомагистрали останавливаться нельзя, но я памятуя о том, как в
                        прошлом году полицейские в Грузии любезно сопроводили нас с мигалками до нужного пути когда мы
                        заблудились, решаю остановиться и спросить дорогу. Не успеваю я выйти из машины, как из чёрного
                        патрульного Форда доносится в мегафон: «На РоссиУ налЭво!». Я же вижу, что с автомагистрали есть
                        съезд только направо, куда я в прошлый раз и повернул, попав снова в Тбилиси, и хочу подойти и
                        всё-таки уточнить правильную дорогу. Видя это мне в мегафон кричат: «Не подходи! На РоссиУ
                        налЭво!». Тут я понимаю, что налево мне говорят потому, чтобы я не свернул направо, а трасса
                        действительно впереди уходит левее. Я прыгаю в машину, и быстро уезжаю.</p>
                    <p>Двигаюсь дальше по автомагистрали и буквально через несколько километров замечаю указатель на
                        Владикавказ. Вот теперь можно свернуть направо, что я и делаю. Эта дорога ведёт прямо к границе
                        с Россией, и двигаясь по ней я вскоре там оказываюсь уже на Кавказских горах.</p>
                    <img src={arm2015_037_4} alt="Кавказские горы" className="img-fluid travel__img"/>
                    <p>Здесь активно тает снежный покров, дорога местами по прежнему не восстановлена.</p>
                    <img src={arm2015_038} alt="Кавказские горы" className="img-fluid travel__img"/>
                    <p> Добираюсь до границы. Грузинский КПП прохожу быстро и без проблем, как всегда. Далее едем к
                        российскому КПП. Перед ним выстроилась огромная очередь из фур, но легковушки объезжают её по
                        встречной. Дорога извилистая и идёт вдоль скалы, у последнего поворота перед КПП необходимо
                        встроится обратно в свою полосу. Стоим, ждём. Грузовики пропускают легковые машины в общую
                        очередь по одной. Где-то через полчаса я прохожу досмотр авто и проверку документов. Всё можно
                        ехать. </p>
                    <p>Начинаю спускаться с гор, и проехав буквально пару километров по Осетии меня останавливает
                        полиция. Всё приехали, думаю я! Оказывается я забыл включить ближний свет фар. Месяц
                        передвигаясь по Закавказью я совсем забыл про это правило. Сотрудник мне говорит: «Давай 500
                        рублей и проезжай!». Но денег у меня нет, т.к. до банкомата я просто не успел доехать. Я
                        объясняю это инспектору, и тот помявшись меня отпускает.
                    </p>
                    <p>Решаю задаться сразу на Элисту, чтобы навигатор мог предложить мне максимально «прямую» дорогу. В
                        результате я заезжаю во Владикавказ с другой дороги, минуя центр. Замечаю по пути торговый
                        комплекс, и направляюсь туда, чтобы снять наличных денег. Снимаю специально с разменом, понимая,
                        что придётся «кормить» дармоедов. Еду дальше по заданному маршруту и постепенно выезжаю из
                        города. Маршрут совершенно не пересекается с тем по которому мы ехали по пути в Армению. Еду по
                        трассе на Ростов, дорога широкая и довольно загруженная. Встречаю первый пункт ГИБДД, но, о
                        радость, меня не останавливают! Спустя какое-то время по пути встречается ещё один, но и тут
                        загруженность трассы такова, что на меня не обращают внимания. Я проехал ещё какое-то количество
                        километров, недоумевая, когда же будет пост на границе республики, но о чудо, вскоре увидел
                        таблицу возвещающую о том, что я двигаюсь уже по территории Карачаево-Черкесии. Будучи здесь
                        впервые, я не знал, чего ожидать от местных дорог. Но характер трассы и поведение ГИБДД
                        оставалось прежним, с самих Кавказских гор меня так никто и не остановил, и я не отдал ни одного
                        рубля в качестве штрафов и магарычей. </p>
                    <p>Двигаясь по Карачаево-Черкесии я всё хотел найти взглядом Эльбрус, но так ничего и не увидел.
                        Несмотря на свои размеры, он видимо, находился слишком далеко от трассы по которой я двигался.
                        Постепенно я доехал до Ставропольского края и проехал Пятигорск и Минводы. Здесь я посетил
                        местный «Магнит» на предмет чего-нибудь перекусить. Вообще, надо сказать, что фуры «Магнита»
                        были моим постоянным спутником, на протяжении всего пути. Всюду от самой Армении и до Челябинска
                        я регулярно встречал транспорт этого ритейлера (привет Галицкому ☺). Пожалуй, ни одна сеть так
                        не распространена на территории России и СНГ как Магнит. В любом городе или даже крупном селе
                        есть её магазины. Используя её базу можно было бы, наверное, какие-нибудь стратегические задачи
                        решать.</p>
                    <p>После Минеральных Вод я проехал ещё немного, до Александровского - это село административный
                        центр района. И решил искать ночлега здесь, т.к. оставался примерно час до заката. Я нашёл адрес
                        гостиницы по Google Maps, и направился по нему. Гостиница занимала половину этажа в
                        административном здании. За 1200 рублей я занял номер без удобств и завтрака. В номере особого
                        ремонта не было, зато была раковина, что было большим плюсом. Я сходил в соседний магазин за
                        продуктами и перекусив лёг отдыхать.</p>
                    <p>Утром следующего дня я, заехав на АЗС в Александровском, я отправился в путь. Пока дороги были
                        ещё не загружены я выбрался из Ставрополья, и постепенно вышел на ту самую разбитую дорогу до
                        Элисты. Но при свете солнца обошлось без неприятностей. Немного не доехав до столицы Калмыкии,
                        рядом с трассой я увидел буддийский хурул, и не мог не остановиться. Я зашёл на территорию,
                        здесь никого не было, кроме нескольких работников, облагораживающих территорию. Я подошёл к
                        ступам. Здесь всё было обвязано разноцветными тряпочками с изображениями Будды и строчками из
                        Дхаммапады. Очень красиво. Затем я подошёл к самому храму и покрутил молитвенные барабаны.
                        Калмыки исповедают тибетский буддизм, их духовным лидером является Далай-Лама. В сам храм я
                        зайти не рискнул, т.к. не знал местных обычаев, а прихожан и туристов рядом с храмом не было,
                        поэтому решил двигаться дальше.</p>
                    <img src={arm2015_039} alt="Калмыцкий хурул" className="img-fluid travel__img"/>
                    <p>Двигаясь к выходу я увидел, покидающего территорию и идущего по направлению к трассе молодого
                        монаха. Причем судя по цвету кожи, явно не местного. Монах дошёл до трассы и встал на обочине. Я
                        же сев в машину предложил подвезти его, он согласился. Монаха звали Саду, и он был родом из
                        далёкой Шри-Ланки. Он учился здесь в Элисте и видимо служил в этом храме. Выяснилось, что у него
                        на родине исповедуют другой вид буддизма - Тхареваду. Однако, видимо, это не было препятствием
                        для него, и это здорово, что данная религия освобождена от подобного рода условностей. Мы
                        доехали до Элисты и обменялись контактами. Я спросил у Саду, как проехать к центральному хурулу
                        в Элисте, он сказал, что этот хурул является самым большим буддийским храмом в Европе, чем меня
                        ещё больше заинтриговал.</p>
                    <img src={arm2015_040} alt="Монах Саду" className="img-fluid travel__img"/>
                    <p>Распрощавшись с моим новым знакомым, я поехал к Хурулу. Это был действительно внушительных
                        размеров храм, стоящий на большой ухоженной территории, с клумбами и фонтанами. Подле храма
                        также располагалось несколько статуй и стоек с молитвенными барабанами. Всё очень красиво и для
                        меня необычно. Посетителей в храме было много, причём большинство было именно местных жителей,
                        хотя были и туристы, а также простые приезжие типа меня. Недалеко от входа на территорию храма
                        стояла группа местных детишек, молодая женщина - руководитель группы, рассказывала им о
                        традициях калмыцкого народа, о том как важно знать и понимать свою историю, чтить корни. Всё это
                        меня немало порадовало.
                    </p>
                    <img src={arm2015_042} alt="Центральный хурул в Элисте" className="img-fluid travel__img"/>
                    <p>Я немного прошёлся по территории и сделал пару снимков. Ко мне подошла женщина и вручив мне флаер
                        предложила посетить ресторан калмыцкой кухни, который видимо располагался неподалёку. Меня это
                        действительно заинтересовало, и если бы у меня было побольше времени и хорошая компания, я бы с
                        большим удовольствием познакомился с местной кухней.</p>
                    <img src={arm2015_041} alt="Центральный хурул в Элисте" className="img-fluid travel__img"/>
                    <p>Немного изучив территорию, я вошёл внутрь храма. В холле прихожане должны снять обувь и выключить
                        свои телефоны. Съёмка в храме также запрещена. Из холла дверь вела в огромный зал с низкими
                        скамьями, за которыми собственно находился алтарь за невысоким ограждением. В центре алтаря
                        находилась позолоченная статуя сидящего Будды, довольно больших размеров, у прохода за
                        ограждение был портрет Далай-Ламы. Обход храма осуществляется по часовой стрелке. В центре зала
                        стоял монах, мимо которого проходили прихожане двигавшиеся к алтарю. Монах с благодушной улыбкой
                        возлагал свои ладони на каждого, судя по всему раздавая что-то вроде благословений.</p>
                    <p>Вообще, надо сказать, что я находился внутри буддийского храма впервые и меня буквально очаровала
                        та светлая, радушная и не гнетущая атмосфера царившая в нём. Теперь мне стало понятно почему
                        храм посещает так много людей. Народ идёт сюда просто за зарядом хорошего настроения и теплоты
                        царящей здесь. В хуруле не делают различий между людьми разных культур и вероисповеданий, он
                        открыт для всех и это подкупает.</p>
                    <p>Вдохновлённый выйдя из храма я направился на выезд из города, и вскоре достиг той самой «прямой
                        как стрела» дороги соединяющей Калмыкию с Волгоградской областью. Здесь можно было немножко
                        притопить, пристроившись за каким-нибудь местным водителем.</p>
                    <p>На одном из участков все местные посворачивали с трассы и я какое-то время ехал один. И тут меня
                        обогнал белый Volkswagen Jetta, какое же было моё удивление когда взглянув на номерной знак я
                        увидел цифры родного 74-го региона. Здесь за 2000 км от дома это был первый челябинский
                        автомобиль который я встретил на своём пути. Судя по всему водитель Джетты пользовался
                        антирадаром и я решил за ним не отставать. Так мы ехали почти до самого Волгограда, где
                        Volkswagen свернул на заправку. У меня же бензин ещё был, и я решил дотянуть до города и
                        поискать там заправку с магазином и удобствами. Заправившись в Волгограде и немного перекусив я
                        продолжил движение. В центре города, двигаясь по той самой прямой улице длинной в 30 км, я снова
                        встретил своего земляка, правда на одном из светофоров снова потерял его, и вновь двигался
                        «один».</p>
                    <p>Постепенно Волгоград закончился, затем закончилась и Волгоградская область и я уже ехал по
                        Саратовской. Время было уже около пяти вечера, и я решил, что через часок буду присматривать
                        себе стоянку. Доехав до городка Камышин, я заметил один отельчик и решил расспросить о ценах.
                        Остановившись на парковке возле кафешки при отеле, я увидел ту самую Джетту. Ну значит нам
                        судьба ехать вместе! Оплатив 1500 руб. за номер, я спустился переставить машину во двор
                        гостиницы. И тут встретил Вадима, того самого водителя Джетты. Он шёл ко мне с радостной
                        улыбкой, как будто встретил закадычного товарища. Как выяснялось он едет из Махачкалы, где был
                        по работе. Сюда заехал перекусить, но узнав, что я уже снял здесь номер, последовал моему
                        примеру. Мы договорились, что завтра продолжим путь вместе.
                    </p>
                    <img src={arm2015_043} alt="Встреча земляков" className="img-fluid travel__img"/>
                    <p>На следующий день, встав пораньше мы выехали из города Камышин. Поскакав на неровных дорогах
                        Саратовской губернии, мы въехали в Самарскую, которая уже за табличкой о своём начале встретила
                        нас более ровным асфальтом. За Самарской мелкий кусочек Оренбургской области, и почти такой же
                        небольшой участок по Татарстану. После чего мы выходим на финишную прямую по Башкирии. Здесь
                        дорога отличная, а участок близ Уфы и вовсе фееричные 4 полосы в каждом направлении. К этому
                        времени уже смеркалось, но такая дорога взбодрила нас, позволив немного надавить на педаль.</p>
                    <p>Ночлег мы нашли уже за Уфой, в гостиничном комплексе «Серебряный рожок», имеющим свою немаленькую
                        территорию. За отличный двухместный номер мы отдали 2700 рублей на двоих. Правда почему-то опять
                        без завтрака.</p>
                    <img src={arm2015_044} alt="ГК Серебряный рожок" className="img-fluid travel__img"/>
                    <p>На следующее утро мы продолжили штурмовать финишную прямую, быстро выехали из Башкирии, а дальше
                        переехали Уральский хребет и ещё до вечера были дома. Вадим жил со мной в одном районе, поэтому
                        мы ехали вместе до самого моего дома, где мы с ним распрощались. Это было долгое путешествие
                        после которого было очень приятно отдохнуть. Однако, движение затягивает, и я ещё неделю после
                        своего прибытия просыпаясь утром испытывал желание куда-нибудь выдвинуться. Эта жажда новых
                        открытий, новых путешествий ведёт человека по жизни, ведь пока он не стоит на месте он
                        чувствует, что живёт, по-настоящему.</p>
                </div>
            </div>
        </div>
    );
};

export default BigArmenia;
import React from 'react';
import  './Main.css';
import ava from '../../assets/img/ava_edit.png';

const Main = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <img src={ava} alt="Автор сайта" className="main__photoAva img-fluid" />
                </div>
                <div className="col-md-8 main__textCol">
                    <h1>Это личная страница Артёма Аршакяна</h1>
                    <p className="main__p">
                        В&nbsp;разные времена, моими увлечениями были баскетбол, музыка и&nbsp;веб-разработка. А&nbsp;постоянным интересом
                        является культура, которую я&nbsp;замечаю вокруг и&nbsp;присваиваю себе то&nbsp;что нравится из&nbsp;неё.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Main;
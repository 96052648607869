import React from 'react';
import elbrus2021_01 from '../../../images/elbrus2021/elbrus2021_01.jpg'
import elbrus2021_03 from '../../../images/elbrus2021/elbrus2021_03.jpg'
import elbrus2021_04 from '../../../images/elbrus2021/elbrus2021_04.jpg'
import elbrus2021_05 from '../../../images/elbrus2021/elbrus2021_05.jpg'
import elbrus2021_06 from '../../../images/elbrus2021/elbrus2021_06.jpg'
import elbrus2021_07 from '../../../images/elbrus2021/elbrus2021_07.jpg'
import elbrus2021_08 from '../../../images/elbrus2021/elbrus2021_08.jpg'
import elbrus2021_09 from '../../../images/elbrus2021/elbrus2021_09.jpg'
import elbrus2021_10 from '../../../images/elbrus2021/elbrus2021_10.jpg'
import elbrus2021_11 from '../../../images/elbrus2021/elbrus2021_11.jpg'
import elbrus2021_12 from '../../../images/elbrus2021/elbrus2021_12.jpg'
import elbrus2021_13 from '../../../images/elbrus2021/elbrus2021_13.jpg'
import elbrus2021_14 from '../../../images/elbrus2021/elbrus2021_14.jpg'
import elbrus2021_15 from '../../../images/elbrus2021/elbrus2021_15.jpg'
import elbrus2021_16 from '../../../images/elbrus2021/elbrus2021_16.jpg'
import elbrus2021_17 from '../../../images/elbrus2021/elbrus2021_17.jpg'
import elbrus2021_18 from '../../../images/elbrus2021/elbrus2021_18.jpg'
import elbrus2021_19 from '../../../images/elbrus2021/elbrus2021_19.jpg'
import elbrus2021_20 from '../../../images/elbrus2021/elbrus2021_20.jpg'
import elbrus2021_21 from '../../../images/elbrus2021/elbrus2021_21.jpg'
import elbrus2021_22 from '../../../images/elbrus2021/elbrus2021_22.jpg'
import elbrus2021_23 from '../../../images/elbrus2021/elbrus2021_23.jpg'
import elbrus2021_24 from '../../../images/elbrus2021/elbrus2021_24.jpg'
import elbrus2021_25 from '../../../images/elbrus2021/elbrus2021_25.jpg'
import elbrus2021_26 from '../../../images/elbrus2021/elbrus2021_26.jpg'
import elbrus2021_27 from '../../../images/elbrus2021/elbrus2021_27.jpg'
import elbrus2021_28 from '../../../images/elbrus2021/elbrus2021_28.jpg'
import elbrus2021_29 from '../../../images/elbrus2021/elbrus2021_29.jpg'

const Elbrus2021 = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">

                    <p>
                        В начале ноября 2021 года, мы с женой решили съездить на несколько дней на Кавказ, в
                        Приэльбрусье.
                        Для начала прилетели в аэропорт Минвод, и сразу на такси в Железноводск, для того,
                        чтобы забрать от туда наш персональный транспорт на эти дни. И забрав машину, тут же
                        отправились в Кабардино-Балкарскую республику по дороге с сторону Эльбруса.
                    </p>
                    <p>
                        Но в этот день увидеть высочайший пик Европы нам было не суждено, небо заволокло
                        тяжёлыми облаками.
                    </p>

                    <img src={elbrus2021_01} className="img-fluid travel__img" loading="lazy" alt="Приэльбрусье"/>

                    <p>
                        Тем не менее мы проехали дальше на юг к водопадам Джилы-Су. Некоторые уклоны по пути впечатляют.
                    </p>

                    <img src={elbrus2021_03} className="img-fluid travel__img" loading="lazy" alt="Приэльбрусье"/>

                    <p>
                        Упёршись в конец автодороги, мы оставили машину и прошли до самого первого водопада. Если я не
                        напутал с его названием это был Сылтран-Су.
                    </p>

                    <img src={elbrus2021_04} className="img-fluid travel__img" loading="lazy" alt="Приэльбрусье"/>

                    <p>Полюбовавшись немного на окрестности мы отправились на ночлег в Кисловодск.</p>

                    <div id="elbrus_1" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#elbrus_1" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#elbrus_1" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={elbrus2021_05} className="d-block w-100" alt="Приэльбрусье"/>
                            </div>
                            <div className="carousel-item">
                                <img src={elbrus2021_06} className="d-block w-100" alt="Приэльбрусье"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#elbrus_1"
                                data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#elbrus_1"
                                data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        На следующее утро мы направились в Карачаево-Черкесскую республику на горнолыжный курорт
                        Домбай.
                    </p>

                    <img src={elbrus2021_07} className="img-fluid travel__img" loading="lazy" alt="Карачево-Черкесия"/>

                    <p>
                        По пути исследуем заповедные тропы.
                    </p>

                    <div id="kchr_1" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#kchr_1" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#kchr_1" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#kchr_1" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={elbrus2021_08} className="d-block w-100" alt="Карачаево-Черкесия"/>
                            </div>
                            <div className="carousel-item">
                                <img src={elbrus2021_09} className="d-block w-100" alt="Карачаево-Черкесия"/>
                            </div>
                            <div className="carousel-item">
                                <img src={elbrus2021_10} className="d-block w-100" alt="Карачаево-Черкесия"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#kchr_1" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#kchr_1" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Приближаемся к месту назначения. Начинается красота.
                    </p>

                    <div id="dombay_1" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#dombay_1" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#dombay_1" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={elbrus2021_11} className="d-block w-100" alt="Домбай"/>
                            </div>
                            <div className="carousel-item">
                                <img src={elbrus2021_12} className="d-block w-100" alt="Домбай"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#dombay_1" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#dombay_1" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Паркуемся (приготовте деньги). И покупаем билеты на местную канатку.
                        Она многоуровневая. Подъём начинается с 1600 м над уровем моря, а максимально
                        можно подняться на 3170 м.
                    </p>
                    <p>
                        Если вы не горнолыжник, то наверху можно поглазеть на окрестные горы, пофоткаться,
                        покушать в многочисленном не самом качественном и не дешёвом общепите и купить какую-нибудь
                        безделицу.
                    </p>

                    <div id="dombay_2" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#dombay_2" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#dombay_2" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#dombay_2" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#dombay_2" data-bs-slide-to="3"
                                    aria-label="Slide 4"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={elbrus2021_13} className="d-block w-100" alt="Домбай"/>
                            </div>
                            <div className="carousel-item">
                                <img src={elbrus2021_14} className="d-block w-100" alt="Домбай"/>
                            </div>
                            <div className="carousel-item">
                                <img src={elbrus2021_15} className="d-block w-100" alt="Домбай"/>
                            </div>
                            <div className="carousel-item">
                                <img src={elbrus2021_16} className="d-block w-100" alt="Домбай"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#dombay_2" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#dombay_2" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        На обратном пути заворачиваем в местный заповедник. Вот тут красота.
                        И вода и горы и лес. Нравится.
                    </p>

                    <div id="dombay_3" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#dombay_3" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#dombay_3" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#dombay_3" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={elbrus2021_17} className="d-block w-100" alt="Домбай"/>
                            </div>
                            <div className="carousel-item">
                                <img src={elbrus2021_18} className="d-block w-100" alt="Домбай"/>
                            </div>
                            <div className="carousel-item">
                                <img src={elbrus2021_19} className="d-block w-100" alt="Домбай"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#dombay_3" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#dombay_3" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Стемнело. Возвращаясь в&nbsp;Кисловодск, попадаем в&nbsp;такой плотный туман, что в&nbsp;двух метрах от&nbsp;капота не&nbsp;видно
                        вообще ничего. Нет мне реально есть с&nbsp;чем сравнивать, я&nbsp;видел туман на&nbsp;горных серпантинах
                        по&nbsp;всему Кавказу и&nbsp;Закавказью, но&nbsp;хуже я&nbsp;ещё не&nbsp;видел. При этом дорога относительно прямая, но
                        скорость потока довольна высокая 65-80&nbsp;км/ч. Местные дорогу знают наизусть, а&nbsp;мне она не&nbsp;знакома и
                        не&nbsp;видна поэтому страшно. С&nbsp;облегчением добираемся до&nbsp;Кисловодска, спим.
                    </p>

                    <p>
                        На следующее утро погода стоит. Решаем снова двигаться в Джилы-Су.
                        И не зря. Виды открываются. Эльбрус виден.
                    </p>

                    <div id="elbrus_2" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#elbrus_2" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#elbrus_2" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#elbrus_2" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={elbrus2021_20} className="d-block w-100" alt="Эльбрус"/>
                            </div>
                            <div className="carousel-item">
                                <img src={elbrus2021_21} className="d-block w-100" alt="Эльбрус"/>
                            </div>
                            <div className="carousel-item">
                                <img src={elbrus2021_22} className="d-block w-100" alt="Эльбрус"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#elbrus_2" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#elbrus_2" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        В этот раз идём на другой водопад. Каракая-Су. На скале рядом с водопадом отчётливо
                        виднеется голова "пришельца".
                    </p>

                    <div id="karakaya" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#karakaya" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#karakaya" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={elbrus2021_23} className="d-block w-100" alt="Каракая-Су"/>
                            </div>
                            <div className="carousel-item">
                                <img src={elbrus2021_24} className="d-block w-100" alt="Каракая-Су"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#karakaya" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#karakaya" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        В финале прогулялись по Кисловодску. Покушали в ресторане. Испили водички.
                    </p>

                    <div id="kislovodsk" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#kislovodsk" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#kislovodsk" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#kislovodsk" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#kislovodsk" data-bs-slide-to="3"
                                    aria-label="Slide 4"></button>
                        </div>
                        <div className="carousel-inner travel__img">
                            <div className="carousel-item active">
                                <img src={elbrus2021_25} className="d-block w-100" alt="Кисловодск"/>
                            </div>
                            <div className="carousel-item">
                                <img src={elbrus2021_26} className="d-block w-100" alt="Кисловодск"/>
                            </div>
                            <div className="carousel-item">
                                <img src={elbrus2021_27} className="d-block w-100" alt="Кисловодск"/>
                            </div>
                            <div className="carousel-item">
                                <img src={elbrus2021_28} className="d-block w-100" alt="Кисловодск"/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#kislovodsk" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#kislovodsk" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <p>
                        Да, а я говорил, что мы посетили в Кисловодске армянскую церьковь? В общем тут
                        и говорить не о чем...
                    </p>

                    <img src={elbrus2021_29} className="img-fluid travel__img" loading="lazy" alt="Армянская церьковь в Кисловодске"/>

                    <p>
                        Далее в опять в Железноводск, сдали машину, убили время до отлёта, и на такси в аэропорт
                        Минвод. Тут всё рядом. В целом неплохой отдых.
                    </p>

                </div>
            </div>
        </div>
    );
};

export default Elbrus2021;